/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceAttendanceStatistics: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M873.39008 161.25952h-68.8128V73.97376c0-18.08384-14.62272-32.68608-32.768-32.91136h-2.39616c-18.2272 0-32.9728 14.82752-32.9728 33.03424V161.3824H291.16416V73.97376c0-18.18624-14.7456-32.91136-32.9728-33.01376h-2.41664c-18.2272 0-33.0752 14.70464-33.0752 33.01376v87.28576h-71.8848A109.7728 109.7728 0 0 0 40.96 270.68416v602.9312A109.71136 109.71136 0 0 0 150.8352 983.04H873.2672A109.7728 109.7728 0 0 0 983.04 873.51296v-602.7264c0-60.47744-49.02912-109.40416-109.64992-109.52704z m-93.7984 416.82944l-301.11744 300.4416c-8.51968 8.3968-19.98848 13.18912-32.01024 13.4144a43.52 43.52 0 0 1-30.78144-12.75904l-163.18464-162.59072a43.52 43.52 0 0 1 1.2288-60.25216 43.74528 43.74528 0 0 1 60.37504-1.20832l132.58752 132.3008 271.29856-270.80704a43.74528 43.74528 0 0 1 60.37504 1.20832 43.6224 43.6224 0 0 1 1.2288 60.25216z m126.01344-175.55456H120.46336v-151.1424H905.6256v151.1424z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceAttendanceStatistics.defaultProps = {
  size: 18,
};

export default IconAHumanResourceAttendanceStatistics;
