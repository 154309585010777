/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialReceiptsMyReceipts: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M942.08 40.96a81.92 81.92 0 0 1 81.92 81.92l0.04096 532.6848a267.61216 267.61216 0 0 0-36.63872-45.44512c-26.05056-26.09152-55.35744-45.54752-87.92064-58.368-32.5632-12.86144-65.9456-19.27168-100.1472-19.27168-34.2016 0-67.6864 6.41024-100.4544 19.2512s-62.17728 32.29696-88.22784 58.38848c-26.07104 26.09152-45.60896 55.43936-58.63424 88.04352A267.9808 267.9808 0 0 0 532.48 798.4128c0 34.24256 6.51264 67.66592 19.53792 100.27008 13.02528 32.60416 32.5632 61.93152 58.63424 88.02304L607.08864 983.04H81.92a81.92 81.92 0 0 1-81.92-81.92V122.88a81.92 81.92 0 0 1 81.92-81.92h860.16zM456.2944 255.26272a40.96 40.96 0 0 0-57.67168 5.24288L327.68 345.57952l-70.94272-85.07392-3.52256-3.74784a40.96 40.96 0 0 0-59.392 56.17664L240.2304 368.64H204.8l-4.77184 0.26624A40.96 40.96 0 0 0 204.8 450.56h81.89952v40.96H204.8l-4.77184 0.26624A40.96 40.96 0 0 0 204.8 573.44h81.89952L286.72 655.36l0.26624 4.77184A40.96 40.96 0 0 0 368.64 655.36l-0.02048-81.92H450.56l4.77184-0.26624A40.96 40.96 0 0 0 450.56 491.52h-81.94048v-40.96H450.56l4.77184-0.26624A40.96 40.96 0 0 0 450.56 368.64h-35.45088l46.42816-55.7056 3.072-4.13696a40.96 40.96 0 0 0-8.31488-53.53472z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M798.72 573.44c124.416 0 225.28 100.864 225.28 225.28s-100.864 225.28-225.28 225.28-225.28-100.864-225.28-225.28 100.864-225.28 225.28-225.28z m0.04096 81.92c-32.19456 0-58.30656 28.13952-58.30656 62.85312 0 28.63104 17.7152 52.75648 41.984 60.33408-59.94496 8.66304-106.5984 64.36864-106.5984 131.2768v0.8192c0 10.81344 1.2288 21.34016 3.50208 31.4368H918.1184A141.96736 141.96736 0 0 0 921.6 910.66368v-0.8192c0.04096-66.92864-46.55104-122.63424-106.496-131.29728 24.2688-7.63904 41.984-31.76448 41.984-60.33408 0-34.7136-26.112-62.85312-58.32704-62.85312z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconAFinancialReceiptsMyReceipts.defaultProps = {
  size: 18,
};

export default IconAFinancialReceiptsMyReceipts;
