/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconProjectExperience: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M750.848 120.49066667H668.064c0.832 4.224 1.344 8.512 1.344 12.832 0 32.48-24.736 64.48-54.208 64.48h-13.792c3.264-5.824 5.664-12.256 5.664-19.552 0-21.344-16.288-38.592-36.704-38.592h-166.24c-20.384 0-36.672 17.28-36.672 38.592 0 7.296 2.4 13.728 5.728 19.552h-12.288c-31.008 0-55.808-31.968-55.808-64.48 0.032-4.32 0.512-8.608 1.344-12.832H222.688c-67.328 0-83.872 36.32-83.872 88.736v519.104c0 56.32 23.68 87.808 88.992 87.808h518.016c65.28 0 97.024-24.704 97.024-87.776V209.22666667c-0.064-52.384-29.76-88.736-92-88.736zM305.056 654.95466667h-11.392c-13.28 0-26.496-15.488-26.496-28.128 0-12.608 11.2-23.392 24.48-23.392h10.368c13.248 0 24.48 9.824 25.472 23.392 0 12.64-9.152 28.128-22.432 28.128z m1.472-128.832h-11.424c-13.28 0-21.728-13.568-21.728-26.24 0-12.64 11.232-25.28 24.48-25.28h10.368c13.28 0 24.48 11.712 25.472 25.28 0.032 12.672-13.92 26.24-27.168 26.24z m0-139.904h-11.424c-13.28 0-21.728-13.44-21.728-25.92 0-12.512 11.232-24.992 24.48-24.992h10.368c13.28 0 24.48 11.584 25.472 24.992 0.032 12.48-13.92 25.92-27.168 25.92z m366.24 268.736h-290.24c-13.28 0-26.496-15.488-26.496-28.128 0-12.608 11.2-23.392 24.48-23.392h289.216c13.248 0 24.48 9.824 25.472 23.392 0 12.64-9.152 28.128-22.432 28.128z m1.472-128.832H384c-13.28 0-21.728-13.568-21.728-26.24 0-12.64 11.2-25.28 24.48-25.28h289.216c13.28 0 24.48 11.712 25.472 25.28 0.032 12.672-13.952 26.24-27.2 26.24z m0-139.904H384c-13.28 0-21.728-13.44-21.728-25.92 0-12.512 11.2-24.992 24.48-24.992h289.216c13.28 0 24.48 11.584 25.472 24.992 0.032 12.48-13.952 25.92-27.2 25.92z"
        fill={getIconColor(color, 0, '#2c2c2c')}
      />
    </svg>
  );
};

IconProjectExperience.defaultProps = {
  size: 18,
};

export default IconProjectExperience;
