/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconA34Woderizhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M903.04 85.333333H174.72a35.626667 35.626667 0 0 0-35.626667 35.626667v187.946667h-18.133333a35.626667 35.626667 0 1 0 0 71.04h18.133333v264.106666h-18.133333a35.626667 35.626667 0 1 0 0 71.04h18.133333v187.52A35.626667 35.626667 0 0 0 174.293333 938.666667h728.746667A35.626667 35.626667 0 0 0 938.666667 903.04V120.96A35.626667 35.626667 0 0 0 903.04 85.333333z m-35.413333 782.293334H210.346667v-152.533334h18.346666a35.626667 35.626667 0 1 0 0-71.04h-18.346666V379.946667h18.346666a35.626667 35.626667 0 1 0 0-71.04h-18.346666V156.373333h657.28z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M336.213333 358.4h351.573334a35.626667 35.626667 0 0 0 0-71.253333H336.213333a35.626667 35.626667 0 0 0 0 71.253333z m0 190.72h351.573334a35.626667 35.626667 0 0 0 0-71.253333H336.213333a35.626667 35.626667 0 0 0 0 71.253333z m0 187.733333h351.573334a35.626667 35.626667 0 0 0 0-71.253333H336.213333a35.626667 35.626667 0 0 0 0 71.253333z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconA34Woderizhi.defaultProps = {
  size: 18,
};

export default IconA34Woderizhi;
