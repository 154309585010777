/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTianchongxing: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M943.9 215.9c-2.1 7.6-6.5 14.7-13 20.1L511.8 587.8c-0.2 0.2-0.4 0.4-0.7 0.5-16.4 13.2-40.3 11.6-54.8-3.3L106.7 292.4c-17.2-14.4-19.5-40.3-5.1-57.6 14.4-17.2 40.3-19.5 57.6-5.1L485.6 503l392.7-329.6c5.1-4.3 10.9-7.1 17-8.5-6.5-1.9-13.3-3-20.4-3H93.3c-39.1 0-71.1 32-71.1 71.1v592.5c0 39.1 32 71.1 71.1 71.1h567.3c-43.7-41.3-71-99.7-71-164.6 0-125 101.3-226.3 226.3-226.3 48.4 0 93.3 15.2 130.1 41.1V233c0-5.9-0.7-11.6-2.1-17.1z"
        fill={getIconColor(color, 0, '#242424')}
      />
      <path
        d="M819.3 735.3m-188.4 0a188.4 188.4 0 1 0 376.8 0 188.4 188.4 0 1 0-376.8 0Z"
        fill={getIconColor(color, 1, '#242424')}
      />
      <path
        d="M915.9 692.4H810.6l8.7-8.7c16-16 16-41.9 0-57.9s-41.9-16-57.9 0L685 702.1c-8.1 8.1-12.1 18.9-12 29.6-0.2 10.7 3.8 21.4 12 29.6l76.4 76.4c16 16 41.9 16 57.9 0s16-41.9 0-57.9l-5.5-5.5h102.1c22.6 0 41-18.3 41-41 0-22.6-18.3-40.9-41-40.9z"
        fill={getIconColor(color, 2, '#FFFFFF')}
      />
    </svg>
  );
};

IconTianchongxing.defaultProps = {
  size: 18,
};

export default IconTianchongxing;
