/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconNaviconLymxtj: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M896 956.928H126.464c-14.336 0-25.6-11.264-25.6-25.6V313.856c0-14.336 11.264-25.6 25.6-25.6H896c14.336 0 25.6 11.264 25.6 25.6v617.472c0 14.336-11.264 25.6-25.6 25.6z m-743.936-51.2H870.4V339.456H152.064v566.272z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M979.456 336.896H43.52c-14.336 0-25.6-11.264-25.6-25.6V112.64c0-14.336 11.264-25.6 25.6-25.6h935.936c14.336 0 25.6 11.264 25.6 25.6v198.656c0 14.336-11.264 25.6-25.6 25.6z m-910.336-51.2h884.736V138.24H69.12v147.456zM717.824 506.88H303.616c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h414.208c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6zM717.824 647.168H303.616c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h414.208c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6zM717.824 793.088H303.616c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h414.208c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconNaviconLymxtj.defaultProps = {
  size: 18,
};

export default IconNaviconLymxtj;
