/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconKaifaguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M1024 230.4V170.6752C1024 119.4752 978.2784 76.8 923.4176 76.8H100.608C45.7216 76.8 0 119.4752 0 170.6752V230.4h1024zM0 281.6v558.848C0 898.7136 45.7216 947.2 100.5824 947.2H923.392C978.2784 947.2 1024 898.688 1024 840.448V281.6H0z m321.8176 448.3072a35.7376 35.7376 0 0 1 0 51.5072 35.9936 35.9936 0 0 1-25.6 11.0592c-9.1392 0-18.2784-3.6864-25.6-11.0592l-135.296-136.1664a35.7376 35.7376 0 0 1 0-51.5328l135.296-136.1664c14.6432-14.72 36.5824-14.72 51.2 0a35.7376 35.7376 0 0 1 0 51.5328l-109.696 110.4128 109.696 110.4128z m268.8-257.6384L504.6784 766.72a36.736 36.736 0 0 1-34.7392 25.7792c-3.6608 0-7.3216 0-10.9568-1.8432-20.1216-5.5296-31.104-25.7792-25.6-46.0032l85.9392-294.4256c5.4784-20.2496 25.6-31.2832 45.696-25.7792 20.1216 7.3728 31.104 27.6224 25.6 47.872z m298.0608 172.9792l-135.296 136.192a35.9936 35.9936 0 0 1-25.6 11.008c-9.1648 0-18.304-3.6608-25.6-11.008a35.7376 35.7376 0 0 1 0-51.5328l109.696-110.4128-109.696-110.4128a35.7376 35.7376 0 0 1 0-51.5328c14.6176-14.72 36.5568-14.72 51.2 0l135.296 136.192c14.6432 12.8768 14.6432 36.7872 0 51.5072z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconKaifaguanli.defaultProps = {
  size: 18,
}

export default IconKaifaguanli
