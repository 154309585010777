/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconJihuarenwuguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M748.384 957.44c-106.672 0-193.12-85.888-193.12-191.888 0-105.968 86.448-191.904 193.12-191.904 106.64 0 193.12 85.92 193.12 191.888 0 106.016-86.48 191.904-193.12 191.904z m135.904-184.784a14.272 14.272 0 0 0-14.32-14.192h-100.16v-85.28a14.144 14.144 0 0 0-4.16-10.064 14.32 14.32 0 0 0-10.128-4.16h-14.304a14.24 14.24 0 0 0-14.288 14.208v113.728c0 7.84 6.384 14.208 14.288 14.208h128.752a14.272 14.272 0 0 0 14.32-14.208v-14.24z m-336.784-132.88H285.36a43.046 43.046 0 0 0-30.352 12.48 42.512 42.512 0 0 0-12.576 30.128v14.24a42.427 42.427 0 0 0 12.576 30.144 43.033 43.033 0 0 0 30.352 12.496h229.6c-0.32 4.736-0.72 9.424-0.72 14.208 0 61.888 26.688 117.44 69.024 156.384H242.432c-63.2 0-114.432-50.912-114.432-113.728v-554.4C128 178.912 179.248 128 242.432 128h329.04v85.28c0 62.832 51.232 113.744 114.432 113.744h114.432V552.64a214.784 214.784 0 0 0-71.52-12.4 214.496 214.496 0 0 0-181.312 99.52zM671.6 483.392a42.784 42.784 0 0 0-42.912-42.64H285.36c-11.376 0-22.304 4.464-30.352 12.48a42.512 42.512 0 0 0-12.576 30.144V497.6a42.371 42.371 0 0 0 12.576 30.144c8.048 8 18.976 12.48 30.352 12.48h343.344c11.36 0 22.288-4.48 30.336-12.464a42.48 42.48 0 0 0 12.56-30.144v-14.24z m100.16-199.008H643.024a28.796 28.796 0 0 1-20.256-8.32 28.352 28.352 0 0 1-8.4-20.128V128h42.912l143.04 127.92c0 7.568-2.992 14.8-8.352 20.128a28.654 28.654 0 0 1-20.224 8.32z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconJihuarenwuguanli.defaultProps = {
  size: 18,
}

export default IconJihuarenwuguanli
