/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationSerialNumber: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M880.31232 40.96c66.7648-1.1264 121.856 52.224 123.20768 119.31648v703.5904c-1.4336 67.05152-56.5248 120.34048-123.22816 119.15264H143.68768c-66.7648 1.14688-121.87648-52.24448-123.20768-119.37792V160.27648c1.37216-67.09248 56.46336-120.4224 123.20768-119.296h736.62464zM588.12416 402.59584l-10.97728 2.99008-190.54592 338.28864 3.072 11.10016 40.0384 22.89664 10.97728-3.072 190.58688-338.39104-3.072-11.03872-40.07936-22.77376z m-282.86976 22.1184l-11.32544 0.12288-159.744 163.34848 0.1024 11.38688 162.73408 160.70656 11.32544-0.1024 31.9488-32.70656-0.08192-11.50976-124.416-122.65472 122.10176-125.05088-0.1024-11.38688-32.54272-32.1536z m441.89696-3.95264l-11.32544 0.1024-31.86688 32.60416 0.1024 11.50976 1.6384 1.6384c12.9024 12.75904 97.6896 96.50176 118.1696 116.79744l2.80576 2.7648 0.90112 0.90112 0.75776 0.75776-122.10176 124.928 0.1024 11.40736 32.54272 32.13312 11.32544-0.1024 159.6416-163.47136-0.1024-11.38688-162.6112-160.5632z m189.72672-226.46784l-849.7152 8.192-7.3728 8.13056 0.36864 46.2848 7.51616 7.9872 849.7152-8.192 7.39328-8.13056-0.38912-46.2848-7.51616-7.9872z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationSerialNumber.defaultProps = {
  size: 18,
};

export default IconAAdministrationSerialNumber;
