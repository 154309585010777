/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconJishiqi1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.577043 113.775304c179.400348 0.111304 341.25913 109.857391 410.779827 278.505739 57.900522 140.53287 43.008 301.30087-39.691131 428.299131-82.67687 126.976-222.185739 203.330783-371.645217 203.419826h-0.623305C264.859826 1022.530783 66.626783 819.021913 66.782609 568.609391c0.155826-250.434783 198.656-453.676522 444.17113-454.834087zM445.217391 614.4c0 11.865043 4.496696 22.661565 11.865044 30.764522 8.147478 9.082435 19.745391 14.736696 32.656695 14.736695h177.574957A45.278609 45.278609 0 0 0 712.347826 614.4c0-25.154783-20.168348-45.501217-45.033739-45.501217L534.26087 568.876522v-135.991652c0-24.264348-18.16487-44.165565-41.182609-45.924174l-3.339131-0.111305c-24.598261 0-44.521739 20.613565-44.521739 46.035479zM766.820174 0.333913C871.735652 50.910609 960.934957 131.606261 1024 233.093565l-74.150957 50.754783c-53.960348-86.817391-130.226087-155.848348-220.026434-199.123478zM257.491478 0l36.886261 84.435478c-89.84487 43.186087-166.177391 112.172522-220.204522 198.945392L0 232.537043C63.176348 131.094261 152.442435 50.465391 257.469217 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconJishiqi1.defaultProps = {
  size: 18,
};

export default IconJishiqi1;
