/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Shipin: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M756.7872 10.24H217.68192c-41.00096 0.128-74.20416 33.53088-74.33216 74.77248v853.97504c0.128 41.2416 33.3312 74.6496 74.33216 74.77248h706.39104c40.99584-0.128 74.19904-33.53088 74.32704-74.77248V253.34784L756.7872 10.24z"
        fill={getIconColor(color, 0, '#F7E2E2')}
      />
      <path
        d="M756.7872 178.4576c0.12288 41.25184 33.32096 74.67008 74.32704 74.83392h167.22944L756.78208 10.24v168.2176z"
        fill={getIconColor(color, 1, '#F49292')}
      />
      <path
        d="M94.7968 625.11104h567.99744c46.1312 0 69.1968 23.20384 69.1968 69.61152v185.62048c0 46.40768-23.0656 69.61152-69.1968 69.61152H94.7968C48.6656 949.95456 25.6 926.75072 25.6 880.34304v-185.62048c0-46.40768 23.0656-69.61152 69.1968-69.61152z"
        fill={getIconColor(color, 2, '#F47F7F')}
      />
      <path
        d="M218.94656 900.3008a49.90976 49.90976 0 0 1-16.9472-40.60672 49.22368 49.22368 0 0 1 15.50848-38.8096c10.32192-9.1648 24.97024-13.7472 43.8272-13.7472h58.35264l2.36544 22.85568H261.504a39.68 39.68 0 0 0-26.17856 7.77216 29.79328 29.79328 0 0 0-9.20064 23.1424 29.7472 29.7472 0 0 0 11.56608 22.04672 55.9616 55.9616 0 0 0 32.80896 8.23808c11.91936 0.4608 23.82848-1.09056 35.23584-4.5824 8.36096-3.072 12.5696-7.3728 12.5696-12.93824l3.51744 23.20384a46.1312 46.1312 0 0 1-13.6704 9.68704 76.70272 76.70272 0 0 1-18.85184 5.80096 122.34752 122.34752 0 0 1-23.0656 2.03264c-20.224 0.1536-35.98336-4.54656-47.28832-14.09536z m99.35872-100.41344a45.8752 45.8752 0 0 0-11.53536-33.35168 43.03872 43.03872 0 0 0-32.4608-11.60192 75.76576 75.76576 0 0 0-24.56576 4.06016 70.96832 70.96832 0 0 0-21.33504 11.60192l-17.8176-12.2368a66.03264 66.03264 0 0 1 26.34752-18.85696 93.96736 93.96736 0 0 1 36.61824-6.9632c12.9024-0.37376 25.72288 2.28864 37.4272 7.77728a53.2992 53.2992 0 0 1 23.7568 22.50752 72.34048 72.34048 0 0 1 8.0128 35.49696v113.75104H318.3104v-112.1792z m168.20736-64.5632h25.94816L442.9824 912.0768h-19.49184l-69.1968-176.7424h25.89184l53.2224 145.01376 53.10976-145.01888z m47.57504-71.168h24.448v24.76544h-24.448v-24.77056z m0 70.9376h24.448v176.97792h-24.448v-176.97792z"
        fill={getIconColor(color, 3, '#FFFFFF')}
      />
      <path
        d="M289.99168 149.4528h334.45376v365.44512H289.9968z"
        fill={getIconColor(color, 4, '#F49292')}
      />
      <path
        d="M364.9536 294.47168h184.5248v75.41248H364.9536V294.47168z m0 110.21312h184.5248V480.1024H364.9536V404.6848z m0-220.42624h184.5248v75.41248H364.9536V184.25856z"
        fill={getIconColor(color, 5, '#F7E2E2')}
      />
    </svg>
 );
};

export default Shipin;

