/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseApplicationsMyApplyCases: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M859.52512 0H162.59072C106.41408 0 61.44 43.97056 61.44 98.9184v826.1632C61.44 980.02944 106.3936 1024 162.6112 1024h698.7776c56.2176 0 101.1712-43.97056 101.1712-98.9184V98.9184C962.56 43.97056 915.72224 0 859.52512 0zM484.84352 840.8064h-185.48736c-20.60288 0-37.4784-16.4864-37.4784-36.61824 0-20.15232 16.87552-36.6592 37.4784-36.6592h185.48736c20.60288 0 37.4784 16.50688 37.4784 36.6592 0 20.13184-16.87552 36.61824-37.4784 36.61824z m185.46688-217.98912c20.60288 0 37.4784 16.4864 37.4784 36.6592 0 20.13184-16.87552 36.61824-37.4784 36.61824H299.35616c-20.60288 0-37.4784-16.4864-37.4784-36.63872 0-20.15232 16.87552-36.63872 37.4784-36.63872H670.3104zM512 163.84c47.2064 0 85.48352 40.18176 85.48352 89.76384 0 41.1648-26.4192 75.91936-62.464 86.4256 79.54432 11.73504 140.75904 83.47648 140.82048 170.35264 0 7.4752-0.47104 14.88896-1.35168 22.09792H349.51168A184.7296 184.7296 0 0 1 348.16 510.3616c0-86.77376 61.27616-158.59712 140.84096-170.33216-36.0448-10.50624-62.48448-45.2608-62.48448-86.4256C426.51648 204.04224 464.7936 163.84 512 163.84z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseApplicationsMyApplyCases.defaultProps = {
  size: 18,
};

export default IconABusinessCaseApplicationsMyApplyCases;
