/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconBanbenfenfa: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 998.4C243.3792 998.4 25.6 780.6208 25.6 512S243.3792 25.6 512 25.6s486.4 217.7792 486.4 486.4-217.7792 486.4-486.4 486.4zM252.544 317.4912l214.3232 453.9648h89.088l215.3984-453.9648h-96.6656L512 683.5712l-162.688-366.08h-96.768z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconBanbenfenfa.defaultProps = {
  size: 18,
}

export default IconBanbenfenfa
