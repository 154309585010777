/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLeft: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M751.7696 4.1984c19.2 0 32 6.3488 44.7488 19.1488 25.6 25.6 19.2 63.9488-6.4 89.4976l-409.088 370.688c-12.8 12.8-19.1488 25.6-19.1488 38.4s6.4 25.6 19.1488 38.3488l415.488 351.5392c25.6 25.6 31.9488 63.9488 6.4 89.4976-25.6 25.6-63.8976 31.9488-89.4976 6.4l-409.088-351.5392c-38.3488-32-63.8976-83.0976-63.8976-134.2464 0-51.1488 19.1488-95.8976 57.4976-134.2464l409.088-370.688c12.8-6.4512 32-12.8 44.7488-12.8z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconLeft.defaultProps = {
  size: 18,
};

export default IconLeft;
