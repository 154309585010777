/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFaburen: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M720.568573 321.97085571c-9.26971435-101.96685791-92.69714356-180.75942993-199.29885865-180.75942993S331.24057007 220.00399781 317.33599854 317.33599854C215.36914062 335.87542724 141.21142578 419.30285644 141.21142578 525.90457153c0 115.87142945 92.69714356 208.568573 213.20343018 208.568573h106.60171508V544.44400024H312.70114136L512 331.24057007l199.29885864 208.568573H567.61828614v190.02914428h120.50628662c106.60171508-9.26971435 194.66400146-97.33200073 194.66400146-208.568573 0-92.69714356-69.52285766-176.12457276-162.22000122-199.29885864zM702.02914429 813.26571656H321.97085571c-18.53942872 0-32.44400024 13.90457153-32.44400024 32.44400024s13.90457153 37.07885742 32.44400024 37.07885742H697.39428711c18.53942872 0 32.44400024-13.90457153 32.44400024-32.44400024s-9.26971435-37.07885742-27.80914306-37.07885742z"
        fill={getIconColor(color, 0, '#BFCBD9')}
      />
    </svg>
  );
};

IconFaburen.defaultProps = {
  size: 18,
};

export default IconFaburen;
