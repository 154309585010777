/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconDown: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M4.181333 272.213333c0-19.2 6.4-32 19.2-44.757333 25.557333-25.557333 63.872-19.2 89.472 6.4l370.730667 409.088c12.8 12.8 25.6 19.2 38.357333 19.2 12.8 0 25.557333-6.4 38.357334-19.2l351.573333-415.488c25.557333-25.557333 63.872-31.957333 89.472-6.4 25.557333 25.6 31.957333 63.957333 6.4 89.514667l-351.573333 409.088c-32 38.357333-83.114667 63.914667-134.229334 63.914666-51.157333 0-95.872-19.2-134.229333-57.514666L16.938667 316.970667c-6.4-12.8-12.8-32-12.8-44.757334z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconDown.defaultProps = {
  size: 18,
};

export default IconDown;
