/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconUnlock: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M743.09375001 904.8125h-465.93750002c-47.2996875 0-85.78125001-38.4815625-85.78124999-85.78125001v-314.06249998c0-47.2996875 38.4815625-85.78125001 85.78125001-85.78125001h465.93749998c47.2996875 0 85.78125001 38.4815625 85.78125001 85.78125001v314.06249998c0 47.2996875-38.4815625 85.78125001-85.78125001 85.78125001z m-465.93750002-425.625c-14.21625001 0-25.78125001 11.565-25.78124999 25.78125001v314.06249998c0 14.2153125 11.565 25.78125001 25.78125001 25.78125001h465.93749998c14.2153125 0 25.78125001-11.5659375 25.78125001-25.78124999v-314.06250002c0-14.21625001-11.5659375-25.78125001-25.78124999-25.78124999h-465.93750002z"
        fill={getIconColor(color, 0, '#515151')}
      />
      <path
        d="M512.09375001 773.5625c-16.5684375 0-30-13.4315625-30.00000002-30V584.1865625c0-16.5684375 13.4315625-30 30.00000002-30 16.5684375 0 30 13.4315625 29.99999998 30V743.5625c0 16.5684375-13.4325 30-29.99999998 30zM310.88 450.6828125c-16.5684375 0-30-13.4315625-30-30 0-102.66 23.5565625-180.091875 70.01625001-230.143125 20.9146875-22.531875 46.3903125-39.40874999 75.71906249-50.16093751 25.4428125-9.328125 53.863125-14.0578125 84.4696875-14.05781249 60.2015625 0 107.295 13.15874999 143.9690625 40.2290625 20.72625001 15.2990625 38.0784375 35.2284375 51.57375 59.2340625 13.618125 24.2259375 23.724375 53.409375 30.0346875 86.7403125 3.0825 16.2796875-7.61625001 31.974375-23.895 35.0578125-16.2778125 3.0853125-31.974375-7.6153125-35.0578125-23.895-18.230625-96.2840625-68.0625-137.3653125-166.6246875-137.3653125-49.3734375 0-88.4728125 15.1528125-116.2125 45.0375-35.825625 38.5959375-53.990625 102.2934375-53.990625 189.32437499-0.001875 16.5675-13.4334375 29.9990625-30.001875 29.99906251z"
        fill={getIconColor(color, 1, '#515151')}
      />
    </svg>
  );
};

IconUnlock.defaultProps = {
  size: 18,
};

export default IconUnlock;
