/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Zantingjishi: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
        fill={getIconColor(color, 0, '#F97075')}
      />
      <path
        d="M332.8 256m64 0l0 0q64 0 64 64l0 384q0 64-64 64l0 0q-64 0-64-64l0-384q0-64 64-64Z"
        fill={getIconColor(color, 1, '#FFFFFF')}
      />
      <path
        d="M563.2 256m64 0l0 0q64 0 64 64l0 384q0 64-64 64l0 0q-64 0-64-64l0-384q0-64 64-64Z"
        fill={getIconColor(color, 2, '#FFFFFF')}
      />
    </svg>
 );
};

export default Zantingjishi;

