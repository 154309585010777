/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodewenshu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M279.373913 60.994783c-3.072 6.41113-3.072 12.844522-3.072 19.255652 0 43.497739 28.850087 86.973217 67.005217 89.733565l3.851131 0.155826h323.517217c36.953043 0 67.784348-44.966957 67.784348-89.889391 0-4.808348-1.736348-9.638957-2.604522-14.447305l-0.467478-4.808347h104.737391c78.202435 0 115.311304 45.879652 117.025392 116.75826l0.066782 5.209044v529.67513h-0.022261L957.217391 957.217391a66.782609 66.782609 0 0 1-66.782608 66.782609H177.708522c-78.246957 0-109.456696-42.874435-110.859131-116.646957L66.782609 902.010435V182.984348c0-69.053217 17.67513-119.696696 99.105391-121.922783l5.654261-0.066782h107.831652z m142.914783 428.989217h-109.857392c-20.925217 0-37.888 21.481739-37.888 47.994435 0 26.512696 16.962783 48.016696 37.888 48.016695h109.857392c20.925217 0 37.865739-21.504 37.865739-48.016695 0-26.490435-16.962783-47.994435-37.865739-47.994435z m117.426087-169.984h-227.283479c-20.925217 0-37.888 21.481739-37.888 47.994435 0 26.512696 16.962783 48.016696 37.888 48.016695h227.283479c20.925217 0 37.865739-21.504 37.865739-48.016695 0-26.490435-16.962783-47.994435-37.865739-47.994435zM624.461913 0c24.642783 0 46.213565 25.689043 46.213565 54.561391 0 32.100174-21.570783 54.583652-46.213565 54.583652h-231.067826c-24.665043 0-46.235826-25.689043-46.235826-54.583652 0-32.100174 21.570783-54.561391 46.213565-54.561391z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconWodewenshu.defaultProps = {
  size: 18,
};

export default IconWodewenshu;
