/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveWorkOverTimeMyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M798.72 552.96c124.416 0 225.28 100.864 225.28 225.28s-100.864 225.28-225.28 225.28-225.28-100.864-225.28-225.28 100.864-225.28 225.28-225.28zM455.92576 20.48c251.8016 0 455.92576 210.88256 455.92576 471.04 0 7.70048-0.16384 15.36-0.53248 22.97856A285.57312 285.57312 0 0 0 798.72 491.52c-158.35136 0-286.72 128.36864-286.72 286.72 0 63.65184 20.74624 122.4704 55.82848 170.02496a442.9824 442.9824 0 0 1-111.90272 14.29504C204.12416 962.56 0 751.67744 0 491.52S204.12416 20.48 455.92576 20.48z m342.8352 614.4c-32.19456 0-58.30656 28.13952-58.30656 62.85312 0 28.63104 17.7152 52.75648 41.984 60.33408-59.94496 8.66304-106.5984 64.36864-106.5984 131.2768v0.8192c0 10.81344 1.2288 21.34016 3.50208 31.4368H918.1184A141.96736 141.96736 0 0 0 921.6 890.18368v-0.8192c0.04096-66.92864-46.55104-122.63424-106.496-131.29728 24.2688-7.63904 41.984-31.76448 41.984-60.33408 0-34.7136-26.112-62.85312-58.32704-62.85312zM459.30496 231.71072c-17.44896 0-31.60064 14.45888-31.88736 32.39936v229.60128l-221.22496-0.4096h-0.1024c-17.408 0.02048-31.47776 14.60224-31.45728 32.5632 0.04096 17.87904 13.90592 32.39936 31.1296 32.70656h0.512l252.94848 0.49152h0.1024a30.9248 30.9248 0 0 0 22.56896-9.89184l0.34816-0.38912 0.28672-0.28672c5.4272-5.95968 8.51968-13.80352 8.64256-21.99552V264.66304c0-18.18624-14.27456-32.95232-31.86688-32.95232z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveWorkOverTimeMyList.defaultProps = {
  size: 18,
};

export default IconAExecutiveWorkOverTimeMyList;
