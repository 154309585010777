/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconShezhi1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M425.6 33.28c56.576-10.24 116.1472-10.24 172.6976 0l2.944 0.512c14.2592 2.6368 25.4464 13.7216 28.0576 27.9552l17.0752 92.3136A379.7504 379.7504 0 0 1 754.688 216.576l88.32-31.4368a34.944 34.944 0 0 1 38.144 10.3424l1.9712 2.304a481.0752 481.0752 0 0 1 86.5536 149.6832l0.9728 2.816a34.816 34.816 0 0 1-10.112 38.2976l-71.1168 60.7744a389.5552 389.5552 0 0 1 0 125.44l71.1168 60.8c11.008 9.472 15.0272 24.576 10.3424 38.0416l-0.9728 2.816a484.8128 484.8128 0 0 1-86.5792 149.7344l-1.9456 2.2784a34.816 34.816 0 0 1-38.1696 10.3168l-88.32-31.4112a382.3104 382.3104 0 0 1-108.288 62.5152l-17.0752 92.3136c-2.6112 14.2336-13.824 25.344-28.0576 27.9296l-2.944 0.5376a486.6816 486.6816 0 0 1-172.6976 0l-2.944-0.512a34.8416 34.8416 0 0 1-28.0576-27.9552l-17.152-92.7488a376.6016 376.6016 0 0 1-107.5712-62.2848l-88.96 31.616a34.944 34.944 0 0 1-38.1696-10.3168l-1.9712-2.304a482.304 482.304 0 0 1-86.528-149.6832l-1.024-2.816a34.816 34.816 0 0 1 10.1376-38.2976l71.7824-61.44A378.1888 378.1888 0 0 1 130.3552 512c0-20.6592 1.6384-41.5232 5.0176-61.952l-72.0128-61.44a34.9184 34.9184 0 0 1-10.112-38.272l0.9984-2.816A484.8128 484.8128 0 0 1 140.8 197.7344l1.9456-2.304a34.816 34.816 0 0 1 38.1696-10.3168l88.96 31.6416a384.384 384.384 0 0 1 107.5712-62.2848l17.152-92.7488c2.6368-14.2336 13.824-25.344 28.0832-27.9296z m86.5792 276.7872a191.1552 191.1552 0 1 0 0 382.2848 191.1552 191.1552 0 1 0 0-382.2848z m0 69.4784c32.512 0 62.976 12.6208 86.016 35.6608a121.088 121.088 0 0 1 35.6608 86.016 121.088 121.088 0 0 1-35.6608 85.9904 121.1392 121.1392 0 0 1-86.016 35.6608c-32.512 0-62.976-12.7232-86.016-35.6608a121.088 121.088 0 0 1-35.6864-86.016 121.088 121.088 0 0 1 35.6864-85.9904c23.04-23.04 53.504-35.6608 86.016-35.6608z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconShezhi1.defaultProps = {
  size: 18,
}

export default IconShezhi1
