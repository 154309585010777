/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhongzhihetong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M102.403686 921.591706V102.399078h614.394471v204.798157a421.884203 421.884203 0 0 1 102.399078 13.31188V102.399078a102.399078 102.399078 0 0 0-102.399078-102.399078H102.403686a102.399078 102.399078 0 0 0-102.399078 102.399078v819.192628a102.399078 102.399078 0 0 0 102.399078 102.399078h344.060904a415.740258 415.740258 0 0 1-83.967244-102.399078z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M614.399078 255.997696a51.199539 51.199539 0 0 0-51.199539-51.199539H204.802765a51.199539 51.199539 0 0 0 0 102.399078h358.396774a51.199539 51.199539 0 0 0 51.199539-51.199539zM409.600922 511.995392a51.199539 51.199539 0 0 0-51.19954-51.199539H204.802765a51.199539 51.199539 0 0 0 0 102.399078h153.598617a51.199539 51.199539 0 0 0 51.19954-51.199539zM204.802765 716.793549a51.199539 51.199539 0 0 0 0 102.399078h102.399078a51.199539 51.199539 0 0 0 0-102.399078zM933.884203 499.707503a307.197235 307.197235 0 1 0 0 434.172092 307.197235 307.197235 0 0 0 0-434.172092z m-361.980742 72.19135a204.798157 204.798157 0 0 1 247.293774-31.231719L540.671742 819.192627a204.798157 204.798157 0 0 1 31.231719-247.293774z m289.789392 289.789392a204.798157 204.798157 0 0 1-248.317765 31.231719L892.924572 614.39447a204.798157 204.798157 0 0 1-31.231719 247.293775z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconZhongzhihetong.defaultProps = {
  size: 18,
};

export default IconZhongzhihetong;
