/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFaxFill: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M371.80994783 762.74643479c-14.24695652 0-25.64452174-11.39756522-25.64452174-25.64452174s11.39756522-25.64452174 25.64452174-26.21440001h280.38010434c14.24695652 0 25.64452174 11.39756522 25.64452174 26.21440001 0 14.24695652-11.39756522 25.64452174-25.64452174 25.64452174H371.80994783z"
        fill={getIconColor(color, 0, '#2C2C2C')}
      />
      <path
        d="M852.21732174 278.34991304h-66.10587827v-104.8576c0-39.89147826-32.48306087-72.37453913-72.37453912-72.37453912H312.54260869c-39.89147826 0-72.37453913 32.48306087-72.37453912 72.37453912v104.8576h-68.38539131c-31.34330434 0-56.41794783 25.07464348-56.41794783 56.41794783v358.45342609c0 31.34330434 25.07464348 56.41794783 56.41794783 56.41794782h26.2144c9.11805217 0 15.9565913-7.40841739 15.95659131-15.9565913v-58.12758261h-0.56987827c-17.09634783 0-30.77342609-14.81683479-29.0637913-31.91318261 1.70963478-14.81683479 14.81683479-26.2144 30.20354783-26.2144h26.21439999v231.94045217c0 39.89147826 20.51561739 72.37453913 72.37453914 72.37453914H711.45739131c53.56855652 0 72.37453913-32.48306087 72.37453912-72.37453914V617.42747826h25.64452174c7.40841739 0 14.81683479 3.41926957 20.5156174 8.54817392 5.12890435 5.69878261 8.54817391 13.1072 7.97829565 20.51561739 0 15.9565913-13.1072 28.49391305-28.49391305 28.49391304h-1.13975652l0.56987827 58.1275826c0 9.11805217 7.40841739 15.9565913 15.9565913 15.95659132h26.21439999c31.34330434 0 56.41794783-25.07464348 56.41794783-56.41794783V334.76786087c1.13975653-31.34330434-23.93488696-56.41794783-55.2781913-56.41794783zM298.29565217 173.49231304c0-7.97829566 6.26866087-14.24695652 14.24695652-14.24695651h401.19429566c7.97829566 0 14.24695652 6.26866087 14.24695652 14.24695651v104.8576H298.29565217v-104.8576z m427.40869566 675.87561739c0 7.97829566-6.26866087 14.24695652-14.24695652 14.24695653H313.11248696c-7.97829566 0-14.24695652-6.26866087-14.24695653-14.24695653V617.42747826H725.70434783v231.94045217z m29.0637913-385.23770434c-20.51561739 0-37.04208696-16.52646957-37.04208696-37.04208696s16.52646957-37.04208696 37.04208696-37.04208695 37.04208696 16.52646957 37.04208696 37.04208695c0.56987826 19.94573913-16.52646957 37.04208696-37.04208696 37.04208696z"
        fill={getIconColor(color, 1, '#2C2C2C')}
      />
    </svg>
  );
};

IconFaxFill.defaultProps = {
  size: 18,
};

export default IconFaxFill;
