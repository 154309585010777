/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXiaoxi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.999744 0.000768A509.439236 509.439236 0 0 0 225.715373 87.450237a515.404027 515.404027 0 0 0-138.239792 138.265392A509.720835 509.720835 0 0 0 0.000512 512c-0.0256 48.127928 6.65599 95.795056 19.81437 141.644588l4.019194 13.31198a509.490436 509.490436 0 0 0 44.748733 101.222248l1.484798 2.508796-66.150301 193.48451a44.441533 44.441533 0 0 0 5.990391 40.601539c8.319988 11.647983 21.862367 18.687972 36.249546 18.687972l2.943995-0.1024c1.919997-0.128 3.839994-0.383999 5.734392-0.767999l240.332439-46.69433-7.091189-3.327995A509.439236 509.439236 0 0 0 511.999744 1023.999232a509.439236 509.439236 0 0 0 286.284371-87.449469 515.634427 515.634427 0 0 0 138.239792-138.265392A509.695235 509.695235 0 0 0 1023.998976 512a509.516036 509.516036 0 0 0-87.449469-286.284371 515.404027 515.404027 0 0 0-138.239792-138.239792A509.439236 509.439236 0 0 0 511.999744 0.000768z m0 76.799885a432.741751 432.741751 0 0 1 243.302035 74.316688 438.373742 438.373742 0 0 1 117.580624 117.580624A432.767351 432.767351 0 0 1 947.199091 512a432.997751 432.997751 0 0 1-74.316688 243.302035 438.578542 438.578542 0 0 1-117.580624 117.580624A432.741751 432.741751 0 0 1 511.999744 947.199347a432.690551 432.690551 0 0 1-179.327731-38.527942l-11.391983-5.375992-6.93759-3.379195-234.111648 45.516732 66.995099-195.762907-6.143991-9.881585A432.716151 432.716151 0 0 1 76.800397 512a433.02335 433.02335 0 0 1 74.316688-243.302035 438.527342 438.527342 0 0 1 117.580624-117.580624A432.741751 432.741751 0 0 1 511.999744 76.800653z"
        fill={getIconColor(color, 0, '#888888')}
      />
      <path
        d="M499.788562 471.93606H252.006534a46.46393 46.46393 0 0 1 0-92.927861h247.807628a46.46393 46.46393 0 0 1 0 92.927861z m-117.683023 161.023759H252.006534a46.46393 46.46393 0 0 1 0-92.902261h130.099005a46.46393 46.46393 0 0 1 0 92.927861z"
        fill={getIconColor(color, 1, '#CCCCCC')}
      />
    </svg>
  );
};

IconXiaoxi.defaultProps = {
  size: 18,
};

export default IconXiaoxi;
