/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKufangguanliLingyongguihuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M847.3 637c-27.3 16.4-109.6 86.8-139.2 90.3-55.1 1.5-110.2-0.9-164.9-7.5-63.8-6-39-31.7-28.8-34.6 10.2-2.9 132.4-9.7 173.8-14.5 36.7-4.1 31.5-40.2-9.9-41.4-41.4-1.2-227.9-13.9-291.7-9.7-63.8 4.1-155.8 87.8-155.8 87.8l3.5 13.3 134.3 119.1 18-1.5c19-10.7 39.2-18.9 60.3-24.4 27.4-5.8 178.6 10.8 231 3.3 52.4-7.5 186.5-120.2 216.5-145 14.3-12 24.7-29 20.7-33.8-14.3-21.8-50.6-13.1-68.4-2.5l0.6 1.1z m-643.7 93.4c-9.4-9.4-24.4-10.2-34.8-1.9-8.5 6.6-43.7 34-53.7 41.4-15.5 10.8-1.7 28.6 7 36.9 8.7 8.3 103.6 96.5 118.1 110.4 4.4 5.7 11 9.3 18.1 10.2 7.1 0.9 14.3-1.2 20-5.6h1.2c10.6-8.9 35-30 50.8-41.4 6.5-2.7 10.9-8.7 11.6-15.7 0.7-7-2.5-13.7-8.3-17.7-10.9-11.1-117.6-106.4-130-116.6z m38.5-126.1c53-36.9 98.4-56.2 138.6-58.8 10.4 0 22.8-1 38.5-1 58.6 0 149.2 5 209.1 8.1l52 2.7c23.5 0.3 46.4 7.5 65.9 20.7 9.3 6.7 17.4 14.9 24 24.2 15.7-11.6 28.6-20.7 39.2-27.4 8-4.8 16.5-8.8 25.3-12V349.2c-0.4-8.6-3.9-16.8-9.9-23L607 107.8c-6.5-6-15-9.4-23.8-9.5H309.5c-63.4 0-114.8 51.4-114.8 114.8v427.6c11.2-9.3 27.9-22.8 47.4-36.4z m350.8-436.4l164.5 164.3H592.9V167.9z m0 0"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconKufangguanliLingyongguihuan.defaultProps = {
  size: 18,
};

export default IconKufangguanliLingyongguihuan;
