/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCheckboxSelectedFill: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M799 97H225c-70.4 0-128 57.6-128 128v574c0 70.4 57.6 128 128 128h574c70.4 0 128-57.6 128-128V225c0-70.4-57.6-128-128-128z m-72.495 309.75L470.809 662.446a31.76 31.76 0 0 1-16.611 8.768c-10.152 2.018-21.111-0.882-28.938-8.709L297.495 534.74c-12.445-12.445-12.445-32.81 0-45.255s32.81-12.445 45.255 0L448.005 594.74 681.25 361.495c12.445-12.445 32.81-12.445 45.255 0s12.445 32.81 0 45.255z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconCheckboxSelectedFill.defaultProps = {
  size: 18,
};

export default IconCheckboxSelectedFill;
