/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTaizhang: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M775.896 68C877.574 68 960 150.426 960 252.104v517.792C960 871.574 877.574 954 775.896 954H258.104C156.426 954 74 871.574 74 769.896V252.104C74 150.426 156.426 68 258.104 68h517.792zM427.415 403.535c-11.677-11.677-30.608-11.677-42.285 0l-0.024 0.024c-11.677 11.676-11.677 30.608 0 42.285l86.875 86.874h-73.515c-16.513 0-29.9 13.387-29.9 29.9v0.034c0 16.514 13.387 29.9 29.9 29.9h88.617v46.025l-120.835 0.001c-16.513 0-29.9 13.387-29.9 29.9v0.034c0 16.513 13.387 29.9 29.9 29.9h120.835v73.35l0.004 0.494c0.264 16.285 13.548 29.406 29.896 29.406h0.034l0.494-0.004c16.286-0.264 29.406-13.548 29.406-29.896v-73.35h120.835c16.513 0 29.9-13.387 29.9-29.9v-0.034c0-16.513-13.387-29.9-29.9-29.9H546.916V592.55l88.618 0.001c16.513 0 29.9-13.386 29.9-29.9v-0.033c0-16.514-13.387-29.9-29.9-29.9h-77.642l86.09-86.09 0.347-0.352c11.329-11.702 11.213-30.373-0.347-41.933l-0.024-0.023-0.352-0.347c-11.702-11.329-30.373-11.213-41.933 0.347l-86.737 86.735zM757.485 245.2h-478.67c-16.522 0-29.916 13.394-29.916 29.917 0 16.523 13.394 29.917 29.917 29.917h478.67c16.522 0 29.917-13.394 29.917-29.917 0-16.523-13.395-29.917-29.917-29.917z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconTaizhang.defaultProps = {
  size: 18,
};

export default IconTaizhang;
