/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFeiyongZhangdan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M903.64 0.65H119.82c-33.59 0-55.99 20.39-55.99 50.98v917.6c0 20.39 11.2 35.68 33.59 40.78 16.8 10.2 39.19 10.2 55.98 0l128.77-76.46 78.38 71.37c22.4 20.39 55.99 20.39 78.38 0l72.79-66.27 72.78 66.27c11.2 10.2 22.39 15.29 39.19 15.29s27.99-5.1 44.79-10.2l78.38-71.37 128.77 76.46c16.8 10.2 39.19 10.2 55.99 0 16.8-10.2 27.99-25.49 27.99-45.88V51.63C959.63 21.04 937.23 0.65 903.64 0.65zM885 921.92l-86.69-50.54c-24.77-16.85-61.92-11.23-80.49 5.62l-80.49 73-80.49-73c-12.38-11.23-24.77-16.85-43.34-16.85-18.58 0-30.96 5.61-43.34 16.85l-80.49 73-80.49-78.61c-24.77-16.85-55.72-22.46-80.49-5.62L142 921.92V74h743v847.92zM352.92 264.87c-11.91-14.19-10.06-35.34 4.13-47.25 14.19-11.91 35.34-10.06 47.25 4.13L512.65 351.1 621 221.75c11.91-14.19 33.06-16.04 47.25-4.13 14.19 11.91 16.04 33.06 4.13 47.25l-109.47 130.7h83.78c18.51 0 33.51 15 33.51 33.51s-15 33.51-33.51 33.51H546.17v52.28H646.7c18.51 0 33.51 15 33.51 33.51 0 18.51-15 33.51-33.51 33.51H546.17V696.5c0 18.51-15 33.51-33.51 33.51-18.51 0-33.51-15-33.51-33.51V581.88H378.61c-18.51 0-33.51-15.01-33.51-33.51 0-18.51 15.01-33.51 33.51-33.51h100.53v-52.28H378.61c-18.51 0-33.51-15-33.51-33.51s15.01-33.51 33.51-33.51h83.78L352.92 264.87z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconFeiyongZhangdan.defaultProps = {
  size: 18,
};

export default IconFeiyongZhangdan;
