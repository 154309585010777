/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconGaojisousuo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M0 912.5632h259.84v-76.8H0v76.8z m0-379.52h259.84v-76.8H0v76.8zM921.6 153.6V76.8H0v76.8h921.6z m-260.5056 153.6768a277.0176 277.0176 0 0 1 228.1216 433.9968L1024 875.9552 952.832 947.2l-134.7584-134.7584A277.0176 277.0176 0 1 1 661.0944 307.2v0.0768zM665.6 384a204.8 204.8 0 1 0 0 409.6 204.8 204.8 0 0 0 0-409.6z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconGaojisousuo.defaultProps = {
  size: 18,
}

export default IconGaojisousuo
