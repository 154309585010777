/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShouyefull: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M272 976A80 80 0 0 1 192 896V480H48L510.4128 48 972.8 480h-147.2V896c0 44.1856-35.84 80-80 80h-473.6z"
        fill={getIconColor(color, 0, '#5D73FA')}
      />
      <path
        d="M745.6 1024h-473.6c-70.5792 0-128-57.4208-128-128V528h-96a48 48 0 0 1-32.768-83.072L477.6192 12.928a48 48 0 0 1 65.536 0L1005.568 444.928A48 48 0 0 1 972.8 528h-99.2V896c0 70.5792-57.4208 128-128 128zM169.6768 432H192c26.5216 0 48 21.504 48 48V896c0 17.664 14.336 32 32 32h473.6c17.664 0 32-14.336 32-32V480c0-26.496 21.504-48 48-48h25.4976L510.4128 113.6896 169.7024 432z"
        fill={getIconColor(color, 1, '#5D73FA')}
      />
      <path
        d="M712.0128 814.4128h-403.2a48 48 0 0 1 0-96h403.2a48 48 0 0 1 0 96z"
        fill={getIconColor(color, 2, '#DEE3FE')}
      />
    </svg>
  );
};

IconShouyefull.defaultProps = {
  size: 18,
};

export default IconShouyefull;
