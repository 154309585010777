/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialAllocationsMyAllocations: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 40.96c260.15744 0 471.04 210.88256 471.04 471.04S772.15744 983.04 512 983.04 40.96 772.15744 40.96 512 251.84256 40.96 512 40.96z m-163.84 498.40128a57.9584 57.9584 0 0 0-19.61984 1.59744 55.5008 55.5008 0 0 0-39.936 38.85056 53.69856 53.69856 0 0 0 14.86848 53.00224l111.8208 108.68736c14.17216 13.96736 34.97984 19.456 54.49728 14.41792a55.5008 55.5008 0 0 0 39.936-38.85056 53.71904 53.71904 0 0 0-14.848-52.98176l-15.70816-15.2576h201.80992c20.11136 0 38.7072-10.4448 48.7424-27.36128a53.41184 53.41184 0 0 0 0-54.74304 56.6272 56.6272 0 0 0-48.7424-27.36128z m260.77184-256.8192a57.38496 57.38496 0 0 0-54.49728-14.47936 55.54176 55.54176 0 0 0-39.95648 38.85056 53.69856 53.69856 0 0 0 14.82752 53.00224l15.74912 15.29856h-201.76896c-20.11136 0-38.7072 10.42432-48.7424 27.36128a53.41184 53.41184 0 0 0 0 54.74304 56.6272 56.6272 0 0 0 48.7424 27.36128h337.69472c20.11136 0 38.68672-10.4448 48.7424-27.36128a53.39136 53.39136 0 0 0 4.58752-44.91264l-0.45056-1.20832-0.16384-0.43008a54.41536 54.41536 0 0 0-12.9024-19.53792l-111.8208-108.70784z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialAllocationsMyAllocations.defaultProps = {
  size: 18,
};

export default IconAFinancialAllocationsMyAllocations;
