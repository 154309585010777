/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQingjiashenhe: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M203.390671 241.74551a241.387292 241.387292 0 1 0 482.774585 0 241.387292 241.387292 0 0 0-482.774585 0zM554.699503 511.739013h-188.217609C164.089115 511.739013 0 680.561714 0 888.813905 0 963.220757 58.619704 1023.478026 130.877253 1023.478026h659.375718C862.561694 1023.478026 921.130224 963.143997 921.130224 888.813905 921.181397 680.561714 757.11787 511.739013 554.699503 511.739013z"
        fill={getIconColor(color, 0, '#F85F69')}
      />
      <path
        d="M511.739013 767.60852a255.869507 255.869507 0 1 0 511.739013 0c0-141.316728-114.552778-255.869507-255.869506-255.869507s-255.869507 114.552778-255.869507 255.869507z"
        fill={getIconColor(color, 1, '#F498A6')}
      />
      <path
        d="M670.326933 530.903639c145.743271 49.817793 250.854464 191.236869 250.803291 357.910266 0 55.139879-32.213971 102.552498-78.321656 123.380276a255.869507 255.869507 0 0 1-176.345264-479.678564z"
        fill={getIconColor(color, 2, '#F33B3D')}
      />
      <path
        d="M857.572238 905.778053c-12.230562 0-24.384364-4.52889-33.774775-13.561084l-91.550109-88.735545c-8.955433-8.699563-13.970475-20.469561-13.944888-32.751296V658.35224c0-25.561364 21.339517-46.261207 47.719663-46.261206 26.354559 0 47.719663 20.72543 47.719663 46.261206v93.264435l77.605221 75.225635a45.288903 45.288903 0 0 1 0 65.400246c-8.955433 8.699563-21.109234 13.561084-33.774775 13.561084z"
        fill={getIconColor(color, 3, '#FFFFFF')}
      />
    </svg>
  );
};

IconQingjiashenhe.defaultProps = {
  size: 18,
};

export default IconQingjiashenhe;
