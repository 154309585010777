/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialReceiptsUnClaimedReceipts: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M942.08 40.96a81.92 81.92 0 0 1 81.92 81.92l0.04096 532.6848a267.61216 267.61216 0 0 0-36.63872-45.44512c-26.05056-26.09152-55.35744-45.54752-87.92064-58.368-32.5632-12.86144-65.9456-19.27168-100.1472-19.27168-34.2016 0-67.6864 6.41024-100.4544 19.2512s-62.17728 32.29696-88.22784 58.38848c-26.07104 26.09152-45.60896 55.43936-58.63424 88.04352A267.9808 267.9808 0 0 0 532.48 798.4128c0 34.24256 6.51264 67.66592 19.53792 100.27008 13.02528 32.60416 32.5632 61.93152 58.63424 88.02304L607.08864 983.04H81.92a81.92 81.92 0 0 1-81.92-81.92V122.88a81.92 81.92 0 0 1 81.92-81.92h860.16zM400.87552 258.00704l-2.2528 2.49856L327.68 345.57952l-70.94272-85.07392-3.52256-3.74784a40.96 40.96 0 0 0-61.44 53.51424l2.048 2.6624L240.2304 368.64H204.8l-4.77184 0.26624a40.96 40.96 0 0 0 1.72032 81.55136L204.8 450.56h81.89952v40.96H204.8l-4.77184 0.26624a40.96 40.96 0 0 0 1.72032 81.55136L204.8 573.44h81.89952L286.72 655.36l0.26624 4.77184a40.96 40.96 0 0 0 81.55136-1.72032L368.64 655.36l-0.02048-81.92H450.56l4.77184-0.26624a40.96 40.96 0 0 0-1.72032-81.53088L450.56 491.52h-81.94048v-40.96H450.56l4.77184-0.26624a40.96 40.96 0 0 0-1.72032-81.53088L450.56 368.64h-35.45088l46.42816-55.7056 3.072-4.13696a40.96 40.96 0 0 0-63.73376-50.7904z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M798.98624 573.44c31.15008 0 60.35456 5.87776 87.59296 17.65376a226.57024 226.57024 0 0 1 71.41376 48.3328 229.888 229.888 0 0 1 48.29184 71.5776c11.81696 27.27936 17.7152 56.44288 17.7152 87.4496 0 31.0272-5.89824 60.27264-17.7152 87.71584a229.0688 229.0688 0 0 1-48.29184 71.86432 226.57024 226.57024 0 0 1-71.41376 48.3328c-27.2384 11.75552-56.44288 17.63328-87.59296 17.63328-31.17056 0-60.45696-5.87776-87.8592-17.65376a225.77152 225.77152 0 0 1-71.68-48.3328 229.0688 229.0688 0 0 1-48.29184-71.84384A219.42272 219.42272 0 0 1 573.44 798.47424c0-31.0272 5.89824-60.19072 17.7152-87.4496a229.888 229.888 0 0 1 48.29184-71.61856 225.77152 225.77152 0 0 1 71.68-48.3328 220.32384 220.32384 0 0 1 87.8592-17.63328z m141.27104 182.0672c6.16448-6.144 9.4208-13.7216 9.76896-22.75328a29.16352 29.16352 0 0 0-8.74496-22.77376 29.96224 29.96224 0 0 0-22.60992-8.94976 31.744 31.744 0 0 0-22.58944 9.46176l-144.87552 144.22016-49.84832-49.09056a31.744 31.744 0 0 0-22.58944-9.46176 29.96224 29.96224 0 0 0-22.60992 8.94976c-6.16448 6.144-9.33888 13.39392-9.50272 21.72928-0.18432 8.35584 2.82624 15.60576 8.99072 21.74976L727.04 920.1664c6.144 6.144 14.21312 9.1136 24.14592 8.94976 9.9328-0.18432 17.98144-3.33824 24.14592-9.46176l-2.048 2.048 166.95296-166.21568z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconAFinancialReceiptsUnClaimedReceipts.defaultProps = {
  size: 18,
};

export default IconAFinancialReceiptsUnClaimedReceipts;
