/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconExamined: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 90.125C278.05113613 90.125 90.125 278.05113613 90.125 512c0 232.03125 187.92613613 421.875 421.875 421.875s421.875-189.84375 421.875-421.875C933.875 278.05113613 745.94886387 90.125 512 90.125z m164.9147731 673.08238613c-15.34090925 15.34090925-42.1875 15.34090925-57.52840923 0l-143.82102312-145.73863613c-11.50568152-11.50568152-9.58806848-26.84659075-9.58806765-47.94034075 0-23.01136387-5.75284075-289.55965924-5.75284158-289.55965925 0-23.01136387 19.17613613-42.1875 40.26988696-42.1875 23.01136387 0 40.26988613 19.17613613 40.26988614 42.1875V569.52840924c0 1.91761387 136.15056848 136.15056848 136.15056848 136.15056766 17.2585231 15.34090925 15.34090925 42.1875 0 57.52840923z"
        fill={getIconColor(color, 0, '#FF9418')}
      />
    </svg>
  );
};

IconExamined.defaultProps = {
  size: 18,
};

export default IconExamined;
