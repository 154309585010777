/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const DaochuzhongxinXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M734.293333 498.261333l-4.096-3.626666a36.778667 36.778667 0 0 0-47.914666 3.584l-133.461334 133.461333V303.36l-0.384-4.992a36.821333 36.821333 0 0 0-36.394666-31.786667l-4.949334 0.341334a36.821333 36.821333 0 0 0-31.829333 36.437333v328.362667l-133.461333-133.546667-4.138667-3.584a36.821333 36.821333 0 0 0-47.914667 55.594667l196.266667 196.352 4.096 3.541333a36.778667 36.778667 0 0 0 47.957333-3.541333l196.266667-196.309334 3.584-4.138666a36.778667 36.778667 0 0 0-3.541333-47.914667l-0.042667 0.042667zM21.333333 512c0 270.933333 219.690667 490.666667 490.666667 490.666667S1002.666667 782.933333 1002.666667 512C1002.666667 240.938667 782.976 21.333333 512 21.333333S21.333333 240.938667 21.333333 512z m907.733334 0a417.066667 417.066667 0 1 1-834.133334 0c0-230.357333 186.709333-417.066667 417.066667-417.066667S929.066667 281.642667 929.066667 512z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default DaochuzhongxinXianxing;

