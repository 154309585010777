/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShouye: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M8.576 458.9824l-2.0736 2.1248c-14.72 16.896-2.944 44.2112 20.4032 44.2112h124.2112v408.2432A110.592 110.592 0 0 0 261.9136 1024h496.8192l5.5296-0.128a110.592 110.592 0 0 0 105.2672-110.3104V505.344h127.5648c24.3712 0 36.1472-29.7728 18.3296-46.336L530.3296 7.2192a26.9312 26.9312 0 0 0-36.6592 0L8.576 458.9824zM512 76.8l384 372.6848h-79.5136c-13.6448 0-24.7296 11.4944-24.7296 25.6768v417.4592c0 30.1312-23.5264 54.5792-52.5312 54.5792H281.728c-29.0304 0-52.5568-24.448-52.5568-54.5792V475.136l-0.1792-2.9952c-1.408-12.8-11.8784-22.6816-24.576-22.6816H128L512 76.8z"
        fill={getIconColor(color, 0, '#888888')}
      />
      <path
        d="M698.112 789.6576H325.888c-24.448 0-44.288-22.016-44.288-49.2288 0-27.1872 19.84-49.2288 44.288-49.2288h372.224c24.448 0 44.288 22.0416 44.288 49.2288s-19.84 49.2288-44.288 49.2288z"
        fill={getIconColor(color, 1, '#CCCCCC')}
      />
    </svg>
  );
};

IconShouye.defaultProps = {
  size: 18,
};

export default IconShouye;
