/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShoukuanrenling: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M879.872 363.4432H141.6448A141.2864 141.2864 0 0 1 0.3584 222.208V141.6448A141.2864 141.2864 0 0 1 141.6192 0.4096H879.872a141.2864 141.2864 0 0 1 141.2352 141.2352V222.208a141.2352 141.2352 0 0 1-141.2352 141.2352z"
        fill={getIconColor(color, 0, '#FFAC3E')}
      />
      <path
        d="M789.5552 1018.9824H231.936a105.9328 105.9328 0 0 1-105.9328-105.9328V264.448A105.9328 105.9328 0 0 1 231.936 158.5408h557.6192a105.9328 105.9328 0 0 1 105.9328 105.9072v648.6016a105.9328 105.9328 0 0 1-105.9328 105.9328z"
        fill={getIconColor(color, 1, '#FFAC3E')}
      />
      <path
        d="M789.5552 158.5408H231.936a105.9328 105.9328 0 0 0-105.9328 105.9072v97.9456a140.8 140.8 0 0 0 17.3056 1.1008h738.304c4.6848 0 9.2928-0.2304 13.8752-0.6912V264.448a105.9328 105.9328 0 0 0-105.9328-105.9072z"
        fill={getIconColor(color, 2, '#FF7C0E')}
      />
      <path
        d="M634.0608 674.304H557.824v-25.2672h76.288a47.0784 47.0784 0 1 0 0-94.1568h-17.3056l43.9552-40.704c19.072-17.664 20.1728-47.5136 2.5344-66.56a47.1296 47.1296 0 0 0-66.56-2.56l-88.3968 81.9968-87.8592-79.7952a47.104 47.104 0 0 0-63.3344 69.6832l41.728 37.8368h-11.4688a47.0784 47.0784 0 1 0 0 94.1568h76.2624v25.2416h-76.288a47.0784 47.0784 0 1 0 0 94.1568h76.288v66.048a47.0784 47.0784 0 1 0 94.1568 0v-66.048h76.288c26.0096 0 47.0784-21.0688 47.0784-47.0784a46.976 46.976 0 0 0-47.104-46.9504z"
        fill={getIconColor(color, 3, '#FFFFFF')}
      />
    </svg>
  );
};

IconShoukuanrenling.defaultProps = {
  size: 18,
};

export default IconShoukuanrenling;
