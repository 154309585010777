/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationTenantDocumentSettings: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M851.64032 0H170.51648A68.34176 68.34176 0 0 0 102.4 68.89472v888.07424C102.4 994.2016 131.85024 1024 170.51648 1024h682.96704C890.30656 1024 921.6 994.2016 921.6 956.96896V68.89472C919.7568 29.77792 890.30656 0 851.64032 0zM487.15776 835.95264h-171.2128c-14.7456 0-27.60704-13.02528-27.60704-27.91424 0-14.90944 12.88192-27.93472 27.60704-27.93472h171.2128c14.72512 0 27.60704 13.02528 27.60704 27.93472 0 14.88896-12.9024 27.91424-27.60704 27.91424z m171.2128-147.08736h-342.4256c-14.7456 0-27.60704-13.02528-27.60704-27.91424 0-14.90944 12.88192-27.93472 27.60704-27.93472h342.4256c14.70464 0 27.58656 13.02528 27.58656 27.93472 0 14.88896-12.88192 27.91424-27.60704 27.91424z m27.58656-225.28c0 14.90944-12.88192 27.93472-27.60704 27.93472H315.94496c-14.7456 0-27.60704-13.02528-27.60704-27.93472v-247.6032c0-14.90944 12.88192-27.93472 27.60704-27.93472h342.4256c14.70464 0 27.58656 13.02528 27.58656 27.91424v247.62368z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationTenantDocumentSettings.defaultProps = {
  size: 18,
};

export default IconAAdministrationTenantDocumentSettings;
