/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAnalyzeStatisticsDataViews: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M645.0176 864.1536c23.28576 21.54496 52.96128 43.08992 88.20736 57.4464H1.8432v-57.4464z m169.2672-200.02816c96.52224 0 181.49376 92.59008 209.7152 126.976-8.84736 13.312-24.576 34.52928-46.96064 55.62368-48.00512 44.93312-102.78912 67.85024-162.75456 67.85024-109.01504 0-183.17312-87.71584-208.81408-123.31008 31.744-35.30752 122.81856-127.13984 208.81408-127.13984z m0.512 37.5808c-41.1648 0-74.5472 39.19872-74.5472 87.57248 0 48.3328 33.3824 87.552 74.5472 87.552 41.1648 0 74.5472-39.19872 74.5472-87.552 0-48.37376-33.3824-87.57248-74.5472-87.57248z m118.51776-516.62848v494.7968c-34.2016-21.0944-73.50272-36.67968-115.26144-36.67968-23.1424 0-46.44864 5.9392-68.95616 15.42144V429.71136h-77.66016v275.968a596.93056 596.93056 0 0 0-87.69536 85.11488s11.0592 18.80064 31.88736 42.8032H84.5824V185.05728h848.73216z m-598.30272 166.4h-77.68064v397.76256h77.68064V351.47776z m138.0352 108.19584h-77.68064v289.56672h77.66016v-289.5872z m138.0352-152.51456h-77.68064v441.91744h77.66016V307.15904zM1018.12224 102.4v57.4464H0V102.4h1018.14272z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAnalyzeStatisticsDataViews.defaultProps = {
  size: 18,
};

export default IconAAnalyzeStatisticsDataViews;
