/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksLogLogSummaryReport: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M823.9104 136.54016H681.7792C665.74336 58.63424 595.80416 0 512 0s-153.74336 58.63424-169.75872 136.54016H200.0896C123.55584 136.54016 61.44 197.69344 61.44 273.05984v614.4C61.44 962.82624 123.55584 1024 200.0896 1024h623.8208c76.53376 0 138.6496-61.17376 138.6496-136.54016v-614.4c0-75.3664-62.11584-136.51968-138.6496-136.51968zM512 68.25984c45.11744 0 82.55488 28.672 96.9728 68.28032h-193.9456c14.41792-39.60832 51.85536-68.28032 96.9728-68.28032zM719.95392 819.2H304.04608c-19.12832 0-34.65216-15.29856-34.65216-34.14016s15.52384-34.11968 34.65216-34.11968h415.90784c19.12832 0 34.65216 15.27808 34.65216 34.11968 0 18.8416-15.52384 34.14016-34.65216 34.14016z m0-204.8H304.04608c-19.12832 0-34.65216-15.29856-34.65216-34.14016s15.52384-34.11968 34.65216-34.11968h415.90784c19.12832 0 34.65216 15.27808 34.65216 34.11968 0 18.8416-15.52384 34.14016-34.65216 34.14016z m0-204.8H304.04608c-19.12832 0-34.65216-15.29856-34.65216-34.14016s15.52384-34.11968 34.65216-34.11968h415.90784c19.12832 0 34.65216 15.27808 34.65216 34.11968 0 18.8416-15.52384 34.14016-34.65216 34.14016z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksLogLogSummaryReport.defaultProps = {
  size: 18,
};

export default IconAWorksLogLogSummaryReport;
