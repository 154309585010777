/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWupinlingyong1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M589.9 554.7c-5.2-3.9-12.3-6-21.3-6.2h-116c-9 0.2-16.1 2.3-21.3 6.2-5.1 3.9-7.8 9.2-7.8 16 0 6.7 2.6 12 7.8 15.9 5.2 3.9 12.2 5.9 21.3 5.9h116.1c9 0 16.1-2 21.3-5.9 5.2-3.9 7.8-9.2 7.8-15.9-0.1-6.7-2.7-12-7.9-16zM644.2 345.1H377l-20.7 70.5h311.4z"
        fill={getIconColor(color, 0, '#8a8a8a')}
      />
      <path
        d="M659 128H365c-150.6 0-237 86.4-237 237v294c0 150.6 86.4 237 237 237h294c150.6 0 237-86.4 237-237V365c0-150.6-86.4-237-237-237z m58 551c0 5.9-1.2 11-3.6 15.4-2.4 4.4-5 8.1-7.9 11-4.2 4.3-8.6 7.3-12.9 9-4.2 1.7-8.8 2.6-13.3 2.6H342c-10.3-0.3-18.7-3.9-25-10.8-6.4-6.9-9.7-15.1-9.9-24.4v-237l37.8-117.3c0-0.8 3.1-4.2 9.1-10.2 6.1-6 15.6-9.4 28.6-10.2h258.9c1.3-0.5 7 1.1 16.9 5 10 3.9 16.9 12.9 20.9 27L717 444.8V679z"
        fill={getIconColor(color, 1, '#8a8a8a')}
      />
    </svg>
  );
};

IconWupinlingyong1.defaultProps = {
  size: 18,
};

export default IconWupinlingyong1;
