/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconReply: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M755.2 556.8c6.4 0 12.8 0 19.2 6.4 6.4 6.4 6.4 12.8 6.4 19.2 0 6.4 0 12.8-6.4 19.2-6.4 6.4-12.8 6.4-19.2 6.4L390.4 608c-6.4 0-12.8 0-19.2-6.4C371.2 595.2 364.8 588.8 364.8 582.4c0-6.4 0-12.8 6.4-19.2C377.6 563.2 384 556.8 390.4 556.8L755.2 556.8zM755.2 371.2c6.4 0 12.8 0 19.2 6.4 6.4 6.4 6.4 12.8 6.4 19.2 0 6.4 0 12.8-6.4 19.2-6.4 6.4-12.8 6.4-19.2 6.4L390.4 422.4c-6.4 0-12.8 0-19.2-6.4C371.2 416 364.8 403.2 364.8 396.8c0-6.4 0-12.8 6.4-19.2C377.6 377.6 384 371.2 390.4 371.2L755.2 371.2zM908.8 684.8 908.8 294.4c0-32-25.6-57.6-57.6-57.6L294.4 236.8c-32 0-57.6 25.6-57.6 57.6l0 396.8c0 6.4 0 12.8 0 12.8 6.4 25.6 32 38.4 57.6 38.4L448 742.4l108.8 108.8c6.4 6.4 12.8 6.4 19.2 6.4 0 0 0 0 6.4 0l121.6-121.6 147.2 0C876.8 748.8 908.8 723.2 908.8 684.8L908.8 684.8 908.8 684.8zM742.4 185.6c-6.4-19.2-25.6-25.6-44.8-25.6L172.8 160c-32 0-57.6 25.6-57.6 57.6l0 396.8c0 32 25.6 57.6 57.6 57.6l12.8 0L185.6 294.4c0-25.6 6.4-44.8 25.6-64 6.4-6.4 12.8-12.8 19.2-19.2 19.2-12.8 44.8-19.2 64-19.2L742.4 192zM723.2 800l-102.4 102.4c-12.8 12.8-25.6 19.2-38.4 19.2-19.2 0-38.4-6.4-57.6-25.6l-96-96-128 0c-51.2 0-96-32-108.8-83.2L172.8 716.8C108.8 716.8 64 672 64 608L64 211.2c0-57.6 44.8-108.8 108.8-108.8l524.8 0c51.2 0 89.6 32 102.4 83.2l51.2 0c57.6 0 115.2 44.8 115.2 108.8l0 396.8c0 64-51.2 108.8-115.2 108.8L723.2 800z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconReply.defaultProps = {
  size: 18,
};

export default IconReply;
