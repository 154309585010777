/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconGongzuoliuguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M845.8496 467.712h-44.4416c-24.576 0-44.5696-19.712-44.5696-44.2368s19.8656-44.288 44.544-44.288h44.5696c49.1776 0 89.0368-39.552 89.0368-88.3968s-39.8592-88.448-89.0368-88.448h-44.544c-24.576 0-44.5696-19.84-44.5696-44.2624 0-24.4224 19.8656-44.2624 44.544-44.2624h44.5696C944.3072 113.8176 1024 193.024 1024 290.688s-79.7952 177.0496-178.1504 177.0496zM693.12 998.4h-356.096c-24.576 0-44.544-19.84-44.544-44.288v-176.8192c0-24.4224 19.8656-44.288 44.544-44.288h356.096c24.576 0 44.544 19.7632 44.544 44.288v176.8448A44.5184 44.5184 0 0 1 693.1456 998.4z m0-707.5072h-356.096c-24.576 0-44.544-19.8656-44.544-44.288V69.888c0-24.4224 19.8656-44.2624 44.544-44.2624h356.096c24.576 0 44.544 19.7376 44.544 44.288v176.8192a44.416 44.416 0 0 1-44.544 44.1856z m-514.9696 486.4h44.4416c24.576 0 44.5696 19.712 44.5696 44.2624 0 24.4224-19.8656 44.2624-44.544 44.2624H178.0224C79.6928 865.792 0 786.6368 0 688.9472S79.7952 512 178.1504 512h44.4416c24.576 0 44.5696 19.7376 44.5696 44.288 0 24.3968-19.8656 44.2368-44.544 44.2368H178.0224c-49.1776 0-89.0368 39.5776-89.0368 88.4224 0 48.8448 39.9616 88.32 89.1392 88.32z m333.3376-75.008l-185.6-184.3712a4.9408 4.9408 0 0 1 0-7.04l185.6-184.3712a5.0432 5.0432 0 0 1 7.0656 0l185.6256 184.3712a4.9408 4.9408 0 0 1 0 7.04l-185.6 184.3712a5.0432 5.0432 0 0 1-7.0912 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconGongzuoliuguanli.defaultProps = {
  size: 18,
}

export default IconGongzuoliuguanli
