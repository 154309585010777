/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceEmployeeOutLogMyEmployeeOutLog: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M778.24 614.4a184.32 184.32 0 0 1 184.32 184.32 184.32 184.32 0 0 1-184.32 184.32 184.32 184.32 0 0 1-184.32-184.32 184.32 184.32 0 0 1 184.32-184.32zM675.84 71.68C894.93504 243.13856 919.08096 348.16 921.3952 448.512l0.12288 7.51616 0.06144 7.51616L921.6 471.04v123.78112A265.0112 265.0112 0 0 0 778.24 552.96c-147.0464 0-266.24 119.1936-266.24 266.24 0 45.50656 11.40736 88.33024 31.5392 125.80864-71.76192-86.34368-107.64288-157.2864-107.64288-212.84864 0-27.87328 1.024-51.6096 1.7408-74.71104l0.28672-9.87136c1.67936-62.38208-0.32768-123.63776-32.768-253.3376-46.08-184.32-51.24096-184.32 0-199.68s122.88 15.36 168.96 107.52S599.04 471.04 675.84 471.04V71.68z m102.44096 609.75104c-26.35776 0-47.7184 23.01952-47.7184 51.4048 0 23.42912 14.49984 43.17184 34.36544 49.3568-49.0496 7.10656-87.2448 52.69504-87.2448 107.43808v0.67584c0 8.82688 1.024 17.44896 2.88768 25.7024h195.33824c1.86368-8.25344 2.8672-16.87552 2.8672-25.7024v-0.67584c0.04096-54.74304-38.0928-100.352-87.12192-107.4176 19.8656-6.2464 34.34496-25.98912 34.34496-49.3568 0-28.40576-21.36064-51.42528-47.7184-51.42528zM583.68 40.96c11.18208 0 21.66784 2.9696 30.67904 8.192v202.24c-56.40192-91.79136-145.32608-136.88832-226.85696-112.4352-4.21888 1.2288-8.41728 2.51904-12.5952 3.87072-47.73888 15.872-71.8848 49.152-67.13344 103.0144 0.38912 4.17792 0.49152 6.81984 0.57344 9.0112l0.04096 1.16736 0.04096 0.73728 0.08192 1.47456a90.112 90.112 0 0 0 2.21184 13.312l0.86016 3.8912c1.92512 8.3968 5.03808 21.17632 10.15808 41.7792l1.98656 8.02816 10.0352 40.07936 6.3488 25.3952c1.69984 6.84032 3.4816 14.04928 5.40672 21.64736 26.48064 105.86112 32.80896 161.83296 31.0272 233.90208-0.16384 7.49568-0.3072 11.6736-0.88064 28.28288a1616.13824 1616.13824 0 0 0-1.24928 60.8256c0 32.60416 8.2944 67.25632 24.76032 104.30464H122.88a61.44 61.44 0 0 1-61.44-61.44V102.4a61.44 61.44 0 0 1 61.44-61.44h460.8z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceEmployeeOutLogMyEmployeeOutLog.defaultProps = {
  size: 18,
};

export default IconAHumanResourceEmployeeOutLogMyEmployeeOutLog;
