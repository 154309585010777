/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDismissCircle24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m150.613333 276.053334l-3.584-3.114667a32 32 0 0 0-37.632-0.298667l-4.010666 3.413334L512 466.730667l-105.386667-105.386667-3.584-3.072a32 32 0 0 0-37.632-0.298667l-4.010666 3.413334-3.114667 3.584a32 32 0 0 0-0.298667 37.632l3.413334 4.010666L466.730667 512l-105.386667 105.386667-3.072 3.584a32 32 0 0 0-0.298667 37.632l3.413334 4.010666 3.584 3.114667a32 32 0 0 0 37.632 0.298667l4.010666-3.413334L512 557.269333l105.386667 105.386667 3.584 3.072a32 32 0 0 0 37.632 0.298667l4.010666-3.413334 3.114667-3.584a32 32 0 0 0 0.298667-37.632l-3.413334-4.010666L557.269333 512l105.386667-105.386667 3.072-3.584a32 32 0 0 0 0.298667-37.632l-3.413334-4.010666-3.584-3.114667 3.584 3.114667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentDismissCircle24Filled;

