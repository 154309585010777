/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFenpei: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1025 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M883.977427 687.042065a51.192074 51.192074 0 0 0-73.131535 73.131535l39.3082 39.3082h-38.576884a272.232138 272.232138 0 0 1-271.866481-272.597796A374.799116 374.799116 0 0 0 165.460098 152.633375H51.192074a51.192074 51.192074 0 0 0 0 102.384149h114.268024a272.232138 272.232138 0 0 1 271.86648 271.86648 374.799116 374.799116 0 0 0 374.25063 374.25063h36.565767l-36.565767 35.468794a51.192074 51.192074 0 0 0 36.565767 87.392184 50.460759 50.460759 0 0 0 36.565767-14.991965l124.872096-124.872095a51.374903 51.374903 0 0 0 0-73.131535zM427.819479 733.297761a51.192074 51.192074 0 0 0-71.120418 13.712163 271.500823 271.500823 0 0 1-225.427956 119.935717H51.192074a51.192074 51.192074 0 1 0 0 102.384149h80.079031a373.702143 373.702143 0 0 0 310.809023-164.545953 51.192074 51.192074 0 0 0-14.260649-71.486076z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M51.192074 580.270025h279.728121a51.192074 51.192074 0 0 0 0-102.384149H51.192074a51.192074 51.192074 0 1 0 0 102.384149zM553.788547 352.099636a51.740561 51.740561 0 0 0 29.069785 8.958613 51.009246 51.009246 0 0 0 42.233462-22.122289 272.232138 272.232138 0 0 1 224.148154-118.107429h6.21618l-43.87892 43.878921a51.192074 51.192074 0 0 0 36.565767 87.392184 50.460759 50.460759 0 0 0 36.565767-14.991965l124.872096-124.689266a51.374903 51.374903 0 0 0 0-73.131535L883.977427 15.146089a51.192074 51.192074 0 0 0-73.131535 73.131535l31.263731 31.080902a374.799116 374.799116 0 0 0-302.216067 162.352008 51.374903 51.374903 0 0 0 13.894991 70.389102z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconFenpei.defaultProps = {
  size: 18,
};

export default IconFenpei;
