/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Shenjirizhi: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M693.333333 85.333333a32 32 0 0 1 31.701334 27.648L725.333333 117.333333v31.957334h31.957334a96 96 0 0 1 96 96V682.666667h-160.170667l-6.570667 0.213333a96 96 0 0 0-89.173333 88.917333l-0.256 6.869334v160.213333H245.504a96 96 0 0 1-96-96L149.418667 245.333333a96 96 0 0 1 96-96l31.872-0.042666 0.042666-31.957334a32 32 0 0 1 63.701334-4.352L341.333333 117.333333v31.957334h128V117.333333a32 32 0 0 1 63.744-4.352l0.298667 4.352v31.957334h127.872l0.042667-31.957334a32 32 0 0 1 32-32z m141.226667 661.333334l-173.482667 173.44 0.042667-141.44 0.298667-4.352a32 32 0 0 1 27.349333-27.349334l4.352-0.298666h141.397333zM479.872 682.666667H309.333333l-4.352 0.298666a32 32 0 0 0 0 63.402667l4.352 0.298667h170.581334l4.352-0.298667a32 32 0 0 0 0-63.402667L479.914667 682.666667z m213.333333-170.666667H309.333333l-4.352 0.298667a32 32 0 0 0 0 63.402666l4.352 0.298667h383.914667l4.352-0.298667a32 32 0 0 0 0-63.402666L693.248 512z m0-170.666667H309.333333l-4.352 0.298667a32 32 0 0 0 0 63.402667l4.352 0.298666h383.914667l4.352-0.298666a32 32 0 0 0 0-63.402667L693.248 341.333333z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default Shenjirizhi;

