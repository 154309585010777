/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconBiaodanshezhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M360.3 570.2h-61.2c-18.6 0-32.3-13.6-32.3-32.4 0-18.8 13.6-32.5 32.3-32.5h61.2c18.6 0 32.3 13.7 32.3 32.5 0 15.6-13 32.4-32.3 32.4z m431.8 370.9H568.8L457.2 746.6 568.8 552h223.3l111.7 194.6-111.7 194.5z m-36.5-322.4l-1.1-1.8H606.4l-73.1 127.8-1.1 1.8 73.1 127.9 1.1 1.8h148.1l73.1-128 1-1.8-73-127.7z m-54.1 164.5c-12.8 7.4-29.2 7.4-42-0.1-13-7.5-21-21.5-21-36.5s8.1-29 21-36.6c6.5-3.7 13.7-5.7 21-5.7 7.2 0 14.5 2 21 5.7 13 7.5 21 21.5 21 36.6 0 15-8.1 29-21 36.6z m-30.8-470.9H299.1c-18.6 0-32.3-13.7-32.3-32.5 0-18.7 13.6-32.4 32.3-32.4h371.6c18.7 0 32.3 13.7 32.3 32.4-0.1 15.8-13 32.5-32.3 32.5z m146.7 137.6c-18.7 0-32.3-13.7-32.3-32.5v-221c0-26.8-21.7-48.6-48.4-48.6H233.1c-26.7 0-48.4 21.8-48.4 48.6V808c0 26.8 21.7 48.6 48.4 48.6h161.4c18.7 0 32.3 13.7 32.3 32.5 0 15.6-13 32.5-32.3 32.5H233.1c-62.3 0-112.9-50.9-112.9-113.5V196.5c2.6-61.6 54.3-113.6 112.9-113.6h503.6c62.2 0 112.9 50.9 112.9 113.5v221.1c0 18.7-13.6 32.4-32.2 32.4z m-146.7-9.8H299.1c-18.6 0-32.3-13.7-32.3-32.5s13.6-32.5 32.3-32.5h371.6c18.7 0 32.3 13.7 32.3 32.5-0.1 18.8-13.6 32.5-32.3 32.5z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconBiaodanshezhi.defaultProps = {
  size: 18,
}

export default IconBiaodanshezhi
