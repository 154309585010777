/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBorderRight24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M565.333333 192a32 32 0 0 0 0-64h-106.666666a32 32 0 0 0 0 64h106.666666zM192 458.666667a32 32 0 0 0-64 0v106.666666a32 32 0 0 0 64 0v-106.666666z m405.333333 405.333333a32 32 0 0 1-32 32h-106.666666a32 32 0 0 1 0-64h106.666666a32 32 0 0 1 32 32zM266.666667 128a32 32 0 0 1 0 64h-21.333334c-29.44 0-53.333333 23.893333-53.333333 53.333333v21.333334a32 32 0 0 1-64 0v-21.333334A117.333333 117.333333 0 0 1 245.333333 128h21.333334zM298.666667 864a32 32 0 0 1-32 32h-21.333334A117.333333 117.333333 0 0 1 128 778.666667v-21.333334a32 32 0 0 1 64 0v21.333334c0 29.44 23.893333 53.333333 53.333333 53.333333h21.333334a32 32 0 0 1 32 32zM757.333333 128a32 32 0 0 0 0 64h21.333334c29.44 0 53.333333 23.893333 53.333333 53.333333v533.333334c0 29.44-23.893333 53.333333-53.333333 53.333333h-21.333334a32 32 0 0 0 0 64h21.333334A117.333333 117.333333 0 0 0 896 778.666667V245.333333A117.333333 117.333333 0 0 0 778.666667 128h-21.333334z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentBorderRight24Regular;

