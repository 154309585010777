/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceAppointMyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M122.88 20.48a61.44 61.44 0 0 1 61.44 61.44v860.16a61.44 61.44 0 0 1-122.88 0V81.92a61.44 61.44 0 0 1 61.44-61.44z m593.59232 512C839.82336 532.48 941.42464 633.40544 942.08 757.43232 942.08 880.78336 841.15456 982.38464 717.12768 983.04 593.77664 983.04 492.17536 882.11456 491.52 758.08768c0.36864-124.19072 100.7616-224.8704 224.95232-225.60768z m106.86464 151.06048c-47.5136 46.83776-94.33088 93.67552-143.1552 142.49984-27.0336-23.10144-52.77696-44.8512-77.16864-65.96608-11.8784-9.89184-23.7568-15.83104-36.29056-2.6624-10.5472 10.56768-7.24992 23.7568 7.92576 36.9664l83.10784 71.22944c21.77024 19.1488 29.02016 18.47296 50.13504-2.62144 49.47968-48.82432 98.304-98.304 147.10784-147.10784 19.78368-19.80416 23.10144-32.33792 11.8784-43.54048-11.22304-11.8784-23.7568-8.58112-43.54048 11.20256zM276.5824 55.52128c120.4224-22.24128 237.60896-24.53504 350.72 32.9728 88.6784 46.03904 183.9104 26.86976 277.48352 9.216 17.89952-3.072 34.18112-8.43776 57.77408-13.80352V498.8928c0 31.45728-0.8192 63.67232 0 95.88736-50.44224-82.08384-143.21664-138.8544-251.45344-138.8544-133.44768 0-244.1216 85.15584-278.30272 200.21248-63.46752-2.29376-127.75424 11.50976-192.83968 39.87456-2.4576 0.77824-4.89472 0-11.40736-0.75776-0.8192-12.288-2.43712-24.53504-2.43712-36.82304 0-181.02272 1.6384-361.2672-0.8192-542.28992-0.8192-37.5808 16.2816-54.4768 51.28192-60.60032z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceAppointMyList.defaultProps = {
  size: 18,
};

export default IconAHumanResourceAppointMyList;
