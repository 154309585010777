/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQiyechaxun: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M348.16 302.592H186.88c-19.456 0-32.256 12.8-32.256 32.256s12.8 32.256 32.256 32.256H348.16c19.456 0 32.256-12.8 32.256-32.256s-12.8-32.256-32.256-32.256zM348.16 431.616H186.88c-19.456 0-32.256 12.8-32.256 32.256s12.8 32.256 32.256 32.256H348.16c19.456 0 32.256-12.8 32.256-32.256s-12.8-32.256-32.256-32.256zM348.16 560.128H186.88c-19.456 0-32.256 12.8-32.256 32.256s12.8 32.256 32.256 32.256H348.16c19.456 0 32.256-12.8 32.256-32.256 0-18.944-12.8-32.256-32.256-32.256z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M566.272 860.672H475.136V314.88h303.616v121.344c0 18.432 12.288 30.208 30.208 30.208 18.432 0 30.208-12.288 30.208-30.208V314.88c0-33.28-27.136-60.928-63.488-60.928H475.136V163.328c0-33.28-27.136-60.928-57.856-60.928H138.24c-30.208 0-57.856 27.136-57.856 60.928v697.856c-18.432 0-30.208 12.288-30.208 30.208 0 18.432 12.288 30.208 30.208 30.208h485.376c18.432 0 30.208-12.288 30.208-30.208s-11.776-30.72-29.696-30.72z m-424.96 0L138.24 163.328h276.48v697.856H141.312z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M960 870.912l-73.728-73.728c57.344-77.312 50.688-188.416-19.968-259.072-77.312-77.312-205.312-77.312-285.696 0-36.864 36.864-60.416 87.552-60.416 141.312s19.968 104.448 60.416 141.312c36.864 36.864 87.552 60.416 141.312 60.416 43.52 0 83.968-13.312 117.76-36.864l73.728 73.728c6.656 6.656 16.896 10.24 23.552 10.24 6.656 0 16.896-3.584 23.552-10.24 12.8-10.24 12.8-33.792-0.512-47.104z m-347.136-79.872c-30.72-30.72-46.592-69.632-46.592-108.544s15.36-81.408 46.592-108.544c30.72-30.72 69.632-46.592 108.544-46.592s77.312 15.36 108.544 46.592c61.952 61.952 61.952 158.72 0 220.672-54.272 54.784-158.72 54.784-217.088-3.584z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconQiyechaxun.defaultProps = {
  size: 18,
};

export default IconQiyechaxun;
