/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveStampApplyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M798.718452 655.361991a184.319005 184.319005 0 0 1 184.319004 184.319004 184.319005 184.319005 0 0 1-184.319004 184.319005 184.319005 184.319005 0 0 1-184.319005-184.319005 184.319005 184.319005 0 0 1 184.319005-184.319004zM544.644944 864.052064a238.590712 238.590712 0 0 0 55.213782 122.305899l0.245758 0.1024H103.180448C68.856153 986.480843 41.023983 959.09923 40.962544 925.286933c0.02048-33.791818 27.79121-61.19391 62.054065-61.234869h441.628335z m254.114467-141.659395c-26.357618 0-47.718142 23.019396-47.718142 51.404522 0 23.428993 14.499762 43.171607 34.365255 49.356534-49.049335 7.106522-87.244329 52.694755-87.244329 107.437499v0.675837c0 8.826832 1.023994 17.448866 2.887664 25.702261h195.337185c1.86367-8.253395 2.867185-16.875429 2.867185-25.702261v-0.675837c0.04096-54.742744-38.092594-100.351458-87.12145-107.41702 19.865493-6.246366 34.344775-25.98898 34.344775-49.356533 0-28.405607-21.360525-51.425002-47.718143-51.425002zM444.703083 0.00553A215.714675 215.714675 0 0 1 595.967547 60.523603a203.385782 203.385782 0 0 1 62.361263 147.025126 192.429041 192.429041 0 0 1-77.086304 155.708599c-58.736323 62.709421-65.330847 74.341999-65.330847 115.260818v12.349373c0 52.838115 46.694148 105.53287 128.122188 144.751858a241.191658 241.191658 0 0 0-98.958826 160.603293l-0.942075 6.881243H67.053923a25.8661 25.8661 0 0 1-26.05042-25.661302v-56.073937a45.711113 45.711113 0 0 1 13.926325-32.870222 47.042306 47.042306 0 0 1 33.505099-13.250489 400.648077 400.648077 0 0 0 182.516775-53.083873c38.215474-21.913482 102.399447-68.034193 102.399447-131.153212v-13.496247c-0.184319-40.898339-3.358702-53.923549-65.248928-114.216343a192.039923 192.039923 0 0 1-77.045344-155.708599 203.201463 203.201463 0 0 1 62.361263-146.922727A215.755635 215.755635 0 0 1 444.703083 0.00553z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveStampApplyList.defaultProps = {
  size: 18,
};

export default IconAExecutiveStampApplyList;
