/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIncomeO: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M365.714286 892.342857c-212.114286-14.628571-365.714286-80.457143-365.714286-168.228571v109.714285C0 936.228571 212.114286 1024 482.742857 1024H512c-65.828571-36.571429-109.714286-80.457143-146.285714-131.657143z m-29.257143-73.142857c-7.314286-29.257143-14.628571-58.514286-14.628572-87.771429 0-14.628571 0-29.257143 7.314286-43.885714C138.971429 672.914286 0 614.4 0 533.942857v109.714286c0 80.457143 138.971429 153.6 336.457143 175.542857z m7.314286-190.171429c14.628571-43.885714 43.885714-80.457143 80.457142-117.028571C182.857143 504.685714 0 438.857143 0 336.457143v109.714286c0 87.771429 146.285714 160.914286 343.771429 182.857142zM775.314286 512H687.542857v175.542857H512v87.771429h175.542857V950.857143h87.771429v-175.542857H950.857143V687.542857h-175.542857V512zM512 0C226.742857 0 0 95.085714 0 219.428571s226.742857 219.428571 512 219.428572h14.628571c102.4-58.514286 241.371429-95.085714 387.657143-95.085714h21.942857C994.742857 307.2 1024 263.314286 1024 219.428571c0-124.342857-226.742857-219.428571-512-219.428571z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconIncomeO.defaultProps = {
  size: 18,
};

export default IconIncomeO;
