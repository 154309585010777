/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentStoreMicrosoft24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M485.6328125 406.53124999v158.203125h-158.203125V406.53124999h158.203125z m0 369.14062501V617.46875001h-158.203125v158.20312499h158.203125z m210.9375-369.14062499v158.20312498h-158.203125V406.53124999h158.203125z m0 369.14062499V617.46875001h-158.203125v158.20312499h158.203125zM353.796875 248.328125V169.2265625C353.796875 125.5203125 389.234375 90.125 432.8984375 90.125h158.203125c43.70625001 0 79.1015625 35.4375 79.1015625 79.1015625V248.328125h237.3046875c14.5546875 0 26.3671875 11.8125 26.3671875 26.3671875v527.34375C933.875 874.8546875 874.8125 933.875 802.0390625 933.875H221.9609375A131.8359375 131.8359375 0 0 1 90.125 802.0390625v-527.34375c0-14.5546875 11.8125-26.3671875 26.3671875-26.3671875H353.796875zM406.53124999 169.2265625V248.328125h210.93750002V169.2265625a26.3671875 26.3671875 0 0 0-26.36718751-26.3671875h-158.203125a26.3671875 26.3671875 0 0 0-26.36718751 26.3671875z m-263.67187499 632.8125c0 43.70625001 35.4375 79.1015625 79.1015625 79.1015625h580.078125c43.70625001 0 79.1015625-35.4375 79.1015625-79.1015625V301.0625H142.859375v500.9765625z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentStoreMicrosoft24Regular;

