/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Wenjianjia: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M95.104 397.66016c0-32.74752 21.4016-59.45344 47.91808-59.45344h817.5104v-44.64128c0-36.03968-22.49216-65.31072-50.10944-65.31072H494.8992l-43.34592-58.9056C432.7168 133.6832 405.09952 117.76 383.33952 117.76H39.50592C17.7408 117.76 0 140.81024 0 169.34912v619.264l95.104-390.95296z"
        fill={getIconColor(color, 0, '#5F77F7')}
      />
      <path
        d="M981.20192 396.928H184.17152c-23.59296 0-42.79808 23.7824-42.79808 53.23776l-98.02752 402.8416c0 29.45024 19.2 53.23264 42.79296 53.23264h797.03552c23.59296 0 42.79808-23.7824 42.79808-53.23776L1024 450.16576c0-29.45536-19.2-53.23776-42.79808-53.23776z"
        fill={getIconColor(color, 1, '#E1E8F9')}
      />
    </svg>
 );
};

export default Wenjianjia;

