/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveBusinessCardMyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M715.264 587.10016c-20.25472 0-57.09824 16.7936-57.09824 37.60128 0 20.7872 16.384 37.5808 36.61824 37.5808h146.30912c20.23424 0 36.59776-16.7936 36.59776-37.5808 0-20.7872-16.36352-37.60128-36.59776-37.60128h-125.82912zM621.69088 361.8816c-20.23424 0-36.59776 16.7936-36.59776 37.5808 0 20.7872 16.36352 37.60128 36.59776 37.60128H841.1136c20.23424 0 36.59776-16.7936 36.59776-37.60128 0-20.7872-16.36352-37.5808-36.59776-37.5808H621.69088zM877.69088 61.44C958.54592 61.44 1024 128.63488 1024 211.64032v600.71936C1024 895.36512 958.54592 962.56 877.69088 962.56H146.30912C65.45408 962.56 0 895.36512 0 812.35968V211.64032C0 128.63488 65.45408 61.44 146.30912 61.44h731.38176zM327.76192 286.72c-53.67808 0-97.1776 44.21632-97.1776 98.75456 0 44.99456 29.4912 82.92352 69.95968 94.8224C200.64256 493.8752 122.88 581.44768 122.88 686.592v1.29024c0 16.97792 2.048 33.52576 5.85728 49.37728h397.88544c3.82976-15.85152 5.85728-32.39936 5.85728-49.37728v-1.29024c0.08192-105.14432-77.59872-192.7168-177.50016-206.336 40.448-11.9808 69.95968-49.88928 69.95968-94.80192 0-54.53824-43.49952-98.75456-97.1776-98.75456z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveBusinessCardMyList.defaultProps = {
  size: 18,
};

export default IconAExecutiveBusinessCardMyList;
