/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWenjianjia: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M586.752 665.6c-12.288 0-22.528-10.24-22.528-22.528V283.136c0-44.544-43.52-80.384-97.28-80.384s-97.28 36.352-97.28 80.384v360.448c0 12.288-10.24 22.528-22.528 22.528s-22.528-10.24-22.528-22.528V283.136c0-69.12 63.488-125.44 141.824-125.44s141.824 56.32 141.824 125.44v360.448c0.512 12.288-9.216 22.016-21.504 22.016z"
        fill={getIconColor(color, 0, '#000000')}
      />
      <path
        d="M528.384 871.936c-112.128 0-203.264-97.28-203.264-216.576V429.568c0-12.288 10.24-22.528 22.528-22.528s22.528 10.24 22.528 22.528V655.36c0 94.72 71.168 172.032 158.72 172.032s158.72-77.312 158.72-172.032V429.568c0-12.288 10.24-22.528 22.528-22.528 12.288 0 22.528 10.24 22.528 22.528V655.36c-0.512 119.296-91.648 216.576-204.288 216.576z"
        fill={getIconColor(color, 1, '#000000')}
      />
      <path
        d="M531.456 763.392c-42.496 0-77.312-32.256-77.312-72.192V336.384c0-12.288 10.24-22.528 22.528-22.528s22.528 10.24 22.528 22.528V691.2c0 15.36 14.336 27.648 32.256 27.648s32.256-12.288 32.256-27.648V446.464c0-12.288 10.24-22.528 22.528-22.528s22.528 10.24 22.528 22.528V691.2c-0.512 39.936-34.816 72.192-77.312 72.192z"
        fill={getIconColor(color, 2, '#000000')}
      />
    </svg>
  );
};

IconWenjianjia.defaultProps = {
  size: 18,
};

export default IconWenjianjia;
