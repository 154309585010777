/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceExternalApplicationFeishu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M0 341.6064c153.31328 137.9328 280.00256 228.33152 380.06784 271.19616 142.72512 73.05216 280.41216 109.568 413.0816 109.568C617.84064 855.22432 473.33376 921.6 359.69024 921.6 246.0672 921.6 126.1568 876.544 0 786.432z m873.73824-15.01184c50.21696 0 100.31104 14.29504 150.26176 42.9056-46.73536 56.832-81.18272 100.94592-103.38304 132.36224-22.20032 31.39584-59.55584 96.48128-112.08704 195.23584a1047.79776 1047.79776 0 0 1-134.0416-12.0832c-43.60192-6.84032-120.58624-32.50176-230.99392-76.96384 76.45184-87.6544 167.44448-167.15776 272.95744-238.55104 48.78336-23.87968 81.96096-42.9056 157.2864-42.9056zM625.39776 102.4c92.03712 75.01824 141.49632 145.98144 148.35712 212.91008-63.24224 26.3168-168.59136 90.60352-239.32928 172.2368C493.3632 410.46016 374.43584 282.07104 177.7664 102.4h447.65184z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceExternalApplicationFeishu.defaultProps = {
  size: 18,
};

export default IconAHumanResourceExternalApplicationFeishu;
