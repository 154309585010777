/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWode: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M795.4176 563.2C921.6768 563.2 1024 666.368 1024 793.6s-102.3232 230.4-228.5824 230.4H228.608C102.3232 1024 0 920.832 0 793.6s102.3232-230.4 228.5824-230.4z m-17.152 76.8H245.76C152.4224 640 76.8 708.7616 76.8 793.6s75.6224 153.6 168.9344 153.6H778.24C871.5776 947.2 947.2 878.4384 947.2 793.6s-75.6224-153.6-168.9344-153.6zM486.4 0c141.3888 0 256 114.6112 256 256s-114.6112 256-256 256S230.4 397.3888 230.4 256 345.0112 0 486.4 0z m0 76.8a179.2 179.2 0 1 0 0 358.4 179.2 179.2 0 0 0 0-358.4z"
        fill={getIconColor(color, 0, '#888888')}
      />
      <path
        d="M824.1664 839.9872h-243.584a45.7216 45.7216 0 0 1 0-91.4176h243.584a45.7216 45.7216 0 0 1 0 91.4176z"
        fill={getIconColor(color, 1, '#CCCCCC')}
      />
    </svg>
  );
};

IconWode.defaultProps = {
  size: 18,
};

export default IconWode;
