/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveWorkOverTimeCreate: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M798.72 552.96c124.416 0 225.28 100.864 225.28 225.28s-100.864 225.28-225.28 225.28-225.28-100.864-225.28-225.28 100.864-225.28 225.28-225.28zM455.92576 20.48c251.8016 0 455.92576 210.88256 455.92576 471.04 0 7.70048-0.16384 15.36-0.53248 22.97856A285.57312 285.57312 0 0 0 798.72 491.52c-158.35136 0-286.72 128.36864-286.72 286.72 0 63.65184 20.74624 122.4704 55.82848 170.02496a442.9824 442.9824 0 0 1-111.90272 14.29504C204.12416 962.56 0 751.67744 0 491.52S204.12416 20.48 455.92576 20.48z m346.3168 655.36h-0.36864c-12.84096 0-23.2448 10.62912-23.47008 23.81824v60.98944h-58.63424c-12.82048 0-23.2448 10.6496-23.4496 23.81824v0.4096c0 13.25056 10.28096 24.02304 23.06048 24.22784h59.02336v60.60032c0 13.23008 10.30144 24.00256 23.06048 24.22784h0.4096c12.82048 0 23.22432-10.6496 23.4496-23.81824v-60.98944h58.63424c12.82048 0 23.2448-10.6496 23.4496-23.83872v-0.4096c0-13.23008-10.28096-24.00256-23.06048-24.22784H825.344v-60.57984c0-13.25056-10.30144-24.00256-23.06048-24.22784zM459.32544 231.71072c-17.44896 0-31.60064 14.45888-31.88736 32.39936v229.60128l-221.22496-0.4096h-0.1024c-17.408 0.02048-31.47776 14.60224-31.45728 32.5632 0.04096 17.87904 13.90592 32.39936 31.1296 32.70656h0.512l252.94848 0.49152h0.1024a30.9248 30.9248 0 0 0 22.56896-9.89184l0.34816-0.38912 0.28672-0.28672c5.4272-5.95968 8.51968-13.80352 8.64256-21.99552V264.66304c0-18.18624-14.27456-32.95232-31.86688-32.95232z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveWorkOverTimeCreate.defaultProps = {
  size: 18,
};

export default IconAExecutiveWorkOverTimeCreate;
