/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGaizhangshenhe1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M895.710609 1023.332174H121.188174c-27.737043 0-50.287304-22.127304-50.287304-49.062957 0-27.069217 22.706087-49.062957 50.309565-49.062956h774.633739c27.737043 0 50.287304 22.127304 50.287304 49.062956 0 27.069217-22.706087 49.062957-50.420869 49.062957zM945.41913 585.46087H684.588522a8.281043 8.281043 0 0 0-3.138783-0.667827h-0.155826c-34.014609 0-61.484522-26.935652-61.484522-59.993043 0-19.21113 9.149217-36.129391 23.507479-47.193043 76.933565-44.81113 128.311652-126.797913 128.311652-220.672C771.628522 115.066435 653.712696 0 508.304696 0c-145.385739 0-263.168 115.066435-263.168 257.068522 0 93.718261 51.511652 175.727304 128.445217 220.672a59.458783 59.458783 0 0 1 25.266087 48.39513c0 30.141217-22.817391 55.206957-52.46887 59.325218H71.457391c-27.069217 0-49.196522 21.593043-49.196521 48.016695v143.716174c0 26.401391 22.127304 48.016696 49.196521 48.016696H945.41913c27.046957 0 49.196522-21.593043 49.196522-48.016696v-143.716174c0-26.401391-22.149565-48.016696-49.196522-48.016695z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconGaizhangshenhe1.defaultProps = {
  size: 18,
};

export default IconGaizhangshenhe1;
