/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIcon2: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M958.027711 260.422574l-41.485825 23.444107c39.127119 69.224444 59.80749 148.110052 59.80749 228.133319 0 256.038716-208.31066 464.349376-464.349376 464.349376C255.955328 976.349376 47.650624 768.038716 47.650624 512S255.955328 47.65658 512 47.65658c92.561337 0 181.936039 27.148943 258.456985 78.516316l26.559267-39.567887C712.609128 29.948417 614.055724 0 512 0 229.681965 0 0 229.681965 0 511.994044s229.681965 512 512 512c282.312079 0 512-229.687921 512-512 0-88.213218-22.812736-175.211345-65.972289-251.57147z"
        fill={getIconColor(color, 0, '#040000')}
      />
      <path
        d="M424.81127 652.682511L267.278307 495.149548l-33.700904 33.688991 191.233867 191.239824 365.617283-365.623239-33.694947-33.688991z"
        fill={getIconColor(color, 1, '#040000')}
      />
    </svg>
  );
};

IconIcon2.defaultProps = {
  size: 18,
};

export default IconIcon2;
