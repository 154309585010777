/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconUp: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M999.50592 721.7152c0 18.432-6.144 30.72-18.432 42.96704-24.53504 24.53504-61.31712 18.432-85.89312-6.144L539.27936 365.7728c-12.288-12.288-24.576-18.432-36.82304-18.432-12.288 0-24.53504 6.144-36.82304 18.432l-337.5104 398.86848c-24.53504 24.53504-61.31712 30.67904-85.89312 6.144-24.53504-24.576-30.67904-61.39904-6.144-85.93408l337.5104-392.72448c30.72-36.82304 79.79008-61.35808 128.86016-61.35808 49.11104 0 92.03712 18.432 128.86016 55.21408l355.9424 392.72448c6.144 12.288 12.288 30.72 12.288 42.96704z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconUp.defaultProps = {
  size: 18,
};

export default IconUp;
