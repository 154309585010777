/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconJigou: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M25.6 972.8c-14.1312 0-25.6-19.7888-25.6-33.6128 0-13.824 11.4688-35.9424 25.6-35.9424h67.84V251.904c0-32.384 21.248-61.1328 52.6592-71.3216L514.048 55.0144c40.2688-13.0304 83.712 8.3456 97.024 47.744 2.5856 7.6032 3.8912 15.5648 3.8912 23.6032v776.8832h50.8416V424.32l223.5136 128.64a74.8544 74.8544 0 0 1 38.7072 65.2288v285.056H998.4c14.1312 0 25.6 22.0928 25.6 35.9424 0 13.824-11.4688 33.6128-25.6 33.6128H25.6z m413.4144-307.2H277.76c-12.032 0-21.7856 11.4688-21.7856 25.6 0 14.0032 9.5488 25.344 21.4016 25.6h161.6128c12.032 0 21.7856-11.4688 21.7856-25.6s-9.7536-25.6-21.7856-25.6z m409.4464 0h-109.7216c-12.1088 0-21.9392 11.4688-21.9392 25.6 0 14.0032 9.6256 25.344 21.5552 25.6H848.4608c12.1088 0 21.9392-11.4688 21.9392-25.6s-9.8304-25.6-21.9392-25.6z m-409.4464-153.6H277.76c-12.032 0-21.7856 11.4688-21.7856 25.6 0 14.0032 9.5488 25.344 21.4016 25.6h161.6128c12.032 0 21.7856-11.4688 21.7856-25.6s-9.7536-25.6-21.7856-25.6z m0-153.6H277.76c-12.032 0-21.7856 11.4688-21.7856 25.6 0 13.9776 9.5488 25.344 21.4016 25.6h161.6128c12.032 0 21.7856-11.4688 21.7856-25.6s-9.7536-25.6-21.7856-25.6z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconJigou.defaultProps = {
  size: 18,
}

export default IconJigou
