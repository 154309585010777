/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconA17Houbufeiyongshenhe: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M640 480v-64h-64.7v-64h-63.6v64H384.3v-64h-63.6v64H256v64h160.2v64H256v64h160.2v64h63.6v-64H640v-64H479.8v-64z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M804.9 128l-4.7 4.8-45.3 45.2-14 14-36.9 36.9-36.9-36.9-13.8-13.7 0.2-0.3-0.2-0.2-45.3-45.2-45.3 45.4-0.2 0.1 0.2 0.2-13.8 13.7-36.9 36.9-36.9-36.9-13.8-13.7 0.2-0.3-0.2-0.2-45.3-45.2-45.3 45.4-0.2 0.1 0.2 0.2-13.8 13.7-36.9 36.9-36.9-36.9-59.3-59.2-4.7-4.8H128v768h91.1l4.7-4.7 45.3-45.3 14-14 36.9-37 36.9 37 13.8 13.8-0.2 0.2 0.2 0.2 45.3 45.3 32-32v-91l-32 32-96-96L192.6 832h-0.6V192h0.6L320 319.4l45.3-45.3 50.7-50.6 96 96 96-96 96 96L831.4 192h0.6v256h64V128zM608 736h320v64H608zM736 640h64v64h-64zM672 832h192v64H672z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconA17Houbufeiyongshenhe.defaultProps = {
  size: 18,
};

export default IconA17Houbufeiyongshenhe;
