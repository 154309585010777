/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXiansuo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M960.6 160.2c0-53-43.1-96.1-96.1-96.1s-96.1 43.1-96.1 96.1c0 41.7 26.7 77.2 63.8 90.5v596.6c0 13.5-5.3 26.2-15 35.8-9.7 9.7-22.4 15-35.8 15H770c-13.5 0-26.2-5.3-35.8-15s-15-22.4-15-35.8l-0.5-668.7c0-63.2-51.7-114.8-114.8-114.8H595c-63.2 0-114.8 51.7-114.8 114.8l0.5 666c0 13.5-5.3 26.2-15 35.8-9.7 9.7-22.4 15-35.8 15h-10.4c-13.5 0-26.2-5.3-35.8-15-9.7-9.7-15-22.4-15-35.8l0.5-666.2c0-63.2-51.7-114.8-114.8-114.8h-11.3c-63.2 0-114.8 51.7-114.8 114.8v594.2c-37.5 13.1-64.5 48.8-64.5 90.8 0 53 43.1 96.1 96.1 96.1 53 0 96.1-43.1 96.1-96.1 0-41.6-26.6-77.2-63.8-90.5V178.5c0-13.5 5.3-26.2 15-35.8 9.7-9.7 22.4-15 35.8-15h11.3c13.5 0 26.2 5.3 35.8 15 9.7 9.7 15 22.4 15 35.8l-0.5 666.2c0 63.2 51.7 114.8 114.8 114.8h10.4c63.2 0 114.8-51.7 114.8-114.8l-0.5-666c0-13.5 5.3-26.2 15-35.8 9.7-9.7 22.4-15 35.8-15h8.9c13.5 0 26.2 5.3 35.8 15 9.7 9.7 15 22.4 15 35.8l0.5 668.7c0 63.2 51.7 114.8 114.8 114.8h11.3c63.2 0 114.8-51.7 114.8-114.8V251c37.6-13.2 64.6-48.9 64.6-90.8zM159.7 895.6c-17.7 0-32.1-14.4-32.1-32.1s14.4-32.1 32.1-32.1 32.1 14.4 32.1 32.1-14.4 32.1-32.1 32.1z m704.8-703.3c-17.7 0-32.1-14.4-32.1-32.1s14.4-32.1 32.1-32.1c17.7 0 32.1 14.4 32.1 32.1s-14.4 32.1-32.1 32.1z"
        fill={getIconColor(color, 0, '#C93939')}
      />
    </svg>
  );
};

IconXiansuo.defaultProps = {
  size: 18,
};

export default IconXiansuo;
