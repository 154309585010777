/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveStampApply: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M787.249714 637.995044c108.133816 0 195.787743 86.425133 195.787742 193.002478C983.037456 937.574867 895.38353 1024 787.249714 1024c-108.113336 0-195.767263-86.425133-195.767263-193.002478 0-106.597824 87.653927-193.002478 195.787743-193.002478zM544.665424 864.052064a238.590712 238.590712 0 0 0 55.213781 122.305899l0.245759 0.1024H103.180448C68.856153 986.480843 41.023983 959.09923 40.962544 925.286933c0.02048-33.791818 27.79121-61.19391 62.054065-61.234869h441.628335z m214.83404-145.878253l-0.204799 0.06144c-7.782358 0-15.216558 3.071983-20.705169 8.499154a28.753765 28.753765 0 0 0-8.581073 20.254611l-0.532477 111.984035c-0.06144 7.720918 3.010544 15.134638 8.560593 20.561809 5.48861 5.386211 12.90233 8.417235 20.664209 8.437715l113.745306-0.471038c7.782358 0 15.257518-3.071983 20.766607-8.478674 5.50909-5.427171 8.601554-12.799931 8.601554-20.47989 0-15.974314-13.148089-28.938084-29.368161-28.938083l-84.172346 0.327678 0.327678-82.800193c0-15.892394-13.00473-28.815204-29.122402-28.958564zM444.682604 0.00553A215.714675 215.714675 0 0 1 595.967547 60.523603a203.385782 203.385782 0 0 1 62.361263 147.025126 192.429041 192.429041 0 0 1-77.086304 155.708599c-58.736323 62.709421-65.330847 74.341999-65.330847 115.260818v12.349373c0 52.838115 46.694148 105.53287 128.122188 144.751858a241.191658 241.191658 0 0 0-98.958826 160.603293l-0.942075 6.881243H67.053923a25.8661 25.8661 0 0 1-26.05042-25.661302v-56.073937a45.711113 45.711113 0 0 1 13.926325-32.870222 47.042306 47.042306 0 0 1 33.505099-13.250489 400.648077 400.648077 0 0 0 182.516775-53.083873c38.215474-21.913482 102.399447-68.034193 102.399447-131.153212v-13.496247c-0.184319-40.898339-3.358702-53.923549-65.248928-114.216343a192.039923 192.039923 0 0 1-77.045344-155.708599 203.201463 203.201463 0 0 1 62.361263-146.922727A215.755635 215.755635 0 0 1 444.703083 0.00553z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveStampApply.defaultProps = {
  size: 18,
};

export default IconAExecutiveStampApply;
