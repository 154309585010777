/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentPin24Filled2: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M898.901333 331.008l-205.909333-205.909333a117.333333 117.333333 0 0 0-187.904 30.506666l-103.893333 207.786667a32 32 0 0 1-18.176 15.957333L205.226667 440.746667a53.333333 53.333333 0 0 0-20.309334 88.106666L317.397333 661.333333 128 850.773333V896h45.226667l189.44-189.44 132.437333 132.48a53.333333 53.333333 0 0 0 88.149333-20.309333l61.44-177.749334a32 32 0 0 1 15.914667-18.176l207.786667-103.893333a117.333333 117.333333 0 0 0 30.506666-187.904z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default AIcFluentPin24Filled2;

