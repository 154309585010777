/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersMyCustomersContacts: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M172.62592 768a35.0208 35.0208 0 0 1 35.10272 34.9184 35.0208 35.0208 0 0 1-35.10272 34.89792H55.58272A35.0208 35.0208 0 0 1 20.48 802.9184 35.0208 35.0208 0 0 1 55.58272 768H172.6464z m0-290.9184A35.0208 35.0208 0 0 1 207.72864 512a35.0208 35.0208 0 0 1-35.10272 34.9184H55.58272A35.0208 35.0208 0 0 1 20.48 512a35.0208 35.0208 0 0 1 35.10272-34.9184H172.6464z m0-290.89792a35.0208 35.0208 0 0 1 35.10272 34.89792A35.0208 35.0208 0 0 1 172.62592 256H55.58272A35.0208 35.0208 0 0 1 20.48 221.0816a35.0208 35.0208 0 0 1 35.10272-34.89792H172.6464z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M863.08864 0C940.6464 0 1003.52 62.52544 1003.52 139.63264v744.73472C1003.52 961.47456 940.6464 1024 863.08864 1024H254.54592c-68.54656 0-127.05792-49.17248-138.50624-116.36736H172.6464c57.20064 0 103.936-45.40416 105.2672-102.25664 1.37216-56.85248-43.17184-104.40704-100.31104-107.06944l-4.95616-0.12288H114.0736v-81.44896H172.6464c56.23808 0 102.54336-43.95008 105.20576-99.79904L277.9136 512c0-55.9104-44.17536-101.9904-100.352-104.61184l-4.95616-0.12288H114.0736V325.8368H172.6464c56.23808 0 102.54336-43.9296 105.20576-99.79904l0.1024-4.9152c0-55.93088-44.17536-101.9904-100.352-104.63232l-4.95616-0.1024H116.03968C127.488 49.19296 185.99936 0 254.5664 0h608.54272zM661.2992 476.52864L614.4 511.87712l-46.8992-35.34848C465.5104 498.15552 389.12 588.24704 389.12 696.32h450.56c0-108.07296-76.3904-198.16448-178.3808-219.79136z m-64.59392 57.9584c6.57408-4.3008 33.21856-3.9936 40.22272 1.024l-18.78016 16.03584 30.04416 109.71136L614.4 685.32224l-33.792-24.064 30.04416-109.71136-15.03232-16.05632zM619.8272 245.76c-65.29024 0-118.0672 50.87232-118.0672 113.5616 0 62.6688 52.77696 113.54112 118.0672 113.54112 65.29024 0 118.0672-50.87232 118.0672-113.54112 0-62.6688-52.77696-113.5616-118.0672-113.5616z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconACustomersMyCustomersContacts.defaultProps = {
  size: 18,
};

export default IconACustomersMyCustomersContacts;
