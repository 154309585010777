/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentLocation24Regular1: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M249.301333 194.901333a371.498667 371.498667 0 1 1 525.397334 525.397334l-50.645334 50.090666c-37.333333 36.608-85.76 83.712-145.322666 141.312a96 96 0 0 1-133.461334 0l-148.949333-144.896c-18.730667-18.389333-34.389333-33.877333-47.018667-46.506666a371.498667 371.498667 0 0 1 0-525.397334z m480.128 45.226667A307.498667 307.498667 0 1 0 294.570667 675.072L357.973333 737.706667a32169.813333 32169.813333 0 0 0 131.754667 128 32 32 0 0 0 44.501333 0l144.810667-140.8c20.053333-19.669333 36.821333-36.266667 50.346667-49.834667a307.498667 307.498667 0 0 0 0-434.858667zM512 341.290667a128.085333 128.085333 0 1 1 0 256.170666A128.085333 128.085333 0 0 1 512 341.333333z m0 64a64.042667 64.042667 0 1 0 0 128.170666A64.042667 64.042667 0 0 0 512 405.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default AIcFluentLocation24Regular1;

