/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRenshibiaozhunyongzhangshenpiliucheng: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M853.333333 896H341.333333V298.666667h512v597.333333zM256 768H170.666667V128h512v85.333333H298.666667a42.666667 42.666667 0 0 0-42.666667 42.666667v512zM768 213.333333V85.333333a42.666667 42.666667 0 0 0-42.666667-42.666666H128a42.666667 42.666667 0 0 0-42.666667 42.666666v725.333334a42.666667 42.666667 0 0 0 42.666667 42.666666h128v85.333334a42.666667 42.666667 0 0 0 42.666667 42.666666h597.333333a42.666667 42.666667 0 0 0 42.666667-42.666666V256a42.666667 42.666667 0 0 0-42.666667-42.666667h-128z"
        fill={getIconColor(color, 0, '#1768E4')}
      />
      <path
        d="M437.973333 672.64a405.162667 405.162667 0 0 1 318.72 0c6.784 2.858667 11.306667 10.453333 11.306667 18.944v98.986667c0 11.093333-7.68 20.053333-17.066667 20.053333h-307.2c-9.386667 0-17.066667-8.96-17.066666-20.053333v-98.986667c0-8.490667 4.522667-16.085333 11.306666-18.944z m77.226667-157.226667l-2.56-23.424a103.765333 103.765333 0 0 1 23.381333-78.72C552.234667 394.410667 573.994667 384 597.333333 384s45.141333 10.410667 61.312 29.269333c18.005333 21.034667 26.538667 49.706667 23.381334 78.72l-2.56 23.424C674.389333 562.090667 639.146667 597.333333 597.333333 597.333333c-41.770667 0-77.056-35.242667-82.133333-81.92z"
        fill={getIconColor(color, 1, '#8BB3F1')}
      />
    </svg>
  );
};

IconRenshibiaozhunyongzhangshenpiliucheng.defaultProps = {
  size: 18,
};

export default IconRenshibiaozhunyongzhangshenpiliucheng;
