/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHetongshoukuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M508.672 0C675.328 0 810.666667 133.589333 810.666667 298.666667a297.898667 297.898667 0 0 1-145.28 255.36l-8.277334 4.693333-0.170666 1.450667c-1.706667 14.378667-2.773333 27.093333-3.114667 38.101333l-0.128 7.936a42.666667 42.666667 0 1 1-85.333333 0c0-19.413333 1.834667-42.154667 5.461333-68.394667l1.962667-13.44 3.498666-22.656 20.864-9.6C675.925333 457.301333 725.333333 382.336 725.333333 298.666667c0-117.674667-96.853333-213.333333-216.661333-213.333334-119.808 0-216.704 95.658667-216.704 213.333334 0 83.882667 49.621333 158.805333 125.184 193.408l8.832 3.84 22.186667 8.96 3.84 23.68c4.266667 25.984 6.4 51.882667 6.4 77.653333 0 66.389333-14.037333 115.669333-45.994667 146.432l-6.186667 5.546667-11.861333 9.813333H179.072l57.642667 170.666667h303.573333a42.666667 42.666667 0 0 1 42.368 37.674666l0.298667 4.992a42.666667 42.666667 0 0 1-37.674667 42.368l-4.992 0.298667H221.653333c-25.6 0-48.554667-15.061333-58.794666-38.144l-2.133334-5.461333-72.021333-213.333334a63.957333 63.957333 0 0 1 54.101333-84.053333l6.826667-0.341333h209.962667l1.92-3.541334c6.4-13.312 10.666667-34.133333 11.434666-63.018666l0.128-9.898667c0-10.581333-0.426667-21.205333-1.28-31.829333l-1.066666-10.026667-7.424-3.84a298.24 298.24 0 0 1-156.458667-250.453333L206.634667 298.666667c0-165.077333 135.338667-298.666667 302.037333-298.666667z"
        fill={getIconColor(color, 0, '#191919')}
      />
      <path
        d="M607.232 779.605333a43.093333 43.093333 0 0 1 4.394667-3.84l116.309333-116.266666a42.666667 42.666667 0 0 1 60.330667 60.330666L740.096 768l196.096 0.042667a42.666667 42.666667 0 0 1 42.368 37.674666l0.298667 4.992a42.666667 42.666667 0 0 1-42.666667 42.666667h-194.944l47.018667 46.933333a42.666667 42.666667 0 1 1-60.330667 60.373334l-115.626667-115.541334a42.837333 42.837333 0 0 1-13.781333-17.066666l-0.512-1.28a34.090667 34.090667 0 0 1-1.92-5.717334l-0.213333-1.024a39.253333 39.253333 0 0 1-1.066667-7.125333v-5.632a42.496 42.496 0 0 1 12.416-27.648z"
        fill={getIconColor(color, 1, '#191919')}
      />
    </svg>
  );
};

IconHetongshoukuan.defaultProps = {
  size: 18,
};

export default IconHetongshoukuan;
