/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXiaoshouxiansuo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M902.095238 551.009524c-24.380952-29.257143-63.390476-43.885714-102.4-43.885714s-73.142857 14.628571-97.523809 39.009523c-29.257143 24.380952-43.885714 63.390476-43.885715 102.4v204.8c0 24.380952-19.504762 43.885714-43.885714 43.885715-9.752381 0-24.380952-4.87619-29.257143-14.628572-9.752381-9.752381-14.628571-19.504762-14.628571-29.257143v-190.171428h-97.52381v190.171428c0 78.019048 63.390476 141.409524 141.409524 141.409524 78.019048 0 141.409524-63.390476 141.409524-141.409524v-204.8c0-9.752381 4.87619-24.380952 14.628571-29.257143 9.752381-9.752381 19.504762-14.628571 34.133334-14.628571 9.752381 0 24.380952 4.87619 29.257142 14.628571s14.628571 19.504762 14.628572 29.257143v195.047619h97.523809v-195.047619c-4.87619-34.133333-19.504762-68.266667-43.885714-97.523809zM263.314286 219.428571h429.104762v97.52381H263.314286zM263.314286 414.47619h433.980952v97.52381H263.314286z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M180.419048 126.780952h594.895238v316.952381h97.523809V29.257143H82.895238v936.228571h341.333333v-97.523809h-243.809523z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconXiaoshouxiansuo.defaultProps = {
  size: 18,
};

export default IconXiaoshouxiansuo;
