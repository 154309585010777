/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Zhangdanshenqing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M786.765913 22.26087a30.586435 30.586435 0 0 1 0 61.217391H140.02087c-32.50087 0-58.902261 27.40313-58.902261 61.217391s26.401391 61.217391 58.902261 61.217391h646.722782a30.47513 30.47513 0 0 1 26.646261 15.515827 31.098435 31.098435 0 0 1 3.940174 15.092869v673.391304c0 50.710261-39.557565 91.826087-88.353391 91.826087H110.592C61.796174 1001.73913 22.26087 960.623304 22.26087 909.913043V114.086957C22.26087 63.376696 61.796174 22.26087 110.592 22.26087h676.173913z m167.913739 748.544c25.978435 0 47.059478 21.103304 47.059478 47.104v71.412869a47.081739 47.081739 0 0 1-47.059478 47.104h-86.772869a11.642435 11.642435 0 0 1-8.303305-3.428174 11.887304 11.887304 0 0 1-3.472695-8.347826v-142.068869c0-3.116522 1.26887-6.144 3.472695-8.347827 2.203826-2.226087 5.186783-3.450435 8.303305-3.428173zM481.725217 424.225391l-215.418434 215.440696a98.014609 98.014609 0 0 0-25.822609 45.568l-16.673391 66.782609c-7.301565 29.094957 19.055304 55.429565 48.128 48.150261l66.804869-16.695653a98.03687 98.03687 0 0 0 45.568-25.800347l215.440696-215.462957a83.433739 83.433739 0 0 0-118.027131-117.982609z m472.932174 94.853566c26.000696 0 47.059478 21.103304 47.059479 47.12626v71.390609a47.081739 47.081739 0 0 1-47.059479 47.104h-86.772869a11.776 11.776 0 0 1-11.776-11.776v-142.046609c0-3.161043 1.26887-6.166261 3.472695-8.370087 2.226087-2.203826 5.186783-3.428174 8.303305-3.428173h86.772869z m0-251.725914c25.978435 0 47.059478 21.103304 47.059479 47.104v71.41287a47.081739 47.081739 0 0 1-47.059479 47.104h-86.772869a11.642435 11.642435 0 0 1-8.303305-3.428174 11.887304 11.887304 0 0 1-3.472695-8.347826v-142.06887c0-3.116522 1.26887-6.144 3.472695-8.347826 2.203826-2.226087 5.186783-3.450435 8.303305-3.428174z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Zhangdanshenqing;

