/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWorks: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M1003.52 452.68992V885.3504c0 55.82848-41.84064 97.6896-97.60768 97.6896H118.08768C62.32064 983.04 20.48 941.17888 20.48 885.3504V452.68992h983.04zM780.41088 780.67712H243.58912c-20.93056 0-34.87744 20.93056-34.87744 34.87744 0 20.93056 13.94688 34.89792 34.87744 34.89792h536.82176c13.94688 0 27.89376-13.96736 34.87744-34.89792 0-20.93056-13.94688-34.87744-34.87744-34.87744z m0-202.38336H243.58912c-20.93056 0-34.87744 13.96736-34.87744 34.89792 0 20.93056 13.94688 34.87744 34.87744 34.87744h529.85856c20.91008 0 34.85696-13.94688 34.85696-34.87744 0-13.96736-13.9264-27.91424-27.89376-34.89792zM208.71168 124.70272v125.60384c0 34.89792 34.87744 69.79584 69.7344 69.79584 34.85696 0 69.71392-27.91424 69.71392-69.79584V124.7232h327.68v125.60384c0 34.89792 27.89376 69.79584 69.71392 69.79584 34.85696 0 69.7344-27.91424 69.7344-69.79584V124.7232h90.624c55.76704 0 97.60768 41.86112 97.60768 97.6896v174.46912H20.48v-174.46912c0-55.82848 41.84064-97.6896 97.60768-97.6896h90.624zM278.44608 40.96c27.87328 0 55.76704 20.93056 55.76704 55.82848v153.51808c-6.9632 27.91424-27.89376 55.82848-62.75072 55.82848-27.87328 0-48.80384-27.91424-48.80384-55.82848V96.78848C222.65856 68.87424 243.58912 40.96 278.44608 40.96z m467.10784 0c27.89376 0 55.78752 20.93056 55.78752 55.82848v153.51808c0 27.91424-27.89376 55.82848-55.78752 55.82848-27.87328 0-55.76704-20.93056-55.76704-55.82848V96.78848C689.7664 68.87424 710.69696 40.96 745.55392 40.96z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconWorks.defaultProps = {
  size: 18,
};

export default IconWorks;
