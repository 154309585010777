/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhangdanguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M714.684728 254.469842a47.164275 47.164275 0 0 1 0 66.704577l-83.889524 83.843788 56.025442 0.057169a47.164275 47.164275 0 1 1 0 94.32855l-119.299889-0.057169v72.135614l174.787944 0.057169a47.164275 47.164275 0 0 1 0 94.32855l-174.787944-0.057169v72.192783a55.499488 55.499488 0 0 1-110.987543 0v-72.192783l-174.787944 0.057169a47.164275 47.164275 0 0 1 0-94.32855l174.730775-0.057169v-72.135614l-119.299889 0.057169a47.164275 47.164275 0 0 1 0-94.32855l57.763373-0.057169-83.855222-83.843788a47.164275 47.164275 0 1 1 66.693143-66.704577l129.51024 129.487373a46.947033 46.947033 0 0 1 5.716882 6.860258 35.593306 35.593306 0 0 1 5.511074-6.860258l129.464505-129.487373a47.164275 47.164275 0 0 1 66.704577 0zM789.438674 94.32855H234.546694a127.623669 127.623669 0 0 0-127.326392 118.911141l-0.297277 8.735395v657.189863l0.583122-0.285844a149.839472 149.839472 0 0 1 147.346911 0.743195l9.592928 5.979858 51.589141 34.838678a53.429977 53.429977 0 0 0 53.441411 3.727407l6.219967-3.578768 53.086964-35.444667a149.782303 149.782303 0 0 1 156.642562-6.117064l9.615795 5.888388 53.109832 35.318896a56.17408 56.17408 0 0 0 55.8082 3.635937l6.482944-3.727407 52.458107-34.987317a149.782303 149.782303 0 0 1 147.346911-10.782039l6.860258 3.578768V221.952219a127.646537 127.646537 0 0 0-118.911141-127.326392z m0-94.32855c122.581379 0 221.952219 99.370839 221.952219 221.952219v732.789908a52.98406 52.98406 0 0 1-82.586075 43.928519l-51.726346-34.872979a55.510922 55.510922 0 0 0-61.82236-0.137205l-52.446673 35.010184a150.525498 150.525498 0 0 1-166.932949 0.137205l-53.098398-35.318895a55.510922 55.510922 0 0 0-61.58225 0.080036L428.097444 999.082262a147.792828 147.792828 0 0 1-164.852004-0.457351l-51.577707-34.884412a55.510922 55.510922 0 0 0-61.742324-0.308712L96.746975 998.624911A54.207473 54.207473 0 0 1 12.617343 953.404376V221.952219C12.594475 99.370839 111.965314 0.022868 234.546694 0.022868z"
        fill={getIconColor(color, 0, '#231815')}
      />
    </svg>
  );
};

IconZhangdanguanli.defaultProps = {
  size: 18,
};

export default IconZhangdanguanli;
