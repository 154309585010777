/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconJiaose: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M779.9808 531.4048a298.3168 298.3168 0 0 1-328.6528 0 382.8992 382.8992 0 0 0-215.6544 347.392c0 160.512 762.7008 158.4128 762.7008 0a382.208 382.208 0 0 0-216.32-347.392h-2.0736z m-420.1728-250.8032c0.768-141.4144 115.6352-255.488 256.768-254.976 141.1072 0.512 255.1808 115.4048 254.9248 256.8192-0.256 141.4144-114.7392 255.9232-255.8464 255.9232a256.256 256.256 0 0 1-181.1968-75.776 257.3568 257.3568 0 0 1-74.6496-182.016zM190.6176 889.9328a44.544 44.544 0 0 0 0 16.6656C94.9248 891.3152 25.6 860.7488 25.6 814.8992a330.0864 330.0864 0 0 1 186.5216-298.0864 261.0176 261.0176 0 0 0 133.8112 42.3936 427.648 427.648 0 0 0-155.3152 330.7264z m126.8736-609.3312a299.8784 299.8784 0 0 1 54.784-174.4128h-18.0224c-116.7872 0-211.456 94.8736-211.456 211.9168 0 117.0432 94.6688 211.9168 211.456 211.9168a207.616 207.616 0 0 0 74.1888-13.9008 299.6992 299.6992 0 0 1-110.9504-235.52z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconJiaose.defaultProps = {
  size: 18,
}

export default IconJiaose
