/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentGlobe24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M379.904 704h264.192C617.642667 843.648 564.650667 938.666667 512 938.666667c-51.072 0-102.4-89.344-129.621333-222.037334l-2.474667-12.544h264.192-264.192z m-249.088 0.042667H314.453333c15.573333 88.832 41.941333 164.437333 76.501334 217.301333a427.904 427.904 0 0 1-253.952-205.397333l-6.229334-11.904z m578.688 0h183.68a427.861333 427.861333 0 0 1-260.138667 217.301333c32.213333-49.408 57.344-118.528 73.173334-199.722667l3.242666-17.578666h183.722667-183.722667z m12.885333-277.333334h207.786667a429.013333 429.013333 0 0 1-10.965333 213.333334H718.506667a1217.664 1217.664 0 0 0 5.546666-185.344L722.346667 426.666667h207.786666-207.786666zM93.781333 426.666667h207.786667a1203.669333 1203.669333 0 0 0 1.450667 188.586666l2.432 24.746667H104.789333a428.672 428.672 0 0 1-11.008-213.333333z m272.085334 0h292.266666a1102.421333 1102.421333 0 0 1-1.578666 188.8l-2.645334 24.533333H370.090667a1108.437333 1108.437333 0 0 1-5.632-192.512L365.866667 426.666667h292.266666-292.266666z m271.786666-316.757334l-4.650666-7.253333A427.818667 427.818667 0 0 1 911.872 362.666667H715.946667c-13.482667-103.082667-40.789333-191.658667-78.378667-252.714667l-4.608-7.253333 4.608 7.253333z m-251.861333-5.674666l5.205333-1.578667C353.408 160.298667 325.461333 244.736 310.613333 343.893333l-2.602666 18.773334H112.128a427.861333 427.861333 0 0 1 273.664-258.432l5.205333-1.578667-5.205333 1.578667zM512 85.418667c56.277333 0 112.896 108.458667 137.130667 263.808l2.005333 13.44H372.864C395.946667 200.149333 454.101333 85.418667 512 85.418667z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default IcFluentGlobe24Filled;

