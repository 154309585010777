/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconShenjirizhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M686.08 25.6a104.4736 104.4736 0 0 1 98.4576 69.4784h93.0304A69.5296 69.5296 0 0 1 947.2 164.608v764.3392c0 38.4-31.1552 69.4784-69.632 69.4784H146.432A69.5296 69.5296 0 0 1 76.8 928.9216V164.5824c0-38.4 31.1552-69.504 69.632-69.504h93.0304A104.4736 104.4736 0 0 1 337.92 25.6h348.16z m11.008 728.064H327.6288c-12.544 0-20.4288 7.68-20.4288 19.968 0 12.288 7.8592 19.968 20.4288 19.968H696.32c12.5696 0 20.4288-7.68 20.4288-19.968 0.768-12.288-7.8592-19.968-19.6608-19.968zM512.3584 332.8c-67.584 0-122.624 53.76-122.624 119.808 0 49.92 30.6688 92.16 75.4688 109.824l-6.2976 49.92h-98.2528c-18.0736 0-36.9408 13.824-38.528 32.256l-7.8592 43.776c-4.7104 13.056 5.504 24.576 18.0992 24.576h360.8064c12.5696 0 22.784-12.288 20.4288-23.808l-7.8592-43.776c-3.9168-17.664-20.4288-32.256-38.5024-32.256h-99.84L559.5392 563.2c44.8-17.664 75.4688-59.904 75.4688-109.824 0-66.048-55.04-120.576-122.624-120.576z m170.2912-256H342.016c-18.5344 0-34.2784 16.3584-34.816 37.3504-0.512 21.76 14.9504 39.4496 34.1504 39.4496H681.984c18.5344 0 34.2784-16.3584 34.816-37.3504C717.312 94.6432 701.824 76.8 682.624 76.8z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconShenjirizhi.defaultProps = {
  size: 18,
}

export default IconShenjirizhi
