/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShezhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M727.792941 30.117647c28.611765 0 54.964706 16.112941 69.150118 42.194824l216.545882 398.064941a87.552 87.552 0 0 1 0 83.245176l-216.545882 398.064941c-14.155294 26.081882-40.568471 42.194824-69.180235 42.194824H295.755294c-28.611765 0-55.055059-16.143059-69.270588-42.285177L10.511059 553.562353a87.582118 87.582118 0 0 1 0-83.064471L226.484706 72.402824C240.64 46.260706 267.083294 30.117647 295.725176 30.117647z m0 83.817412H295.695059L79.691294 512l216.033882 398.064941h432.037648L944.308706 512l-216.545882-398.064941zM512 331.294118a180.705882 180.705882 0 1 1 0 361.411764 180.705882 180.705882 0 0 1 0-361.411764z m0 80.323764a100.382118 100.382118 0 1 0 0 200.764236 100.382118 100.382118 0 0 0 0-200.764236z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconShezhi.defaultProps = {
  size: 18,
};

export default IconShezhi;
