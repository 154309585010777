/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentLibrary24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 128h42.666666c44.970667 0 81.834667 34.816 85.12 78.933333L298.666667 213.333333v597.333334a85.376 85.376 0 0 1-78.933334 85.077333L213.333333 896H170.666667a85.376 85.376 0 0 1-85.12-78.933333L85.333333 810.666667V213.333333c0-44.970667 34.816-81.834667 78.933334-85.12L170.666667 128h42.666666-42.666666z m256 0h42.666666c44.970667 0 81.834667 34.816 85.12 78.933333L554.666667 213.333333v597.333334a85.376 85.376 0 0 1-78.933334 85.077333L469.333333 896h-42.666666a85.376 85.376 0 0 1-85.12-78.933333L341.333333 810.666667V213.333333c0-44.970667 34.816-81.834667 78.933334-85.12L426.666667 128h42.666666-42.666666z m297.557333 85.333333c35.84 0 68.608 22.656 80.64 57.429334l2.005333 6.698666 128.64 501.12a85.333333 85.333333 0 0 1-55.296 102.058667l-6.144 1.834667-41.344 10.581333a85.418667 85.418667 0 0 1-101.845333-54.784l-2.005333-6.613333-128.682667-501.12a85.333333 85.333333 0 0 1 55.381333-102.058667l6.101334-1.834667 41.301333-10.581333A84.906667 84.906667 0 0 1 724.224 213.333333zM213.333333 192H170.666667a21.376 21.376 0 0 0-20.992 17.493333L149.333333 213.333333v597.333334c0 10.410667 7.552 19.2 17.493334 20.992L170.666667 832h42.666666c10.453333 0 19.2-7.594667 20.992-17.493333L234.666667 810.666667V213.333333a21.376 21.376 0 0 0-17.493334-20.992L213.333333 192z m256 0h-42.666666a21.376 21.376 0 0 0-20.992 17.493333L405.333333 213.333333v597.333334c0 10.410667 7.552 19.2 17.493334 20.992l3.84 0.341333h42.666666c10.453333 0 19.2-7.594667 20.992-17.493333L490.666667 810.666667V213.333333a21.376 21.376 0 0 0-17.493334-20.992L469.333333 192z m254.933334 85.333333l-2.688 0.170667-2.688 0.554667-41.301334 10.538666a21.248 21.248 0 0 0-16.042666 21.76l0.64 4.266667 128.682666 501.12a21.333333 21.333333 0 0 0 20.608 16l2.688-0.128 2.645334-0.512 41.386666-10.666667a21.333333 21.333333 0 0 0 15.957334-22.144l-0.64-3.754666-128.64-501.205334a21.376 21.376 0 0 0-20.608-16z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentLibrary24Regular;

