/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCasesStatistics: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M915.80416 40.96H108.19584C48.68096 40.96 0 89.2928 0 148.41856v727.16288C0 934.7072 48.68096 983.04 108.19584 983.04h807.60832C975.31904 983.04 1024 934.7072 1024 875.58144V148.41856C1024 89.2928 975.31904 40.96 915.80416 40.96z m-88.6784 371.17952l-93.7984 223.66208a83.33312 83.33312 0 0 1 25.6 60.14976c0 46.40768-37.82656 83.968-84.5824 83.968-46.6944 0-84.54144-37.56032-84.54144-83.968 0-11.01824 2.17088-21.56544 6.06208-31.21152l-172.42112-171.23328c-6.73792 1.69984-13.824 2.60096-21.13536 2.60096a84.04992 84.04992 0 0 1-31.6416-6.12352l-89.7024 101.45792c1.59744 6.4512 2.51904 13.27104 2.51904 20.29568 0 46.42816-37.82656 83.968-84.56192 83.968-46.73536 0-84.54144-37.53984-84.54144-83.968 0-46.40768 37.80608-83.968 84.54144-83.968a83.5584 83.5584 0 0 1 44.78976 12.82048l82.04288-92.81536a83.23072 83.23072 0 0 1-7.9872-35.7376c0-46.42816 37.80608-83.968 84.54144-83.968s84.56192 37.53984 84.56192 83.968c0 16.44544-4.8128 31.76448-13.02528 44.70784l164.18816 163.06176a83.82464 83.82464 0 0 1 36.20864-8.06912h2.048l92.672-220.93824a83.5584 83.5584 0 0 1-28.54912-62.8736c0-46.3872 37.80608-83.968 84.54144-83.968s84.56192 37.5808 84.56192 83.968c0.1024 45.9776-36.67968 83.08736-82.39104 84.21376z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCasesStatistics.defaultProps = {
  size: 18,
};

export default IconABusinessCasesStatistics;
