/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Xls: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M756.7872 10.24H217.68192c-41.00096 0.128-74.20416 33.53088-74.33216 74.77248v853.97504c0.128 41.2416 33.3312 74.6496 74.33216 74.77248h706.39104c40.99584-0.128 74.19904-33.53088 74.32704-74.77248V253.34784L756.7872 10.24z"
        fill={getIconColor(color, 0, '#E9FFE9')}
      />
      <path
        d="M756.7872 178.4576c0.12288 41.25184 33.32096 74.67008 74.32704 74.83392h167.22944L756.78208 10.24v168.2176z"
        fill={getIconColor(color, 1, '#97DD97')}
      />
      <path
        d="M94.7968 625.11104h567.99744c46.1312 0 69.1968 23.20384 69.1968 69.61152v185.62048c0 46.40768-23.0656 69.61152-69.1968 69.61152H94.7968C48.6656 949.95456 25.6 926.75072 25.6 880.34304v-185.62048c0-46.40768 23.0656-69.61152 69.1968-69.61152z"
        fill={getIconColor(color, 2, '#65CE65')}
      />
      <path
        d="M251.2384 138.37824h353.19808v49.88416H251.2384z"
        fill={getIconColor(color, 3, '#97DD97')}
      />
      <path
        d="M300.83072 138.3168V493.6192h-49.5872V138.3168h49.5872z m115.33312 0V493.6192h-49.59232V138.3168h49.59232z m115.328 0V493.6192H481.8944V138.3168h49.59232z m115.328 0V493.6192h-49.59232V138.3168h49.59232z"
        fill={getIconColor(color, 4, '#97DD97')}
      />
      <path
        d="M251.2384 248.4736h353.19808v49.88928H251.2384V248.4736z m0 110.10048h373.20704v49.88416H251.24352v-49.88416z m0 110.15168h395.58144v49.88928H251.24352v-49.88928z"
        fill={getIconColor(color, 5, '#97DD97')}
      />
      <path
        d="M249.45152 808.59136l13.55264 20.3008-56.91392 77.37856h-27.33568l70.69696-97.67936z m1.03936 14.15168l-70.69696-93.21472h27.44832l57.66656 79.4624 72.07936 97.28h-27.1616l-59.33568-83.52768z m4.5568-16.3584L306.944 729.2928h27.44832L267.79136 824.7808l-12.7488-18.39104zM382.3104 859.7504a25.4464 25.4464 0 0 0 5.77024 17.408 19.60448 19.60448 0 0 0 15.33952 6.20032h16.26112v22.85568h-20.58752A38.1184 38.1184 0 0 1 368.64 893.91616a50.7904 50.7904 0 0 1-10.78272-34.80064v-200.76544h24.448v201.40032z m103.33696 49.0752l-13.43488-2.78528a114.85184 114.85184 0 0 1-24.97024-8.2944c-7.41376-3.4304-14.336-7.8336-20.5824-13.1072l14.06464-17.408a128.8192 128.8192 0 0 0 29.81376 15.08352 88.79616 88.79616 0 0 0 29.29664 5.39648 73.50784 73.50784 0 0 0 38.97856-8.5248 27.4688 27.4688 0 0 0 13.83936-24.59648 21.7088 21.7088 0 0 0-6.68672-17.40288 36.352 36.352 0 0 0-16.896-7.8848 244.8384 244.8384 0 0 0-28.3136-3.36896h-9.63072a165.92896 165.92896 0 0 1-29.17888-5.10464 40.3456 40.3456 0 0 1-19.6096-12.82048 41.06752 41.06752 0 0 1-7.8336-27.0848 49.8688 49.8688 0 0 1 8.18176-29.0048 51.17952 51.17952 0 0 1 24.10496-18.21696c12.17536-4.5056 25.088-6.65088 38.05696-6.3232 7.74656 0 15.47264 0.76288 23.0656 2.26304 7.6544 1.28512 15.16544 3.30752 22.43584 6.03136 6.84544 2.7648 13.3632 6.28736 19.4304 10.50112l-14.06976 16.88064a118.20544 118.20544 0 0 0-26.12224-11.60192 90.2144 90.2144 0 0 0-25.43104-4.00384 60.4928 60.4928 0 0 0-34.59584 8.35584 26.9056 26.9056 0 0 0-12.17024 23.552 18.90816 18.90816 0 0 0 6.46144 16.01024 36.5568 36.5568 0 0 0 16.72192 6.90176c9.1904 1.39264 18.44736 2.304 27.73504 2.72384h1.67424c0.47616 0.0512 0.96256 0.0512 1.43872 0h2.07872c10.60352 0.4352 21.13536 1.9712 31.42656 4.5824a42.7008 42.7008 0 0 1 21.79584 14.1568 46.60224 46.60224 0 0 1 8.82176 30.51008 48.46592 48.46592 0 0 1-8.82176 29.35296 54.9632 54.9632 0 0 1-25.77408 18.50368 116.71552 116.71552 0 0 1-40.8832 6.37952l-14.41792 0.34816z"
        fill={getIconColor(color, 6, '#FFFFFF')}
      />
    </svg>
 );
};

export default Xls;

