/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentWarning24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M465.450667 118.698667a96 96 0 0 1 126.933333 31.573333l3.541333 5.888 331.050667 597.717333a96 96 0 0 1-77.397333 142.250667l-6.570667 0.256H180.992A96 96 0 0 1 93.866667 759.978667l3.072-6.101334L427.946667 156.16a96 96 0 0 1 37.504-37.461333zM512 682.752a42.624 42.624 0 1 0 0 85.205333 42.624 42.624 0 0 0 0-85.205333z m-0.085333-341.504a42.666667 42.666667 0 0 0-42.368 37.717333L469.333333 383.914667V597.333333l0.298667 4.992a42.666667 42.666667 0 0 0 84.778667 0L554.666667 597.333333V383.914667l-0.298667-4.992a42.666667 42.666667 0 0 0-42.410667-37.674667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentWarning24Filled;

