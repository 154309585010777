/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveSocialSecurityApply: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M877.71136 81.92H146.28864C65.49504 81.92 0 151.94112 0 238.30528v547.38944C0 872.0384 65.49504 942.08 146.28864 942.08h731.42272C958.50496 942.08 1024 872.05888 1024 785.69472V238.30528C1024 151.9616 958.50496 81.92 877.71136 81.92z m-93.61408 664.6784h-146.28864c-20.19328 0-36.57728-17.5104-36.57728-39.1168 0-21.58592 16.384-39.09632 36.57728-39.09632h146.28864c20.19328 0 36.5568 17.5104 36.5568 39.1168 0 21.58592-16.384 39.09632-36.5568 39.09632z m109.71136-195.50208h-256c-20.19328 0-36.57728-17.5104-36.57728-39.09632s16.384-39.1168 36.57728-39.1168h256c20.19328 0 36.57728 17.53088 36.57728 39.1168 0 21.58592-16.384 39.1168-36.57728 39.1168z m0-195.4816h-256c-20.19328 0-36.57728-17.5104-36.57728-39.1168 0-21.58592 16.384-39.09632 36.57728-39.09632h256c20.19328 0 36.57728 17.5104 36.57728 39.1168 0 21.58592-16.384 39.09632-36.57728 39.09632z m-370.15552 61.0304c-4.9152 2.048-13.25056-5.5296-25.02656-23.71584a249.0368 249.0368 0 0 0-34.95936-39.34208L431.5136 535.1424l-50.85184-2.19136 32.5632-189.0304h37.72416a191.24224 191.24224 0 0 0-114.2784-33.34144c-62.52544 0-169.1648 39.09632-169.1648 121.07776 0.57344 35.4304 16.81408 68.46464 43.78624 89.088l13.824-24.45312c13.824 9.0112 29.55264 14.17216 45.71136 15.03232 22.87616 0 41.2672-13.9264 41.2672-30.06464 0-30.16704-62.8736-38.23616-62.8736-89.43616 0-31.15008 34.304-47.28832 76.12416-47.28832h77.6192l-4.01408 18.82112s-22.17984 1.59744-37.82656 1.59744c-15.6672 0-40.3456 4.87424-40.3456 22.58944 0 40.93952 58.9824 43.74528 58.9824 92.61056 0 48.88576-65.26976 56.58624-93.26592 56.58624h-52.92032a209.7152 209.7152 0 0 0 102.87104 27.48416c78.97088 0 133.0176-36.6592 162.5088-81.85856 11.42784-18.08384 20.6848-23.59296 24.10496-21.504 3.44064 2.06848 0 12.20608 0 12.20608-17.14176 50.70848-80.896 128.3072-209.59232 128.3072S101.25312 510.95552 101.25312 433.3568c0-77.59872 86.40512-169.22624 209.26464-169.22624 152.6784 0 211.18976 124.37504 215.08096 140.02176 0-0.49152 2.9696 10.24-1.9456 12.45184z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveSocialSecurityApply.defaultProps = {
  size: 18,
};

export default IconAExecutiveSocialSecurityApply;
