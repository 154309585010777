/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersCustomerStorageAudit: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M878.36672 198.08256v418.48832c0.47104 26.27584-23.28576 52.4288-51.97824 52.4288h-105.04192c-48.41472 0-52.3264 104.69376-209.34656 104.69376s-157.02016-104.67328-209.34656-104.67328H197.98016c-28.73344 0-52.34688-23.87968-52.34688-52.44928V198.08256c0-28.8768 26.17344-52.4288 52.34688-52.4288a52.34688 52.34688 0 1 0 0-104.69376H145.16224A104.16128 104.16128 0 0 0 40.96 145.16224v733.71648A104.16128 104.16128 0 0 0 145.16224 983.04h733.71648A104.16128 104.16128 0 0 0 983.04 878.83776V145.12128A104.16128 104.16128 0 0 0 878.83776 40.96h-52.81792a52.34688 52.34688 0 0 0 0 104.67328c26.17344 0 52.34688 23.8592 52.34688 52.4288z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M514.19136 122.88c-59.35104 0-107.64288 43.06944-107.64288 96.01024 0 33.9968 20.2752 65.3312 52.57216 82.51392l-10.48576 75.63264h-87.79776c-18.26816 0-33.15712 13.312-33.15712 29.65504v61.99296c0 16.36352 14.88896 29.65504 33.1776 29.65504h302.2848c18.28864 0 33.1776-13.312 33.1776-29.65504v-61.99296c0-16.34304-14.88896-29.65504-33.1776-29.65504h-83.61984l-10.4448-75.53024c32.39936-17.16224 52.75648-48.5376 52.75648-82.61632C621.83424 165.94944 573.5424 122.88 514.19136 122.88z m164.2496 413.02016H345.55904c-9.89184 0-17.89952 8.3968-17.89952 18.75968 0 10.38336 8.00768 18.78016 17.89952 18.78016h332.84096c9.89184 0 17.89952-8.3968 17.89952-18.78016 0-10.36288-8.00768-18.75968-17.89952-18.75968z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconACustomersCustomerStorageAudit.defaultProps = {
  size: 18,
};

export default IconACustomersCustomerStorageAudit;
