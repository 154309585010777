/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveOfficeSuppliesStock: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M359.89504 534.34368c-4.42368 9.03168-4.42368 13.47584-4.42368 22.528 0 85.25824 71.51616 157.04064 156.44672 157.04064s156.4672-71.7824 156.4672-157.06112c0-8.86784 0-13.47584-4.44416-22.3232H1003.52v358.8096c0 49.29536-40.26368 89.7024-89.35424 89.7024H109.83424C60.74368 983.04 20.48 942.63296 20.48 893.3376V534.3232z m214.69184 0c4.42368 9.03168 4.42368 13.47584 4.42368 22.528 0 35.96288-31.25248 67.33824-66.92864 67.33824-35.84 0-67.09248-31.5392-67.09248-67.35872 0-9.03168 0-13.47584 4.42368-22.50752zM690.7904 40.96c49.09056 0 89.35424 40.40704 89.35424 89.7024v89.7024h134.02112c49.2544 0 89.35424 40.42752 89.35424 89.7024v134.5536H20.48v-134.5536c0-49.27488 40.26368-89.7024 89.35424-89.7024h134.02112V130.6624c0-49.29536 40.26368-89.7024 89.35424-89.7024z m-22.4256 89.53856h-312.7296c-13.4144 0-22.4256 9.03168-22.4256 22.528v67.33824h357.5808V153.00608c0-13.47584-9.0112-22.50752-22.4256-22.50752z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveOfficeSuppliesStock.defaultProps = {
  size: 18,
};

export default IconAExecutiveOfficeSuppliesStock;
