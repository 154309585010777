/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhichu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.999488 94.682429c230.108818 0 417.317571 187.207729 417.317571 417.317571S742.108307 929.317571 511.999488 929.317571c-230.109842 0-417.317571-187.207729-417.317571-417.317571S281.889647 94.682429 511.999488 94.682429M511.999488 66.861804c-245.445108 0-445.138196 199.693088-445.138196 445.138196 0 245.445108 199.693088 445.138196 445.138196 445.138196s445.138196-199.694111 445.138196-445.138196C957.137685 266.553868 757.444597 66.861804 511.999488 66.861804L511.999488 66.861804z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M415.059646 663.657049c-4.02057-13.530155-6.792707-27.495214-6.792707-42.111097 0-6.792707 1.468444-13.313214 2.337231-19.942191-86.451978-10.107195-148.996452-38.634926-148.996452-79.823l0 52.815903C261.608741 616.491843 326.326718 651.594315 415.059646 663.657049z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M639.586328 451.90225c38.198997-16.844643 61.944817-39.775912 61.944817-65.097624l0-31.298843c0-51.838645-98.460476-93.89653-219.960691-93.89653-121.44598 0-219.960691 42.057885-219.960691 93.89653l0 31.298843c0 51.892881 98.514711 93.89653 219.960691 93.89653 4.673439 0 9.074679-0.434905 13.748119-0.651846 32.549324-19.23611 71.944566-30.646997 114.599038-30.646997C620.078019 449.402313 629.804545 450.706005 639.586328 451.90225z"
        fill={getIconColor(color, 2, '#333333')}
      />
      <path
        d="M418.429393 570.195424c8.04114-22.007223 20.973684-41.839921 37.926798-59.065234-109.436458-4.238534-194.748473-35.102473-194.748473-83.245959l0 52.816926C261.608741 523.084453 327.955822 558.567595 418.429393 570.195424z"
        fill={getIconColor(color, 3, '#333333')}
      />
      <path
        d="M435.925882 707.23659c-2.118244-3.151783-4.184299-6.466272-6.085602-9.726525-96.449679-7.879458-168.230516-37.60241-168.230516-81.833797l0 52.816926c0 13.855566 6.955412 26.951839 19.561521 38.742373 34.559097 32.494065 111.339808 55.153134 200.399169 55.153134 4.184299 0 8.096399-0.435928 12.172227-0.490164C469.995839 747.555877 450.435341 728.863143 435.925882 707.23659z"
        fill={getIconColor(color, 4, '#333333')}
      />
      <path
        d="M510.152421 603.505064l222.569098 0 0 44.556798-222.569098 0 0-44.556798Z"
        fill={getIconColor(color, 5, '#333333')}
      />
    </svg>
  );
};

IconZhichu.defaultProps = {
  size: 18,
};

export default IconZhichu;
