/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTag: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M762.70933333 608.256c8.192-8.192 20.48-8.192 28.672 0 8.192 8.192 8.192 20.48 0 28.672l-276.48 276.48c-40.96 40.96-104.448 38.912-145.408 0l-245.76-245.76c-40.96-40.96-40.96-104.448 0-145.408l403.456-403.456c20.48-20.48 55.296-34.816 83.968-34.816l241.664-2.048c57.344 0 102.4 45.056 102.4 102.4l-2.048 241.664c0 28.672-16.384 65.536-34.816 83.968l-36.864 36.864c-8.192 8.192-20.48 8.192-28.672 0s-8.192-20.48 0-28.672l36.864-36.864c12.288-12.288 22.528-38.912 22.528-55.296l2.048-241.664c0-34.816-26.624-61.44-61.44-61.44l-241.664 2.048c-16.384 0-43.008 10.24-55.296 22.528l-403.456 403.456c-24.576 24.576-24.576 63.488 0 86.016l245.76 245.76c24.576 24.576 63.488 24.576 86.016 0l278.528-274.432z m-186.368-147.456c-47.104-47.104-47.104-124.928 0-174.08s124.928-47.104 174.08 0c47.104 47.104 47.104 124.928 0 174.08-49.152 49.152-126.976 49.152-174.08 0z m28.672-28.672c32.768 32.768 83.968 32.768 116.736 0 32.768-32.768 32.768-83.968 0-116.736-32.768-32.768-83.968-32.768-116.736 0-32.768 32.768-32.768 86.016 0 116.736z"
        fill={getIconColor(color, 0, '#32373B')}
      />
    </svg>
  );
};

IconTag.defaultProps = {
  size: 18,
};

export default IconTag;
