/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessPerformanceCaseSearch: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M673.44384 390.22592h168.69376c11.53024 0.63488 20.39808 10.42432 19.968 21.97504a21.95456 21.95456 0 0 1-19.968 22.75328h-169.08288a21.95456 21.95456 0 0 1-19.16928-22.7328 21.23776 21.23776 0 0 1 19.16928-23.16288l0.4096 1.16736z m0-113.80736h168.69376c11.59168 0.83968 20.41856 10.73152 19.968 22.36416a21.95456 21.95456 0 0 1-19.968 22.77376h-169.08288a21.95456 21.95456 0 0 1-19.16928-22.77376 21.56544 21.56544 0 0 1 19.16928-22.36416h0.4096z m0-110.26432h168.69376c11.65312 1.024 20.41856 11.0592 19.968 22.75328a21.56544 21.56544 0 0 1-19.968 22.36416h-169.08288c-11.264-1.2288-19.6608-10.99776-19.16928-22.36416-1.14688-11.8784 7.3728-22.50752 19.16928-23.94112l0.4096 1.18784zM103.58784 717.90592h814.08c9.44128-0.88064 18.1248-5.5296 24.10496-12.92288 5.9392-7.39328 8.704-16.87552 7.59808-26.3168V80.19968A35.20512 35.20512 0 0 0 917.66784 40.96H103.58784c-19.5584 2.048-33.75104 19.61984-31.70304 39.23968v600.04352a35.28704 35.28704 0 0 0 31.70304 37.6832z m861.06112 52.98176c9.0112 0 18.39104 21.1968 18.39104 31.78496 0 10.60864-6.656 32.9728-15.64672 32.9728H732.54912l59.10528 115.36384c4.096 10.36288 0.90112 22.17984-7.82336 29.04064a22.28224 22.28224 0 0 1-29.75744-7.0656l-71.61856-136.56064H354.07872l-78.27456 136.97024a26.95168 26.95168 0 0 1-32.11264 4.3008 30.26944 30.26944 0 0 1-7.04512-34.14016l62.2592-107.1104H56.60672c-8.23296 0-15.6672-22.38464-15.6672-32.9728 0-10.60864 6.656-32.58368 15.64672-32.58368zM360.448 204.8a155.648 155.648 0 0 1 125.31712 247.97184l80.85504 80.85504a23.30624 23.30624 0 0 1-32.9728 32.9728l-80.87552-80.85504A155.648 155.648 0 1 1 360.448 204.8z m0 46.63296a109.01504 109.01504 0 1 0 2.31424 218.03008 109.01504 109.01504 0 0 0-2.31424-218.03008z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessPerformanceCaseSearch.defaultProps = {
  size: 18,
};

export default IconABusinessPerformanceCaseSearch;
