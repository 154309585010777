/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRicheng: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M883.2 0a38.4 38.4 0 1 1 0 76.8256H793.6V204.8h84.1216c79.104 0 143.3088 65.6384 146.176 147.456l0.1024 5.7344v512.8192c0 84.48-65.3568 153.1904-146.2784 153.1904H146.2784C65.3568 1024 0 955.264 0 870.8096V357.9904C0 273.5104 65.3568 204.8 146.2784 204.8H230.4V76.8256H140.8A38.4 38.4 0 1 1 140.8 0h742.4zM855.552 281.6H168.448C117.9648 281.6 76.8 324.224 76.8 377.088v474.624C76.8 904.5504 117.9392 947.2 168.4224 947.2H855.552C906.0608 947.2 947.2 904.576 947.2 851.712V377.088c0-52.864-41.1392-95.488-91.6224-95.488zM716.8 204.8V76.8256H307.2V204.8h409.6z"
        fill={getIconColor(color, 0, '#888888')}
      />
      <path
        d="M469.3248 534.7328h-198.0928c-25.2416 0-45.696-21.504-45.696-48.0256s20.4544-48.0256 45.696-48.0256h198.0928c25.2672 0 45.7216 21.504 45.7216 48.0256s-20.48 48.0256-45.7216 48.0256z m289.536 256.1536H271.232c-25.2416 0-45.696-21.504-45.696-48.0256s20.4544-48.0256 45.696-48.0256h487.6288c25.2416 0 45.7216 21.504 45.7216 48.0256s-20.48 48.0256-45.7216 48.0256z"
        fill={getIconColor(color, 1, '#CCCCCC')}
      />
    </svg>
  );
};

IconRicheng.defaultProps = {
  size: 18,
};

export default IconRicheng;
