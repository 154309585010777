/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACommonToolsCaseLawWkInfo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M40.96 434.54464c0-57.22112 37.4784-131.15392 83.968-164.94592C124.928 269.59872 459.63264 20.48 512 20.48c52.34688 0 387.60448 249.48736 387.60448 249.48736C945.68448 303.59552 983.04 377.15968 983.04 434.50368V899.8912a102.8096 102.8096 0 0 1-30.49472 73.19552A105.22624 105.22624 0 0 1 878.61248 1003.52H145.38752a105.12384 105.12384 0 0 1-73.9328-30.4128A102.72768 102.72768 0 0 1 40.96 899.8912V434.54464z m157.02016-51.89632c0 28.7744 23.4496 51.75296 52.3264 51.75296a51.9168 51.9168 0 0 0 52.34688-51.75296c0-28.75392-23.4496-51.73248-52.34688-51.73248a51.9168 51.9168 0 0 0-52.3264 51.73248z m0 206.9504c0 28.7744 23.4496 51.75296 52.3264 51.75296a51.9168 51.9168 0 0 0 52.34688-51.75296c0-28.75392-23.4496-51.73248-52.34688-51.73248a51.9168 51.9168 0 0 0-52.3264 51.73248z m0 206.97088c0 28.75392 23.4496 51.73248 52.3264 51.73248a51.9168 51.9168 0 0 0 52.34688-51.73248c0-28.7744-23.4496-51.75296-52.34688-51.75296a51.9168 51.9168 0 0 0-52.3264 51.75296z m156.99968-413.9008a51.87584 51.87584 0 0 0 52.224 51.73248h366.592c13.86496-0.02048 27.15648-5.48864 36.94592-15.17568a51.4048 51.4048 0 0 0 15.27808-36.57728 51.87584 51.87584 0 0 0-52.224-51.73248h-366.592c-13.84448 0-27.136 5.46816-36.94592 15.1552a51.4048 51.4048 0 0 0-15.27808 36.57728z m0 206.92992a51.87584 51.87584 0 0 0 52.224 51.75296h366.592c13.86496 0 27.15648-5.46816 36.94592-15.17568a51.4048 51.4048 0 0 0 15.27808-36.57728 51.87584 51.87584 0 0 0-52.224-51.73248h-366.592c-13.84448 0-27.136 5.46816-36.94592 15.17568a51.4048 51.4048 0 0 0-15.27808 36.5568z m0 206.97088a51.87584 51.87584 0 0 0 52.224 51.73248h366.592c13.86496 0 27.15648-5.46816 36.94592-15.1552a51.4048 51.4048 0 0 0 15.27808-36.57728 51.87584 51.87584 0 0 0-52.224-51.75296h-366.592c-13.84448 0.02048-27.136 5.48864-36.94592 15.17568a51.4048 51.4048 0 0 0-15.27808 36.57728z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACommonToolsCaseLawWkInfo.defaultProps = {
  size: 18,
};

export default IconACommonToolsCaseLawWkInfo;
