/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLanguage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M841.03840533 220.37970386c0-0.42520551 0-0.85041184 1e-8-1.27561734L841.03840533 172.75665595c0-14.59873142-11.76402639-26.36275864-26.36275863-26.36275864-14.59873142 0-26.36275864 11.76402639-26.36275863 26.36275863 0 0 0 21.26028928-21.26028929 21.26028928l-513.0816394 0c-21.26028928 0-21.26028928-21.26028928-21.26028928-21.26028928 0-14.59873142-11.76402639-26.36275864-26.36275863-26.36275863S179.98515448 158.15792453 179.98515448 172.75665595l0 126.85305734c0 14.59873142 11.76402639 26.36275864 26.36275863 26.36275863s26.36275864-11.76402639 26.36275864-26.36275863l0-0.14173517c0-52.72551645 52.72551645-52.72551645 52.72551645-52.72551645l125.29396967 0c11.19708572 0.992147 46.34742975 7.3702337 49.18213476 47.33957675l0 481.89988257c-2.83470502 39.96934305-37.98504986 46.34742975-49.18213476 47.33957675l-31.18175684 0c-14.59873142 0-26.36275864 11.76402639-26.36275863 26.36275863s11.76402639 26.36275864 26.36275863 26.36275865l106.72665024 0 0 0 0 0 48.75692925 0 0 0 0 0 106.72665025 0c14.59873142 0 26.36275864-11.76402639 26.36275863-26.36275865s-11.76402639-26.36275864-26.36275863-26.36275863l-31.323492 0c-11.19708572-0.992147-46.34742975-7.3702337-49.18213477-47.33957675l0-481.89988257c2.83470502-39.96934305 37.98504986-46.34742975 49.18213477-47.33957675l125.1522345 0c0 0 52.72551645-0.14173517 52.72551645 52.72551645l0 0.14173517c0 14.59873142 11.76402639 26.36275864 26.36275862 26.36275863s26.36275864-11.76402639 26.36275864-26.36275863l0-77.95439292C841.03840533 221.23011487 841.03840533 220.80490936 841.03840533 220.37970386z"
        fill={getIconColor(color, 0, '#515151')}
      />
    </svg>
  );
};

IconLanguage.defaultProps = {
  size: 18,
};

export default IconLanguage;
