/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentFolderAdd24Regular01: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M874.24 657.493333a20.053333 20.053333 0 0 0-17.066667-17.066666H746.666667v-110.933334a20.906667 20.906667 0 0 0-17.066667-17.066666h-7.68a20.053333 20.053333 0 0 0-17.066667 17.066666V640h-111.36a20.053333 20.053333 0 0 0-17.066666 17.066667v7.68a20.906667 20.906667 0 0 0 17.066666 17.066666h110.506667v110.506667a20.053333 20.053333 0 0 0 17.066667 17.066667h7.68a20.906667 20.906667 0 0 0 17.066666-17.066667V682.666667h110.506667a20.906667 20.906667 0 0 0 17.066667-17.066667v-7.68z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M160 746.666667h-4.266667a32 32 0 0 1-27.733333-32V405.333333h209.066667A98.56 98.56 0 0 0 390.4 384l101.12-85.333333h334.08a32 32 0 0 1 27.733333 31.573333v85.333333a283.733333 283.733333 0 0 1 64 46.08V317.44a96.426667 96.426667 0 0 0-96-82.773333h-329.813333l-101.12-85.333334L384 145.066667A96 96 0 0 0 328.96 128H153.6a96 96 0 0 0-89.6 96V721.066667A96 96 0 0 0 160 810.666667h331.52a303.786667 303.786667 0 0 1-30.293333-64zM128 224v-4.266667a32 32 0 0 1 31.573333-27.733333h173.653334a32.426667 32.426667 0 0 1 16.213333 6.826667l80.64 67.413333-80.64 67.84-3.84 2.56a32.853333 32.853333 0 0 1-16.64 4.693333H128z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M725.333333 426.666667a234.666667 234.666667 0 1 0 234.666667 234.666666A234.666667 234.666667 0 0 0 725.333333 426.666667z m0 413.866666a179.2 179.2 0 1 1 179.2-179.2 179.2 179.2 0 0 1-179.2 179.2z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
 );
};

export default IcFluentFolderAdd24Regular01;

