/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAMoren: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M471.59296 638.60736v258.31424A86.07744 86.07744 0 0 1 385.4336 983.04H127.0784A86.07744 86.07744 0 0 1 40.96 896.9216V638.60736a86.07744 86.07744 0 0 1 86.1184-86.1184h258.37568a86.17984 86.17984 0 0 1 86.13888 86.1184z m401.85856-86.1184a86.07744 86.07744 0 0 1 86.1184 86.1184v258.31424A86.07744 86.07744 0 0 1 873.472 983.04H615.07584a86.07744 86.07744 0 0 1-86.1184-86.1184V638.60736a86.07744 86.07744 0 0 1 86.1184-86.1184h258.37568zM527.7696 225.71008l162.32448-162.304a76.34944 76.34944 0 0 1 108.15488 0l162.32448 162.304a76.32896 76.32896 0 0 1 0 108.1344l-162.32448 162.304a76.34944 76.34944 0 0 1-108.1344 0l-162.34496-162.304a76.63616 76.63616 0 0 1 0-108.1344z m-142.31552-161.11616a86.07744 86.07744 0 0 1 86.13888 86.09792v258.33472a86.07744 86.07744 0 0 1-86.13888 86.1184H127.0784A86.07744 86.07744 0 0 1 40.96 409.02656V150.69184a86.07744 86.07744 0 0 1 86.1184-86.09792h258.37568z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAMoren.defaultProps = {
  size: 18,
};

export default IconAMoren;
