/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationWorkflow: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M907.4688 40.96A75.5712 75.5712 0 0 1 983.04 116.5312v168.79616a75.5712 75.5712 0 0 1-75.5712 75.5712H491.9296a75.5712 75.5712 0 0 1-75.5712-75.5712v-55.48032H116.5312v244.36736h790.9376a75.5712 75.5712 0 0 1 75.5712 75.5712v226.65216a75.5712 75.5712 0 0 1-75.5712 75.5712H607.6416v55.48032A75.5712 75.5712 0 0 1 532.0704 983.04H116.5312A75.5712 75.5712 0 0 1 40.96 907.4688v-168.79616a75.5712 75.5712 0 0 1 75.5712-75.5712h415.5392a75.5712 75.5712 0 0 1 75.5712 75.5712v37.76512h299.8272V549.7856H116.5312A75.5712 75.5712 0 0 1 40.96 474.2144V229.84704a75.5712 75.5712 0 0 1 75.5712-75.5712h299.8272V116.5312A75.5712 75.5712 0 0 1 491.9296 40.96h415.5392z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationWorkflow.defaultProps = {
  size: 18,
};

export default IconAAdministrationWorkflow;
