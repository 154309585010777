/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQuanxuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M328.98048 737.792a42.666667 42.666667 0 0 1 4.864 54.442667l-4.864 5.973333-170.666667 170.666667a42.666667 42.666667 0 0 1-54.442666 4.864l-5.973334-4.864-85.333333-85.333334a42.666667 42.666667 0 0 1 54.442667-65.28l5.973333 4.864 55.125333 55.210667 140.458667-140.544a42.666667 42.666667 0 0 1 60.416 0zM981.353813 810.666667a42.666667 42.666667 0 1 1 0 85.333333H469.39648a42.666667 42.666667 0 0 1 0-85.333333h512zM328.98048 396.501333a42.666667 42.666667 0 0 1 4.864 54.442667l-4.864 5.973333-170.666667 170.666667a42.666667 42.666667 0 0 1-54.442666 4.821333l-5.973334-4.864-85.333333-85.333333a42.666667 42.666667 0 0 1 54.442667-65.28l5.973333 4.906667 55.125333 55.210666 140.458667-140.544a42.666667 42.666667 0 0 1 60.416 0zM981.353813 469.333333a42.666667 42.666667 0 1 1 0 85.333334H469.39648a42.666667 42.666667 0 0 1 0-85.333334h512zM328.98048 55.210667a42.666667 42.666667 0 0 1 4.864 54.442666l-4.864 5.973334-170.666667 170.666666a42.666667 42.666667 0 0 1-54.442666 4.821334l-5.973334-4.864-85.333333-85.333334A42.666667 42.666667 0 0 1 67.007147 135.68l5.973333 4.906667 55.125333 55.168 140.458667-140.501334a42.666667 42.666667 0 0 1 60.416 0zM981.353813 128.085333a42.666667 42.666667 0 1 1 0 85.333334H469.39648a42.666667 42.666667 0 0 1 0-85.333334h512z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconQuanxuan.defaultProps = {
  size: 18,
};

export default IconQuanxuan;
