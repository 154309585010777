/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Lianhezhangdan: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M782.01856 35.84c16.60928 0 30.0544 13.32736 30.0544 29.76256 0 16.4352-13.44512 29.75744-30.0544 29.75744H146.46784c-31.93344 0-57.87648 26.6496-57.87648 59.52s25.94304 59.52 57.87648 59.52h635.5456c6.22592 0 12.032 1.87392 16.8448 5.09952 2.176 1.4848 4.16768 3.24096 5.93408 5.23264 1.28512 1.46944 2.4064 3.06688 3.4048 4.7616 1.10592 1.98144 2.048 4.10112 2.69824 6.3232 0.768 2.64704 1.1776 5.4528 1.1776 8.3456v654.71488c0 49.3056-38.8608 89.28256-86.81472 89.28256H117.5296C69.57568 988.16 30.72 948.18304 30.72 898.87744V125.11744C30.72 75.81184 69.57568 35.84 117.5296 35.84h664.48896zM462.86848 373.76H384.4864v49.79712C327.05536 427.65312 281.6 474.4192 281.6 531.36384c0 59.61728 49.8176 108.12416 111.0528 108.12416h82.18112c19.62496 0 35.584 15.5392 35.584 34.6368 0 19.10272-15.95904 34.64192-35.584 34.64192H297.74848v76.3136h86.73792V834.56h78.3872v-49.47968h11.96544c62.83776 0 113.96096-49.7664 113.96096-110.9504 0-61.184-51.1232-110.95552-113.96096-110.95552H392.6528c-18.01728 0-32.67584-14.26944-32.67584-31.81056 0-17.54624 14.65856-31.81568 32.67584-31.81568h138.752V423.23456H462.86336V373.76z m398.88384-99.62496a11.50464 11.50464 0 0 0-11.56608 11.45344v138.12736a11.50464 11.50464 0 0 0 11.56608 11.44832h85.2736c25.5488 0 46.25408-20.5056 46.25408-45.80352V319.93856c0-25.2928-20.7104-45.80352-46.25408-45.80352h-85.2736z m-11.56608 256.2048a11.50464 11.50464 0 0 1 11.56608-11.44832h85.2736c25.5488 0 46.25408 20.5056 46.25408 45.80352v69.4272c0 25.2928-20.7104 45.7984-46.25408 45.7984h-85.2736a11.50976 11.50976 0 0 1-11.56608-11.44832v-138.12736z m11.56608 233.2928a11.50976 11.50976 0 0 0-11.56608 11.45344v138.12224a11.50464 11.50464 0 0 0 11.56608 11.45344h85.2736c25.5488 0 46.25408-20.51072 46.25408-45.80352v-69.42208c0-25.2928-20.7104-45.80352-46.25408-45.80352h-85.2736z"
        fill={getIconColor(color, 0, '#1585FF')}
      />
    </svg>
 );
};

export default Lianhezhangdan;

