/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconMale: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M891.73333333 165.54666666l2.94912 0.14336A30.72 30.72 0 0 1 922.45333333 196.26666666v184.32l-0.14336 2.94912A30.72 30.72 0 0 1 891.73333333 411.30666666l-2.94912-0.14336A30.72 30.72 0 0 1 861.01333333 380.58666666v-110.16192l-151.2448 151.26528A305.93024 305.93024 0 0 1 779.09333333 616.10666666c0 169.65632-137.54368 307.2-307.2 307.2-169.65632 0-307.2-137.54368-307.2-307.2 0-169.65632 137.54368-307.2 307.2-307.2 73.76896 0 141.45536 26.0096 194.41664 69.3248L817.53429333 226.98666666H707.41333333l-2.94912-0.14336A30.72 30.72 0 0 1 707.41333333 165.54666666h184.32zM471.89333333 370.34666666c-135.72096 0-245.76 110.03904-245.76 245.76s110.03904 245.76 245.76 245.76 245.76-110.03904 245.76-245.76-110.03904-245.76-245.76-245.76z"
        fill={getIconColor(color, 0, '#131415')}
      />
    </svg>
  );
};

IconMale.defaultProps = {
  size: 18,
};

export default IconMale;
