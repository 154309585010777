/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAnalyzeStatisticsBureauOfJusticeReport: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M1024 242.81088v67.33824h-20.11136a86.4256 86.4256 0 0 0-59.02336 23.57248l-0.2048 0.2048a36.88448 36.88448 0 0 1-25.9072 9.95328H105.0624c-10.09664 0-18.61632-3.25632-25.8048-9.95328-0.1024-0.1024-0.2048-0.1024-0.3072-0.2048a86.77376 86.77376 0 0 0-59.02336-23.47008H0v-67.44064L512 40.96l512 201.85088z m-36.90496 639.16032h-950.272c-10.01472 0-18.61632 3.25632-25.8048 9.95328-7.41376 6.71744-11.01824 14.60224-11.01824 23.7568V983.04h1024v-67.33824c0-9.17504-3.6864-17.05984-10.99776-23.7568a36.72064 36.72064 0 0 0-25.9072-9.97376z m-31.21152-67.25632v33.52576H68.32128v-33.52576c0-9.07264 3.60448-17.05984 11.01824-23.7568a36.82304 36.82304 0 0 1 25.8048-9.95328h31.51872l-0.1024-403.70176h136.54016v403.70176h68.34176V377.30304h136.43776v403.70176h68.62848V377.30304h136.45824v403.70176h68.11648V377.30304h136.45824v403.70176h31.51872c10.09664 0 18.51392 3.23584 25.8048 9.95328 7.31136 6.69696 11.01824 14.68416 11.01824 23.7568z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAnalyzeStatisticsBureauOfJusticeReport.defaultProps = {
  size: 18,
};

export default IconAAnalyzeStatisticsBureauOfJusticeReport;
