/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceAttendanceMyApplyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M798.5152 20.48H225.4848C135.3728 20.48 61.44 94.208 61.44 184.15616V905.216c0 12.288 3.91168 28.50816 12.24704 40.79616 8.31488 16.22016 28.8768 32.93184 48.96768 36.864 7.35232 1.47456 15.17568 2.4576 22.528 2.4576 13.23008 0 25.96864-2.94912 39.17824-10.81344l119.00928-65.37216c5.38624-3.44064 12.73856-5.89824 20.0704-5.89824 8.8064 0 18.1248 2.94912 24.9856 9.8304l69.55008 57.50784C446.85312 991.232 479.19104 1003.52 512 1003.52c32.80896 0 65.61792-12.288 94.02368-32.93184l73.95328-57.50784c6.8608-6.88128 14.70464-9.8304 23.51104-9.8304 6.8608 0 14.19264 1.96608 21.54496 5.89824l114.60608 65.37216c12.24704 8.35584 24.4736 12.288 41.14432 12.288 45.056 0 81.77664-36.864 81.77664-82.08384V184.15616C962.56 94.208 888.6272 20.48 798.5152 20.48z m-121.46688 539.68896a39.1168 39.1168 0 0 1-27.91424 11.30496H453.2224a40.87808 40.87808 0 0 1-27.91424-11.30496 41.1648 41.1648 0 0 1-11.264-28.01664v-196.608c0-21.62688 17.63328-39.3216 39.17824-39.3216 10.79296 0 20.5824 4.42368 27.91424 11.30496 6.8608 7.3728 11.264 17.2032 11.264 28.01664v157.2864h156.73344c21.54496 0 39.17824 17.69472 39.17824 39.3216a41.1648 41.1648 0 0 1-11.264 28.01664z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceAttendanceMyApplyList.defaultProps = {
  size: 18,
};

export default IconAHumanResourceAttendanceMyApplyList;
