/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFalv: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1115 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M718.390888 1024h-322.909842a38.971878 38.971878 0 0 1-38.971878-38.971878v-55.743703a38.971878 38.971878 0 0 1 38.971878-38.971877h94.645988v-578.732381h-361.88172a38.971878 38.971878 0 0 1-38.971877-38.971878v-55.743703a38.971878 38.971878 0 0 1 38.971877-38.971878h361.88172V38.971878a38.971878 38.971878 0 0 1 38.971877-38.971878h55.674111a38.971878 38.971878 0 0 1 38.971877 38.971878v138.920824h361.88172a38.971878 38.971878 0 0 1 38.971878 38.971878v55.743703a38.971878 38.971878 0 0 1-38.971878 38.971878h-361.88172v578.732381h94.645989a38.971878 38.971878 0 0 1 38.971877 38.971877v55.743703a38.971878 38.971878 0 0 1-38.971877 38.971878z m-317.342431-44.539289h311.77502v-44.608881h-94.645989a39.055389 39.055389 0 0 1-38.971877-38.971877v-589.867203a38.971878 38.971878 0 0 1 38.971877-38.971878h361.88172v-44.608881h-361.88172a38.971878 38.971878 0 0 1-38.971877-38.971878V44.539289h-44.539289v138.920824a38.971878 38.971878 0 0 1-38.971877 38.971878h-361.88172v44.608881h361.88172a38.971878 38.971878 0 0 1 38.971877 38.971878v589.825447a39.055389 39.055389 0 0 1-38.971877 38.971878h-94.645988z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M378.778812 645.694418a178.254584 178.254584 0 0 1-356.509168 0l178.254584-356.314309z"
        fill={getIconColor(color, 1, '#FF505E')}
      />
      <path
        d="M200.524228 846.246483C89.955444 846.246483 0 756.263202 0 645.694418a22.269644 22.269644 0 0 1 2.352231-9.965666l178.254584-356.314309a22.269644 22.269644 0 0 1 39.834826 0l178.254584 356.314309a22.269644 22.269644 0 0 1 2.352232 9.965666c0 110.568784-89.955444 200.552065-200.524229 200.552065z m-155.88751-195.416129a155.98494 155.98494 0 0 0 311.77502 0l-155.88751-311.705427z"
        fill={getIconColor(color, 2, '#333333')}
      />
      <path
        d="M1093.57872 645.694418a178.254584 178.254584 0 0 1-356.49525 0l178.254584-356.314309z"
        fill={getIconColor(color, 3, '#FF505E')}
      />
      <path
        d="M915.338054 846.246483c-110.568784 0-200.524228-89.941526-200.524228-200.51031a22.269644 22.269644 0 0 1 2.352231-9.965666l178.254584-356.314308a22.269644 22.269644 0 0 1 39.834827 0L1113.482214 635.728752a22.102622 22.102622 0 0 1 2.352232 9.965666C1115.848364 756.263202 1025.906838 846.246483 915.338054 846.246483z m-155.88751-195.416129a155.98494 155.98494 0 0 0 311.77502 0l-155.88751-311.705427zM22.269644 623.424773h356.509168v44.539289H22.269644z"
        fill={getIconColor(color, 4, '#333333')}
      />
      <path
        d="M737.08347 623.424773h356.509168v44.539289H737.08347z"
        fill={getIconColor(color, 5, '#333333')}
      />
    </svg>
  );
};

IconFalv.defaultProps = {
  size: 18,
};

export default IconFalv;
