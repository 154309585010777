/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXingbieNan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M927.698 0.006H652.811v96.295h206.791L641.926 313.996c-67.461-52.85-152.366-84.441-244.7-84.441-219.38 0-397.22 177.839-397.22 397.22 0 219.38 177.839 397.22 397.22 397.22 219.381 0 397.22-177.84 397.22-397.22 0-92.335-31.586-177.234-84.436-244.689l217.688-217.688V371.19h96.295V0.006h-96.295zM397.226 927.698c-165.931 0-300.924-134.993-300.924-300.924 0-165.932 134.993-300.924 300.924-300.924 165.932 0 300.924 134.992 300.924 300.924-0.001 165.931-134.993 300.924-300.924 300.924z"
        fill={getIconColor(color, 0, '#040000')}
      />
    </svg>
  );
};

IconXingbieNan.defaultProps = {
  size: 18,
};

export default IconXingbieNan;
