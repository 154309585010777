/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShousuo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M967.04 1016.32a47.36 47.36 0 0 1-32.64-14.08L512 579.84l-422.4 422.4a45.44 45.44 0 0 1-64 0 45.44 45.44 0 0 1 0-64l453.76-456.32a46.08 46.08 0 0 1 64 0L998.4 936.96a45.44 45.44 0 0 1 0 64 46.08 46.08 0 0 1-31.36 15.36z"
        fill={getIconColor(color, 0, '#323333')}
      />
      <path
        d="M56.96 560.64a45.44 45.44 0 0 1-32.64-13.44 45.44 45.44 0 0 1 0-64L479.36 26.88a47.36 47.36 0 0 1 64 0L998.4 481.92a45.44 45.44 0 0 1 0 64 46.08 46.08 0 0 1-64 0L512 128 89.6 547.2a46.72 46.72 0 0 1-32.64 13.44z"
        fill={getIconColor(color, 1, '#323333')}
      />
    </svg>
  );
};

IconShousuo.defaultProps = {
  size: 18,
};

export default IconShousuo;
