/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACommonToolsNavigation: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M845.312 178.93376C756.16256 90.0096 637.91104 40.96 512.1024 40.96 386.2528 40.96 268.00128 90.0096 178.95424 178.93376 89.94816 267.85792 40.96 386.12992 40.96 512a468.33664 468.33664 0 0 0 137.91232 333.18912C267.79648 934.0928 386.048 983.04 511.97952 983.04c125.82912 0 244.18304-49.0496 333.33248-137.97376 183.64416-183.62368 183.64416-482.5088 0-666.13248zM629.92384 590.49984L284.42624 853.8112l109.62944-420.31104L739.5328 170.1888l-109.62944 420.31104zM512 562.83136a50.70848 50.70848 0 1 0 0-101.45792c-13.55776 0-26.27584 5.26336-35.84 14.848a50.50368 50.50368 0 0 0-14.92992 35.88096A50.70848 50.70848 0 0 0 512 562.83136z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACommonToolsNavigation.defaultProps = {
  size: 18,
};

export default IconACommonToolsNavigation;
