/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconYewuguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M716.8 253.561905c-4.87619-112.152381-92.647619-199.92381-204.8-199.92381-112.152381 0-199.92381 92.647619-204.8 199.92381H58.514286v716.8h911.847619V253.561905h-253.561905z m-204.8-102.4c58.514286 0 102.4 43.885714 107.27619 102.4H404.72381c4.87619-53.638095 48.761905-102.4 107.27619-102.4z m355.961905 721.67619H156.038095v-331.580952h716.8v331.580952z m0-429.104762H156.038095V351.085714h716.8v92.647619z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M375.466667 609.52381h273.066666v97.523809H375.466667z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconYewuguanli.defaultProps = {
  size: 18,
};

export default IconYewuguanli;
