/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseCaseInfoChange: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M728.18688 0c24.12544 0 47.28832 9.80992 64.34816 27.25888A94.16704 94.16704 0 0 1 819.2 93.0816V515.8912A266.8544 266.8544 0 0 0 773.69344 512c-150.81472 0-273.08032 125.05088-273.08032 279.26528 0 97.15712 48.49664 182.6816 122.0608 232.73472H91.01312C40.7552 1024 0 982.3232 0 930.9184V93.0816C0 41.6768 40.7552 0 91.01312 0h637.17376z m-411.2384 457.1136h-111.67744c-26.86976 0-48.61952 21.97504-48.61952 49.11104 0 27.136 21.74976 49.13152 48.61952 49.13152h111.67744c26.84928 0 48.59904-21.99552 48.59904-49.13152s-21.74976-49.11104-48.59904-49.11104zM556.83072 203.24352H205.27104c-26.86976 0-48.61952 21.99552-48.61952 49.152 0 27.11552 21.87264 49.11104 48.61952 49.11104h351.55968c26.84928 0 48.61952-21.99552 48.61952-49.13152S583.68 203.24352 556.83072 203.24352zM798.72 573.44c-124.416 0-225.28 100.864-225.28 225.28s100.864 225.28 225.28 225.28 225.28-100.864 225.28-225.28a225.28 225.28 0 0 0-225.28-225.28z m-118.64064 160.5632h189.2352l-44.25728-45.17888a20.5824 20.5824 0 0 1 0-28.71296 19.59936 19.59936 0 0 1 28.11904 0l78.21312 79.872c12.53376 12.77952 3.64544 34.65216-14.06976 34.65216H680.07936a20.31616 20.31616 0 0 1 0-40.61184z m237.28128 129.4336h-189.2352l44.27776 45.19936c7.7824 7.9872 7.7824 20.72576 0 28.71296a19.59936 19.59936 0 0 1-28.11904 0l-78.2336-79.872c-12.51328-12.75904-3.62496-34.65216 14.06976-34.65216h237.24032a20.31616 20.31616 0 0 1 0 40.61184z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseCaseInfoChange.defaultProps = {
  size: 18,
};

export default IconABusinessCaseCaseInfoChange;
