/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconYanjingZhengyan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 284.19072c72.35072 0 160.18944 36.57728 247.33184 102.9888 64.68608 49.30048 108.30848 99.36896 126.17728 125.17888-17.8432 25.5232-62.29504 75.64288-129.3312 125.63456-88.04864 65.664-174.76608 101.82144-244.17792 101.82144-70.528 0-157.68064-36.58752-245.4016-103.02464-65.13664-49.3312-109.26592-99.0976-127.67744-125.05088 18.13504-25.984 61.57312-75.648 125.75232-124.55936C351.81056 320.76288 439.64928 284.19072 512 284.19072m0-70.87616c-220.32896 0-450.56 250.59328-450.56 298.68544s235.18208 298.68544 450.56 298.68544c210.42688 0 450.56-245.5296 450.56-298.68544 0-48.09216-230.23104-298.68544-450.56-298.68544z"
        fill={getIconColor(color, 0, '#666666')}
      />
      <path
        d="M512 446.18752c36.28544 0 65.81248 29.52192 65.81248 65.81248s-29.52704 65.81248-65.81248 65.81248S446.18752 548.29056 446.18752 512 475.71456 446.18752 512 446.18752m0-70.87616c-75.48928 0-136.68864 61.19424-136.68864 136.68864S436.51072 648.68864 512 648.68864s136.68864-61.19424 136.68864-136.68864S587.48928 375.31136 512 375.31136z"
        fill={getIconColor(color, 1, '#666666')}
      />
    </svg>
  );
};

IconYanjingZhengyan.defaultProps = {
  size: 18,
};

export default IconYanjingZhengyan;
