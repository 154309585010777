/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Beizhu: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M681.301333 170.666667C705.621333 170.666667 725.333333 189.824 725.333333 213.461333v411.733334c0 23.637333-19.712 42.794667-44.032 42.794666H158.08l-1.621333 1.578667-98.773334 95.914667c-5.546667 5.376-15.018667 1.578667-15.018666-6.058667v-133.674667-412.288C42.666667 189.824 62.378667 170.666667 86.698667 170.666667h594.602666z"
        fill={getIconColor(color, 0, '#FFCA00')}
      />
      <path
        d="M746.666667 896a234.666667 234.666667 0 1 0 0-469.333333 234.666667 234.666667 0 0 0 0 469.333333z"
        fill={getIconColor(color, 1, '#3A7FCC')}
      />
      <path
        d="M770.133333 567.466667a23.466667 23.466667 0 0 0-46.933333 0v70.4H652.8a23.466667 23.466667 0 1 0 0 46.933333h70.4v70.4a23.466667 23.466667 0 1 0 46.933333 0v-70.4h70.4a23.466667 23.466667 0 1 0 0-46.933333h-70.4V567.466667z"
        fill={getIconColor(color, 2, '#FFFFFF')}
      />
    </svg>
 );
};

export default Beizhu;

