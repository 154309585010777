/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentShieldKeyhole24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M597.12 469.333333a85.333333 85.333333 0 0 1-53.12 79.018667v102.314667a32 32 0 0 1-64 0v-102.144A85.333333 85.333333 0 1 1 597.12 469.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M160 213.333333a32 32 0 0 0-32 32V469.333333c0 213.376 126.208 370.176 372.266667 467.114667a32 32 0 0 0 23.466666 0C769.792 839.509333 896 682.666667 896 469.333333V245.333333a32 32 0 0 0-32-32c-113.621333 0-224.341333-40.234667-332.8-121.6a32 32 0 0 0-38.4 0C384.341333 173.098667 273.621333 213.333333 160 213.333333z m32 256V276.394667c109.952-6.485333 216.746667-46.506667 320-119.466667 103.253333 72.96 210.048 112.981333 320 119.466667V469.333333c0 181.589333-104.661333 314.837333-320 402.858667C296.661333 784.170667 192 650.922667 192 469.333333z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
 );
};

export default IcFluentShieldKeyhole24Regular;

