/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessBorrowReceiveBorrow: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M102.07232 1024C68.46464 1024 40.96 996.352 40.96 962.56V61.44c0-33.792 27.50464-61.44 61.11232-61.44h621.2608c33.60768 0 61.11232 27.648 61.11232 61.44v266.24h137.48224C955.53536 327.68 983.04 355.328 983.04 389.12v573.44c0 33.792-27.50464 61.44-61.11232 61.44z m682.37312-81.92h117.12512V409.6h-117.1456v532.48zM285.3888 430.08H163.18464a40.96 40.96 0 0 0 0 81.92h122.20416a40.96 40.96 0 0 0 0-81.92z m183.82848-143.36H163.67616a40.96 40.96 0 0 0 0 81.92h305.5616a40.96 40.96 0 0 0 0-81.92z m162.95936-143.36H163.67616a40.96 40.96 0 0 0 0 81.92h468.50048a40.96 40.96 0 0 0 0-81.92z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessBorrowReceiveBorrow.defaultProps = {
  size: 18,
};

export default IconABusinessBorrowReceiveBorrow;
