/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksScheduleCourtInformations: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M229.376 776.66304V474.19392c20.76672 0 37.6832-16.9984 37.6832-37.80608v-37.80608h-188.416v37.80608c0 20.82816 16.93696 37.80608 37.6832 37.80608v302.46912h113.0496z m226.0992 0V474.19392c20.76672 0 37.6832-16.9984 37.6832-37.80608v-37.80608h-188.416v37.80608c0 20.82816 16.93696 37.80608 37.6832 37.80608v302.46912h113.0496z m226.0992 0V474.19392c20.76672 0 37.6832-16.9984 37.6832-37.80608v-37.80608h-188.416v37.80608c0 20.82816 16.93696 37.80608 37.6832 37.80608v302.46912h113.0496z m226.0992 0V474.19392c20.74624 0 37.6832-16.9984 37.6832-37.80608v-37.80608h-188.416v37.80608c0 20.82816 16.93696 37.80608 37.6832 37.80608v302.46912h113.0496z m37.6832 113.43872h-37.6832v-75.63264H116.3264v75.63264H78.6432c-20.74624 0-37.6832 16.97792-37.6832 37.80608v37.80608C40.96 986.5216 57.89696 1003.52 78.6432 1003.52h866.7136c20.74624 0 37.6832-16.9984 37.6832-37.80608v-37.80608a37.82656 37.82656 0 0 0-37.6832-37.80608zM77.16864 360.77568h869.66272c20.00896 0 36.20864-16.91648 36.20864-37.80608 0-15.872-9.33888-29.47072-22.58944-35.08224L529.87904 25.62048a34.9184 34.9184 0 0 0-36.49536 0L58.92096 290.28352c-14.1312 8.64256-20.97152 26.07104-16.71168 42.53696 4.28032 16.50688 18.6368 27.9552 34.95936 27.9552zM512 147.16928a81.1008 81.1008 0 0 1 81.03936 81.28512A81.08032 81.08032 0 0 1 512 309.71904a81.05984 81.05984 0 0 1-80.95744-81.26464A81.08032 81.08032 0 0 1 512 147.18976z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksScheduleCourtInformations.defaultProps = {
  size: 18,
};

export default IconAWorksScheduleCourtInformations;
