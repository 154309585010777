/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Fapiaoliebiao: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M0 139.642435A139.642435 139.642435 0 0 1 139.642435 0H667.158261a139.642435 139.642435 0 0 1 139.620174 139.642435v480.968348H1024v201.683478A201.683478 201.683478 0 0 1 822.316522 1024H139.620174A139.642435 139.642435 0 0 1 0 884.357565V139.642435z m806.778435 791.262608h15.515826a108.610783 108.610783 0 0 0 108.633043-108.610782v-108.588522h-124.148869v217.199304zM232.737391 248.253217a46.525217 46.525217 0 1 0 0 93.072696h341.325913a46.525217 46.525217 0 0 0 0-93.072696H232.737391z m0 217.199305a46.525217 46.525217 0 1 0 0 93.094956h341.325913a46.525217 46.525217 0 0 0 0-93.094956H232.737391z m0 217.221565a46.525217 46.525217 0 1 0 0 93.072696h155.158261a46.525217 46.525217 0 1 0 0-93.072696h-155.158261z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Fapiaoliebiao;

