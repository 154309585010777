/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceLeave: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M739.69664 20.48c31.3344 0 56.9344 26.0096 56.9344 57.87648h113.74592c62.54592 0 113.74592 52.03968 113.62304 115.6096v693.94432C1024 951.48032 972.8 1003.52 910.25408 1003.52H113.74592C51.2 1003.52 0 951.48032 0 887.9104V193.9456c0-63.56992 51.2-115.6096 113.74592-115.6096h113.74592c0-31.8464 25.6-57.87648 56.9344-57.87648 31.3344 0 56.9344 26.0096 56.9344 57.87648h341.4016c0-31.8464 25.6-57.87648 56.9344-57.87648z m-61.27616 802.14016H345.57952c-9.89184 0-17.89952 8.3968-17.89952 18.75968 0 10.38336 8.00768 18.78016 17.89952 18.78016h332.84096c9.89184 0 17.89952-8.3968 17.89952-18.78016 0-10.36288-8.00768-18.75968-17.89952-18.75968zM514.17088 409.6c-59.33056 0-107.6224 43.06944-107.6224 96.01024 0 33.9968 20.2752 65.3312 52.57216 82.51392l-10.48576 75.63264h-87.79776c-18.26816 0-33.15712 13.312-33.15712 29.65504v61.99296c0 16.36352 14.88896 29.65504 33.1776 29.65504h302.2848c18.28864 0 33.1776-13.312 33.1776-29.65504v-61.99296c0-16.34304-14.88896-29.65504-33.1776-29.65504h-83.61984l-10.4448-75.53024c32.39936-17.16224 52.75648-48.5376 52.75648-82.61632 0-52.9408-48.29184-96.01024-107.64288-96.01024zM227.65568 193.96608h-56.9344c-31.3344 0-56.9344 26.0096-56.9344 57.87648 0 31.8464 25.6 57.87648 56.9344 57.87648h682.74176c31.3344 0 56.9344-26.0096 56.9344-57.87648 0-31.8464-25.6-57.87648-56.9344-57.87648h-56.66816c0 31.8464-25.6 57.87648-56.9344 57.87648-31.3344 0-56.9344-26.0096-56.9344-57.87648h-341.4016c0 31.8464-25.6 57.87648-56.9344 57.87648-31.3344 0-56.9344-26.0096-56.9344-57.87648z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceLeave.defaultProps = {
  size: 18,
};

export default IconAHumanResourceLeave;
