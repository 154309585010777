/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialElectronicInvoicesMyElectronicInvoices: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M896.674994 0C956.353582 0 1003.518919 51.138447 1003.518919 112.844552v154.41886c0 61.726584-47.165336 112.844552-106.843925 112.844552h-4.77183v608.193222c0 29.081536-32.952248 45.916059-56.483716 28.876736l-93.880113-68.03441-93.880113 68.01393c-12.472293 9.03166-29.327295 9.03166-41.779109 0L512 949.163992l-93.880113 67.99345a35.594162 35.594162 0 0 1-41.779109 0l-93.880113-67.99345-93.880113 67.99345c-23.551948 17.059802-56.504196 0.22528-56.504196-28.876736V380.107964h-4.75135C67.646418 380.107964 20.481081 328.989996 20.481081 267.263412V112.844552C20.481081 51.138447 67.646418 0 127.325006 0h769.349988zM512.08192 327.679279c-53.677962 0-97.177386 44.216223-97.177386 98.754343 0 44.994461 29.491135 82.923338 69.959526 94.822191-99.90122 13.59869-177.663609 101.170977-177.663609 206.315066v1.290238c0 16.977883 2.047995 33.525686 5.857267 49.377171h397.884564c3.829752-15.851485 5.857267-32.399289 5.857267-49.377171v-1.290238c0.08192-105.144089-77.598549-192.716376-177.499769-206.335546 40.447911-11.980774 69.959526-49.88917 69.959526-94.801711 0-54.53812-43.499424-98.754343-97.177386-98.754343zM896.674994 71.270243H127.325006c-19.005398 0-35.614642 18.02236-35.614641 41.574309v154.41886c0 23.572428 16.609243 41.574309 35.635121 41.574309h4.73087v-80.772943a71.270243 71.270243 0 0 1 71.249763-71.270243h617.347762a71.270243 71.270243 0 0 1 71.249763 71.270243l-0.02048 80.772943h4.77183c19.005398 0 35.614642-18.00188 35.614641-41.574309V112.844552c0-23.551948-16.609243-41.574309-35.635121-41.574309z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialElectronicInvoicesMyElectronicInvoices.defaultProps = {
  size: 18,
};

export default IconAFinancialElectronicInvoicesMyElectronicInvoices;
