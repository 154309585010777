/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHetongguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M862.9 128.9l0.1 0.1v767.5l-0.1 0.1H161.1l-0.1-0.1V129l0.1-0.1h701.8m0.1-64H161c-35.2 0-64 28.8-64 64v767.7c0 35.2 28.8 64 64 64h702c35.2 0 64-28.8 64-64V128.9c0-35.2-28.8-64-64-64z"
        fill={getIconColor(color, 0, '#666666')}
      />
      <path
        d="M702.3 289H319.1c-17.7 0-32-14.3-32-32s14.3-32 32-32h383.3c17.7 0 32 14.3 32 32s-14.4 32-32.1 32zM702.3 416.8H319.1c-17.7 0-32-14.3-32-32s14.3-32 32-32h383.3c17.7 0 32 14.3 32 32s-14.4 32-32.1 32zM447.1 546h-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h128c17.7 0 32 14.3 32 32s-14.3 32-32 32zM560.4 851.5c-6.6 0-13.2-2.1-18.8-6.1-9.9-7.2-14.8-19.3-12.7-31.3l12.3-71.6-52-50.7c-8.7-8.5-11.9-21.2-8.1-32.8 3.8-11.6 13.8-20 25.8-21.8l71.9-10.4 32.1-65.1c5.4-10.9 16.5-17.8 28.7-17.8s23.3 6.9 28.7 17.8l32.1 65.1 71.9 10.4c12.1 1.8 22.1 10.2 25.8 21.8 3.8 11.6 0.6 24.3-8.1 32.8l-52 50.7 12.3 71.6c2.1 12-2.9 24.1-12.7 31.3-9.9 7.2-22.9 8.1-33.7 2.4L639.6 814l-64.3 33.8c-4.7 2.5-9.8 3.7-14.9 3.7z m79.1-105.6c5.1 0 10.2 1.2 14.9 3.7l21.8 11.4-4.2-24.2c-1.8-10.4 1.7-21 9.2-28.3l17.6-17.2-24.3-3.5c-10.4-1.5-19.4-8.1-24.1-17.5l-10.9-22.1-10.9 22.1c-4.7 9.4-13.7 16-24.1 17.5l-24.3 3.5 17.6 17.2c7.5 7.4 11 17.9 9.2 28.3l-4.2 24.2 21.8-11.4c4.7-2.5 9.8-3.7 14.9-3.7z"
        fill={getIconColor(color, 1, '#666666')}
      />
    </svg>
  );
};

IconHetongguanli.defaultProps = {
  size: 18,
};

export default IconHetongguanli;
