/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDualScreenSettings24Regular01: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M682.666667 780.373333h-68.266667a31.573333 31.573333 0 0 0-27.306667 35.84 32 32 0 0 0 31.573334 27.733334h68.266666a31.573333 31.573333 0 0 0 27.733334-31.573334 31.573333 31.573333 0 0 0-32-32zM492.8 355.84A85.333333 85.333333 0 0 1 554.666667 458.666667v4.266666l-7.68 26.88a213.333333 213.333333 0 0 0 63.146666 39.253334l20.906667-21.76a85.333333 85.333333 0 0 1 120.746667-3.413334l2.986666 3.413334 21.333334 22.186666a229.12 229.12 0 0 0 63.146666-38.826666l-8.106666-29.44a85.333333 85.333333 0 0 1 58.026666-105.386667h3.413334l24.746666-6.4a246.613333 246.613333 0 0 0 0-77.226667l-21.333333-6.4a85.333333 85.333333 0 0 1-63.146667-102.826666v-4.266667l7.68-26.88a230.4 230.4 0 0 0-63.146666-39.253333l-21.333334 22.186666a85.333333 85.333333 0 0 1-120.32 2.986667l-3.413333-2.986667-20.906667-22.613333a217.173333 217.173333 0 0 0-63.573333 39.253333l8.533333 29.013334a85.333333 85.333333 0 0 1-58.453333 105.813333h-2.986667l-25.6 6.826667a234.666667 234.666667 0 0 0 0 77.226666z m100.693333-105.813333a134.826667 134.826667 0 0 0 15.36-85.333334 135.253333 135.253333 0 0 0 85.333334 29.44 136.533333 136.533333 0 0 0 85.333333-29.866666 137.813333 137.813333 0 0 0 85.333333 147.626666 136.106667 136.106667 0 0 0-85.333333 148.906667 136.106667 136.106667 0 0 0-170.666667 0 135.253333 135.253333 0 0 0-18.773333-91.306667 133.12 133.12 0 0 0-66.56-55.893333 135.68 135.68 0 0 0 69.973333-63.573333z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M693.333333 247.04a64 64 0 1 1-61.866666 64 63.146667 63.146667 0 0 1 61.866666-64z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M864 529.92V896a10.666667 10.666667 0 0 1-10.666667 10.666667h-320V537.6a280.746667 280.746667 0 0 1-64-63.146667v433.493334H149.333333a10.666667 10.666667 0 0 1-10.666666-11.946667V364.373333a10.666667 10.666667 0 0 1 10.666666-10.666666h270.08a287.573333 287.573333 0 0 1-3.413333-42.666667v-21.333333H149.333333a74.666667 74.666667 0 0 0-74.666666 74.666666V896a74.666667 74.666667 0 0 0 74.666666 75.946667H853.333333A74.666667 74.666667 0 0 0 928 896V459.093333a288.853333 288.853333 0 0 1-64 70.826667z"
        fill={getIconColor(color, 2, '#333333')}
      />
      <path
        d="M319.573333 780.373333h-4.266666a31.573333 31.573333 0 0 0-27.306667 35.84 32 32 0 0 0 31.573333 27.733334H388.266667a32 32 0 0 0-4.266667-63.573334z"
        fill={getIconColor(color, 3, '#333333')}
      />
    </svg>
 );
};

export default IcFluentDualScreenSettings24Regular01;

