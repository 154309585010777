/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDocumentMultiple24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 181.333333A96 96 0 0 1 266.666667 85.333333h186.922666a96 96 0 0 1 67.882667 28.117334l197.077333 197.077333c18.005333 18.005333 28.117333 42.410667 28.117334 67.84v357.632a96 96 0 0 1-96 96h-384A96 96 0 0 1 170.666667 736v-554.666667z m96-32a32 32 0 0 0-32 32v554.666667c0 17.664 14.336 32 32 32h384a32 32 0 0 0 32-32V383.914667h-138.496a96 96 0 0 1-96-96V149.333333H266.666667z m245.504 45.312v93.269334c0 17.664 14.336 32 32 32h93.226666l-125.226666-125.269334zM282.837333 874.666667A96 96 0 0 0 373.333333 938.666667h277.333334A202.666667 202.666667 0 0 0 853.333333 736v-250.922667a96 96 0 0 0-28.16-67.882666l-35.84-35.882667v354.688a138.666667 138.666667 0 0 1-138.666666 138.666667H282.837333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentDocumentMultiple24Regular;

