/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const RenyuanguanliXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M865.536 232.106667A158.293333 158.293333 0 0 1 938.666667 365.696V682.666667a256 256 0 0 1-256 256H365.696a158.378667 158.378667 0 0 1-133.589333-73.130667H682.666667A182.869333 182.869333 0 0 0 865.536 682.666667V232.106667zM658.304 85.333333a158.464 158.464 0 0 1 158.464 158.464v414.506667a158.464 158.464 0 0 1-158.464 158.464H243.797333A158.464 158.464 0 0 1 85.333333 658.304V243.797333A158.464 158.464 0 0 1 243.797333 85.333333h414.506667z m0 73.130667H243.797333a85.333333 85.333333 0 0 0-85.333333 85.333333v414.506667a85.333333 85.333333 0 0 0 85.333333 85.333333h414.506667a85.333333 85.333333 0 0 0 85.333333-85.333333V243.797333a85.333333 85.333333 0 0 0-85.333333-85.333333zM597.333333 451.029333a85.333333 85.333333 0 0 1 85.333334 85.333334v24.405333c0 51.242667-17.92 79.786667-57.557334 107.008-38.741333 26.709333-96.597333 39.253333-174.08 39.253333-65.664 0-123.050667-12.757333-164.522666-38.997333-41.984-26.581333-67.072-54.656-67.072-107.264v-24.405333a85.333333 85.333333 0 0 1 85.333333-85.333334z m0 73.173334H304.768a12.202667 12.202667 0 0 0-12.202667 12.16v24.405333c0 11.776 2.261333 14.72 6.314667 20.053333 1.450667 1.92 2.858667 3.84 4.181333 5.888 7.68 11.178667 18.218667 20.181333 30.464 26.069334 27.52 14.293333 67.84 21.12 117.504 21.12 62.378667 0 102.186667-6.954667 126.208-20.821334a59.306667 59.306667 0 0 0 24.533334-25.173333c1.024-2.048 1.962667-3.754667 2.773333-5.205333 3.285333-5.888 4.992-8.96 4.992-21.930667v-24.405333a12.202667 12.202667 0 0 0-12.202667-12.16z m-146.304-341.333334a121.898667 121.898667 0 1 1 0 243.797334 121.898667 121.898667 0 0 1 0-243.797334z m0 73.130667a48.768 48.768 0 1 0 0 97.536 48.768 48.768 0 0 0 0-97.536z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default RenyuanguanliXianxing;

