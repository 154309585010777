/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveIntranetInfo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M680.26368 694.33344C652.6976 863.96928 587.69408 983.04 512.12288 983.04c-75.5712 0-140.57472-119.07072-168.1408-288.70656z m-397.4144 0c16.73216 108.1344 48.66048 204.8 95.0272 269.0048A472.04352 472.04352 0 0 1 77.6192 694.33344z m664.02304-0.14336a472.65792 472.65792 0 0 1-300.21632 269.02528c46.34624-64.1024 78.15168-160.768 94.88384-269.02528zM687.98464 390.43072c3.9936 38.87104 6.30784 79.42144 6.30784 121.50784 0 42.0864-2.31424 82.51392-6.30784 121.50784H336.01536a1186.87744 1186.87744 0 0 1-6.30784-121.50784c0-42.0864 2.31424-82.6368 6.30784-121.50784z m278.8352 0c10.30144 38.87104 16.22016 79.42144 16.22016 121.50784 0 42.0864-5.9392 82.51392-16.34304 121.50784h-217.7024c3.9936-39.77216 6.30784-80.6912 6.30784-121.50784 0-40.79616-2.31424-81.6128-6.30784-121.50784z m-691.56864 0a1222.5536 1222.5536 0 0 0-6.30784 121.50784c0 40.91904 2.31424 81.7152 6.06208 121.50784H57.30304C47.0016 594.57536 40.96 554.02496 40.96 511.93856c0-42.0864 6.0416-82.51392 16.34304-121.50784zM512.12288 40.96c75.5712 0 140.57472 119.07072 168.1408 288.70656h-336.2816C371.56864 160.03072 436.57216 40.96 512.14336 40.96z m134.26688 19.70176a472.04352 472.04352 0 0 1 300.2368 269.0048h-205.2096c-16.75264-108.1344-48.68096-204.8-95.0272-269.0048z m-268.77952 0c-46.34624 64.22528-78.27456 160.89088-94.76096 269.0048H77.63968A471.61344 471.61344 0 0 1 377.61024 60.66176z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveIntranetInfo.defaultProps = {
  size: 18,
};

export default IconAExecutiveIntranetInfo;
