/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Gongshirili: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M938.666667 346.069333V784.64A154.069333 154.069333 0 0 1 784.597333 938.666667H239.36A154.069333 154.069333 0 0 1 85.333333 784.597333V346.026667h853.333334zM286.805333 654.208a59.264 59.264 0 1 0 0 118.528 59.264 59.264 0 0 0 0-118.528z m225.194667 0a59.264 59.264 0 1 0 0 118.528 59.264 59.264 0 0 0 0-118.528z m-225.194667-213.333333a59.264 59.264 0 1 0 0 118.528 59.264 59.264 0 0 0 0-118.528z m225.194667 0a59.264 59.264 0 1 0 0 118.528 59.264 59.264 0 0 0 0-118.528z m225.194667 0a59.264 59.264 0 1 0 0 118.528 59.264 59.264 0 0 0 0-118.528zM784.554667 85.333333A154.069333 154.069333 0 0 1 938.666667 239.402667v35.541333H85.333333v-35.541333A154.069333 154.069333 0 0 1 239.402667 85.333333H784.64z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Gongshirili;

