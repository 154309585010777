/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBuildingRetail24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M400.97399902 537.85632325a98.87695313 98.87695313 0 0 1 98.87695312 98.87695311v166.85485841a98.87695313 98.87695313 0 0 1-98.87695312 98.87695312H234.11914062a98.87695313 98.87695313 0 0 1-98.87695312-98.87695312v-166.85485841a98.87695313 98.87695313 0 0 1 98.87695313-98.87695311h166.85485839z m389.87182617-1e-8a98.87695313 98.87695313 0 0 1 98.87695313 98.87695313v166.8548584a98.87695313 98.87695313 0 0 1-98.87695313 98.87695312H624.04040527a98.87695313 98.87695313 0 0 1-98.87695313-98.87695312v-166.85485841a98.87695313 98.87695313 0 0 1 98.87695313-98.87695311h166.80541992zM783.28173828 146.20471191l104.85900879 104.85900879a98.87695313 98.87695313 0 0 1 0 139.8614502l-104.80957032 104.80957031a98.87695313 98.87695313 0 0 1-139.91088867 0l-104.80957031-104.80957032a98.87695313 98.87695313 0 0 1 0-139.86145019l104.80957031-104.85900879a98.87695313 98.87695313 0 0 1 139.91088867 1e-8zM400.97399902 147.93505859a98.87695313 98.87695313 0 0 1 98.87695312 98.87695313v166.8548584a98.87695313 98.87695313 0 0 1-98.87695312 98.87695312H234.11914062a98.87695313 98.87695313 0 0 1-98.87695312-98.87695313V246.81201172a98.87695313 98.87695313 0 0 1 98.87695313-98.87695313h166.85485839z"
        fill={getIconColor(color, 0, '#515151')}
      />
    </svg>
 );
};

export default IcFluentBuildingRetail24Regular;

