/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Icon24GfTelephone2: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M944.626667 731.686667a149.82 149.82 0 0 0-31.713334-66.313334L758.766667 480.4A148.926667 148.926667 0 0 0 644.046667 426.666667H379.953333a148.926667 148.926667 0 0 0-114.72 53.733333L111.086667 665.373333a149.82 149.82 0 0 0-31.713334 66.313334L58.393333 836.6A85.333333 85.333333 0 0 0 142.066667 938.666667h739.866666a85.333333 85.333333 0 0 0 83.673334-102.066667zM512 725.333333c-94.253333 0-170.666667-47.753333-170.666667-106.666666s76.413333-106.666667 170.666667-106.666667 170.666667 47.753333 170.666667 106.666667-76.413333 106.666667-170.666667 106.666666z m469.333333-405.333333v53.333333a53.393333 53.393333 0 0 1-53.333333 53.333334h-192a53.393333 53.393333 0 0 1-53.333333-53.333334v-53.333333c0-13.253333-16.666667-28.9-43.566667-40.846667C605.506667 264.22 560.366667 256 512 256s-93.506667 8.22-127.1 23.153333C358 291.1 341.333333 306.746667 341.333333 320v53.333333a53.393333 53.393333 0 0 1-53.333333 53.333334H96a53.393333 53.393333 0 0 1-53.333333-53.333334v-53.333333c0-65.666667 50.92-126.08 143.38-170.113333C273.466667 108.26 389.226667 85.333333 512 85.333333s238.533333 22.926667 325.953333 64.553334C930.413333 193.92 981.333333 254.333333 981.333333 320z"
        fill={getIconColor(color, 0, '#5C5C66')}
      />
    </svg>
  );
};

Icon24GfTelephone2.defaultProps = {
  size: 18,
};

export default Icon24GfTelephone2;
