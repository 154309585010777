/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconBanbenliebiao1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M960 64v761.6l-224-0.064V960H64V288l209.088-0.064V64H960z m-313.6 313.6H153.6v492.8h492.8V377.6zM330.88 482.112l69.248 191.104 66.88-191.104h82.304L437.184 780.8H362.816L250.688 482.112H330.88zM870.4 153.6H362.688l-0.128 134.336L736 288v447.936l134.4 0.064V153.6z"
        fill={getIconColor(color, 0, '#000000')}
        fillOpacity=".45"
      />
    </svg>
  )
}

IconBanbenliebiao1.defaultProps = {
  size: 18,
}

export default IconBanbenliebiao1
