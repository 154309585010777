/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Aliyunpan01: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M375.466667 183.466667A303.36 303.36 0 0 0 170.666667 404.053333a362.666667 362.666667 0 0 0 674.986666 252.16 137.386667 137.386667 0 0 0 19.626667-58.88c-2.133333-6.826667-22.613333-17.493333-42.666667-24.32l-40.106666-11.093333a357.546667 357.546667 0 0 0-24.32-206.506667 317.44 317.44 0 0 0-150.186667-154.026666A293.546667 293.546667 0 0 0 499.626667 170.666667a358.826667 358.826667 0 0 0-124.16 12.8z m163.413333 123.733333a193.706667 193.706667 0 0 1 123.306667 189.013333c0 30.293333 2.986667 35.413333 52.906666 47.786667 17.493333 4.266667 31.573333 11.946667 31.573334 17.066667a286.293333 286.293333 0 0 1-68.693334 106.666666 244.906667 244.906667 0 0 1-234.24 45.226667 267.52 267.52 0 0 1-136.106666-125.44 229.546667 229.546667 0 0 1 3.84-193.28 181.76 181.76 0 0 1 227.413333-87.04z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Aliyunpan01;

