/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCaigou: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M207.962937 189.233875 207.962937 189.233875c19.73753-19.685341 52.062749-19.685341 71.592548 0L541.066501 450.690656c19.685341 19.739577 19.685341 51.934836 0 71.595618l0 0c-19.581987 19.787672-51.804876 19.787672-71.594595 0L207.962937 260.957407C188.379926 241.218854 188.379926 209.048153 207.962937 189.233875L207.962937 189.233875zM896.550795 447.323978 577.122695 447.323978c16.784268 31.05632 12.224416 70.765717-13.986549 97.02887-31.911804 31.886221-83.769892 31.886221-115.627461 0l-96.978728-97.02887L121.759623 447.323978c-32.016181 0-58.332547 26.290783-58.332547 58.332547l0 0c0 32.0653 26.316366 58.357106 58.332547 58.357106l13.986549 0 134.589324 394.543891 118.736265 0 247.936859 0L755.848239 958.557522l134.588301-394.543891 6.114255 0c32.118512 0 58.3305-26.290783 58.3305-58.357106l0 0C954.881294 473.614761 928.669306 447.323978 896.550795 447.323978L896.550795 447.323978zM275.412119 600.796372l470.799646 0c16.476253 0 29.840632 13.468756 29.840632 29.941939l0 0c0 16.476253-13.365402 29.943986-29.840632 29.943986L275.412119 660.682297c-16.473183 0-29.941939-13.467733-29.941939-29.943986l0 0C245.469156 614.265128 258.938935 600.796372 275.412119 600.796372L275.412119 600.796372zM341.204567 838.268902l342.222245 0c16.368806 0 29.838585 13.57211 29.838585 30.045293l0 0c0 16.474206-13.469779 29.942963-29.838585 29.942963L341.204567 898.257158c-16.423041 0-29.943986-13.468756-29.943986-29.942963l0 0C311.260581 851.841012 324.781526 838.268902 341.204567 838.268902L341.204567 838.268902zM308.308343 721.397101l410.964886 0c16.47523 0 29.945009 13.57211 29.945009 30.149671l0 0c0 16.474206-13.469779 29.839609-29.945009 29.839609L308.308343 781.38638c-16.47523 0-29.788443-13.366426-29.788443-29.839609l0 0C278.5199 734.969211 291.833113 721.397101 308.308343 721.397101z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M544.692075 62.259996 544.692075 233.05901 459.319685 233.05901 630.067534 403.832442 800.918737 233.05901 715.544301 233.05901 715.544301 62.259996 544.692075 62.259996Z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconCaigou.defaultProps = {
  size: 18,
};

export default IconCaigou;
