/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceWorkAttendanceAuditAppeals: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M837.67296 0A104.40704 104.40704 0 0 1 942.08 104.40704l0.02048 446.91456A286.06464 286.06464 0 0 0 839.68 532.48c-18.80064 0-37.15072 1.80224-54.94784 5.26336L576.512 180.81792c-58.28608-99.9424-152.65792-99.86048-210.90304 0L157.16352 538.03008c-28.9792 49.72544-5.48864 90.03008 52.51072 90.03008l416.29696-0.02048a286.35136 286.35136 0 0 0-59.74016 104.67328H157.3888a52.51072 52.51072 0 0 0-52.71552 52.34688c0 29.10208 23.552 52.34688 52.71552 52.34688h396.14464A285.0816 285.0816 0 0 0 580.56704 942.08L104.40704 942.08A104.40704 104.40704 0 0 1 0 837.67296V104.40704A104.40704 104.40704 0 0 1 104.40704 0h733.26592zM471.04 471.04a52.34688 52.34688 0 0 1 3.44064 104.57088L471.04 575.6928A52.34688 52.34688 0 1 1 471.04 471.04z m0-261.16096a52.34688 52.34688 0 0 1 52.34688 52.3264v103.6288a52.34688 52.34688 0 1 1-104.69376 0v-103.6288A52.34688 52.34688 0 0 1 471.04 209.87904z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M819.67104 614.4c26.29632 0 51.97824 4.93568 77.02528 14.80704a197.2224 197.2224 0 0 1 67.64544 44.91264 197.48864 197.48864 0 0 1 44.8512 67.72736c9.87136 25.06752 14.80704 50.7904 14.80704 77.12768 0 26.3168-4.9152 52.0192-14.78656 77.1072a197.48864 197.48864 0 0 1-44.8512 67.72736 200.192 200.192 0 0 1-67.66592 45.13792c-25.04704 10.0352-50.72896 15.0528-77.02528 15.0528a207.2576 207.2576 0 0 1-77.27104-15.0528 199.5776 199.5776 0 0 1-67.87072-45.13792 200.45824 200.45824 0 0 1-45.09696-67.72736A206.1312 206.1312 0 0 1 614.4 818.9952c0-26.33728 5.0176-52.06016 15.03232-77.12768a200.45824 200.45824 0 0 1 45.09696-67.72736 196.66944 196.66944 0 0 1 67.87072-44.91264A210.14528 210.14528 0 0 1 819.67104 614.4z m83.57888 298.22976h-168.09984a9.17504 9.17504 0 0 0-9.03168 9.3184c0 5.16096 4.03456 9.3184 9.0112 9.3184h168.1408a9.17504 9.17504 0 0 0 9.0112-9.3184 9.17504 9.17504 0 0 0-9.0112-9.3184z m-82.944-205.02528c-29.98272 0-54.3744 21.38112-54.3744 47.65696 0 16.87552 10.24 32.44032 26.56256 40.96l-5.30432 37.53984h-44.3392c-9.216 0-16.73216 6.61504-16.73216 14.7456v30.76096c0 8.11008 7.49568 14.72512 16.73216 14.72512h152.6784c9.23648 0 16.75264-6.61504 16.75264-14.72512V848.4864c0-8.11008-7.51616-14.72512-16.75264-14.72512h-42.22976l-5.28384-37.4784c16.384-8.51968 26.66496-24.10496 26.66496-41.02144 0-26.27584-24.39168-47.65696-54.3744-47.65696z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceWorkAttendanceAuditAppeals.defaultProps = {
  size: 18,
};

export default IconAHumanResourceWorkAttendanceAuditAppeals;
