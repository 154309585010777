/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconSuoping: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M817.131268 1023.996625H207.535906c-22.911829 0.203933-44.681635-8.840479-61.465289-25.35902C127.839041 980.691535 117.285529 955.199958 117.091793 928.678522V462.478376c0.203933-26.511239 10.788035-52.002816 29.01961-69.908099 16.783654-16.498148 38.512674-25.491576 61.444896-25.308037h482.392396v0.050983h126.438219c23.013795-0.203933 44.773405 8.830282 61.567255 25.38961 18.211182 17.94607 28.754498 43.437646 28.927841 69.948886v466.108376c-0.193736 26.511239-10.747249 52.002816-28.958431 69.897903-16.640901 16.355395-38.216971 25.338627-60.792311 25.338627z m-609.860475-63.402649h609.860475a23.329891 23.329891 0 0 0 16.314609-7.137641c6.281124-6.179158 9.931518-15.294946 10.023287-25.053122V462.855651c-0.06118-9.595029-3.711574-18.782194-10.023287-24.991941a23.391071 23.391071 0 0 0-16.385986-7.137641H206.903715a23.340087 23.340087 0 0 0-16.314609 7.137641c-6.291321 6.179158-9.951911 15.294946-10.053878 25.063318V928.32164c0.09177 9.768372 3.75236 18.924946 10.053878 25.124498a23.45225 23.45225 0 0 0 16.396182 7.137641h0.326292z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M757.817468 394.232328h-63.402649v-149.89047c0-99.74344-81.134589-180.888226-180.87803-180.888226s-180.87803 81.114196-180.87803 180.857636v149.89047h-63.402648v-149.89047c0-134.69749 109.583189-244.311268 244.280678-244.311268s244.280679 109.613779 244.280679 244.311268z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M511.303727 637.391377m-80.063943 0a80.063943 80.063943 0 1 0 160.127886 0 80.063943 80.063943 0 1 0-160.127886 0Z"
        fill={getIconColor(color, 2, '#333333')}
      />
      <path
        d="M471.01684 697.561694h78.0756v100.508187a18.833177 18.833177 0 0 1-18.833177 18.833177h-40.409247a18.833177 18.833177 0 0 1-18.833176-18.833177v-100.508187z"
        fill={getIconColor(color, 3, '#333333')}
      />
    </svg>
  )
}

IconSuoping.defaultProps = {
  size: 18,
}

export default IconSuoping
