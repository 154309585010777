/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const CloudBackup24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M259.712 330.666667a245.418667 245.418667 0 0 1 483.242667 0h3.712a170.666667 170.666667 0 0 1 169.045333 194.218666 277.376 277.376 0 0 0-487.253333 147.114667H256a170.666667 170.666667 0 0 1 0-341.333333h3.712zM938.666667 704a234.666667 234.666667 0 1 1-469.333334 0 234.666667 234.666667 0 0 1 469.333334 0z m-256-76.501333V832a21.333333 21.333333 0 0 0 42.666666 0v-204.501333l70.229334 70.272a21.333333 21.333333 0 0 0 30.208-30.208l-106.666667-106.666667a21.333333 21.333333 0 0 0-30.208 0l-106.666667 106.666667a21.333333 21.333333 0 0 0 30.208 30.208L682.666667 627.498667z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default CloudBackup24Filled;

