/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Daochuzhongxin: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 21.333333C782.976 21.333333 1002.666667 240.938667 1002.666667 512c0 270.933333-219.690667 490.666667-490.666667 490.666667S21.333333 782.933333 21.333333 512C21.333333 240.938667 241.024 21.333333 512 21.333333z m0.042667 245.290667l-4.949334 0.341333a36.821333 36.821333 0 0 0-31.829333 36.437334v328.362666l-133.461333-133.546666-4.138667-3.584a36.821333 36.821333 0 0 0-47.914667 55.594666l196.266667 196.352 4.096 3.541334a36.778667 36.778667 0 0 0 47.957333-3.541334l196.266667-196.309333 3.584-4.138667a36.778667 36.778667 0 0 0-3.541333-47.914666l-0.042667 0.042666-4.138667-3.626666a36.778667 36.778667 0 0 0-47.914666 3.584l-133.461334 133.461333V303.36l-0.384-4.992a36.821333 36.821333 0 0 0-36.394666-31.786667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Daochuzhongxin;

