/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M163.797333 249.898667c125.482667-167.04 385.28-215.637333 570.197334-100.693334 182.613333 113.536 249.770667 331.818667 174.933333 536.661334-70.570667 193.365333-256.682667 269.994667-390.784 172.714666-50.218667-36.437333-69.717333-82.133333-79.104-156.330666l-4.522667-42.154667-1.92-16.938667c-5.248-39.850667-13.269333-57.728-30.037333-67.072-22.869333-12.714667-38.101333-13.013333-68.096-1.408l-14.976 6.229334-7.637333 3.328c-43.264 18.773333-72.021333 25.386667-108.416 17.749333l-8.533334-2.005333-6.997333-2.005334c-118.997333-36.864-136.618667-198.272-24.106667-348.074666z m41.984 286.592l5.248 1.536 5.717334 1.322666c18.773333 3.712 34.730667 0.64 61.312-10.325333l25.685333-10.965333c51.285333-21.034667 84.736-23.082667 129.962667 2.133333 39.125333 21.802667 54.4 55.381333 62.208 113.493333l2.261333 19.584 2.304 22.656 2.005333 18.048c7.338667 58.069333 20.693333 89.173333 53.248 112.810667 97.066667 70.4 236.117333 13.141333 293.12-142.890667 64.682667-177.152 7.424-363.264-148.650666-460.330666-156.757333-97.450667-379.690667-55.765333-485.248 84.778666-88.490667 117.888-77.653333 225.28-9.173334 248.149334z m478.933334-85.077334a53.333333 53.333333 0 1 1 103.04-27.605333 53.333333 53.333333 0 0 1-103.04 27.605333z m21.077333 148.821334a53.333333 53.333333 0 1 1 103.04-27.605334 53.333333 53.333333 0 0 1-103.04 27.605334zM600.32 323.285333a53.333333 53.333333 0 1 1 103.04-27.605333 53.333333 53.333333 0 0 1-103.04 27.605333z m-1.194667 383.914667a53.333333 53.333333 0 1 1 103.04-27.605333 53.333333 53.333333 0 0 1-103.04 27.605333z m-149.205333-425.386667a53.333333 53.333333 0 1 1 103.04-27.605333 53.333333 53.333333 0 0 1-103.04 27.605333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default A24Regular;

