/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceWorkAttendanceSetting: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 0c226.18112 0 409.6 191.03744 409.6 426.65984 0.26624 106.14784-37.888 208.67072-107.1104 287.744l-9.99424 10.99776L542.9248 1009.3568c-7.45472 8.94976-18.24768 14.27456-29.73696 14.62272a40.1408 40.1408 0 0 1-30.5152-12.75904h-0.1024l-258.41664-280.9856C145.87904 649.56416 102.0928 540.38528 102.4 426.68032 102.4 191.03744 285.7984 0 512 0z m0 232.73472c-73.17504 0-140.77952 39.936-177.3568 104.73472a213.6064 213.6064 0 0 0 0 209.46944c36.57728 64.8192 104.18176 104.73472 177.3568 104.73472 113.09056 0 204.8-93.7984 204.8-209.46944 0-115.69152-91.70944-209.46944-204.8-209.46944z m-11.1616 94.98624c-7.76192 0.02048-15.19616 3.072-20.6848 8.4992a28.75392 28.75392 0 0 0-8.58112 20.2752L471.04 468.48c-0.06144 7.70048 3.01056 15.11424 8.56064 20.56192 5.48864 5.38624 12.9024 8.3968 20.66432 8.43776l113.74592-0.49152c7.7824 0 15.2576-3.05152 20.76672-8.47872s8.6016-12.77952 8.6016-20.48c0-15.9744-13.14816-28.93824-29.36832-28.93824l-84.1728 0.34816 0.32768-82.80064c0-15.91296-13.0048-28.83584-29.12256-28.95872l-0.2048 0.04096z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceWorkAttendanceSetting.defaultProps = {
  size: 18,
};

export default IconAHumanResourceWorkAttendanceSetting;
