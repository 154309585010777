/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconADocumentsDocumentCenter: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M860.69248 0H163.30752C118.3744 0 81.92 36.90496 81.92 82.41152v860.65152C82.69824 987.99616 118.92736 1024 163.30752 1024h697.38496C905.6256 1024 942.08 987.09504 942.08 941.58848V82.41152C942.08 36.90496 905.6256 0 860.69248 0zM495.616 442.2656l-14.92992-15.11424L423.38304 368.64a42.00448 42.00448 0 0 0-59.71968 0l-76.5952 76.55424V75.5712H495.616v366.6944z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconADocumentsDocumentCenter.defaultProps = {
  size: 18,
};

export default IconADocumentsDocumentCenter;
