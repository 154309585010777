/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTianjiakehulianxiren: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M64 783.04h26.336c29.12 0 52.704-26.944 52.704-60.224s-23.584-60.224-52.704-60.224H64v-90.368h26.336c29.12 0 52.672-26.976 52.672-60.224 0-33.28-23.584-60.224-52.672-60.224H64v-90.368h26.336c29.12 0 52.704-26.944 52.704-60.224s-23.584-60.256-52.704-60.256H64V120.448C64 53.92 111.2 0 169.376 0h685.248C912.8 0 959.968 53.92 960 120.448v783.104C960 970.08 912.8 1024 854.624 1024H169.376C111.2 1024 64.032 970.08 64 903.552v-120.48z m257.12-438.816h-0.064c29.6 175.872 127.296 322.176 293.024 438.88v-0.096c44.032 30.176 102.72 19.2 134.144-25.12 31.424-44.288 24.736-106.624-15.296-142.4-40-35.808-99.584-32.736-136.064 7.008-61.6-58.752-107.136-124.8-136.512-198.208 46.944-22.176 70.08-78.336 53.376-129.536s-67.744-80.576-117.76-67.744c-49.984 12.8-82.464 63.616-74.88 117.184l0.032 0.032z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconTianjiakehulianxiren.defaultProps = {
  size: 18,
};

export default IconTianjiakehulianxiren;
