/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentArchive24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M821.333333 128c41.216 0 74.666667 33.450667 74.666667 74.666667v85.333333c0 29.781333-17.408 55.466667-42.666667 67.456v423.210667A160 160 0 0 1 693.333333 938.666667h-362.666666A160 160 0 0 1 170.666667 778.666667V355.498667A74.666667 74.666667 0 0 1 128 288v-85.333333C128 161.450667 161.450667 128 202.666667 128h618.666666z m-32 234.666667h-554.666666v416a96 96 0 0 0 96 96h362.666666a96 96 0 0 0 96-96V362.666667z m-362.666666 128h170.666666a32 32 0 0 1 4.352 63.701333L597.333333 554.666667h-170.666666a32 32 0 0 1-4.352-63.701334L426.666667 490.666667h170.666666-170.666666z m394.666666-298.666667H202.666667a10.666667 10.666667 0 0 0-10.666667 10.666667v85.333333c0 5.888 4.778667 10.666667 10.666667 10.666667h618.666666a10.666667 10.666667 0 0 0 10.666667-10.666667v-85.333333a10.666667 10.666667 0 0 0-10.666667-10.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentArchive24Regular;

