/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialReceiptsReceiptList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M663.79776 0c25.94816 0 47.32928 17.96096 50.25792 41.12384l0.32768 5.4272v46.53056h126.50496c54.33344 0 98.67264 39.40352 101.08928 88.84224L942.08 186.1632v744.73472c0 49.9712-42.82368 90.76736-96.5632 92.9792l-4.62848 0.1024H183.0912c-54.33344 0-98.67264-39.40352-101.08928-88.82176L81.92 930.9184V186.1632c0-49.9712 42.82368-90.76736 96.5632-92.9792l4.62848-0.12288h126.48448V46.55104C309.61664 20.84864 332.288 0 360.22272 0c25.94816 0 47.32928 17.96096 50.25792 41.12384l0.34816 5.4272-0.02048 46.53056h202.40384V46.55104c0-25.7024 22.65088-46.55104 50.5856-46.55104zM512 721.44896h-202.38336l-5.89824 0.32768c-25.1904 2.6624-44.70784 22.34368-44.70784 46.22336 0 24.63744 20.80768 44.81024 47.12448 46.42816l3.4816 0.12288H512l5.89824-0.32768c25.16992-2.6624 44.70784-22.34368 44.70784-46.22336 0-25.7024-22.67136-46.55104-50.60608-46.55104z m101.19168-186.1632H309.61664l-5.89824 0.3072c-25.1904 2.6624-44.70784 22.36416-44.70784 46.22336 0 24.63744 20.80768 44.81024 47.12448 46.44864l3.4816 0.1024H613.1712l5.89824-0.3072c25.16992-2.70336 44.70784-22.36416 44.70784-46.24384 0-25.7024-22.65088-46.55104-50.5856-46.55104z m101.19168-186.20416H309.61664l-5.89824 0.32768c-25.1904 2.68288-44.70784 22.36416-44.70784 46.22336 0 24.63744 20.80768 44.81024 47.12448 46.44864l3.4816 0.1024h404.76672l5.89824-0.3072c25.1904-2.70336 44.70784-22.36416 44.70784-46.24384 0-25.7024-22.65088-46.55104-50.5856-46.55104z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialReceiptsReceiptList.defaultProps = {
  size: 18,
};

export default IconAFinancialReceiptsReceiptList;
