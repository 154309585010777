/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialChargeMyCharges: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M204.43136 102.4v88.2688h75.01824V102.4h707.03104C1007.12448 102.4 1024 120.87296 1024 143.4624v737.0752C1024 903.12704 1007.12448 921.6 986.48064 921.6H279.4496v-94.45376H204.43136V921.6h-166.912C16.87552 921.6 0 903.12704 0 880.5376V143.4624C0 120.87296 16.87552 102.4 37.51936 102.4h166.912z m75.01824 539.97568H204.43136v102.64576h75.01824v-102.64576zM655.4624 286.72c-53.69856 0-97.19808 44.21632-97.19808 98.75456 0 44.99456 29.4912 82.92352 69.95968 94.8224-99.90144 13.59872-177.664 101.1712-177.664 206.31552v1.29024c0 16.97792 2.048 33.52576 5.85728 49.37728h397.88544c3.82976-15.85152 5.85728-32.39936 5.85728-49.37728v-1.29024c0.08192-105.14432-77.59872-192.7168-177.50016-206.336 40.448-11.9808 69.95968-49.88928 69.95968-94.80192 0-54.53824-43.49952-98.75456-97.1776-98.75456z m-376.0128 170.86464H204.43136v102.66624h75.01824v-102.66624z m0-184.77056H204.43136v102.64576h75.01824v-102.64576z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialChargeMyCharges.defaultProps = {
  size: 18,
};

export default IconAFinancialChargeMyCharges;
