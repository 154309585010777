/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconGonggongtubiaobiaotouZhankaizhedie: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M476.757333 213.333333a42.666667 42.666667 0 0 1 0 85.333334H128a42.666667 42.666667 0 0 1 0-85.333334h348.757333z m448.853334 329.130667l0.597333-0.597333a41.984 41.984 0 0 0 0-59.733334L680.106667 240.128a43.434667 43.434667 0 0 0-60.842667 0l-0.512 0.512a41.984 41.984 0 0 0 0 59.818667L833.706667 512l-215.04 211.456a41.984 41.984 0 0 0 0 59.733333l0.512 0.597334a43.434667 43.434667 0 0 0 60.842666 0l245.333334-241.066667 0.256-0.256zM476.842667 469.333333a42.666667 42.666667 0 0 1 0 85.333334H128a42.666667 42.666667 0 0 1 0-85.333334h348.757333z m0 256a42.666667 42.666667 0 0 1 0 85.333334H128a42.666667 42.666667 0 0 1 0-85.333334h348.757333z"
        fill={getIconColor(color, 0, '#7653D6')}
      />
    </svg>
  )
}

IconGonggongtubiaobiaotouZhankaizhedie.defaultProps = {
  size: 18,
}

export default IconGonggongtubiaobiaotouZhankaizhedie
