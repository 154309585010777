/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationLanguages: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M980.93056 61.44C1004.68736 61.44 1024 80.4864 1024 104.07936V794.2144a42.94656 42.94656 0 0 1-43.06944 42.74176H624.5376l-77.68064 107.8272c-17.14176 23.69536-52.67456 23.69536-69.71392 0l-77.68064-107.8272H43.06944A42.94656 42.94656 0 0 1 0 794.2144V104.18176C0 80.60928 19.31264 61.44 43.06944 61.44z m-621.9776 204.8c-19.70176 0-37.7856 3.29728-54.272 9.78944a117.22752 117.22752 0 0 0-42.3936 28.28288 127.30368 127.30368 0 0 0-27.38176 45.30176c-6.41024 17.87904-9.6256 37.96992-9.6256 60.416 0 21.9136 2.9696 41.6768 8.8064 59.45344 5.85728 17.75616 14.56128 32.74752 25.9072 45.07648a111.26784 111.26784 0 0 0 41.92256 28.50816c16.5888 6.59456 35.51232 9.89184 56.58624 9.89184 19.2512 0 36.08576-2.4576 50.62656-7.45472s26.8288-11.89888 37.00736-20.82816a98.01728 98.01728 0 0 0 23.81824-31.37536c5.7344-12.02176 9.5232-25.10848 11.12064-39.36256v-0.4096h-52.46976a101.08928 101.08928 0 0 1-6.7584 24.65792 53.8624 53.8624 0 0 1-12.94336 18.51392c-5.48864 5.12-12.24704 9.03168-20.39808 11.79648-8.13056 2.8672-17.85856 4.25984-29.42976 4.25984-12.94336 0-24.28928-2.33472-34.36544-7.02464a69.98016 69.98016 0 0 1-25.78432-20.62336 94.4128 94.4128 0 0 1-16.26112-32.64512c-3.76832-12.75904-5.61152-27.11552-5.61152-43.19232 0-15.72864 1.8432-29.98272 5.38624-42.74176 3.54304-12.75904 8.8064-23.61344 15.81056-32.64512 7.10656-9.05216 15.5648-15.9744 25.76384-20.84864 10.09664-4.79232 21.77024-7.22944 34.93888-7.22944 10.77248 0 19.94752 1.37216 27.60704 4.03456 7.68 2.6624 14.21312 6.49216 19.59936 11.4688 5.38624 5.0176 9.6256 11.07968 12.94336 18.20672 3.31776 7.12704 6.06208 15.31904 8.25344 24.45312l49.00864-6.49216a147.92704 147.92704 0 0 0-12.5952-37.41696 91.62752 91.62752 0 0 0-22.67136-28.83584 101.7856 101.7856 0 0 0-34.36544-18.49344c-13.5168-4.36224-29.45024-6.49216-47.77984-6.49216z m256.26624 6.92224H569.1392v272.7936h48.128V349.5936l135.3728 196.32128H798.72v-272.7936h-48.57856v197.18144l-134.92224-197.16096z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationLanguages.defaultProps = {
  size: 18,
};

export default IconAAdministrationLanguages;
