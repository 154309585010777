/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodeanjian: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M808.826435 0C890.63513 0 957.217391 69.453913 957.217391 154.802087v714.395826C957.217391 954.546087 890.63513 1024 808.804174 1024H215.195826C133.342609 1024 66.782609 954.546087 66.782609 869.197913V154.802087C66.782609 69.453913 133.342609 0 215.195826 0zM422.288696 393.97287h-109.857392c-20.925217 0-37.888 21.481739-37.888 47.994434 0 26.490435 16.962783 47.994435 37.888 47.994435h109.857392c20.925217 0 37.865739-21.481739 37.865739-47.994435 0-26.490435-16.962783-47.994435-37.865739-47.994434z m117.426087-169.984h-227.283479c-20.925217 0-37.888 21.481739-37.888 47.994434 0 26.490435 16.962783 47.994435 37.888 47.994435h227.283479c20.925217 0 37.865739-21.481739 37.865739-47.994435 0-26.490435-16.962783-47.994435-37.865739-47.994434z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconWodeanjian.defaultProps = {
  size: 18,
};

export default IconWodeanjian;
