/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Yishoujine: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1025 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M640 546.304a256 256 0 1 1 256 443.392 256 256 0 0 1-256-443.392z m257.706667 94.677333a24.405333 24.405333 0 0 0-25.514667 8.021334l-156.458667 175.786666-51.626666-57.941333a24.448 24.448 0 0 0-25.685334-8.448 28.074667 28.074667 0 0 0-18.944 21.290667 31.914667 31.914667 0 0 0 7.552 28.885333l70.144 78.805333c10.24 11.52 26.88 11.52 37.12 0l175.018667-196.693333a32 32 0 0 0 7.168-28.629333 28.074667 28.074667 0 0 0-18.773333-21.077334zM690.773333 0c48.896 0 85.76 35.584 85.76 83.626667v334.421333h107.989334l1.066666-0.085333 1.024 0.085333h1.28C916.053333 419.413333 938.666667 442.154667 938.666667 470.186667c0 12.8-4.821333 24.32-12.544 33.365333l-7.552 6.485333a298.666667 298.666667 0 0 0-437.034667 342.698667 133.674667 133.674667 0 0 1-94.805333-27.818667L13.269333 504.405333A51.328 51.328 0 0 1 0 470.186667c0-23.936 16.512-43.946667 38.869333-50.090667 0 0 5.376-2.048 13.226667-2.048H162.133333V83.669333C162.133333 35.584 199.04 0 247.936 0H690.773333z m-150.442666 180.437333l-71.552 67.370667-71.253334-67.114667a36.949333 36.949333 0 0 0-50.048 0 31.957333 31.957333 0 0 0 0 47.104L424.533333 300.416H350.848c-19.413333 0-30.848 12.885333-30.848 31.146667s11.434667 32.426667 30.848 32.426666h76.16v39.68H350.848c-19.413333 0-30.848 11.861333-30.848 30.122667 0 18.261333 11.434667 33.450667 30.848 33.450667h76.16v47.701333c0 22.826667 18.048 39.722667 42.325333 39.722667 24.234667 0 42.325333-16.896 42.325334-39.722667V467.2h78.378666c19.370667 0 28.629333-12.16 28.629334-30.464 0-23.765333-13.482667-33.109333-32.896-33.109333h-74.112v-39.68h76.16c19.413333 0 30.848-14.165333 30.848-32.426667s-11.434667-31.146667-30.848-31.146667l-74.666667-0.042666 77.184-72.832a31.957333 31.957333 0 0 0 0-47.104 36.949333 36.949333 0 0 0-50.048 0z"
        fill={getIconColor(color, 0, '#26957D')}
      />
    </svg>
 );
};

export default Yishoujine;

