/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentArrowCounterclockwise24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 192a320 320 0 1 1-316.544 272.725333c2.858667-19.370667-11.306667-38.058667-30.890667-38.058666a31.957333 31.957333 0 0 0-32.085333 26.581333A384 384 0 1 0 256 225.792V181.333333a32 32 0 0 0-64 0v128c0 17.664 14.336 32 32 32h128a32 32 0 0 0 0-64H294.4a318.848 318.848 0 0 1 217.6-85.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentArrowCounterclockwise24Regular;

