/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Guanlianzhangdan1: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M636.970667 725.333333c13.44 0 24.362667 11.477333 24.362666 25.6 0 14.122667-10.922667 25.6-24.362666 25.6h-24.405334c-40.405333 0-73.130667 34.389333-73.130666 76.8s32.725333 76.8 73.130666 76.8h24.405334c13.44 0 24.362667 11.477333 24.362666 25.6 0 14.122667-10.922667 25.6-24.362666 25.6h-24.405334c-67.328 0-121.898667-57.301333-121.898666-128s54.613333-128 121.898666-128z m243.797333 0c67.328 0 121.898667 57.301333 121.898667 128s-54.613333 128-121.898667 128h-24.405333c-13.44 0-24.362667-11.477333-24.362667-25.6 0-14.122667 10.922667-25.6 24.362667-25.6h24.405333c40.405333 0 73.130667-34.389333 73.130667-76.8s-32.725333-76.8-73.130667-76.8h-24.405333c-13.44 0-24.362667-11.477333-24.362667-25.6 0-14.122667 10.922667-25.6 24.362667-25.6zM448 42.666667c235.648 0 426.666667 191.018667 426.666667 426.666666 0 77.738667-20.778667 150.613333-57.088 213.376L618.666667 682.666667a170.666667 170.666667 0 0 0-165.290667 213.290666L448 896c-235.648 0-426.666667-191.018667-426.666667-426.666667s191.018667-426.666667 426.666667-426.666666z m401.066667 768c14.122667 0 25.6 19.114667 25.6 42.666666s-11.477333 42.666667-25.6 42.666667h-204.8c-14.122667 0-25.6-19.114667-25.6-42.666667s11.477333-42.666667 25.6-42.666666h204.8zM449.024 289.450667a180.053333 180.053333 0 0 1-18.261333 38.826666A164.992 164.992 0 0 1 359.210667 392.533333a30.805333 30.805333 0 0 0-5.12 52.949334 30.293333 30.293333 0 0 0 30.336 2.986666 219.392 219.392 0 0 0 63.573333-44.245333v215.765333c0 16.938667 13.653333 30.677333 30.464 30.677334a30.592 30.592 0 0 0 30.506667-30.72V297.685333a30.464 30.464 0 0 0-59.904-8.234666z"
        fill={getIconColor(color, 0, '#636df1')}
      />
    </svg>
 );
};

export default Guanlianzhangdan1;

