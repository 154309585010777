/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Shuaxin: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 192a320 320 0 1 0 316.544 272.704c-2.88-19.328 11.328-38.016 30.912-38.016a32 32 0 0 1 32.064 26.56c39.488 254.72-178.176 475.136-433.28 439.04-255.168-36.16-403.008-308.416-294.4-542.08C272.448 116.48 575.872 54.016 768 225.792v-44.48a32 32 0 1 1 64 0v128a32 32 0 0 1-32 32h-128a32 32 0 1 1 0-64h57.6A318.912 318.912 0 0 0 512 192z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default Shuaxin;

