/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZanting: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.78812059 116.91594553C293.46780809 116.91594553 116.4921875 293.66556201 116.4921875 511.68924365c0 218.03780713 176.98974609 394.78742361 395.29593309 394.7874236 218.3203125 0 395.29593309-176.74961646 395.29593387-394.78742361C907.08405447 293.66556201 730.09430837 116.91594553 511.78812059 116.91594553z m193.40332032 417.34549442l-266.82652076 155.09556372c-18.34873755 9.85944509-40.93505859-2.82505591-40.9350586-23.97059915v-307.36607077c0-21.15966797 22.58632103-33.8441682 40.93505859-23.97059914l266.82652077 153.68303615c18.36286305 9.85944509 18.36286305 36.65509938 0 46.52866919z"
        fill={getIconColor(color, 0, '#29BF7F')}
      />
    </svg>
  );
};

IconZanting.defaultProps = {
  size: 18,
};

export default IconZanting;
