/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZiyuan216: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M989.131021 822.921463c-15.895537-10.597024-205.052421-168.227762-215.649445-181.474042a46.097056 46.097056 0 0 0-26.492561-10.597024H431.992466c-23.578379 0-50.07094 5.298512-50.07094 47.421684a58.813485 58.813485 0 0 0 50.07094 57.753782c26.492561 5.298512 91.929186 10.597024 139.35087 13.246281 15.895537 0 0 10.597024 0 10.597024l-60.403038 39.473916H300.589364S213.163913 738.675119 158.589238 699.201204c-91.929186-60.403039-126.10459 0-126.10459 0s-10.597024 20.929123 0 34.175403c50.07094 42.123172 225.981544 189.156885 238.433048 199.753909a32.850775 32.850775 0 0 0 28.876891 5.298512H663.537448a90.869484 90.869484 0 0 1 47.421684 18.279867l71.000063 57.753783c28.876891 20.929123 44.772428 0 44.772428 0L989.131021 847.82447s15.895537-14.570908 0-24.903007zM728.974074 0.062522H242.570656a40.268693 40.268693 0 0 0-41.858246 47.421684v347.052548a46.361982 46.361982 0 0 0 20.929123 39.473915l270.753972 170.877018 262.806204-170.877018a46.361982 46.361982 0 0 0 20.929123-39.473915V47.484206A47.156758 47.156758 0 0 0 728.974074 0.062522z m-151.007597 412.754099H398.611839a29.936594 29.936594 0 0 1 0-59.873188h179.884489a29.936594 29.936594 0 1 1 0 59.873188zM637.044887 292.80532H338.208801a28.34704 28.34704 0 0 1-29.936594-29.936594 28.34704 28.34704 0 0 1 29.936594-29.936594H637.044887a28.34704 28.34704 0 0 1 29.936594 29.936594A28.34704 28.34704 0 0 1 637.044887 292.80532z m0-120.011301H338.208801a28.34704 28.34704 0 0 1-29.936594-29.936593 28.34704 28.34704 0 0 1 29.936594-29.936594H637.044887a28.34704 28.34704 0 0 1 29.936594 29.936594A28.34704 28.34704 0 0 1 637.044887 173.058945z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconZiyuan216.defaultProps = {
  size: 18,
};

export default IconZiyuan216;
