/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLianxifangshi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M584.49206305 739.25284099c0 79.22708989 64.23622371 143.46331358 143.46331358 143.46331358s143.46331358-64.23622371 143.46331358-143.46331358c0-51.27310752-27.37462521-98.63555432-71.76786662-124.27210807-44.39324141-25.63655377-99.07007217-25.63655377-143.46331358 0-44.39324141 25.63655377-71.69544697 72.99900055-71.69544696 124.27210807zM154.10212231 285.54377507c0 51.27310752 27.37462521 98.63555432 71.69544696 124.2721081 44.39324141 25.63655377 99.07007217 25.63655377 143.46331359-1e-8 44.39324141-25.63655377 71.76786661-72.99900055 71.7678666-124.27210808C441.02874946 206.31668519 376.79252576 142.0804615 297.56543588 142.0804615S154.10212231 206.31668519 154.10212231 285.54377507z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M706.15706397 734.54556418c-192.27415324-89.65551853-342.03797579-250.71680546-417.42682458-449.0017891H154.10212231c54.96650935 287.79566288 269.32865381 518.95916462 552.05494166 595.57914734V734.54556418z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconLianxifangshi.defaultProps = {
  size: 18,
};

export default IconLianxifangshi;
