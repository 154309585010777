/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessBorrowAuditBorrow: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M723.33312 0c32.39936 0 59.12576 25.7024 61.00992 57.83552l0.1024 3.60448v266.24h137.48224c32.41984 0 59.14624 25.7024 61.00992 57.83552L983.04 389.12v573.44a61.50144 61.50144 0 0 1-57.5488 61.3376l-3.56352 0.1024H102.07232c-32.41984 0-59.14624-25.7024-61.00992-57.83552L40.96 962.56V61.44A61.50144 61.50144 0 0 1 98.5088 0.1024L102.05184 0h621.2608z m178.23744 409.6h-117.1456v532.48h117.1456V409.6zM643.42016 894.30016H421.53984c-6.59456 0-11.93984 6.10304-11.93984 13.63968 0 7.55712 5.3248 13.66016 11.93984 13.66016h221.88032c6.59456 0 11.93984-6.10304 11.93984-13.66016 0-7.53664-5.3248-13.63968-11.93984-13.63968zM533.93408 593.92c-39.56736 0-71.76192 31.3344-71.76192 69.8368 0 24.71936 13.5168 47.5136 35.06176 60.0064l-7.00416 54.9888H431.7184c-12.20608 0-22.1184 9.68704-22.1184 21.58592v45.07648c0 11.8784 9.91232 21.56544 22.1184 21.56544h201.5232c12.20608 0 22.1184-9.66656 22.1184-21.56544v-45.07648c0-11.89888-9.91232-21.58592-22.1184-21.58592h-55.74656l-6.9632-54.92736a69.632 69.632 0 0 0 35.16416-60.08832c0-38.5024-32.19456-69.81632-71.76192-69.81632z m-248.54528-163.84H163.18464a40.96 40.96 0 0 0-3.03104 81.79712l3.03104 0.12288h122.20416a40.96 40.96 0 0 0 0-81.92z m183.82848-143.36H163.67616a40.96 40.96 0 0 0-3.01056 81.79712l3.01056 0.12288h305.5616a40.96 40.96 0 0 0 0-81.92z m162.95936-143.36H163.67616a40.96 40.96 0 0 0-3.01056 81.79712l3.01056 0.12288h468.50048a40.96 40.96 0 0 0 0-81.92z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessBorrowAuditBorrow.defaultProps = {
  size: 18,
};

export default IconABusinessBorrowAuditBorrow;
