/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const AIcFluentPin24Regular1: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M692.992 125.098667l205.909333 205.909333a117.333333 117.333333 0 0 1-30.506666 187.904l-207.786667 103.893333a32 32 0 0 0-15.957333 18.176l-61.44 177.749334a53.333333 53.333333 0 0 1-88.106667 20.309333L362.666667 706.602667 173.226667 896H128v-45.226667l189.44-189.44-132.48-132.437333a53.333333 53.333333 0 0 1 20.309333-88.149333l177.749334-61.44a32 32 0 0 0 18.176-15.914667l103.893333-207.786667a117.333333 117.333333 0 0 1 187.946667-30.506666z m160.682667 251.136l-205.909334-205.909334a53.333333 53.333333 0 0 0-85.418666 13.866667l-103.893334 207.829333a96 96 0 0 1-54.528 47.786667l-161.664 55.893333 286.08 286.037334 55.808-161.664a96 96 0 0 1 47.786667-54.485334l207.872-103.936a53.333333 53.333333 0 0 0 13.866667-85.418666z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default AIcFluentPin24Regular1;

