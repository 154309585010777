/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessPreFileCaseOverdue: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M871.36256 0C899.1744 0 921.6 22.9376 921.6 51.15904c0 28.20096-22.4256 51.15904-50.23744 51.15904l-38.912-0.02048v240.0256c0 68.15744-30.49472 129.14688-78.25408 169.6768a222.208 222.208 0 0 1 78.25408 169.6768v240.00512h38.912c27.81184 0 50.23744 22.95808 50.23744 51.15904C921.6 1001.0624 899.1744 1024 871.36256 1024H241.7664l-0.55296-0.02048L152.63744 1024C124.8256 1024 102.4 1001.0624 102.4 972.84096c0-28.20096 22.528-51.15904 50.23744-51.15904h38.89152V681.6768c0-68.05504 30.39232-129.14688 78.17216-169.6768-5.05856-4.21888-9.87136-8.8064-14.58176-13.47584-41.04192-41.7792-63.56992-97.28-63.56992-156.32384L191.52896 102.2976h-38.912C124.84608 102.31808 102.4 79.38048 102.4 51.17952 102.4 22.9376 124.928 0 152.63744 0h718.72512z m-139.3664 102.42048H292.12672v239.90272c0 31.744 12.0832 61.44 34.07872 83.92704a114.40128 114.40128 0 0 0 82.18624 34.7136h0.12288c27.68896 0.1024 50.21696 22.9376 50.21696 51.15904 0 28.30336-22.4256 51.13856-50.11456 51.26144h-0.2048c-64.26624 0.1024-116.40832 53.20704-116.40832 118.51776V921.6h439.86944V681.6768c0-65.3312-52.224-118.51776-116.38784-118.51776-27.79136 0-50.33984-22.9376-50.33984-51.15904 0-28.20096 22.4256-51.15904 50.11456-51.15904h0.12288c64.36864 0 116.61312-53.20704 116.61312-118.51776V102.42048z m-49.00864 192.98304v40.63232c0 36.6592-29.36832 66.4576-65.59744 66.4576-57.856 0.12288-104.83712 48.06656-104.83712 106.98752 0 59.04384 47.2064 106.98752 105.18528 106.98752 35.98336 0 65.24928 29.81888 65.24928 66.4576v190.58688H341.01248v-190.464c0-36.6592 29.36832-66.4576 65.47456-66.4576 57.856-0.12288 104.96-48.06656 104.96-107.1104 0-58.79808-47.104-106.76224-105.18528-106.98752-17.36704 0-33.75104-6.9632-46.08-19.51744a66.88768 66.88768 0 0 1-19.16928-47.04256v-40.5504h341.97504z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessPreFileCaseOverdue.defaultProps = {
  size: 18,
};

export default IconABusinessPreFileCaseOverdue;
