/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconOrderPlan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M8.183301 455.967375c0-133.24242-0.122883-183.252771-0.122883-316.495191 0-115.496908 64.112685-123.020973 125.752601-123.020973 33.712241 0 289.572738 0 503.00839 0 96.892224 0 126.855526 32.972929 126.855526 125.751594 0 47.464055 0 15.381525 0 62.875797 0 49.582276-63.077244 43.901456-63.077244 0 0-42.858965 0-20.016832 0-62.875797 0-46.573657-15.148853-62.875797-63.778282-62.875797-209.258638 0-293.748745 0-503.00839 0-52.069145 0-62.875797 7.060735-62.875797 62.875797 0 265.565231 0 363.193746 0 628.759984 0 34.461626 18.862538 62.875797 62.875797 62.875797 76.262991 0 49.490617 0.030217 125.751594 0 38.438199 0 41.262494 62.875797 0 62.875797s-72.516068 0.501604-149.452901 0.194397C64.735661 896.723448 27.156635 869.849343 13.771455 828.248418c-3.746924-11.635608-5.342388-24.499039-5.342388-36.809496C8.060419 658.655802 8.183301 588.749487 8.183301 455.967375L8.183301 455.967375zM8.183301 455.967375"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M636.203973 959.557949c-173.398966-0.030217-313.76457-140.61137-313.76457-314.287326 0-173.675957 141.040453-314.441434 314.746627-314.195668 173.707181 0.244759 314.011343 141.163336 313.828026 315.147507C950.831746 819.499552 810.096486 959.59018 636.203973 959.557949L636.203973 959.557949zM637.064154 394.0132c-137.846503-0.308215-251.134539 112.48829-251.440739 250.45969-0.307207 138.340049 111.936324 251.470956 249.905709 251.871837 138.523366 0.368649 252.117603-111.782216 252.42481-249.323526C888.322584 507.483547 776.141501 394.350625 637.064154 394.0132L637.064154 394.0132zM637.064154 394.0132"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M573.944606 205.078602c50.104025 0.030217 43.226607 62.87479 0 62.87479-43.227614 0-330.099949 0-377.256796 0-47.156848 0-45.191727-62.87479 0-62.87479C320.47529 205.047378 525.804694 205.047378 573.944606 205.078602z"
        fill={getIconColor(color, 2, '#333333')}
      />
      <path
        d="M637.625186 594.399082c0.062449 3.008619 1.737485 6.693094 3.702605 8.841531 15.472176 16.671796 90.89211 95.725843 106.305866 112.426848 15.842839 17.132104 9.51638 44.609544-11.728274 51.699489-10.806652 3.593824-21.000903 1.567262-29.167585-6.783745-20.507357-21.06033-103.58431-105.365106-123.785466-126.794085-5.403829-5.713051-7.982358-12.956097-7.982358-21.092562 0-41.783235-0.122883-83.59971 0.061441-125.414169 0.120869-17.376862 13.139414-30.578725 29.473785-30.640166 16.516681-0.061441 32.951777 13.386187 33.136101 31.100474C637.764184 505.396551 637.255529 576.530688 637.625186 594.399082z"
        fill={getIconColor(color, 3, '#333333')}
      />
      <path
        d="M259.563606 393.704986c50.105032 0.031224 43.227614 62.875797 0 62.875797-43.227614 0-15.718949 0-62.875797 0-47.156848 0-45.191727-62.875797 0-62.875797C320.47529 393.674769 211.424702 393.674769 259.563606 393.704986z"
        fill={getIconColor(color, 4, '#333333')}
      />
    </svg>
  );
};

IconIconOrderPlan.defaultProps = {
  size: 18,
};

export default IconIconOrderPlan;
