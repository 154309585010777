/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseCloseDeposits: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M798.45376 158.98624c32.48128 0 58.83904 26.5216 58.92096 59.31008V923.648c-0.06144 32.80896-26.4192 59.392-58.92096 59.41248H99.88096C67.44064 982.9376 41.1648 956.49792 40.96 923.77088v-705.3312c0.02048-32.80896 26.37824-59.43296 58.90048-59.45344h698.59328zM891.24864 40.96c50.66752 0.08192 91.70944 41.53344 91.79136 92.672v755.07712a34.304 34.304 0 0 1-34.03776 34.36544h-0.04096a34.2016 34.2016 0 0 1-34.03776-34.36544V133.81632a24.02304 24.02304 0 0 0-23.81824-24.08448H143.27808a34.26304 34.26304 0 0 1-32.768-34.38592c0-18.49344 14.45888-33.66912 32.768-34.38592h747.97056z m-353.0752 304.66048l-0.26624 0.47104-0.24576 0.45056L450.56 507.31008l-87.10144-160.768a35.88096 35.88096 0 0 0-21.9136-17.6128 34.75456 34.75456 0 0 0-27.5456 3.95264l-0.65536 0.4096c-17.16224 10.60864-23.08096 33.36192-13.312 51.38432l77.70112 143.44192h-54.00576c-20.43904 0-37.00736 17.08032-37.00736 38.15424v1.3312c0 21.07392 16.56832 38.15424 37.00736 38.15424l90.39872-0.04096v38.83008h-90.39872c-20.43904 0-37.00736 17.08032-37.00736 38.15424v1.3312c0 21.07392 16.56832 38.13376 37.00736 38.13376l90.39872-0.02048v59.4944C414.12608 802.4064 430.42816 819.2 450.56 819.2s36.41344-16.81408 36.41344-37.56032V722.1248h90.43968c20.43904 0 37.00736-17.03936 37.00736-38.0928v-1.3312c0-21.0944-16.56832-38.15424-37.00736-38.15424h-90.43968v-38.83008h90.43968c20.43904 0 37.00736-17.05984 37.00736-38.0928v-1.35168c0-21.0944-16.56832-38.15424-37.00736-38.15424h-54.02624l77.74208-143.4624 1.7408-3.6864c7.22944-17.53088 0.77824-37.888-15.07328-47.67744l-0.69632-0.4096-3.42016-1.8432a35.1232 35.1232 0 0 0-45.48608 14.60224z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseCloseDeposits.defaultProps = {
  size: 18,
};

export default IconABusinessCaseCloseDeposits;
