/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWeizhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M510.3 71.1c-167 0-302.4 134.7-302.4 300.9s302.4 526.5 302.4 526.5S812.7 538 812.7 371.9 677.3 71.1 510.3 71.1z m0 432.4c-73.1 0-132.3-58.9-132.3-131.6 0-72.7 59.2-131.6 132.3-131.6 73.1 0 132.2 58.9 132.2 131.6 0 72.7-59.1 131.6-132.2 131.6z m0 0"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M644 777.9c-8.2 11.3-16.3 22.2-24.1 32.5C700.6 819.7 756 838.8 756 861c0 31.1-110 56.4-245.6 56.4-135.7 0-245.7-25.3-245.7-56.4 0-22.2 55.4-41.3 136.1-50.6-8.1-10.8-16.2-21.6-24.2-32.5-89.2 15.8-149.8 47-149.8 83 0 51.8 126.9 94 283.5 94s283.4-42.2 283.4-94c0-36-60.6-67.1-149.7-83z m0 0"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconWeizhi.defaultProps = {
  size: 18,
};

export default IconWeizhi;
