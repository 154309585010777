/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWeixuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M864.298667 1024H159.701333C71.637333 1024 0 953.514667 0 866.816V157.184C0 70.485333 71.637333 0 159.701333 0h704.597334C952.362667 0 1024 70.485333 1024 157.184v709.632C1024 953.514667 952.362667 1024 864.298667 1024zM159.701333 72.533333c-47.445333 0-86.016 37.973333-86.016 84.650667v709.632c0 46.677333 38.570667 84.650667 86.016 84.650667h704.597334c47.445333 0 86.016-37.973333 86.016-84.650667V157.184c0-46.677333-38.570667-84.650667-86.016-84.650667H159.701333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconWeixuan.defaultProps = {
  size: 18,
};

export default IconWeixuan;
