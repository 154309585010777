/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Shanchu: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 74.666667a138.666667 138.666667 0 0 1 138.453333 130.816L650.666667 213.333333h224a32 32 0 0 1 4.352 63.701334L874.666667 277.333333h-33.962667l-54.613333 555.52a117.333333 117.333333 0 0 1-109.269334 105.557334l-7.509333 0.256H354.688a117.333333 117.333333 0 0 1-115.797333-98.432l-0.981334-7.424L183.253333 277.333333H149.333333a32 32 0 0 1-31.701333-27.648L117.333333 245.333333a32 32 0 0 1 27.648-31.701333L149.333333 213.333333h224A138.666667 138.666667 0 0 1 512 74.666667z m264.405333 202.666666H247.552l54.058667 549.205334a53.333333 53.333333 0 0 0 47.658666 47.872l5.418667 0.256h314.624c25.6 0 47.317333-18.133333 52.266667-42.752l0.853333-5.376L776.362667 277.333333zM586.666667 394.666667a32 32 0 0 1 31.701333 27.648L618.666667 426.666667v298.666666a32 32 0 0 1-63.701334 4.352L554.666667 725.333333v-298.666666a32 32 0 0 1 32-32z m-149.333334 0a32 32 0 0 1 31.701334 27.648L469.333333 426.666667v298.666666a32 32 0 0 1-63.701333 4.352L405.333333 725.333333v-298.666666a32 32 0 0 1 32-32z m74.666667-256a74.666667 74.666667 0 0 0-74.410667 68.522666L437.333333 213.333333h149.333334A74.666667 74.666667 0 0 0 512 138.666667z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default Shanchu;

