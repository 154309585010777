/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveOfficeSuppliesManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M331.999027 211.3536l218.3168 121.4464a71.80288 71.80288 0 0 0 33.83296 8.78592c27.29984 0.04096 52.3264-16.11776 64.9216-41.92256a81.8176 81.8176 0 0 0-10.79296-88.33024h322.39616c41.86112 0 74.42432 43.47904 59.8016 90.48064a62.95552 62.95552 0 0 1-59.8016 44.6464h-1.47456l-169.51296 539.46368C779.097907 919.49056 749.586227 942.08 716.367667 942.08H307.668787c-33.21856 0-62.73024-22.58944-73.3184-56.15616L64.837427 346.46016h-1.47456c-36.02432 0-65.08544-32.17408-63.26272-71.168 1.8432-36.4544 32.5632-63.95904 66.92864-63.95904H331.999027z m299.33568 547.84H392.701747c-20.11136 0-36.57728 17.55136-36.57728 38.99392s16.46592 38.99392 36.57728 38.99392h238.63296c20.11136 0 36.57728-17.55136 36.57728-38.99392s-16.46592-38.99392-36.57728-38.99392z m46.08-137.05216H346.437427c-20.11136 0-36.57728 17.55136-36.57728 38.99392s16.46592 38.99392 36.57728 38.99392h330.97728c20.19328 0 36.57728-17.46944 36.57728-38.99392 0-21.54496-16.384-38.99392-36.57728-38.99392z m49.19296-137.07264H297.244467c-20.11136 0-36.57728 17.55136-36.57728 38.99392s16.46592 38.99392 36.57728 38.99392h429.3632c20.19328 0 36.57728-17.44896 36.57728-38.99392 0-21.52448-16.384-38.99392-36.57728-38.99392zM344.430387 85.97504l256.73728 142.72512c15.01184 8.27392 22.75328 26.46016 18.71872 43.99104-4.03456 17.5104-18.82112 29.7984-35.7376 29.696-5.65248 0-11.50976-1.35168-16.81408-4.48512L310.597427 155.19744c-17.46944-10.21952-24.08448-33.44384-14.86848-52.28544 9.216-18.8416 30.84288-26.35776 48.70144-16.93696z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveOfficeSuppliesManage.defaultProps = {
  size: 18,
};

export default IconAExecutiveOfficeSuppliesManage;
