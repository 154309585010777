/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWorkExperience: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M882.78857422 522.47208642H621.01184083V479.95499682H402.98815917v42.43469199H141.21142578V310.54581723c0-46.80175797 39.05639672-84.70459017 87.25891137-84.70459018h130.8471679V183.32413745c0-35.10131812 29.25109855-63.52844262 65.42358358-63.52844262h174.51782274c36.17248503 0 65.42358431 28.42712378 65.42358359 63.52844262v42.43469198h130.84716789c48.20251465 0 87.25891137 37.9028322 87.25891137 84.70459017v211.92626921zM882.78857422 564.82438151v211.84387231C882.78857422 823.55240869 843.7321775 861.45524089 795.52966285 861.45524089H228.55273405C180.2678225 861.45524089 141.21142578 823.55240869 141.21142578 776.66825382v-211.76147468h261.7767334v42.26989746h218.02368164V564.82438151H882.78857422zM621.01184083 225.84122705V183.32413745A21.50573731 21.50573731 0 0 0 599.34130827 162.23038754H424.74108863a21.50573731 21.50573731 0 0 0-21.75292945 21.17614753v42.43469198h218.02368164z"
        fill={getIconColor(color, 0, '#2c2c2c')}
      />
    </svg>
  );
};

IconWorkExperience.defaultProps = {
  size: 18,
};

export default IconWorkExperience;
