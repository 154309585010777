/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialReceiptUnClaimedReceipts: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M822.924288 603.01312c111.08352 0 201.1136 89.66144 201.1136 200.25344S934.028288 1003.52 822.944768 1003.52c-111.104 0-201.15456-89.66144-201.15456-200.25344s90.05056-200.25344 201.15456-200.25344zM847.459328 106.7008c0.06144 1.51552 0.06144 3.05152 0 4.58752v383.5904h121.52832c31.9488 1.76128 56.60672 28.30336 54.9888 59.4944a55.7056 55.7056 0 0 1-13.312 33.28l-37.66272 32.1536 0.04096-0.04096a238.53056 238.53056 0 0 0-304.96768 3.7888 235.9296 235.9296 0 0 0-50.21696 299.49952l-14.848 12.73856a146.944 146.944 0 0 1-180.87936 0.36864L14.660608 588.5952a55.33696 55.33696 0 0 1 4.79232-79.52384 58.63424 58.63424 0 0 1 37.45792-14.21312H177.005568V111.28832c0-52.224 40.30464-90.76736 93.75744-90.76736h483.04128c50.2784-1.4336 92.22144 37.1712 93.65504 86.17984z m78.2336 619.23328l-116.38784 115.85536-64.65536-64.32768a18.35008 18.35008 0 0 0-25.62048 0.2048 18.14528 18.14528 0 0 0-0.22528 25.51808l77.55776 77.23008a18.35008 18.35008 0 0 0 25.86624 0l129.31072-128.69632a18.16576 18.16576 0 0 0 0-25.76384 18.35008 18.35008 0 0 0-25.86624-0.02048zM585.233408 176.08704l-2.2528 2.49856L512.058368 263.65952 441.115648 178.5856l-3.52256-3.74784a40.96 40.96 0 0 0-61.44 53.51424l2.048 2.6624L424.608768 286.72H389.178368l-4.77184 0.26624a40.96 40.96 0 0 0 1.72032 81.55136L389.178368 368.64h81.89952v40.96H389.178368l-4.77184 0.26624a40.96 40.96 0 0 0 1.72032 81.55136L389.178368 491.52h81.89952L471.098368 573.44l0.26624 4.77184a40.96 40.96 0 0 0 81.55136-1.72032L553.018368 573.44l-0.02048-81.92H634.938368l4.77184-0.26624a40.96 40.96 0 0 0-1.72032-81.53088L634.938368 409.6h-81.94048v-40.96H634.938368l4.77184-0.26624a40.96 40.96 0 0 0-1.72032-81.53088L634.938368 286.72h-35.45088l46.42816-55.7056 3.072-4.13696a40.96 40.96 0 0 0-63.73376-50.7904z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialReceiptUnClaimedReceipts.defaultProps = {
  size: 18,
};

export default IconAFinancialReceiptUnClaimedReceipts;
