/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksLogCaseWorklog: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M727.3472 744.6528c0 5.36576-0.1024 10.87488-0.34816 16.384l-0.45056 12.0832H293.49888l-0.45056-11.96032a389.18144 389.18144 0 0 1-0.34816-16.40448c0-168.05888 97.36192-425.53344 217.33376-425.53344 119.95136-0.1024 217.31328 257.35168 217.31328 425.41056zM511.85664 314.63424c63.7952 0 115.73248-52.26496 115.73248-116.36736C627.58912 134.06208 575.65184 81.92 511.85664 81.92c-63.7952 0-115.73248 52.26496-115.73248 116.36736 0 64.1024 51.93728 116.36736 115.712 116.36736zM769.80224 742.1952c0 5.85728-0.1024 11.83744-0.32768 17.69472l-0.57344 13.1072H1023.1808l0.45056-12.06272c0.22528-5.38624 0.34816-10.89536 0.34816-16.384 0-168.05888-97.36192-425.53344-217.31328-425.53344-48.51712 0-94.4128 21.56544-132.75136 62.34112l-6.84032 7.3728 5.59104 8.45824c36.9664 55.54176 97.11616 257.10592 97.11616 345.00608z m38.912-427.52c63.81568 0 115.75296-52.26496 115.75296-116.36736C924.4672 134.06208 872.52992 81.92 808.7552 81.92c-63.81568 0-115.73248 52.26496-115.73248 116.36736 0 64.1024 51.9168 116.36736 115.712 116.36736zM4.5056 772.99712h254.30016l-0.57344-13.12768a457.54368 457.54368 0 0 1-0.32768-17.69472c0-87.90016 60.14976-289.46432 97.01376-344.8832l5.59104-8.45824-6.9632-7.3728c-38.23616-40.77568-84.13184-62.34112-132.62848-62.34112C100.94592 319.11936 3.584 576.57344 3.584 744.63232c0 5.38624 0.12288 10.89536 0.34816 16.40448l0.57344 11.96032z m954.22464 43.2128a62.93504 62.93504 0 1 1 0 125.87008H62.93504a62.93504 62.93504 0 1 1 0-125.87008h895.7952zM219.09504 81.92c63.7952 0 115.712 52.14208 115.712 116.36736 0 64.1024-51.9168 116.36736-115.712 116.36736-63.91808 0-115.85536-52.26496-115.75296-116.36736C103.34208 134.18496 155.27936 81.92 219.09504 81.92z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksLogCaseWorklog.defaultProps = {
  size: 18,
};

export default IconAWorksLogCaseWorklog;
