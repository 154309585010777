/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconResume: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 641.33333333c-10.00000031 0-18-1.99999969-25.99999969-7.99999969L116 411.33333364c-24-13.99999969-31.99999969-43.99999969-18-68.00000062 4.00000031-7.99999969 10.00000031-12 18-18L486.00000031 103.33333302c16.00000031-10.00000031 36-10.00000031 51.99999938 0l370.00000031 222c24 13.99999969 31.99999969 46.00000031 16.00000031 68.00000062-4.00000031 6-10.00000031 12-16.00000031 15.99999938L537.99999969 633.33333364c-7.99999969 4.00000031-16.00000031 7.99999969-25.99999969 7.99999969zM162.00000031 369.33333364L512 579.33333364l349.99999969-210L512 159.33333364 162.00000031 369.33333364z"
        fill={getIconColor(color, 0, '#5d73fa')}
      />
      <path
        d="M512 805.33333302c-10.00000031 0-18-1.99999969-25.99999969-7.99999969l-400.00000031-240c-13.99999969-7.99999969-19.99999969-25.99999969-10.00000031-42 7.99999969-13.99999969 25.99999969-18 42-10.00000031L512 743.33333333l394.00000031-235.99999969c13.99999969-7.99999969 31.99999969-4.00000031 42 9.99999938s4.00000031 31.99999969-10.00000031 42l-400.00000031 238.00000031c-7.99999969 6-16.00000031 7.99999969-25.99999969 7.99999969z"
        fill={getIconColor(color, 1, '#5d73fa')}
      />
      <path
        d="M512 969.33333364c-10.00000031 0-18-1.99999969-25.99999969-8.00000062l-400.00000031-240c-13.99999969-7.99999969-18-25.99999969-10.00000031-42s25.99999969-18 42-9.99999938L512 907.33333302l394.00000031-235.99999969c13.99999969-7.99999969 31.99999969-4.00000031 42 10.00000031s4.00000031 31.99999969-10.00000031 42l-397.99999969 240c-10.00000031 4.00000031-18 6-28.00000031 6z"
        fill={getIconColor(color, 2, '#5d73fa')}
      />
    </svg>
  );
};

IconResume.defaultProps = {
  size: 18,
};

export default IconResume;
