/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconComment: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M851.28970156 165.29849188H172.71029844c-38.31333844 0-69.44292656 41.73417281-69.44292656 93.04668v402.97422468c0 51.31250719 31.12958813 93.04668 69.44292656 93.04668h57.92611875c4.44708375 0 8.43805687 2.85069469 9.6923625 7.06972313l29.19111562 92.24848594c1.9384725 6.15750094 9.12222375 8.8941675 14.70958594 5.70138937l182.67252656-103.76529281c1.59638906-0.91222219 3.30680625-1.36833375 5.0172225-1.36833281H851.28970156c38.31333844 0 69.44292656-41.73417281 69.44292656-93.04668094V258.34517187c-0.11402813-51.42653531-31.12958813-93.04668-69.44292656-93.04668zM555.0454925 599.63036h-355.76671875c-18.01639125 0-31.24361531-14.3675025-31.24361531-34.32236625 0-19.84083656 13.11319594-34.32236625 31.24361531-34.32236625h355.76671875c18.01639125 0 31.24361531 14.48152969 31.24361531 34.32236625 0 19.84083656-13.11319594 34.32236625-31.24361531 34.32236625z m173.66433094-118.01876719h-524.52785438c-20.29694719 0-36.03278344-15.27972469-36.0327825-34.77847687s15.84986344-34.77847688 36.0327825-34.77847781h524.52785438c20.29694719 0 36.03278344 15.27972469 36.0327825 34.77847781s-15.84986344 34.77847688-36.0327825 34.77847687z m0-116.42237812h-524.52785438c-20.29694719 0-36.03278344-14.82361312-36.0327825-33.98028281s15.84986344-33.98028281 36.0327825-33.98028282h524.52785438c20.29694719 0 36.03278344 14.93764125 36.0327825 33.98028282 0 19.04264156-15.84986344 33.98028281-36.0327825 33.98028281z"
        fill={getIconColor(color, 0, '#707070')}
      />
    </svg>
  );
};

IconComment.defaultProps = {
  size: 18,
};

export default IconComment;
