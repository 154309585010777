/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAutoSynchronization: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M611.78055377 449.97718422l-76.68581868-77.3599804a33.70805253 33.70805253 0 0 0-48.87667563 0l-73.98917478 74.66333575A34.71929362 34.71929362 0 0 0 461.44264115 496.49429606l14.66300218-14.83154297v144.94462407a35.0563745 35.0563745 0 0 0 34.88783443 35.05637448A33.70805253 33.70805253 0 0 0 545.7127714 626.77591796v-144.94462482l16.8540259 16.85402592a33.70805253 33.70805253 0 0 0 24.43833818 10.44949624 35.0563745 35.0563745 0 0 0 24.43833744-59.663252zM862.56846229 389.97685136a42.13506511 42.13506511 0 0 0-52.92164222-23.93271725 41.46090414 41.46090414 0 0 0-23.93271727 52.41602129 275.90040695 275.90040695 0 0 1 15.67424474 94.55108643 273.20376303 273.20376303 0 0 1-26.62936118 121.51752771 42.13506511 42.13506511 0 0 0 0 31.17994871 37.92155846 37.92155846 0 0 0 21.23607263 21.74169352 42.30360518 42.30360518 0 0 0 30.50578698 0A37.7530184 37.7530184 0 0 0 849.08524128 666.88850013a358.99075601 358.99075601 0 0 0 33.70805181-153.8772583 353.93454832 353.93454832 0 0 0-20.2248308-123.03439047z"
        fill={getIconColor(color, 0, '#515151')}
      />
      <path
        d="M222.62109091 513.01124183A289.72070881 289.72070881 0 0 1 512.00471887 223.62761315a303.37246988 303.37246988 0 0 1 96.9106502 15.50570395 39.10134035 39.10134035 0 0 0 31.51702881-1.34832197 42.97776688 42.97776688 0 0 0 21.40461342-22.4158545 39.10134035 39.10134035 0 0 0-1.34832197-31.51702881 42.80922609 42.80922609 0 0 0-22.58439529-21.40461341h-1.34832196A455.05870447 455.05870447 0 0 0 512.00471887 142.39120767a370.78857422 370.78857422 0 0 0-370.78857422 370.78857422 357.47389327 357.47389327 0 0 0 33.70805253 153.37163739 37.92155846 37.92155846 0 0 0 22.41585452 21.57315348 42.30360518 42.30360518 0 0 0 30.3372469 0 37.7530184 37.7530184 0 0 0 21.57315349-22.41585451 42.64068604 42.64068604 0 0 0-0.84270104-31.34848875 271.1812801 271.1812801 0 0 1-25.78666014-121.34898767zM656.7808036 760.76542557a287.69822587 287.69822587 0 0 1-214.04613195 31.17994797 303.37246988 303.37246988 0 0 1-90.50611983-37.92155847 42.13506511 42.13506511 0 0 0-56.96660809 10.78657637 39.10134035 39.10134035 0 0 0-6.06744952 30.84286784 43.31484701 43.31484701 0 0 0 17.86526774 26.79790124 455.05870447 455.05870447 0 0 0 116.46132001 48.53959549 376.01332198 376.01332198 0 0 0 88.31509684 10.61803632 369.77733239 369.77733239 0 0 0 185.39428711-50.56207845 39.77550133 39.77550133 0 0 0 19.55067055-29.49454514 40.78674317 40.78674317 0 0 0-11.79781822-33.70805253 40.28112224 40.28112224 0 0 0-48.20251464-7.07869063z"
        fill={getIconColor(color, 1, '#515151')}
      />
    </svg>
  );
};

IconAutoSynchronization.defaultProps = {
  size: 18,
};

export default IconAutoSynchronization;
