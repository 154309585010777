/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChengbenguanli221: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M914.346667 11.946667H114.346667a82.346667 82.346667 0 0 0-85.333334 79.786666v840.533334a82.773333 82.773333 0 0 0 85.333334 80.213333h360.106666a37.973333 37.973333 0 1 0 0-75.946667H114.346667a3.84 3.84 0 0 1-4.266667-4.266666V91.733333a4.266667 4.266667 0 0 1 4.266667-4.266666h800a4.693333 4.693333 0 0 1 4.693333 4.266666V554.666667a40.106667 40.106667 0 0 0 79.786667 0V91.733333A82.346667 82.346667 0 0 0 914.346667 11.946667z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M685.226667 459.093333a40.106667 40.106667 0 0 0 0-80.213333H597.333333l102.826667-102.826667a42.666667 42.666667 0 0 0 0-57.173333 42.666667 42.666667 0 0 0-57.173333 0L512 349.866667 379.306667 216.32a40.106667 40.106667 0 0 0-56.746667 0 42.666667 42.666667 0 0 0 0 57.173333L426.666667 378.88H338.773333a40.106667 40.106667 0 0 0 0 80.213333h132.693334v92.586667H338.773333a40.106667 40.106667 0 1 0 0 80.213333h132.693334v147.626667a40.533333 40.533333 0 0 0 80.64 0v-147.626667H682.666667a40.106667 40.106667 0 0 0 0-80.213333h-132.266667v-92.586667zM613.546667 992L738.56 768l-14.08 13.653333 4.693333-2.56-52.906666-13.653333 120.32 228.266667a38.826667 38.826667 0 0 0 33.28 19.2h4.693333a37.546667 37.546667 0 0 0 32.853333-19.2L992.426667 768c23.893333-42.666667-42.666667-81.92-66.133334-38.826667l-125.013333 225.706667 33.28-18.773333h-4.693333l33.28 18.773333L742.826667 725.333333a39.253333 39.253333 0 0 0-52.48-13.653333 95.573333 95.573333 0 0 0-32.853334 42.666667l-42.666666 78.08-66.986667 121.173333c-23.893333 42.666667 42.666667 82.346667 66.56 38.826667z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconChengbenguanli221.defaultProps = {
  size: 18,
};

export default IconChengbenguanli221;
