/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A20Filled2: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M256 770.8672v-119.9104c9.0624 3.84 18.3808 7.3728 27.904 10.752 15.104 5.2224 31.488 5.2224 46.592 0 73.3184-25.4976 132.5056-65.7408 173.056-122.2144C544.256 483.0208 563.2 414.3104 563.2 337.1008V206.592l-0.9216-9.216-0.4608-2.4576A71.8848 71.8848 0 0 0 491.52 136.5504c-1.536 0-3.072 0-4.608-0.1024A102.4 102.4 0 0 1 665.6 204.8l-0.0512 2.8672H819.2a51.2 51.2 0 0 1 51.2 51.2L870.3488 409.6 819.2 409.6a102.4 102.4 0 0 0-102.144 94.72L716.8 512a102.4 102.4 0 0 0 94.72 102.144L819.2 614.4l51.1488-0.0512 0.0512 156.5184a51.2 51.2 0 0 1-51.2 51.2h-153.7536l-0.1024 4.7616A102.4 102.4 0 0 1 460.8 822.1184H307.2a51.2 51.2 0 0 1-51.2-51.2zM409.6 171.4176a207.6672 207.6672 0 0 0 81.92 16.384c9.8816 0 18.176 7.2704 20.0704 16.9984l0.4096 4.3008v128.0512c0 137.3184-67.2256 230.7072-198.3488 276.224a19.712 19.712 0 0 1-12.9024 0c-37.1712-12.9024-69.12-29.6448-96-50.1248a254.0544 254.0544 0 0 1-50.6368-51.2c-32.0512-43.52-49.3568-97.4336-51.5072-161.28L102.4 337.1008v-128c0-11.776 9.216-21.3504 20.48-21.3504 62.6176 0 118.9376-26.112 169.8816-79.104a19.9168 19.9168 0 0 1 28.9792 0c27.648 28.8256 56.832 49.664 87.8592 62.72z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default A20Filled2;

