/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Settings24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512.512 96c31.317333 0.341333 62.506667 3.968 93.098667 10.794667a32 32 0 0 1 24.832 27.690666l7.253333 65.152a59.050667 59.050667 0 0 0 82.218667 47.616l59.776-26.24a32 32 0 0 1 36.266666 7.424 417.792 417.792 0 0 1 94.037334 161.792 32 32 0 0 1-11.562667 35.2l-52.992 39.082667a58.922667 58.922667 0 0 0 0 94.976l53.034667 39.04a32 32 0 0 1 11.605333 35.242667 418.005333 418.005333 0 0 1-94.037333 161.792 32 32 0 0 1-36.181334 7.466666l-60.032-26.325333a58.88 58.88 0 0 0-82.176 47.530667l-7.210666 65.109333a32 32 0 0 1-24.405334 27.605333 406.101333 406.101333 0 0 1-187.989333 0 32 32 0 0 1-24.405333-27.605333l-7.168-65.024a58.965333 58.965333 0 0 0-82.176-47.36l-59.989334 26.282667a32 32 0 0 1-36.224-7.466667 418.048 418.048 0 0 1-94.037333-161.962667 32 32 0 0 1 11.605333-35.242666l53.034667-39.082667a58.88 58.88 0 0 0 0-94.976l-53.034667-38.997333a32 32 0 0 1-11.562666-35.242667 417.834667 417.834667 0 0 1 94.037333-161.792 32 32 0 0 1 36.266667-7.424l59.733333 26.24a59.178667 59.178667 0 0 0 82.346667-47.701333l7.253333-65.109334a32 32 0 0 1 24.874667-27.733333c30.592-6.784 61.866667-10.368 93.909333-10.752zM512 384a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default Settings24Filled;

