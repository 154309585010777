/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceWorkAttendanceMyAppeals: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M40.96 145.36704A104.40704 104.40704 0 0 1 145.36704 40.96h733.26592A104.40704 104.40704 0 0 1 983.04 145.36704v733.26592A104.40704 104.40704 0 0 1 878.63296 983.04H145.36704A104.40704 104.40704 0 0 1 40.96 878.63296V145.36704z m365.568 76.41088L198.144 578.99008c-28.9792 49.72544-5.48864 90.03008 52.51072 90.03008h522.73152c58.0608 0 81.5104-40.30464 52.51072-90.03008L617.45152 221.7984c-58.28608-99.9424-152.65792-99.86048-210.90304 0zM145.65376 826.01984c0 29.10208 23.552 52.34688 52.71552 52.34688h627.3024a52.51072 52.51072 0 0 0 52.71552-52.34688c0-29.0816-23.552-52.3264-52.71552-52.3264H198.3488a52.51072 52.51072 0 0 0-52.71552 52.3264z m314.01984-522.8544a52.34688 52.34688 0 0 1 104.69376 0v103.6288a52.34688 52.34688 0 1 1-104.69376 0v-103.6288zM512 616.67328A52.34688 52.34688 0 1 1 512 512a52.34688 52.34688 0 0 1 0 104.67328z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceWorkAttendanceMyAppeals.defaultProps = {
  size: 18,
};

export default IconAHumanResourceWorkAttendanceMyAppeals;
