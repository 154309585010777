/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveExpressMyExpress: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M354.46784 819.2c0 56.56576-44.07296 102.4-98.46784 102.4-54.39488 0-98.46784-45.83424-98.46784-102.4s44.07296-102.4 98.46784-102.4c54.39488 0 98.46784 45.83424 98.46784 102.4z m551.38304 0c0 56.56576-44.07296 102.4-98.46784 102.4-54.39488 0-98.46784-45.83424-98.46784-102.4s44.07296-102.4 98.46784-102.4c54.39488 0 98.46784 45.83424 98.46784 102.4z m88.00256-348.95872L905.8304 307.2c-18.0224-22.56896-30.16704-40.96-59.0848-40.96h-137.8304V143.36c0-22.56896-17.7152-40.96-39.40352-40.96H39.38304C17.67424 102.4 0 120.79104 0 143.36v614.4c0 22.56896 17.69472 40.96 39.38304 40.96h78.5408c9.64608-70.5536 67.584-125.19424 138.07616-125.19424s128.4096 54.64064 138.07616 125.19424l275.23072-0.04096c9.68704-70.5536 67.60448-125.15328 138.07616-125.15328 70.49216 0 128.4096 54.64064 138.07616 125.19424h39.15776c21.7088 0 39.38304-18.39104 39.38304-40.96V552.96c0-38.8096-30.14656-82.71872-30.14656-82.71872zM748.29824 552.96V348.16h96.3584c6.3488 0 15.1552 9.68704 15.1552 9.68704l82.92352 160.31744c7.33184 11.24352 15.29856 24.55552 15.29856 34.79552H748.3392z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveExpressMyExpress.defaultProps = {
  size: 18,
};

export default IconAExecutiveExpressMyExpress;
