/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhishiwenku: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M887.451044 366.917694l-68.263733 0c-18.851347 0-34.132378 15.281031-34.132378 34.132378 0 18.8493 15.281031 34.132378 34.132378 34.132378l34.132378 0 0 281.055141L170.680311 716.237591 170.680311 435.181427l34.132378 0c18.8493 0 34.132378-15.283078 34.132378-34.132378 0-18.851347-15.283078-34.132378-34.132378-34.132378l-68.263733 0c-18.851347 0-34.132378 15.281031-34.132378 34.132378l0 477.847156c0 1.512447 0.668219 2.803859 0.860601 4.266164-0.191358 1.462305-0.860601 2.753717-0.860601 4.266164 0 18.8493 15.281031 34.132378 34.132378 34.132378l750.902088 0c18.8493 0 34.132378-15.283078 34.132378-34.132378 0-1.512447-0.671289-2.803859-0.862647-4.266164 0.191358-1.462305 0.862647-2.753717 0.862647-4.266164L921.583422 401.050072C921.582399 382.198725 906.300344 366.917694 887.451044 366.917694zM853.318666 784.500301l0 68.796876L170.680311 853.297177 170.680311 784.500301 853.318666 784.500301z"
        fill={getIconColor(color, 0, '#666666')}
      />
      <path
        d="M273.076422 648.505977l477.847156 0c18.8493 0 34.131355-15.283078 34.131355-34.132378 0-18.851347-15.283078-34.131355-34.131355-34.131355l-33.475416 0L717.448162 136.527466c0-18.851347-15.281031-34.132378-34.131355-34.132378-1.100054 0-2.029216 0.524956-3.103688 0.627287-1.289366-0.102331-2.4017-0.627287-3.722788-0.627287L348.823594 102.395088c-1.321088 0-2.433422 0.524956-3.722788 0.627287-1.074472-0.102331-2.003634-0.627287-3.103688-0.627287-18.851347 0-34.132378 15.281031-34.132378 34.132378l0 443.714777-34.788318 0c-18.851347 0-34.132378 15.281031-34.132378 34.131355C238.944044 633.223922 254.225075 648.505977 273.076422 648.505977zM376.128473 170.659845l273.054933 0 0 409.583422L376.128473 580.243267 376.128473 170.659845z"
        fill={getIconColor(color, 1, '#666666')}
      />
      <path
        d="M461.457884 307.187311l102.396111 0c18.851347 0 34.132378-15.283078 34.132378-34.132378 0-18.851347-15.281031-34.132378-34.132378-34.132378L461.457884 238.922555c-18.851347 0-34.132378 15.281031-34.132378 34.132378C427.326529 291.904233 442.606537 307.187311 461.457884 307.187311z"
        fill={getIconColor(color, 2, '#666666')}
      />
      <path
        d="M563.853995 375.451044 461.457884 375.451044c-18.851347 0-34.132378 15.281031-34.132378 34.132378 0 18.8493 15.281031 34.132378 34.132378 34.132378l102.396111 0c18.851347 0 34.132378-15.283078 34.132378-34.132378C597.98535 390.731052 582.705342 375.451044 563.853995 375.451044z"
        fill={getIconColor(color, 3, '#666666')}
      />
    </svg>
  );
};

IconZhishiwenku.defaultProps = {
  size: 18,
};

export default IconZhishiwenku;
