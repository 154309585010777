/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconGongnengfull: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M240 0a240 240 0 0 1 240 240v192c0 26.496-21.504 48-48 48h-192a240 240 0 0 1 0-480z"
        fill={getIconColor(color, 0, '#5D73FA')}
      />
      <path
        d="M784 0a240 240 0 0 1 0 480h-192a48 48 0 0 1-48-48v-192A240 240 0 0 1 784 0z"
        fill={getIconColor(color, 1, '#DEE3FE')}
      />
      <path
        d="M240 544h192c26.496 0 48 21.504 48 48v192a240 240 0 1 1-240-240z m352 0h192a240 240 0 1 1-240 240v-192c0-26.496 21.504-48 48-48z"
        fill={getIconColor(color, 2, '#5D73FA')}
      />
    </svg>
  );
};

IconGongnengfull.defaultProps = {
  size: 18,
};

export default IconGongnengfull;
