/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentNotepad24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M245.504 938.88a96 96 0 0 1-96-96L149.418667 245.333333a96 96 0 0 1 96-96l31.872-0.042666 0.042666-31.957334a32 32 0 0 1 27.648-31.701333L309.333333 85.333333a32 32 0 0 1 31.701334 27.648L341.333333 117.333333v31.957334h128V117.333333a32 32 0 0 1 27.733334-31.701333l4.266666-0.298667a32 32 0 0 1 31.744 27.648l0.298667 4.352v31.957334h127.872l0.042667-31.957334a32 32 0 0 1 27.648-31.701333L693.333333 85.333333a32 32 0 0 1 31.701334 27.648L725.333333 117.333333v31.957334h31.957334a96 96 0 0 1 96 96l0.042666 469.333333c0 2.56-0.298667 5.12-0.853333 7.509333l-1.28 4.010667-1.92 4.096-0.853333 1.365333a29.226667 29.226667 0 0 1-4.48 5.674667l-192.170667 192.213333a31.573333 31.573333 0 0 1-3.626667 3.114667l3.626667-3.114667a31.744 31.744 0 0 1-4.48 3.712l-3.754667 2.218667-4.949333 2.005333-4.864 1.109334-2.261333 0.256-2.346667 0.085333H245.504zM757.248 213.333333H244.992a32 32 0 0 0-31.573333 32l0.085333 597.504c0 17.706667 14.336 32 32 32h351.573333l0.042667-96.170666a96 96 0 0 1 89.429333-95.786667l6.570667-0.213333H789.333333V245.333333a32 32 0 0 0-32-32z m-13.226667 533.333334h-50.901333a32 32 0 0 0-31.701333 27.648l-0.298667 4.352v50.944l82.901333-82.944zM309.333333 682.666667h170.581334a32 32 0 0 1 4.352 63.701333l-4.352 0.298667H309.333333a32 32 0 0 1-4.352-63.701334L309.333333 682.666667h170.581334H309.333333z m0-170.666667h383.914667a32 32 0 0 1 4.352 63.701333l-4.352 0.298667H309.333333a32 32 0 0 1-4.352-63.701333L309.333333 512h383.914667H309.333333z m0-170.666667h383.914667a32 32 0 0 1 4.352 63.701334l-4.352 0.298666H309.333333a32 32 0 0 1-4.352-63.701333L309.333333 341.333333h383.914667H309.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentNotepad24Regular;

