/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconYidu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 30.117647c69.571765 0 126.644706 54.121412 129.325176 122.518588l0.090353 4.879059v71.981177h253.168942c69.571765 0 126.674824 54.121412 129.325176 122.548705l0.090353 4.879059v110.772706c0 10.270118-4.156235 20.148706-11.535059 27.407059a39.695059 39.695059 0 0 1-27.858823 11.354353h-28.129883V866.484706c0 68.457412-54.964706 124.687059-124.446117 127.307294l-4.939294 0.090353H196.909176c-69.541647 0-126.644706-54.121412-129.295058-122.518588l-0.090353-4.879059V506.458353h-28.129883a39.152941 39.152941 0 0 1-39.273411-35.568941L0 467.696941V356.894118c0-70.384941 57.946353-127.397647 129.415529-127.397647h253.168942V157.515294C382.584471 87.160471 440.530824 30.117647 512 30.117647z m365.718588 476.340706H146.281412V866.484706c0 26.202353 20.600471 47.947294 47.164235 49.754353l3.463529 0.090353h141.071059a38.279529 38.279529 0 0 1-0.271059-2.349177l-0.12047-3.19247v-132.909177c0-20.781176 16.655059-37.888 37.767529-38.731294a39.152941 39.152941 0 0 1 40.869647 35.538824l0.120471 3.19247v132.909177c0 1.897412-0.120471 3.764706-0.391529 5.571764h192.090352a38.279529 38.279529 0 0 1-0.271058-2.379294l-0.120471-3.19247v-132.909177c0-20.781176 16.655059-37.888 37.767529-38.731294a39.152941 39.152941 0 0 1 40.869648 35.538824l0.12047 3.19247v132.909177c0 1.897412-0.120471 3.764706-0.391529 5.571764h141.071059a50.296471 50.296471 0 0 0 50.507294-46.471529l0.12047-3.403294V506.458353z m16.865883-199.378824H129.415529c-27.979294 0-50.657882 22.287059-50.657882 49.844706v71.981177h866.484706v-71.981177c0-26.202353-20.630588-47.947294-47.194353-49.754353l-3.463529-0.12047zM512 107.640471a50.296471 50.296471 0 0 0-50.537412 46.441411l-0.090353 3.403294v71.981177h101.25553V157.515294c0-27.527529-22.648471-49.844706-50.627765-49.844706z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconYidu.defaultProps = {
  size: 18,
};

export default IconYidu;
