/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseApplicationsApprove: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M859.52512 0C915.72224 0 962.56 43.97056 962.56 98.9184v826.1632c0 54.94784-44.9536 98.9184-101.1712 98.9184H162.6112C106.3936 1024 61.44 980.02944 61.44 925.0816V98.9184C61.44 43.97056 106.3936 0 162.6112 0zM484.84352 767.54944h-185.48736c-20.60288 0-37.4784 16.4864-37.4784 36.63872 0 20.13184 16.87552 36.61824 37.4784 36.61824h185.48736c20.60288 0 37.4784-16.4864 37.4784-36.61824 0-20.15232-16.87552-36.6592-37.4784-36.6592z m185.46688-144.73216H299.35616c-20.60288 0-37.4784 16.4864-37.4784 36.6592 0 20.13184 16.87552 36.61824 37.4784 36.61824H670.3104c20.60288 0 37.4784-16.4864 37.4784-36.63872 0-20.15232-16.87552-36.63872-37.4784-36.63872z m-10.38336-121.05728H364.07296l-2.17088 0.14336a15.5648 15.5648 0 0 0-13.74208 15.21664c0 8.47872 7.12704 15.36 15.91296 15.36h295.85408l2.17088-0.14336A15.5648 15.5648 0 0 0 675.84 517.12a15.64672 15.64672 0 0 0-15.91296-15.36zM513.9456 163.84c-52.75648 0-95.68256 35.2256-95.68256 78.56128 0 27.81184 18.0224 53.4528 46.73536 67.50208l-9.3184 61.89056h-78.0288l-3.01056 0.12288c-14.848 1.2288-26.48064 11.59168-26.48064 24.12544v50.72896l0.14336 2.47808c1.51552 12.22656 14.09024 21.79072 29.34784 21.79072h268.6976l3.01056-0.12288c14.848-1.2288 26.48064-11.59168 26.48064-24.14592v-50.72896l-0.14336-2.4576c-1.51552-12.22656-14.11072-21.79072-29.34784-21.79072h-74.3424l-9.27744-61.80864 4.23936-2.19136c26.3168-14.41792 42.65984-38.912 42.65984-65.39264C609.62816 199.0656 566.70208 163.84 513.9456 163.84z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseApplicationsApprove.defaultProps = {
  size: 18,
};

export default IconABusinessCaseApplicationsApprove;
