/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialAllocationsManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M950.86592 684.032h-36.57728V512H548.57728v-86.016h109.71136c40.3456-0.14336 73.0112-38.56384 73.13408-86.016V167.936c-0.12288-47.45216-32.78848-85.87264-73.13408-86.016H365.71136c-40.3456 0.14336-73.0112 38.56384-73.13408 86.016v172.032c0.12288 47.45216 32.78848 85.87264 73.13408 86.016h109.71136V512H109.71136v172.032H73.13408C32.78848 684.17536 0.12288 722.59584 0 770.048v86.016c0.12288 47.45216 32.78848 85.87264 73.13408 86.016h146.28864c40.3456-0.14336 73.03168-38.56384 73.15456-86.016v-86.016c-0.12288-47.45216-32.80896-85.87264-73.15456-86.016H182.8864v-86.016h292.5568v86.016H438.8864c-40.3456 0.14336-73.03168 38.56384-73.15456 86.016v86.016c0.12288 47.45216 32.80896 85.87264 73.15456 86.016h146.26816c40.3456-0.14336 73.03168-38.56384 73.15456-86.016v-86.016c-0.12288-47.45216-32.80896-85.87264-73.15456-86.016h-36.5568v-86.016H841.1136v86.016h-36.5568c-40.3456 0.14336-73.03168 38.56384-73.15456 86.016v86.016c0.12288 47.45216 32.80896 85.87264 73.15456 86.016h146.28864c40.3456-0.14336 73.0112-38.56384 73.13408-86.016v-86.016c-0.12288-47.45216-32.78848-85.87264-73.13408-86.016z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialAllocationsManage.defaultProps = {
  size: 18,
};

export default IconAFinancialAllocationsManage;
