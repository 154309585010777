/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTaizhangguanli1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1075 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M969.14285666 995.20609566H91.42857166V121.51466666h232.301714V42.66666666h67.096381v78.823619h113.615238V42.66666666h67.072v78.823619h113.615238V42.66666666h67.096381v78.823619h216.990476v873.691429z m-605.476571-294.765715v61.781334h462.214095v-61.781334z m-103.253333 0v61.781334h67.998476v-61.781334z m103.253333-191.902476v61.805714h462.214095v-61.805714z m-103.253333 0v61.805714h67.998476v-61.805714z m103.253333-191.878095v61.805714h462.214095v-61.805714z m-103.253333 0v61.805714h67.998476v-61.805714z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconTaizhangguanli1.defaultProps = {
  size: 18,
};

export default IconTaizhangguanli1;
