/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseApplicationsApply: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M859.52512 0C915.72224 0 962.56 43.97056 962.56 98.9184v826.1632c0 54.94784-44.9536 98.9184-101.1712 98.9184H162.6112C106.3936 1024 61.44 980.02944 61.44 925.0816V98.9184C61.44 43.97056 106.3936 0 162.6112 0zM484.84352 767.54944h-185.48736c-20.60288 0-37.4784 16.4864-37.4784 36.63872 0 20.13184 16.87552 36.61824 37.4784 36.61824h185.48736c20.60288 0 37.4784-16.4864 37.4784-36.61824 0-20.15232-16.87552-36.6592-37.4784-36.6592z m185.46688-144.73216H299.35616c-20.60288 0-37.4784 16.4864-37.4784 36.6592 0 20.13184 16.87552 36.61824 37.4784 36.61824H670.3104c20.60288 0 37.4784-16.4864 37.4784-36.63872 0-20.15232-16.87552-36.63872-37.4784-36.63872zM591.62624 172.19584c-13.1072 0-26.2144 5.48864-35.59424 14.6432L327.4752 410.33728a49.50016 49.50016 0 0 0-14.99136 34.816v65.9456c0 25.64096 22.48704 47.616 48.70144 47.616h69.3248c14.99136 0 26.2144-5.48864 35.59424-14.66368l228.5568-225.30048c18.7392-18.3296 18.7392-47.63648 1.86368-64.12288l-69.30432-67.7888a51.77344 51.77344 0 0 0-35.59424-14.6432z m0 82.432l31.8464 31.1296-202.3424 197.8368h-33.71008v-31.1296l204.1856-197.8368z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseApplicationsApply.defaultProps = {
  size: 18,
};

export default IconABusinessCaseApplicationsApply;
