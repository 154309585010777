/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Library24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M234.666667 128A64 64 0 0 1 298.666667 192v640A64 64 0 0 1 234.666667 896h-85.333334A64 64 0 0 1 85.333333 832v-640A64 64 0 0 1 149.333333 128h85.333334z m256 0A64 64 0 0 1 554.666667 192v640a64 64 0 0 1-64 64h-85.333334A64 64 0 0 1 341.333333 832v-640A64 64 0 0 1 405.333333 128h85.333334z m310.656 133.290667l137.130666 534.144a64 64 0 0 1-46.08 77.909333l-80.042666 20.48a64 64 0 0 1-77.909334-46.08L597.290667 313.770667a64 64 0 0 1 46.08-77.909334l80.042666-20.608a64.085333 64.085333 0 0 1 77.909334 46.08z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default Library24Filled;

