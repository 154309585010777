/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialContractsManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M892.98944 0C931.28704 0.12288 962.3552 32.80896 962.56 73.13408V950.8864c-0.1024 40.3456-31.1296 73.0112-69.46816 73.13408H130.90816C92.5696 1023.87712 61.5424 991.19104 61.44 950.86592V73.1136C61.5424 32.78848 92.5696 0.12288 130.90816 0z m-49.74592 870.4H587.20256l-1.86368 0.14336c-6.71744 1.024-11.89888 7.43424-11.89888 15.21664 0 8.47872 6.144 15.36 13.76256 15.36h256.04096l1.86368-0.14336c6.71744-1.024 11.89888-7.43424 11.89888-15.21664 0-8.47872-6.144-15.36-13.76256-15.36zM716.9024 532.48c-45.6704 0-82.80064 35.2256-82.80064 78.56128 0 27.81184 15.60576 53.4528 40.448 67.50208l-8.06912 61.89056h-67.52256l-2.60096 0.12288c-12.86144 1.2288-22.91712 11.59168-22.91712 24.12544v50.72896l0.12288 2.47808c1.31072 12.22656 12.20608 21.79072 25.3952 21.79072H831.488l2.60096-0.12288c12.86144-1.2288 22.9376-11.59168 22.9376-24.14592v-50.72896l-0.14336-2.4576c-1.31072-12.22656-12.20608-21.79072-25.3952-21.79072h-64.3072l-8.06912-61.80864 3.87072-2.31424c22.67136-14.41792 36.74112-38.85056 36.74112-65.26976 0-43.3152-37.15072-78.56128-82.82112-78.56128z m-278.71232-55.56224h-145.22368c-20.0704 0-36.31104 16.34304-36.31104 36.57728 0 20.21376 16.22016 36.5568 36.31104 36.5568h145.34656c20.0704 0 36.18816-16.34304 36.18816-36.5568 0-20.23424-16.22016-36.57728-36.31104-36.57728z m289.792-147.78368H292.9664c-20.0704 0-36.31104 16.34304-36.31104 36.57728s16.22016 36.57728 36.31104 36.57728h434.9952c20.09088 0 36.33152-16.34304 36.33152-36.57728s-16.24064-36.57728-36.31104-36.57728z m0-146.16576H292.9664c-20.0704 0-36.31104 16.34304-36.31104 36.57728s16.22016 36.57728 36.31104 36.57728h434.9952c20.09088 0 36.33152-16.34304 36.33152-36.57728s-16.24064-36.57728-36.31104-36.57728z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialContractsManage.defaultProps = {
  size: 18,
};

export default IconAFinancialContractsManage;
