/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhengguifapiao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M778.551822 288.470017c-17.21508-17.21508-45.102221-17.207917-62.317301-0.007163L438.252369 566.432727 315.948859 444.10568c-17.186428-17.21508-45.102221-17.207917-62.317301-0.007163-17.21508 17.207917-17.21508 45.109384 0 62.324464l153.469324 153.497976c8.592702 8.607028 19.882839 12.911054 31.15865 12.911054 11.275811 0 22.551622-4.304026 31.15865-12.903891 0.024559-0.024559 0.039909-0.054235 0.063445-0.077771l309.070194-309.055868C795.766902 333.579401 795.766902 305.677934 778.551822 288.470017z"
        fill={getIconColor(color, 0, '#1A1A1A')}
      />
      <path
        d="M772.326027 63.229067 251.665787 63.229067c-111.594611 0-202.39564 90.801029-202.39564 202.417129l0 492.714771c0 111.6161 90.801029 202.409966 202.39564 202.409966l221.926462 0c24.344454 0 44.069705-19.72525 44.069705-44.062542 0-24.337291-19.72525-44.069705-44.069705-44.069705L251.665787 872.638687c-63.005986 0-114.248044-51.264571-114.248044-114.276696L137.417743 265.646196c0-63.013149 51.242058-114.27772 114.248044-114.27772l520.66024 0c63.005986 0 114.263393 51.264571 114.263393 114.27772l0 347.780845L806.942429 613.42704c-101.251032 0-183.624111 82.373079-183.624111 183.624111l0 114.936729c0 10.422374 3.767813 19.872606 9.815553 27.418466 7.719822 12.754488 21.592784 21.363564 37.59627 21.363564l45.762253 0c34.788318 0 87.221504-35.239596 155.807578-104.758925 67.970044-68.873624 102.427834-120.510678 102.427834-153.469324L974.727807 265.646196C974.72883 154.030096 883.934964 63.229067 772.326027 63.229067zM713.581088 872.637664 711.457728 872.637664l0-75.587536c0-52.648081 42.835597-95.484701 95.484701-95.484701l78.317717 0C866.369913 743.236502 749.670028 860.135932 713.581088 872.637664z"
        fill={getIconColor(color, 1, '#1A1A1A')}
      />
    </svg>
  );
};

IconZhengguifapiao.defaultProps = {
  size: 18,
};

export default IconZhengguifapiao;
