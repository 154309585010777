/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceWorkAttendanceStatistics: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M219.05408 108.40064a31.62112 31.62112 0 1 1 0 63.22176h-93.7984c-11.63264 0-21.07392 9.4208-21.07392 21.07392v79.31904h815.63648V192.69632c0-11.63264-9.44128-21.0944-21.0944-21.0944h-93.55264l-0.2048 0.02048a31.62112 31.62112 0 1 1 0-63.22176h125.37856A52.69504 52.69504 0 0 1 983.04 161.09568v769.24928A52.69504 52.69504 0 0 1 930.34496 983.04H93.65504A52.69504 52.69504 0 0 1 40.96 930.34496V161.09568a52.69504 52.69504 0 0 1 52.69504-52.69504h125.39904z m210.39104 404.66432c-8.3968 0-16.44544 3.33824-22.36416 9.27744l-164.02432 164.2496a31.6416 31.6416 0 1 0 44.72832 44.76928l141.66016-141.84448 141.66016 141.84448a31.60064 31.60064 0 0 0 44.72832 0l164.02432-164.2496a31.6416 31.6416 0 1 0-44.7488-44.78976l-141.66016 141.84448-141.63968-141.824a31.60064 31.60064 0 0 0-22.36416-9.27744z m284.8768-274.00192a31.62112 31.62112 0 0 1-31.60064-31.60064V72.58112a31.62112 31.62112 0 1 1 63.22176 0v134.88128c0 17.44896-14.15168 31.60064-31.62112 31.60064zM623.69792 171.6224H400.30208a31.62112 31.62112 0 1 1 0-63.22176h223.39584a31.62112 31.62112 0 1 1 0 63.22176z m-314.01984 67.44064a31.62112 31.62112 0 0 1-31.62112-31.60064V72.58112a31.62112 31.62112 0 0 1 63.22176 0v134.88128c0 17.44896-14.1312 31.60064-31.60064 31.60064z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceWorkAttendanceStatistics.defaultProps = {
  size: 18,
};

export default IconAHumanResourceWorkAttendanceStatistics;
