/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksTaskTemplates: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M937.02127 295.035172L660.88954 24.90408A87.408605 87.408605 0 0 0 599.531485 0.00041H148.213906C100.352165 0.00041 61.44018 38.011274 61.44018 84.808056v854.384298C61.44018 985.989135 100.352165 1024 148.213906 1024h727.572188C923.647835 1024 962.55982 985.989135 962.55982 939.192354V355.041548a83.783646 83.783646 0 0 0-25.53855-60.006376z m-624.27111-7.106558h109.096916c20.316152 0 36.700145 16.097274 36.700145 35.901426 0 19.804152-16.486393 35.901426-36.700145 35.901426h-109.096916c-20.316152 0-36.720625-16.097274-36.720626-35.901426 0-19.804152 16.383993-35.901426 36.720626-35.901426z m366.632813 512.245556H312.75016c-20.316152 0-36.720625-16.097274-36.720626-35.901426 0-19.804152 16.506873-35.901426 36.720626-35.901426h366.632813c20.316152 0 36.700145 16.097274 36.700145 35.901426 0.1024 19.804152-16.383993 35.901426-36.700145 35.901426z m0-216.207274H312.75016c-20.316152 0-36.720625-16.117754-36.720626-35.921906 0-19.783672 16.506873-35.901426 36.720626-35.901425h366.632813c20.316152 0 36.700145 16.117754 36.700145 35.901425 0.1024 19.804152-16.383993 35.921906-36.700145 35.921906z m-50.07358-292.044683v-171.622332l183.910327 171.622332h-183.910327z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksTaskTemplates.defaultProps = {
  size: 18,
};

export default IconAWorksTaskTemplates;
