/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPeople24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 597.290667L554.666667 597.333333a85.333333 85.333333 0 0 1 85.12 78.933334L640 682.666667v64C639.957333 896 481.450667 938.666667 362.666667 938.666667c-116.138667 0-270.293333-40.789333-277.12-182.186667L85.333333 746.666667v-64.042667c0-44.970667 34.816-81.834667 78.933334-85.12L170.666667 597.333333zM649.386667 597.333333H853.333333a85.333333 85.333333 0 0 1 85.077334 78.933334L938.666667 682.666667v42.666666c-0.042667 130.645333-121.941333 170.666667-213.333334 170.666667a305.493333 305.493333 0 0 1-91.306666-13.738667c14.336-16.469333 25.898667-35.285333 34.218666-56.618666A264.106667 264.106667 0 0 0 725.333333 832l11.392-0.256c42.026667-1.834667 131.669333-15.488 137.642667-97.664L874.666667 725.333333v-42.666666a21.376 21.376 0 0 0-17.493334-20.992L853.333333 661.333333h-172.842666a126.165333 126.165333 0 0 0-25.386667-57.173333L649.386667 597.333333H853.333333h-203.946666zM170.666667 661.290667l-4.266667 0.426666a21.76 21.76 0 0 0-10.837333 5.802667 21.589333 21.589333 0 0 0-5.802667 10.794667l-0.426667 4.309333V746.666667c0 43.050667 19.2 73.472 60.458667 95.658666 35.242667 18.986667 85.461333 30.464 139.349333 32.128l13.525334 0.213334 13.525333-0.213334c53.888-1.664 104.064-13.141333 139.349333-32.128 38.656-20.821333 57.984-48.853333 60.245334-87.765333l0.213333-7.936V682.666667a21.376 21.376 0 0 0-17.493333-20.992L554.666667 661.333333l-384-0.042666zM362.666667 128a192 192 0 1 1 0 384 192 192 0 0 1 0-384z m384 85.333333a149.333333 149.333333 0 1 1 0 298.666667 149.333333 149.333333 0 0 1 0-298.666667z m-384-21.333333c-70.570667 0-128 57.429333-128 128s57.429333 128 128 128 128-57.429333 128-128-57.429333-128-128-128z m384 85.333333c-47.061333 0-85.333333 38.272-85.333334 85.333334s38.272 85.333333 85.333334 85.333333 85.333333-38.272 85.333333-85.333333-38.272-85.333333-85.333333-85.333334z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentPeople24Regular;

