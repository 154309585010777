/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCaiwuguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M805.76 128h-39.68c-12.8-72.32-76.16-128-152.32-128H174.72C113.92 0 64 49.92 64 110.72v802.56c0 60.8 49.92 110.72 110.72 110.72h674.56c61.44 0 110.72-49.92 110.72-110.72V282.24C960 197.12 890.88 128 805.76 128zM896 913.28c0 25.6-21.12 46.72-46.72 46.72H174.72c-25.6 0-46.72-21.12-46.72-46.72V110.72c0-25.6 21.12-46.72 46.72-46.72h439.04c40.96 0 74.88 26.88 86.4 64H279.68c-17.92 0-32 14.08-32 32 0.64 17.92 14.72 32 32 32h525.44c49.92 0 90.88 40.32 90.88 90.24v631.04z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M672 576c17.92 0 32-14.08 32-32s-14.08-32-32-32H554.88l124.8-138.88c11.52-13.44 10.88-33.28-2.56-45.44a32.32 32.32 0 0 0-45.44 2.56L512 464 391.68 330.88a32.32 32.32 0 0 0-45.44-2.56c-13.44 11.52-14.08 32-2.56 45.44L469.12 512H352c-17.92 0-32 14.08-32 32s14.08 32 32 32h128v128h-128c-17.92 0-32 14.08-32 32s14.08 32 32 32h128v96c0 17.92 14.08 32 32 32s32-14.08 32-32V768h128c17.92 0 32-14.08 32-32s-14.08-32-32-32h-128V576h128z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconCaiwuguanli.defaultProps = {
  size: 18,
};

export default IconCaiwuguanli;
