/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconDianzifapiao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M868.5 153.4v136.3h-713V153.4h713m70-90h-853c-11 0-20 9-20 20v276.3c0 11 9 20 20 20h852.9c11 0 20-9 20-20V83.4c0.1-11-8.9-20-19.9-20z"
        fill={getIconColor(color, 0, '#2186F0')}
      />
      <path
        d="M803.5 217.2h-583c-11 0-20 9-20 20v701.1c0 11 9 20 20 20l144.6-64.2c8.3-3.7 17.8-3.4 25.8 0.7L515 958.3h11.6l126.2-63.2c8.5-4.3 18.6-4.2 27.1 0.1l123.5 63.1c11 0 20-9 20-20V237.2c0.1-11-8.9-20-19.9-20zM525.8 720.5c0 9.7-4.7 17.5-10.5 17.5H317.5c-5.8 0-10.5-7.8-10.5-17.5v-35c0-9.7 4.7-17.5 10.5-17.5h197.7c5.8 0 10.5 7.8 10.5 17.5v35z m193.9-138c0 9.7-9 17.5-20 17.5H324.3c-11 0-20-7.8-20-17.5v-35c0-9.7 9-17.5 20-17.5h375.4c11 0 20 7.8 20 17.5v35z m0-144.9c0 9.7-9 17.5-20 17.5H324.3c-11 0-20-7.8-20-17.5v-35c0-9.7 9-17.5 20-17.5h375.4c11 0 20 7.8 20 17.5v35z"
        fill={getIconColor(color, 1, '#2186F0')}
      />
    </svg>
  );
};

IconDianzifapiao.defaultProps = {
  size: 18,
};

export default IconDianzifapiao;
