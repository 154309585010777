/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentHomeRegular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M450.133333 108.032a96 96 0 0 1 123.733334 0l288 242.858667c21.632 18.261333 34.133333 45.098667 34.133333 73.386666v418.261334a74.666667 74.666667 0 0 1-74.666667 74.666666h-149.333333a74.666667 74.666667 0 0 1-74.666667-74.666666v-234.666667a10.666667 10.666667 0 0 0-10.666666-10.666667h-149.333334a10.666667 10.666667 0 0 0-10.666666 10.666667v234.666667a74.666667 74.666667 0 0 1-74.666667 74.666666h-149.333333A74.666667 74.666667 0 0 1 128 842.538667V424.277333c0-28.288 12.501333-55.125333 34.133333-73.386666l288-242.858667z m82.474667 48.938667a32 32 0 0 0-41.216 0L203.392 399.786667a32 32 0 0 0-11.392 24.448v418.261333c0 5.888 4.778667 10.666667 10.666667 10.666667h149.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-234.666667c0-41.258667 33.450667-74.666667 74.666666-74.666666h149.333334c41.216 0 74.666667 33.408 74.666666 74.666666v234.666667c0 5.888 4.778667 10.666667 10.666667 10.666667h149.333333a10.666667 10.666667 0 0 0 10.666667-10.666667V424.277333a32 32 0 0 0-11.392-24.448l-288-242.858666z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentHomeRegular;

