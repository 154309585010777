/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const BookContacts24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M277.333333 85.333333A106.666667 106.666667 0 0 0 170.666667 192v640A106.666667 106.666667 0 0 0 277.333333 938.666667h565.333334a32 32 0 0 0 0-64H277.333333a42.666667 42.666667 0 0 1-42.666666-42.666667h608a32 32 0 0 0 32-32V192A106.666667 106.666667 0 0 0 768 85.333333H277.333333z m384 437.333334v21.333333c0 42.666667-59.008 74.666667-138.666666 74.666667S384 586.666667 384 544v-21.333333a32 32 0 0 1 32-32h213.333333a32 32 0 0 1 32 32zM597.333333 373.12a74.666667 74.666667 0 1 1-149.333333 0C448 331.946667 481.408 298.666667 522.666667 298.666667S597.333333 331.904 597.333333 373.12z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default BookContacts24Filled;

