/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const YoujianmobanXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M85.333333 224A138.666667 138.666667 0 0 1 224 85.333333h448A138.666667 138.666667 0 0 1 810.666667 224v202.965333a184.106667 184.106667 0 0 0-10.666667-0.298666H746.666667V224a74.666667 74.666667 0 0 0-74.666667-74.666667H224A74.666667 74.666667 0 0 0 149.333333 224v490.666667c0 41.216 33.450667 74.666667 74.666667 74.666666H256v10.666667c0 18.56 2.773333 36.48 7.978667 53.333333H224A138.666667 138.666667 0 0 1 85.333333 714.666667V224zM288 213.333333a32 32 0 0 0 0 64h320a32 32 0 1 0 0-64h-320zM341.333333 352a32 32 0 0 1 32-32h234.666667a32 32 0 1 1 0 64h-234.666667A32 32 0 0 1 341.333333 352z m-42.666666 256A138.666667 138.666667 0 0 1 437.333333 469.333333h362.666667A138.666667 138.666667 0 0 1 938.666667 608v192A138.666667 138.666667 0 0 1 800 938.666667h-362.666667A138.666667 138.666667 0 0 1 298.666667 800v-192z m138.666666-74.666667c-21.504 0-40.917333 9.088-54.528 23.68l235.861334 151.594667 235.861333-151.594667a74.453333 74.453333 0 0 0-54.528-23.68h-362.666667zM362.666667 800c0 41.216 33.450667 74.666667 74.666666 74.666667h362.666667a74.666667 74.666667 0 0 0 74.666667-74.666667v-179.882667l-238.677334 153.472a32 32 0 0 1-34.645333 0L362.666667 620.117333v179.882667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default YoujianmobanXianxing;

