/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentClipboardCode24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M484.352 745.984h278.528a29.184 29.184 0 0 0 0-58.368H484.352a29.184 29.184 0 0 0 0 58.368zM269.312 737.28a26.624 26.624 0 0 0 39.424 0l111.616-116.736a30.72 30.72 0 0 0 8.192-20.992 30.208 30.208 0 0 0-8.192-20.48L307.2 460.8a27.136 27.136 0 0 0-39.424 0 30.72 30.72 0 0 0 0 41.472l92.16 96.256-90.624 97.792a30.208 30.208 0 0 0 0 40.96zM902.144 278.016v-29.184A142.848 142.848 0 0 0 762.88 102.4H261.12a142.848 142.848 0 0 0-139.264 146.432v526.336A142.848 142.848 0 0 0 261.12 921.6h501.76a142.848 142.848 0 0 0 139.264-146.432V278.016z m-55.808 497.152a86.016 86.016 0 0 1-83.456 88.064H261.12a86.016 86.016 0 0 1-83.456-88.064V336.384h668.672z m0-497.152H177.664v-29.184A86.016 86.016 0 0 1 261.12 160.768h501.76a86.016 86.016 0 0 1 83.456 88.064z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentClipboardCode24Regular;

