/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBookGlobe24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M513.706667 256.725333a202.666667 202.666667 0 1 0 14.293333-0.512h-0.426667a57.6 57.6 0 0 0-13.909333 0.512z m-69.205334 78.293334c-9.642667 28.885333-15.701333 64.256-17.365333 102.314666h-46.933333a149.333333 149.333333 0 0 1 64.298666-102.314666z m-15.957333 166.314666a359.68 359.68 0 0 0 16.170667 81.493334 149.632 149.632 0 0 1-59.946667-81.493334h43.776z m169.216 89.6l0.128-0.341333c9.386667-25.045333 15.914667-55.722667 18.901333-89.258667h54.442667a149.76 149.76 0 0 1-73.472 89.6z m-45.269333-89.6a279.722667 279.722667 0 0 1-14.506667 66.773334c-5.034667 13.354667-10.24 21.930667-14.336 26.581333a38.997333 38.997333 0 0 1-0.981333 1.066667 38.997333 38.997333 0 0 1-0.981334-1.066667c-4.138667-4.693333-9.301333-13.226667-14.293333-26.581333-6.570667-17.493333-11.776-40.405333-14.549333-66.773334h59.648z m1.664-64h-62.976c1.792-35.328 7.893333-66.005333 16.213333-88.106666 4.992-13.354667 10.154667-21.930667 14.293333-26.581334a33.706667 33.706667 0 0 1 0.981334-1.066666 33.706667 33.706667 0 0 1 0.981333 1.066666c4.138667 4.693333 9.301333 13.226667 14.293333 26.581334 8.32 22.101333 14.421333 52.778667 16.213334 88.106666z m64.042666 0c-1.834667-41.728-8.96-80.213333-20.266666-110.421333a149.461333 149.461333 0 0 1 77.866666 110.421333h-57.6z m-93.098666-117.76l-0.170667 0.085334 0.170667-0.128z m-4.821334 0l0.128 0.085334a0.597333 0.597333 0 0 0-0.170666-0.128l0.042666 0.042666z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M277.333333 85.333333A106.666667 106.666667 0 0 0 170.666667 192v640A106.666667 106.666667 0 0 0 277.333333 938.666667h565.333334a32 32 0 0 0 0-64H277.333333a42.666667 42.666667 0 0 1-42.666666-42.666667h608a32 32 0 0 0 32-32V192A106.666667 106.666667 0 0 0 768 85.333333H277.333333zM810.666667 192V768H234.666667V192a42.666667 42.666667 0 0 1 42.666666-42.666667H768a42.666667 42.666667 0 0 1 42.666667 42.666667z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
 );
};

export default IcFluentBookGlobe24Regular;

