/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconJurassicPc: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M872.8 112H151.2c-48.1 0-87.2 39.6-87.2 88.3V650c0 48.7 39.1 88.3 87.2 88.3h298.6L290 912h444L574.2 738.3h298.6c48.1 0 87.2-39.6 87.2-88.3V200.3c0-48.7-39.1-88.3-87.2-88.3z m-745.4 88.3c0-13.3 10.7-24.1 23.8-24.1h721.6c13.1 0 23.8 10.8 23.8 24.1v305.1H127.4V200.3z"
        fill={getIconColor(color, 0, '#47444F')}
      />
    </svg>
  )
}

IconJurassicPc.defaultProps = {
  size: 18,
}

export default IconJurassicPc
