/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Female: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M281.6 322.56c89.6 2.56 222.72-23.04 322.56-128 38.4 43.52 56.32 87.04 128 94.72 30.72 81.92-15.36 84.48-28.16 84.48 0 51.2-46.08 143.36-120.32 156.16v61.44H435.2v-61.44c-74.24-23.04-110.08-74.24-110.08-153.6-23.04 12.8-43.52-12.8-43.52-53.76z"
        fill={getIconColor(color, 0, '#FFFFFF')}
      />
      <path
        d="M512 51.2c128 0 230.4 102.4 230.4 230.4v215.04c0 56.32-46.08 102.4-102.4 102.4H384c-56.32 0-102.4-46.08-102.4-102.4V281.6c0-128 102.4-230.4 230.4-230.4z"
        fill={getIconColor(color, 1, '#5A6274')}
      />
      <path
        d="M422.4 504.32h179.2v81.92h-179.2z"
        fill={getIconColor(color, 2, '#F3E8BA')}
      />
      <path
        d="M281.6 322.56c89.6 2.56 222.72-23.04 322.56-128 38.4 43.52 56.32 87.04 128 94.72 30.72 81.92-15.36 84.48-28.16 84.48 0 51.2-51.2 151.04-120.32 156.16s-92.16 2.56-148.48 0-110.08-74.24-110.08-153.6c-23.04 12.8-43.52-12.8-43.52-53.76z"
        fill={getIconColor(color, 3, '#FFF7D6')}
      />
      <path
        d="M419.84 576h212.48c117.76 0 217.6 84.48 240.64 199.68l10.24 46.08c12.8 69.12-30.72 135.68-102.4 151.04H296.96c-71.68 0-128-56.32-128-128 0-7.68 0-15.36 2.56-23.04l7.68-48.64c23.04-115.2 122.88-197.12 240.64-197.12z"
        fill={getIconColor(color, 4, '#FFD3D3')}
      />
      <path
        d="M389.12 578.56l128 368.64 133.12-371.2s-20.48-2.56-58.88-2.56c-38.4 0-115.2-2.56-138.24 0-35.84 2.56-64 5.12-64 5.12z"
        fill={getIconColor(color, 5, '#FFFFFF')}
      />
    </svg>
 );
};

export default Female;

