/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconJinzhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 12.624658C235.660274 12.624658 11.221918 238.465753 11.221918 513.40274c0 276.339726 224.438356 500.778082 500.778082 500.778082 276.339726 0 500.778082-224.438356 500.778082-500.778082C1012.778082 238.465753 788.339726 12.624658 512 12.624658z m0 70.136986c106.608219 0 203.39726 39.276712 277.742466 102.4L183.758904 792.547945c-64.526027-75.747945-102.4-172.536986-102.4-277.742466 0-238.465753 193.578082-432.043836 430.641096-432.043835z m0 861.282192c-106.608219 0-203.39726-39.276712-277.742466-102.4l605.983562-605.983562c64.526027 75.747945 102.4 172.536986 102.4 277.742466 0 238.465753-193.578082 430.641096-430.641096 430.641096z"
        fill={getIconColor(color, 0, '#464646')}
      />
    </svg>
  );
};

IconJinzhi.defaultProps = {
  size: 18,
};

export default IconJinzhi;
