/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentFolderZip24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M597.205333 277.333333h-7.509333L411.605333 425.770667l-6.656 5.077333a96 96 0 0 1-46.421333 16.768l-8.362667 0.384L85.333333 447.957333v309.376l0.213334 6.570667A96 96 0 0 0 181.333333 853.333333h479.872v-149.418666h-10.666666a32 32 0 0 1 0-64h10.666666v-64h-10.666666a32 32 0 0 1 0-64h10.666666v-42.794667h-32a32 32 0 0 1-32-32V277.333333z m64 0h64v117.333334h-64V277.333333z m128 0h53.461334a96 96 0 0 1 95.104 82.688l0.682666 6.741334 0.213334 6.570666v384a96 96 0 0 1-89.429334 95.786667L842.666667 853.333333h-117.461334v-85.333333h10.666667a32 32 0 0 0 0-64h-10.666667V640h10.666667a32 32 0 0 0 0-64h-10.666667v-106.88h32a32 32 0 0 0 32-32V277.333333z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M404.906667 187.818667A96 96 0 0 0 350.208 170.666667H181.333333l-6.570666 0.213333A96 96 0 0 0 85.333333 266.666667v117.290666L350.165333 384l4.437334-0.298667a32 32 0 0 0 12.416-4.522666l3.626666-2.56 130.688-108.970667-89.728-74.752-6.656-5.077333z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
 );
};

export default IcFluentFolderZip24Filled;

