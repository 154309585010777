/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const A24Regular1X101: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M160 746.666667h-4.266667a32 32 0 0 1-27.733333-32V405.333333h209.066667A98.56 98.56 0 0 0 390.4 384l101.12-85.333333h334.08a32 32 0 0 1 27.733333 31.573333v85.333333a274.773333 274.773333 0 0 1 64 46.08V317.44a96 96 0 0 0-96-82.773333h-329.813333l-101.12-85.333334L384 145.066667A95.146667 95.146667 0 0 0 328.96 128H153.6a95.573333 95.573333 0 0 0-89.6 96V721.066667A95.573333 95.573333 0 0 0 160 810.666667h331.52a303.786667 303.786667 0 0 1-30.293333-64zM128 224v-4.266667a32 32 0 0 1 31.573333-27.733333h173.653334a32.426667 32.426667 0 0 1 16.213333 6.826667l80.64 67.413333-80.64 67.84-3.84 2.56a32.853333 32.853333 0 0 1-16.64 4.693333H128z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M788.48 719.786667a82.773333 82.773333 0 0 1-128-3.413334h33.28a16.64 16.64 0 0 0 0-33.28h-67.413333a16.64 16.64 0 0 0-16.64 16.64V768a16.64 16.64 0 1 0 32.853333 0v-18.346667a115.2 115.2 0 0 0 170.666667-6.4 16.213333 16.213333 0 0 0 0-23.04 16.64 16.64 0 0 0-24.746667-0.426666z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M725.333333 426.666667a234.666667 234.666667 0 1 0 234.666667 234.666666A234.666667 234.666667 0 0 0 725.333333 426.666667z m0 413.866666a179.2 179.2 0 1 1 179.2-179.2 179.2 179.2 0 0 1-179.2 179.2z"
        fill={getIconColor(color, 2, '#333333')}
      />
      <path
        d="M824.32 550.826667a16.64 16.64 0 0 0-16.213333 16.64v18.346666a115.626667 115.626667 0 0 0-176.64 11.52 16.64 16.64 0 0 0 26.88 19.2 82.773333 82.773333 0 0 1 133.12 0h-33.28a16.64 16.64 0 0 0 0 33.28h66.133333a16.64 16.64 0 0 0 16.64-16.64V567.466667a16.64 16.64 0 0 0-16.64-16.64z"
        fill={getIconColor(color, 3, '#333333')}
      />
    </svg>
 );
};

export default A24Regular1X101;

