/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDocumentCopy24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M234.794667 197.418667L234.666667 288v448.170667a138.666667 138.666667 0 0 0 138.666666 138.666666h367.616a96.042667 96.042667 0 0 1-90.538666 64H373.333333A202.666667 202.666667 0 0 1 170.666667 736.170667V288c0-41.813333 26.752-77.44 64.128-90.581333zM560.128 85.333333c25.472 0 49.92 10.112 67.925333 28.16l197.034667 197.12c18.005333 18.005333 28.117333 42.453333 28.117333 67.882667v357.674667a96 96 0 0 1-96 96H373.418667a96 96 0 0 1-96-96V181.333333A96 96 0 0 1 373.418667 85.333333h186.709333zM554.666667 149.333333H373.418667a32 32 0 0 0-32 32v554.837334c0 17.664 14.336 32 32 32h383.786666a32 32 0 0 0 32-32V384.128H650.666667a96 96 0 0 1-95.786667-89.386667L554.666667 288.170667V149.333333z m64 45.269334l0.042666 93.568c0 16.213333 12.032 29.568 27.648 31.701333l4.352 0.298667 93.44-0.042667-125.44-125.525333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentDocumentCopy24Regular;

