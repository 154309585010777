/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseCloseApplyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M798.45376 158.98624c32.48128 0 58.83904 26.5216 58.92096 59.31008V923.648c-0.06144 32.80896-26.4192 59.392-58.92096 59.41248H99.88096C67.44064 982.9376 41.1648 956.49792 40.96 923.77088v-705.3312c0.02048-32.80896 26.37824-59.43296 58.90048-59.45344h698.59328zM891.24864 40.96c50.66752 0.08192 91.70944 41.53344 91.79136 92.672v755.07712a34.304 34.304 0 0 1-34.03776 34.36544h-0.04096a34.2016 34.2016 0 0 1-34.03776-34.36544V133.81632a24.02304 24.02304 0 0 0-23.81824-24.08448H143.27808a34.26304 34.26304 0 0 1-32.768-34.38592c0-18.49344 14.45888-33.66912 32.768-34.38592h747.97056zM450.6624 327.68c-59.06432 0-106.9056 48.2304-106.9056 107.7248a107.47904 107.47904 0 0 0 76.94336 103.424C310.82496 553.71776 225.28 649.216 225.28 763.904v1.4336c0 18.51392 2.2528 36.5568 6.43072 53.8624h437.69856c4.1984-17.3056 6.43072-35.34848 6.43072-53.8624v-1.4336c0.1024-114.688-85.36064-210.20672-195.23584-225.0752a107.64288 107.64288 0 0 0 76.94336-103.424c0-59.4944-47.84128-107.7248-106.9056-107.7248z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseCloseApplyList.defaultProps = {
  size: 18,
};

export default IconABusinessCaseCloseApplyList;
