/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCalculator: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M864 64H160C107.2 64 64 107.2 64 160v704c0 52.8 43.2 96 96 96h704c52.8 0 96-43.2 96-96V160c0-52.8-43.2-96-96-96z m32 800c0 17.6-14.4 32-32 32H160c-17.6 0-32-14.4-32-32V160c0-17.6 14.4-32 32-32h704c17.6 0 32 14.4 32 32v704z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M416 288h-64V224c0-17.6-14.4-32-32-32s-32 14.4-32 32v64H224c-17.6 0-32 14.4-32 32s14.4 32 32 32h64v64c0 17.6 14.4 32 32 32s32-14.4 32-32v-64h64c17.6 0 32-14.4 32-32s-14.4-32-32-32zM800 608h-192c-17.6 0-32 14.4-32 32s14.4 32 32 32h192c17.6 0 32-14.4 32-32s-14.4-32-32-32zM800 736h-192c-17.6 0-32 14.4-32 32s14.4 32 32 32h192c17.6 0 32-14.4 32-32s-14.4-32-32-32zM800 288h-192c-17.6 0-32 14.4-32 32s14.4 32 32 32h192c17.6 0 32-14.4 32-32s-14.4-32-32-32zM422.72 601.28a31.872 31.872 0 0 0-45.12 0l-57.6 57.6-57.28-57.28a31.872 31.872 0 1 0-45.12 45.12L274.88 704 217.6 761.28a31.872 31.872 0 0 0 22.72 54.4c8.32 0 16.32-3.2 22.72-9.28L320 749.12l57.28 57.28c6.4 6.4 14.4 9.28 22.72 9.28s16.32-3.2 22.72-9.28a31.872 31.872 0 0 0 0-45.12L365.12 704l57.28-57.28c12.8-12.48 12.8-32.96 0.32-45.44z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconCalculator.defaultProps = {
  size: 18,
};

export default IconCalculator;
