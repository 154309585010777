/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinssBiddingManageMyList: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M728.18688 0c24.12544 0 47.28832 9.80992 64.34816 27.25888A94.16704 94.16704 0 0 1 819.2 93.0816V515.8912A266.8544 266.8544 0 0 0 773.69344 512c-150.81472 0-273.08032 125.05088-273.08032 279.26528 0 97.15712 48.49664 182.6816 122.0608 232.73472H91.01312C40.7552 1024 0 982.3232 0 930.9184V93.0816C0 41.6768 40.7552 0 91.01312 0h637.17376zM798.72 573.44c124.416 0 225.28 100.864 225.28 225.28s-100.864 225.28-225.28 225.28-225.28-100.864-225.28-225.28 100.864-225.28 225.28-225.28z m0.04096 81.92c-32.19456 0-58.30656 28.13952-58.30656 62.85312 0 28.63104 17.7152 52.75648 41.984 60.33408-59.94496 8.66304-106.5984 64.36864-106.5984 131.2768v0.8192c0 10.81344 1.2288 21.34016 3.50208 31.4368H918.1184A141.96736 141.96736 0 0 0 921.6 910.66368v-0.8192c0.04096-66.92864-46.55104-122.63424-106.496-131.29728 24.2688-7.63904 41.984-31.76448 41.984-60.33408 0-34.7136-26.112-62.85312-58.32704-62.85312z m-461.312 135.90528H128a34.9184 34.9184 0 0 0 0 69.8368h209.44896a34.9184 34.9184 0 1 0 0-69.8368z m93.10208-162.89792H128a34.9184 34.9184 0 0 0 0 69.81632h302.55104a34.9184 34.9184 0 0 0 0-69.8368z m-11.63264-488.73472c-115.69152 0-209.46944 93.7984-209.46944 209.44896 0 115.69152 93.7984 209.46944 209.46944 209.46944s209.44896-93.7984 209.44896-209.46944-93.7984-209.44896-209.44896-209.44896z m0.22528 69.81632c6.16448 0 11.75552 3.584 14.37696 9.15456l34.48832 73.89184 77.08672 11.8784a15.7696 15.7696 0 0 1 12.63616 10.69056 15.7696 15.7696 0 0 1-3.72736 16.05632l-56.32 57.63072 12.6976 79.07328a15.89248 15.89248 0 0 1-15.0528 20.8896h-0.2048a16.87552 16.87552 0 0 1-7.3728-1.78176l-68.97664-38.35904-68.89472 37.92896a15.872 15.872 0 0 1-23.3472-16.50688l13.45536-81.55136v-0.02048l-56.19712-57.81504a15.7696 15.7696 0 0 1-3.6864-16.11776 15.70816 15.70816 0 0 1 12.65664-10.6496l77.2096-11.63264 34.75456-73.68704a15.91296 15.91296 0 0 1 14.41792-9.07264z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinssBiddingManageMyList.defaultProps = {
  size: 18,
};

export default IconABusinssBiddingManageMyList;
