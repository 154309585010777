/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodehetong: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M466.432088 824.981925h-204.482699a35.255638 35.255638 0 0 1-36.313307-36.313307V193.906008a35.255638 35.255638 0 0 1 36.313307-35.255638h463.964193a35.255638 35.255638 0 0 1 35.255638 35.255638v297.557583a21.858495 21.858495 0 1 0 43.540713 0V193.906008a80.559132 80.559132 0 0 0-78.796351-79.325185H261.949389a80.382854 80.382854 0 0 0-79.677742 79.325185v594.76261a80.382854 80.382854 0 0 0 79.677742 79.854019h204.482699A21.329661 21.329661 0 0 0 488.114305 846.135307a22.211052 22.211052 0 0 0-21.682217-21.153382z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M653.463247 513.322086a188.441384 188.441384 0 1 0 188.441384 188.441384 188.088828 188.088828 0 0 0-188.441384-188.441384z m0 333.342056a145.07695 145.07695 0 1 1 144.900671-144.900672A145.429506 145.429506 0 0 1 653.463247 846.135307zM653.463247 288.567395a22.211052 22.211052 0 0 0-21.858496-21.682217H356.25822a21.682217 21.682217 0 1 0 0 43.364435h275.346531a22.211052 22.211052 0 0 0 21.858496-21.682218zM356.25822 397.331038a21.682217 21.682217 0 1 0 0 43.364435h158.65037a21.682217 21.682217 0 1 0 0-43.364435z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M653.815803 721.330349a352.556378 352.556378 0 0 1-27.146841 8.108797v33.1403c0 10.75297-5.81718 16.393872-17.627819 16.393871h-17.627819l-3.87812-16.217593a134.32398 134.32398 0 0 0 16.393872 1.057669 5.112067 5.112067 0 0 0 5.81718-5.640902v-24.678947l-29.438458 5.464624-2.291616-17.627819c11.105526-1.586504 21.682217-3.525564 31.730074-5.640902V687.484937h-32.258909v-16.393871h31.906352v-15.159925c-7.932519 1.586504-15.865037 2.996729-24.502668 4.054399l-3.525564-16.217594a217.351007 217.351007 0 0 0 60.463419-14.278533l5.993459 16.041315c-6.874849 2.467895-13.749699 4.583233-21.153383 6.522293v19.038045h33.492856c-0.881391-12.515751-1.233947-26.441728-1.233947-41.425375h17.627818c0 15.688759 0 29.438458 1.586504 41.425375h49.005337V687.484937h-47.595111a160.413152 160.413152 0 0 0 4.230676 26.26545c0 2.467895 1.233947 4.759511 1.93906 7.051128a147.192288 147.192288 0 0 0 17.627819-27.675676l14.807368 7.756241A156.182475 156.182475 0 0 1 691.539336 740.368394a58.700637 58.700637 0 0 0 5.288345 10.047857c4.583233 7.227406 8.108797 10.75297 10.400413 10.752969s3.701842-8.285075 5.640902-24.678946l15.159925 7.932518c-4.230677 23.444999-10.224135 35.255638-17.627819 35.255638a31.201239 31.201239 0 0 1-22.916165-15.336202 70.511276 70.511276 0 0 1-8.108797-12.868308 130.622138 130.622138 0 0 1-37.194697 21.505939l-9.695301-14.983646a121.103116 121.103116 0 0 0 39.662593-23.621278c-1.762782-5.112067-3.525564-10.576691-4.93579-16.217593a206.950594 206.950594 0 0 1-5.640902-30.672405h-35.255637v24.150112a273.936306 273.936306 0 0 0 27.146841-7.932518zM722.740575 653.11069l-12.163195 12.163195a166.054054 166.054054 0 0 0-28.733345-22.916164l11.458082-10.929248A224.049578 224.049578 0 0 1 722.740575 653.11069z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconWodehetong.defaultProps = {
  size: 18,
};

export default IconWodehetong;
