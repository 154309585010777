/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQingjiashenpi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1131 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M487.738576 413.402179h-6.589185c-32.945926 0-59.302666 28.553135-57.106271 59.302666 0 32.945926 28.553135 59.302666 59.302666 57.106271 32.945926 0 57.106271-26.35674 57.106271-59.302666 2.196395-28.553135-21.96395-54.909876-52.713481-57.106271zM902.857238 66.371763h-120.801727c2.196395-32.945926-21.96395-63.695456-54.909876-65.891851-32.945926-4.39279-63.695456 21.96395-65.891851 54.909876V66.371763H301.044997c2.196395-32.945926-21.96395-63.695456-54.909875-65.891851-32.945926-4.39279-63.695456 21.96395-65.891852 54.909876V66.371763H59.441543C24.299223 66.371763 0.138877 83.942924 0.138877 119.085244v768.738264c-2.196395 30.749531 21.96395 57.106271 52.713481 59.302666h564.473525c-13.17837-13.17837-24.160345-28.553135-35.142321-46.124296H44.066778V288.207662h878.558015v166.926023c13.17837 2.196395 26.35674 6.589185 39.535111 10.981975V119.085244c0-35.142321-24.160345-52.713481-59.302666-52.713481z m19.767555 177.907999H44.066778V110.299664h878.558015v133.980098z m-674.293276 169.122417H241.742331c-32.945926 0-59.302666 28.553135-57.106271 59.302666 0 32.945926 28.553135 59.302666 59.302666 57.106271 32.945926 0 57.106271-26.35674 57.106271-59.302666 2.196395-28.553135-21.96395-54.909876-52.71348-57.106271z m474.421328 0c-32.945926-2.196395-59.302666 21.96395-61.499061 52.713481v6.589185c0 17.57116 6.589185 32.945926 19.767555 46.124296 30.749531-21.96395 63.695456-39.535111 101.034172-50.517086-2.196395-32.945926-28.553135-54.909876-59.302666-54.909876zM248.331517 650.612844H241.742331c-32.945926 0-59.302666 28.553135-57.106271 59.302666 0 32.945926 28.553135 59.302666 59.302666 57.106271 32.945926 0 57.106271-26.35674 57.106271-59.302666 2.196395-28.553135-21.96395-54.909876-52.71348-57.106271z m239.407059 0h-6.589185c-32.945926 0-59.302666 28.553135-57.106271 59.302666 0 32.945926 28.553135 59.302666 59.302666 57.106271 32.945926 0 57.106271-26.35674 57.106271-59.302666 2.196395-28.553135-21.96395-54.909876-52.713481-57.106271z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M1014.873385 764.825386c0 13.17837-8.78558 21.96395-19.767556 21.96395h-149.354862c-8.78558 0-15.374765-6.589185-15.374765-15.374765v-151.551258c0-8.78558 6.589185-15.374765 15.374765-15.374765h10.981975c8.78558 0 15.374765 6.589185 15.374765 15.374765V747.254225h125.194517c8.78558-2.196395 17.57116 6.589185 17.571161 17.571161z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M863.322127 496.865191c-144.962073 0-263.567405 118.605332-263.567404 263.567404s118.605332 263.567405 263.567404 263.567405 263.567405-118.605332 263.567405-263.567405-116.408937-263.567405-263.567405-263.567404z m0 494.188883c-127.390912 0-230.621479-103.230567-230.621479-230.621479s103.230567-230.621479 230.621479-230.621479 230.621479 103.230567 230.621479 230.621479-103.230567 230.621479-230.621479 230.621479z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconQingjiashenpi.defaultProps = {
  size: 18,
};

export default IconQingjiashenpi;
