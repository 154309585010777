/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconZuhuguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M1005.952 777.984l-161.92-113.984H832l-174.08 113.984-5.952 6.016v228.032c0 5.952 6.016 5.952 12.032 5.952h107.968c6.016 0 12.032-6.016 12.032-6.016v-48a53.76 53.76 0 0 1 53.952-53.952c30.08 0 60.032 24 60.032 54.016v53.952c0 6.016 6.016 6.016 11.968 6.016h102.016c6.016 0 12.032-6.016 12.032-6.016v-227.968c-12.032-6.016-12.032-6.016-18.048-12.032z"
        fill={getIconColor(color, 0, '#000000')}
      />
      <path
        d="M225.92 844.032c54.08-108.032 174.08-180.032 306.048-180.032 54.016 0 102.016 12.032 150.016 30.016l59.968-36.032a437.76 437.76 0 0 0-209.92-54.016c-180.032 0-342.08 120.064-390.08 282.048-5.952 17.984 0 35.968 12.032 54.016 11.968 17.92 30.016 23.936 48 23.936H592v-59.968H201.984c6.016-17.984 11.968-41.984 24-60.032zM531.968 574.016a240.704 240.704 0 0 0 240-240 240.704 240.704 0 0 0-240-240 240.704 240.704 0 0 0-240 240 240.704 240.704 0 0 0 240 240z m0-420.032c102.016 0 180.032 78.016 180.032 180.032 0 101.952-78.08 179.968-180.032 179.968C429.952 513.984 352 435.968 352 334.08c0-102.016 77.952-180.032 179.968-180.032z"
        fill={getIconColor(color, 1, '#000000')}
      />
    </svg>
  )
}

IconZuhuguanli.defaultProps = {
  size: 18,
}

export default IconZuhuguanli
