/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChazhao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M948.032 902.784l-206.976-206.976C797.696 628.928 832 542.528 832 448c0-212.064-171.936-384-384-384S64 235.936 64 448s171.936 384 384 384c94.528 0 180.928-34.304 247.808-90.912l206.976 206.976c14.016 14.016 35.488 15.232 48 2.72C963.264 938.272 962.048 916.8 948.032 902.784zM448 768C271.264 768 128 624.736 128 448S271.264 128 448 128s320 143.264 320 320S624.736 768 448 768z"
        fill={getIconColor(color, 0, '#272636')}
      />
    </svg>
  );
};

IconChazhao.defaultProps = {
  size: 18,
};

export default IconChazhao;
