/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Gongshiliebiao: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M938.666667 784.597333A154.069333 154.069333 0 0 1 784.597333 938.666667H239.36A154.069333 154.069333 0 0 1 85.333333 784.597333V239.36A154.069333 154.069333 0 0 1 239.402667 85.333333H784.64A154.069333 154.069333 0 0 1 938.666667 239.402667V784.64zM749.056 310.528a35.541333 35.541333 0 0 0-30.72-35.242667l-4.864-0.341333H310.528l-4.864 0.341333a35.541333 35.541333 0 0 0 0 70.442667l4.864 0.341333h402.944l4.864-0.341333a35.541333 35.541333 0 0 0 30.72-35.2z m0 402.944a35.541333 35.541333 0 0 0-30.72-35.2l-4.864-0.341333H310.528l-4.864 0.341333a35.541333 35.541333 0 0 0 0 70.4l4.864 0.384h402.944l4.864-0.341333a35.541333 35.541333 0 0 0 30.72-35.242667z m0-201.472a35.541333 35.541333 0 0 0-30.72-35.242667l-4.864-0.298666H310.528l-4.864 0.298666a35.541333 35.541333 0 0 0 0 70.485334l4.864 0.298666h402.944l4.864-0.298666a35.541333 35.541333 0 0 0 30.72-35.242667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Gongshiliebiao;

