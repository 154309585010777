/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const PeopleSwap24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M576 597.376a64 64 0 0 1 63.701333 57.856L640 661.333333v7.253334a74.666667 74.666667 0 0 0-84.821333 14.72l-106.666667 106.709333a74.666667 74.666667 0 0 0 0 105.6l29.141333 29.056c-38.272 9.941333-78.506667 13.952-114.986666 13.952-116.138667 0-270.293333-40.789333-277.12-182.186667L85.333333 746.666667v-85.333334a64 64 0 0 1 57.856-63.701333L149.333333 597.333333l426.666667 0.042667z"
        fill={getIconColor(color, 0, '#212121')}
      />
      <path
        d="M630.613333 758.741333l-52.010666 52.053334h293.461333l-52.053333-52.053334a32 32 0 0 1 45.269333-45.226666l106.666667 106.752a32 32 0 0 1 0 45.226666l-106.666667 106.624a32 32 0 0 1-45.226667-45.269333l52.053334-52.053333h-293.546667l52.053333 52.053333a32 32 0 1 1-45.226666 45.226667l-106.666667-106.581334a32 32 0 0 1 0-45.269333l106.666667-106.709333a32 32 0 1 1 45.226666 45.226666z"
        fill={getIconColor(color, 1, '#212121')}
      />
      <path
        d="M895.488 683.349333L938.666667 726.613333V661.333333l-0.298667-6.186666A64 64 0 0 0 874.666667 597.333333h-213.845334l4.821334 6.912c10.666667 16.512 17.024 36.010667 17.024 57.088v85.333334l-0.298667 11.946666a235.52 235.52 0 0 1-0.64 9.514667h93.44a74.666667 74.666667 0 0 1 120.32-84.778667zM362.666667 128a192 192 0 1 1 0 384 192 192 0 0 1 0-384z m384 85.333333a149.333333 149.333333 0 1 1 0 298.666667 149.333333 149.333333 0 0 1 0-298.666667z"
        fill={getIconColor(color, 2, '#212121')}
      />
    </svg>
 );
};

export default PeopleSwap24Filled;

