/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShoukuanguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M648.533333 502.247619v-97.523809h-92.647619L633.904762 273.066667l-87.771429-48.761905-73.142857 131.657143-78.019047-131.657143-82.895239 48.761905 73.142858 131.657143H297.447619v97.523809h126.780952v43.885714H297.447619v97.52381h126.780952v131.657143h97.52381v-131.657143h126.780952v-97.52381h-126.780952v-43.885714zM1024 716.8h-248.685714l43.885714-43.885714-58.514286-58.514286-146.285714 151.161905 146.285714 146.285714 58.514286-53.638095-43.885714-43.885714H1024z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M482.742857 897.219048c-214.552381 0-385.219048-175.542857-385.219047-385.219048 0-214.552381 175.542857-385.219048 385.219047-385.219048s385.219048 175.542857 385.219048 385.219048c0 39.009524-4.87619 78.019048-19.504762 117.028571l92.647619 29.257143c14.628571-48.761905 19.504762-92.647619 19.504762-146.285714C960.609524 243.809524 741.180952 29.257143 477.866667 29.257143S0 243.809524 0 512s219.428571 482.742857 482.742857 482.742857c63.390476 0 126.780952-9.752381 185.295238-34.133333l-39.009524-92.647619c-43.885714 19.504762-92.647619 29.257143-146.285714 29.257143z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconShoukuanguanli.defaultProps = {
  size: 18,
};

export default IconShoukuanguanli;
