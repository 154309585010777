/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQiehuan: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1142 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M1138.058 262.617a43.717 43.717 0 0 0-9.492-47.656L922.703 9.058l-61.834 61.834 131.19 131.19H4.293v87.474H1097.61c17.723 0 33.674-10.634 40.448-26.979z m3.308 464.344H48.05a43.717 43.717 0 0 0-30.956 74.634l205.863 205.863 61.834-61.833-131.15-131.23h987.766v-87.434z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconQiehuan.defaultProps = {
  size: 18,
};

export default IconQiehuan;
