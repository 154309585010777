/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Youbanmawentianchong: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M184.871385 0A184.871385 184.871385 0 0 0 0 184.871385v654.25723A184.871385 184.871385 0 0 0 184.871385 1024h654.25723A184.871385 184.871385 0 0 0 1024 839.128615V184.871385A184.871385 184.871385 0 0 0 839.128615 0H184.871385zM85.346462 184.871385a99.603692 99.603692 0 0 1 99.524923-99.524923h270.25723V315.076923h-369.821538V184.871385zM540.435692 708.923077h398.217846v130.205538a99.564308 99.564308 0 0 1-99.524923 99.524923h-298.692923V708.923077zM938.653538 315.076923h-398.178461V85.346462h298.653538a99.603692 99.603692 0 0 1 99.524923 99.524923V315.076923zM455.128615 708.923077v229.730461H184.871385a99.564308 99.564308 0 0 1-99.524923-99.524923V708.923077h369.782153z m-369.821538-367.576615h853.346461v341.307076H85.346462V341.346462z"
        fill={getIconColor(color, 0, '#3F51B5')}
        opacity=".504"
      />
      <path
        d="M78.769231 315.076923h866.461538v393.846154H78.769231z"
        fill={getIconColor(color, 1, '#3F51B5')}
      />
    </svg>
 );
};

export default Youbanmawentianchong;

