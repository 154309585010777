/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTongjifenxi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M960 742.4c0 25.6-19.2 51.2-44.8 51.2h-70.4c-25.6 0-44.8-19.2-44.8-51.2V115.2c0-25.6 19.2-51.2 44.8-51.2h70.4c25.6 0 44.8 19.2 44.8 51.2v627.2zM966.4 0h-179.2c-32 0-57.6 25.6-57.6 57.6v742.4c0 32 25.6 57.6 57.6 57.6h179.2c32 0 57.6-25.6 57.6-57.6V57.6c0-32-25.6-57.6-57.6-57.6zM608 742.4c0 25.6-19.2 51.2-44.8 51.2H486.4c-25.6 0-44.8-19.2-44.8-51.2V243.2c0-25.6 19.2-51.2 44.8-51.2h70.4c25.6 0 44.8 19.2 44.8 51.2v499.2h6.4zM614.4 128H435.2c-32 0-57.6 25.6-57.6 57.6v614.4c0 32 25.6 57.6 57.6 57.6h179.2c32 0 57.6-25.6 57.6-57.6V185.6c0-32-25.6-57.6-57.6-57.6z m-371.2 614.4c0 25.6-19.2 51.2-44.8 51.2H121.6c-25.6 0-44.8-19.2-44.8-51.2V435.2c0-25.6 19.2-51.2 44.8-51.2H192c25.6 0 44.8 19.2 44.8 51.2v307.2h6.4zM249.6 320H70.4c-32 0-57.6 25.6-57.6 57.6v422.4c0 32 25.6 57.6 57.6 57.6h179.2c32 0 57.6-25.6 57.6-57.6V377.6c0-32-25.6-57.6-57.6-57.6z m748.8 595.2H25.6c-12.8 0-25.6 12.8-25.6 32s12.8 32 25.6 32h972.8c12.8 0 25.6-12.8 25.6-32s-12.8-32-25.6-32z"
        fill={getIconColor(color, 0, '#5584FF')}
      />
    </svg>
  );
};

IconTongjifenxi.defaultProps = {
  size: 18,
};

export default IconTongjifenxi;
