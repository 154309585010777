/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodelian: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M868.173913 0c49.196522 0 89.043478 42.963478 89.043478 96.01113v831.97774C957.217391 981.036522 917.370435 1024 868.173913 1024H155.826087c-49.196522 0-89.043478-42.963478-89.043478-96.01113V96.01113C66.782609 42.963478 106.629565 0 155.826087 0h712.347826z m-213.704348 720.005565H274.543304c-24.576 0-44.521739 21.481739-44.521739 47.994435 0 26.490435 19.945739 47.994435 44.521739 47.994435H654.469565c24.598261 0 44.521739-21.481739 44.521739-47.994435 0-26.490435-19.923478-47.994435-44.521739-47.994435z m0-160.01113H274.543304c-24.576 0-44.521739 21.504-44.521739 48.016695 0 26.490435 19.945739 47.994435 44.521739 47.994435H654.469565c24.598261 0 44.521739-21.504 44.521739-48.016695 0-26.490435-19.923478-47.994435-44.521739-47.994435zM414.052174 121.58887h-130.604522c-21.25913 0-38.578087 18.67687-38.578087 41.627826v185.032347c0 12.911304 5.565217 25.154783 15.048348 33.057392a27.959652 27.959652 0 0 0 32.122435 2.82713v-0.022261l56.720695-32.834782 56.698435 32.834782a27.937391 27.937391 0 0 0 32.122435-2.82713 42.963478 42.963478 0 0 0 15.048348-32.946087V163.172174c0-22.928696-17.296696-41.605565-38.578087-41.605565z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconWodelian.defaultProps = {
  size: 18,
};

export default IconWodelian;
