/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChuangjiangongzuorizhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M810.688 0C892.992 0 960 69.44 960 154.816v714.368C960 954.56 892.992 1024 810.656 1024H213.344C130.976 1024 64 954.56 64 869.184V154.816C64 69.44 130.976 0 213.344 0zM272 96A48 48 0 0 0 224 144v736a48 48 0 0 0 96 0v-736A48 48 0 0 0 272 96z m493.568 0H576v192l96-64.544 93.536 62.88V96z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconChuangjiangongzuorizhi.defaultProps = {
  size: 18,
};

export default IconChuangjiangongzuorizhi;
