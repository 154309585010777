/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentAdd24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M507.008 128.298667L512 128a42.666667 42.666667 0 0 1 42.368 37.674667L554.666667 170.666667v298.666666h298.666666a42.666667 42.666667 0 0 1 42.368 37.674667L896 512a42.666667 42.666667 0 0 1-37.674667 42.368L853.333333 554.666667h-298.666666v298.666666a42.666667 42.666667 0 0 1-37.674667 42.368L512 896a42.666667 42.666667 0 0 1-42.368-37.674667L469.333333 853.333333v-298.666666H170.666667a42.666667 42.666667 0 0 1-42.368-37.674667L128 512a42.666667 42.666667 0 0 1 37.674667-42.368L170.666667 469.333333h298.666666V170.666667a42.666667 42.666667 0 0 1 37.674667-42.368L512 128l-4.992 0.298667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentAdd24Filled;

