/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXingbieNv: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M554.424 713.857c177.825-21 315.792-172.154 315.792-355.63C870.216 160.378 709.84 0.012 512 0.012c-197.839 0-358.216 160.366-358.216 358.216 0 182.775 136.939 333.484 313.8 355.375v67.208H250.388v86.84h217.196v156.338h86.84V867.65H771.62v-86.84H554.424v-66.953zM240.625 358.228C240.625 208.59 362.363 86.853 512 86.853c149.639 0 271.376 121.737 271.376 271.375 0 149.639-121.737 271.376-271.376 271.376-149.638 0-271.375-121.738-271.375-271.376z"
        fill={getIconColor(color, 0, '#040000')}
      />
    </svg>
  );
};

IconXingbieNv.defaultProps = {
  size: 18,
};

export default IconXingbieNv;
