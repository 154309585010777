/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRenliziyuanfuwuyongzhangshenqingliuchengzhizaojiaofu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1072 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M298.666667 563.584c0-8.490667 3.413333-16.085333 8.490666-18.944 77.482667-43.52 161.536-43.52 239.018667 0 5.12 2.858667 8.490667 10.453333 8.490667 18.944v98.986667c0 11.093333-5.717333 20.096-12.8 20.096H311.466667c-7.082667 0-12.8-9.002667-12.8-20.096v-98.986667zM426.666667 469.333333c-41.770667 0-77.056-35.2-82.133334-81.92l-2.56-23.424a103.808 103.808 0 0 1 23.338667-78.72C381.568 266.410667 403.328 256 426.666667 256c23.338667 0 45.098667 10.410667 61.354666 29.269333 17.962667 20.992 26.453333 49.706667 23.338667 78.72l-2.56 23.424C503.722667 434.133333 468.437333 469.333333 426.666667 469.333333zM128 170.666667v640h341.333333v85.333333H85.333333a42.666667 42.666667 0 0 1-42.666666-42.666667V128a42.666667 42.666667 0 0 1 42.666666-42.666667h682.666667a42.666667 42.666667 0 0 1 42.666667 42.666667v320h-85.333334V170.666667H128z"
        fill={getIconColor(color, 0, '#1768E4')}
      />
      <path
        d="M896 896h-256v-9.856a11.52 11.52 0 0 1 11.52-11.52c52.48 0 95.146667-42.666667 95.146667-95.146667v-10.368a97.706667 97.706667 0 0 0-26.154667-65.408 62.336 62.336 0 0 1-16.512-42.410666 64.256 64.256 0 0 1 78.165333-62.549334 64.085333 64.085333 0 0 1 48.682667 50.304 62.805333 62.805333 0 0 1-15.274667 54.528 98.304 98.304 0 0 0-26.24 65.578667v10.325333c0 52.48 42.666667 95.146667 95.146667 95.146667a11.52 11.52 0 0 1 11.52 11.52v9.813333z m-11.52-106.666667a9.813333 9.813333 0 0 1-9.813333-9.856v-10.325333c0-2.346667 1.578667-5.461333 4.266666-8.405333a148.352 148.352 0 0 0 35.84-127.104 149.290667 149.290667 0 0 0-115.285333-118.4 150.016 150.016 0 0 0-125.696 30.165333 148.821333 148.821333 0 0 0-16.469333 215.637333c2.56 2.816 4.010667 5.717333 4.010666 8.106667v10.325333a9.813333 9.813333 0 0 1-9.813333 9.813334A96.938667 96.938667 0 0 0 554.666667 886.186667v52.48a42.666667 42.666667 0 0 0 42.666666 42.666666h341.333334a42.666667 42.666667 0 0 0 42.666666-42.666666v-52.48a96.938667 96.938667 0 0 0-96.853333-96.853334z"
        fill={getIconColor(color, 1, '#8BB3F1')}
      />
    </svg>
  );
};

IconRenliziyuanfuwuyongzhangshenqingliuchengzhizaojiaofu.defaultProps = {
  size: 18,
};

export default IconRenliziyuanfuwuyongzhangshenqingliuchengzhizaojiaofu;
