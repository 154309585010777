/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessStampFilesCaseFile: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M891.937361 0C919.625767 0 942.071399 23.408172 942.071399 52.284394v919.369773a53.451731 53.451731 0 0 1-14.663387 36.98614c-9.420612 9.830203-22.158917 15.339213-35.470651 15.339213H132.062639C104.374233 1023.97952 81.928601 1000.571349 81.928601 971.695126V52.284394C81.928601 23.408172 104.374233 0 132.062639 0h759.874722z m-102.295554 823.381932H323.01434a45.116538 45.116538 0 0 0-45.177977 44.952701 45.116538 45.116538 0 0 0 45.177977 44.973181h466.627467A45.116538 45.116538 0 0 0 834.758345 868.334633a45.116538 45.116538 0 0 0-45.116538-44.952701z m0-197.894282h-328.083038a45.116538 45.116538 0 0 0-45.096058 44.973181 45.116538 45.116538 0 0 0 45.116537 44.952701h328.062559a45.116538 45.116538 0 0 0 45.116538-44.952701 45.116538 45.116538 0 0 0-45.116538-44.973181z m-0.061439-201.846843H632.0104a45.116538 45.116538 0 0 0-45.116538 44.952701 45.116538 45.116538 0 0 0 45.116538 44.973181h157.569968a45.116538 45.116538 0 0 0 45.116538-44.973181 45.116538 45.116538 0 0 0-45.116538-44.952701z m-350.97922-61.848363H216.704786l-1.761245 0.163837c-5.734285 0.98302-10.157877 6.655867-10.157877 13.51653 0 7.536489 5.324694 13.639407 11.919122 13.639407h221.896362l1.781724-0.143357c5.734285-0.98302 10.157877-6.655867 10.157877-13.51653 0-7.536489-5.324694-13.639407-11.939601-13.639407zM329.117258 61.438771c-39.566569 0-71.760485 31.333773-71.760485 69.835404 0 24.718866 13.51653 47.51265 35.061059 60.005199l-7.00402 54.987701H226.924102l-2.416592 0.143357c-11.058979 1.167337-19.701366 10.342193-19.701366 21.442131v45.075578l0.122877 2.355153c1.208296 10.792744 10.608428 19.209856 21.995081 19.209856h201.519169l2.416592-0.122877c11.058979-1.167337 19.701366-10.342193 19.701366-21.442132v-45.075578l-0.122878-2.355153a21.954121 21.954121 0 0 0-21.99508-19.230335h-55.745445l-6.963061-54.926262 3.706806-2.293714a69.48725 69.48725 0 0 0 31.456651-57.793404C400.877742 92.772545 368.683826 61.438771 329.117258 61.438771z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessStampFilesCaseFile.defaultProps = {
  size: 18,
};

export default IconABusinessStampFilesCaseFile;
