/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDocumentLandscapeData24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M426.666667 384a85.333333 85.333333 0 1 1 170.666666 0v256a85.333333 85.333333 0 1 1-170.666666 0V384z m85.333333-21.333333a21.333333 21.333333 0 0 0-21.333333 21.333333v256a21.333333 21.333333 0 0 0 42.666666 0V384a21.333333 21.333333 0 0 0-21.333333-21.333333zM213.333333 597.333333a85.333333 85.333333 0 1 1 170.666667 0v42.666667a85.333333 85.333333 0 1 1-170.666667 0v-42.666667z m106.666667 0a21.333333 21.333333 0 0 0-42.666667 0v42.666667a21.333333 21.333333 0 0 0 42.666667 0v-42.666667z m405.333333-170.666666a85.333333 85.333333 0 0 0-85.333333 85.333333v128a85.333333 85.333333 0 1 0 170.666667 0v-128a85.333333 85.333333 0 0 0-85.333334-85.333333z m21.333334 85.333333v128a21.333333 21.333333 0 0 1-42.666667 0v-128a21.333333 21.333333 0 0 1 42.666667 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M85.333333 288A117.333333 117.333333 0 0 1 202.666667 170.666667h618.666666A117.333333 117.333333 0 0 1 938.666667 288v448A117.333333 117.333333 0 0 1 821.333333 853.333333H202.666667A117.333333 117.333333 0 0 1 85.333333 736V288zM202.666667 234.666667c-29.44 0-53.333333 23.893333-53.333334 53.333333v448c0 29.44 23.893333 53.333333 53.333334 53.333333h618.666666c29.44 0 53.333333-23.893333 53.333334-53.333333V288c0-29.44-23.893333-53.333333-53.333334-53.333333H202.666667z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
 );
};

export default IcFluentDocumentLandscapeData24Regular;

