/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconHe33Caozuorizhi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M819.2 962.56H204.8c-73.216 0-133.12-59.904-133.12-133.12V215.04c0-73.216 59.904-133.12 133.12-133.12h614.4c73.216 0 133.12 59.904 133.12 133.12v614.4c0 73.216-59.904 133.12-133.12 133.12zM204.8 143.36c-39.424 0-71.68 32.256-71.68 71.68v614.4c0 39.424 32.256 71.68 71.68 71.68h614.4c39.424 0 71.68-32.256 71.68-71.68V215.04c0-39.424-32.256-71.68-71.68-71.68H204.8z"
        fill={getIconColor(color, 0, '#A32A1F')}
      />
      <path
        d="M656.384 591.872c-4.096 0-8.192-1.024-12.288-2.56-15.36-6.656-22.528-25.088-15.872-40.448 9.728-22.528 14.848-46.592 14.848-71.68 0-98.304-79.872-178.176-178.176-178.176-23.552 0-46.592 4.608-68.096 13.312-15.872 6.656-33.792-1.024-39.936-16.896-6.656-15.872 1.024-33.792 16.896-39.936 29.184-11.776 59.904-17.92 91.648-17.92 132.096 0 239.616 107.52 239.616 239.616 0 33.792-6.656 66.048-19.968 96.256-5.632 11.264-16.896 18.432-28.672 18.432zM464.896 716.8C332.8 716.8 225.28 609.28 225.28 476.672c0-37.376 8.192-72.704 24.576-105.984 7.68-15.36 26.112-21.504 40.96-13.824 15.36 7.68 21.504 26.112 13.824 40.96-12.288 24.576-18.432 51.2-18.432 78.848 0 98.304 79.872 178.176 178.176 178.176 26.112 0 51.712-5.632 75.264-16.384 15.36-7.168 33.792-0.512 40.96 14.848 7.168 15.36 0.512 33.792-14.848 40.96-31.232 14.848-65.536 22.528-100.864 22.528z"
        fill={getIconColor(color, 1, '#E09527')}
      />
      <path
        d="M411.136 548.864c-8.192 0-15.872-3.072-21.504-9.216L256 406.016c-11.776-11.776-11.776-31.232 0-43.52 11.776-11.776 31.232-11.776 43.52 0l112.128 112.128 60.928-60.928L363.52 305.152c-11.776-11.776-11.776-31.232 0-43.52 11.776-11.776 31.232-11.776 43.52 0L537.6 392.192c5.632 5.632 9.216 13.824 9.216 21.504 0 8.192-3.072 15.872-9.216 21.504l-104.448 104.448c-5.632 6.144-13.824 9.216-22.016 9.216zM694.272 807.424c-26.624 0-53.76-10.24-73.728-30.72l-88.576-88.576c-11.776-11.776-11.776-31.232 0-43.52 11.776-11.776 31.232-11.776 43.52 0l88.576 88.576c16.896 16.896 44.032 16.896 60.928 0 8.192-8.192 12.8-18.944 12.8-30.208s-4.608-22.528-12.8-30.208L634.88 582.656c-11.776-11.776-11.776-31.232 0-43.52 11.776-11.776 31.232-11.776 43.52 0l89.6 89.6c19.968 19.968 30.72 46.08 30.72 73.728s-10.752 54.272-30.72 73.728c-20.48 20.992-47.104 31.232-73.728 31.232z"
        fill={getIconColor(color, 2, '#E09527')}
      />
    </svg>
  )
}

IconHe33Caozuorizhi.defaultProps = {
  size: 18,
}

export default IconHe33Caozuorizhi
