/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const ShujuzidianXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M779.648 42.666667c30.848 0 60.458667 12.373333 82.261333 34.346666 21.845333 22.016 34.090667 51.882667 34.090667 82.986667v668.8a35.072 35.072 0 0 1-34.901333 35.2H197.802667c0 25.941333 20.864 46.933333 46.549333 46.933333h616.746667c19.285333 0 34.901333 15.786667 34.901333 35.2a35.072 35.072 0 0 1-34.901333 35.2H244.352C180.096 981.333333 128 928.810667 128 864V160C128 95.189333 180.096 42.666667 244.352 42.666667z m0 70.4H244.352c-25.685333 0-46.506667 20.992-46.506667 46.933333V793.6h628.352V160c0-25.941333-20.864-46.933333-46.549333-46.933333zM490.24 277.12l4.522667 0.512c18.176 3.157333 30.72 20.053333 28.757333 38.528l-0.853333 4.693333-6.314667 25.941334h68.309333l9.642667-42.154667a34.944 34.944 0 0 1 37.12-27.221333l4.693333 0.768c16.981333 3.925333 28.544 19.925333 26.965334 37.461333l-0.725334 4.693333-6.058666 26.453334h17.493333a34.986667 34.986667 0 0 1 34.56 30.464l0.341333 4.736a35.114667 35.114667 0 0 1-30.165333 34.858666l-4.693333 0.341334h-33.536l-16.896 74.496h27.733333a34.986667 34.986667 0 0 1 34.602667 30.421333l0.341333 4.778667a35.114667 35.114667 0 0 1-30.208 34.858666l-4.736 0.341334-43.776-0.042667-9.002667 40.021333a34.944 34.944 0 0 1-37.205333 27.221334l-4.650667-0.768a35.157333 35.157333 0 0 1-27.008-37.504l0.768-4.693334 5.461334-24.32H464.213333l-9.813333 40.576a34.944 34.944 0 0 1-37.546667 26.666667l-4.650666-0.853333a35.2 35.2 0 0 1-26.453334-37.888l0.853334-4.693334 5.717333-23.722666h-19.968a34.986667 34.986667 0 0 1-34.56-30.421334l-0.341333-4.778666c0-17.621333 12.885333-32.512 30.165333-34.901334l4.736-0.298666 37.077333-0.042667 18.048-74.453333H394.666667a34.986667 34.986667 0 0 1-34.56-30.421334l-0.341334-4.778666c0-17.578667 12.885333-32.469333 30.165334-34.858667l4.693333-0.341333h49.92l10.368-42.709334a34.858667 34.858667 0 0 1 39.850667-26.453333z m78.421333 140.074667h-69.376l-18.048 74.453333h70.570667l16.853333-74.453333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default ShujuzidianXianxing;

