/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveBusinessFileAuditCall: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M842.325553 0C874.683306 0 901.101978 27.647447 901.101978 61.438771v468.962141A290.810184 290.810184 0 0 0 799.359373 511.98976c-36.822304 0-72.886862 6.922102-108.173197 20.745825-35.286334 13.823724-66.968261 34.774345-95.025299 62.872343-28.057039 28.077518-49.089578 59.677526-63.118098 94.800024A288.577908 288.577908 0 0 0 511.98976 798.376352c0 36.863263 7.0245 72.866383 21.053019 107.968401 14.028519 35.122498 35.061059 66.722506 63.118098 94.820504 8.212316 8.191836 16.752305 15.830723 25.599488 22.855223L58.776424 1023.97952C26.418672 1023.97952 0 996.332073 0 962.540749V61.438771c0-33.791324 26.439151-61.438771 58.776424-61.438771zM404.471911 557.044859a41.102538 41.102538 0 0 0-57.854843 0l-130.557389 130.557389-43.51913-43.51913a41.102538 41.102538 0 0 0-57.854843 0 41.102538 41.102538 0 0 0 0 57.854843L186.876262 774.128517c15.871683 15.871683 41.98316 15.871683 57.854843 0l159.228816-159.228815a41.573569 41.573569 0 0 0 0.51199-57.854843z m-0.51199-307.193856a41.102538 41.102538 0 0 0-57.854843 0l-130.557389 130.557389L172.028559 336.889262a41.102538 41.102538 0 0 0-57.854842 0 41.102538 41.102538 0 0 0 0 57.854843l72.702545 72.190556c15.871683 15.871683 41.98316 15.871683 57.854843 0l159.228816-159.228815a41.102538 41.102538 0 0 0 0-57.854843zM798.704026 346.105078H552.948941c-22.527549 0-40.959181 18.431631-40.959181 40.959181s18.431631 40.959181 40.959181 40.959181h245.755085c22.527549 0 40.959181-18.431631 40.959181-40.959181s-18.431631-40.959181-40.959181-40.959181z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M799.216016 573.428531c28.937661 0 57.179016 5.427091 84.744545 16.301754 27.545049 10.854183 52.345833 27.319774 74.402352 49.396772 22.036039 22.056519 38.50163 46.898262 49.355813 74.484271 10.854183 27.586008 16.260795 55.868323 16.260794 84.826463 0 28.97862-5.427091 57.260935-16.281274 84.846943s-27.299294 52.427751-49.355813 74.484271c-22.036039 22.076998-46.836823 38.624508-74.381872 49.663006a226.033239 226.033239 0 0 1-84.744545 16.547509c-28.937661 0-57.260935-5.529489-84.990301-16.547509a219.541209 219.541209 0 0 1-74.668586-49.663006c-22.036039-22.076998-38.563069-46.898262-49.601568-74.484271A226.750025 226.750025 0 0 1 573.428531 798.437791c0-28.958141 5.529489-57.240455 16.52703-84.826463 11.038499-27.586008 27.565529-52.427751 49.601568-74.484271 22.056519-22.076998 46.939221-38.542589 74.668586-49.396772a231.173617 231.173617 0 0 1 84.990301-16.301754z m91.953361 328.04208h-184.930702a10.096438 10.096438 0 0 0-9.932601 10.239795c0 5.672847 4.444071 10.280754 9.932601 10.280754h184.930702a10.096438 10.096438 0 0 0 9.932601-10.260275c0-5.672847-4.444071-10.239795-9.932601-10.239795z m-91.257055-225.52125c-32.972141 0-59.800404 23.51057-59.800404 52.427751 0 18.554509 11.263775 35.675446 29.203896 45.055099l-5.816204 41.307334H714.737705c-10.157877 0-18.431631 7.249775-18.431631 16.178877v33.852763c0 8.929101 8.273755 16.199356 18.431631 16.199356h167.932642c10.157877 0 18.431631-7.270255 18.431631-16.178877v-33.873242c0-8.929101-8.273755-16.178876-18.431631-16.178877h-46.447711l-5.816204-41.245895c18.00156-9.379652 29.326773-26.50059 29.326773-45.116538 0-28.917182-26.828263-52.427751-59.800404-52.427751z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveBusinessFileAuditCall.defaultProps = {
  size: 18,
};

export default IconAExecutiveBusinessFileAuditCall;
