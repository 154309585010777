/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconKehuguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M556 960H63.75l0.26-32.26c0.46-56.5 12.75-111.25 36.52-162.74 22.89-49.58 55.41-94.04 96.66-132.14 41.1-37.96 88.85-67.75 141.92-88.52C393.92 522.88 452.09 512 512 512c32.41 0 64.76 3.26 96.16 9.68 17.31 3.54 28.48 20.45 24.94 37.76-3.54 17.31-20.45 28.48-37.76 24.94-27.19-5.56-55.23-8.38-83.34-8.38-198.39 0-363 140.82-382.14 320H556c17.67 0 32 14.33 32 32s-14.33 32-32 32z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M512.01 895.54L407.57 772.11l104.45-196.22 40.43 75.96c8.3 15.6 2.39 34.98-13.21 43.28a31.861 31.861 0 0 1-19.98 3.37l-34.8 65.38 32.78 38.73c8.79-1.3 18.06 1.04 25.38 7.22 13.49 11.42 15.17 31.61 3.76 45.1l-34.37 40.61zM518 508c-59.3 0-115.05-23.09-156.98-65.02C319.09 401.05 296 345.3 296 286s23.09-115.05 65.02-156.98C402.95 87.09 458.7 64 518 64s115.05 23.09 156.98 65.02C716.91 170.95 740 226.7 740 286s-23.09 115.05-65.02 156.98C633.05 484.91 577.3 508 518 508z m0-380c-87.12 0-158 70.88-158 158s70.88 158 158 158 158-70.88 158-158-70.88-158-158-158zM755 842c-44.11 0-80-35.89-80-80s35.89-80 80-80 80 35.89 80 80-35.89 80-80 80z m0-96c-8.82 0-16 7.18-16 16s7.18 16 16 16 16-7.18 16-16-7.18-16-16-16z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M802.17 960a31.98 31.98 0 0 1-17.51-5.22 31.976 31.976 0 0 1-14.19-22.43c-1.05-7.67-7.7-13.45-15.46-13.45s-14.41 5.78-15.46 13.45a31.994 31.994 0 0 1-40.43 26.43c-32.34-9.17-62.26-26.46-86.52-50.01a31.99 31.99 0 0 1 2.64-48.22c6.1-4.74 7.78-13.39 3.9-20.1-3.88-6.72-12.2-9.59-19.37-6.67a32.015 32.015 0 0 1-43.12-21.84c-4.09-16.27-6.16-33.08-6.16-49.94s2.07-33.66 6.16-49.94a32.015 32.015 0 0 1 43.12-21.84c7.16 2.92 15.49 0.05 19.37-6.67 3.88-6.72 2.2-15.36-3.9-20.1a32.024 32.024 0 0 1-12.31-23.51 31.97 31.97 0 0 1 9.67-24.71c24.26-23.54 54.18-40.83 86.52-50.01a31.983 31.983 0 0 1 26.24 4 31.976 31.976 0 0 1 14.19 22.43c1.05 7.67 7.7 13.45 15.46 13.45s14.41-5.78 15.46-13.45a31.994 31.994 0 0 1 40.43-26.43c32.34 9.17 62.26 26.46 86.52 50.01a31.99 31.99 0 0 1-2.64 48.22c-6.1 4.74-7.77 13.39-3.9 20.11 3.88 6.72 12.21 9.59 19.37 6.67a32.015 32.015 0 0 1 43.12 21.84c4.09 16.27 6.16 33.08 6.16 49.94s-2.07 33.66-6.16 49.94a32.015 32.015 0 0 1-43.12 21.84c-7.16-2.92-15.49-0.05-19.37 6.67-3.88 6.72-2.2 15.36 3.9 20.1a32.024 32.024 0 0 1 12.31 23.51 31.97 31.97 0 0 1-9.67 24.71c-24.26 23.54-54.18 40.83-86.52 50.01-2.87 0.81-5.81 1.21-8.73 1.21zM755 854.9c25.85 0 49.28 12.6 63.92 32.26 4.29-2.2 8.46-4.61 12.51-7.24-9.69-22.51-8.89-49.1 4.02-71.47 12.92-22.38 35.55-36.37 59.89-39.23a144.76 144.76 0 0 0 0-14.46c-24.34-2.86-46.96-16.84-59.89-39.23-12.92-22.37-13.72-48.96-4.02-71.47-4.04-2.63-8.22-5.05-12.51-7.24-14.64 19.66-38.07 32.26-63.92 32.26s-49.28-12.6-63.92-32.26c-4.29 2.2-8.46 4.61-12.51 7.24 9.7 22.51 8.89 49.1-4.03 71.47-12.92 22.38-35.55 36.37-59.89 39.23a144.76 144.76 0 0 0 0 14.46c24.34 2.86 46.96 16.84 59.89 39.23 12.92 22.37 13.72 48.96 4.03 71.47 4.04 2.63 8.22 5.05 12.51 7.24 14.64-19.65 38.07-32.26 63.92-32.26z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconKehuguanli.defaultProps = {
  size: 18,
};

export default IconKehuguanli;
