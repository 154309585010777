/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZhongzhimima: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M636.172598 447.930011c37.402156 0 67.701422 33.050836 67.701422 73.844462l0 172.261084c0 40.761631-30.299266 73.812467-67.701422 73.812467l-248.217216 0c-37.370161 0-67.701422-33.050836-67.701422-73.812467l0-172.261084c0-40.761631 30.299266-73.844462 67.701422-73.844462L448.009998 447.930011l0 0 127.980003 0 0 0L636.172598 447.930011zM511.520075 512.239963c-35.322481 0-63.990002 27.387721-63.990002 61.206436 0 22.588471 17.53326 39.865771 36.602281 50.488111l0 79.539572 54.807436 0 0-79.539572c19.06902-10.62234 36.602281-27.899641 36.602281-50.488111C575.510077 539.627683 546.842556 512.239963 511.520075 512.239963zM512 320.109983c-60.150601 0-63.830027 50.584096-63.990002 63.990002l0 63.798032-64.405937 0 0-57.622996c0 0 0.031995-134.379003 128.139978-134.379003 128.139978 0 128.139978 134.379003 128.139978 134.379003l0 57.622996L575.990002 447.898016l0-63.798032C575.862022 370.918044 572.150601 320.109983 512 320.109983zM512 1024c-282.739822 0-511.920012-229.180191-511.920012-511.920012s229.180191-511.920012 511.920012-511.920012c91.121762 0 176.612404 23.964256 250.712826 65.717732l47.608561-65.877707L895.556069 224.06099l-223.453085-32.794876 52.759756-73.012592C661.512639 83.922887 589.107952 64.181972 512 64.181972c-247.385346 0-447.930011 200.544665-447.930011 447.930011s200.544665 447.930011 447.930011 447.930011 447.930011-200.544665 447.930011-447.930011c0-60.406561-12.126105-117.933573-33.786721-170.533354l59.158756-24.348196C1010.066177 377.317044 1023.920012 443.066771 1023.920012 512.111983 1023.920012 794.819809 794.739822 1024 512 1024z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconZhongzhimima.defaultProps = {
  size: 18,
};

export default IconZhongzhimima;
