/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Wubanmawentianchong: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M540.435692 398.217846h-85.307077v227.564308h85.307077z"
        fill={getIconColor(color, 0, '#3F51B5')}
        opacity=".504"
      />
      <path
        d="M0 184.871385A184.871385 184.871385 0 0 1 184.871385 0h654.25723A184.871385 184.871385 0 0 1 1024 184.871385v654.25723A184.871385 184.871385 0 0 1 839.128615 1024H184.871385A184.871385 184.871385 0 0 1 0 839.128615V184.871385z m184.871385-99.524923a99.564308 99.564308 0 0 0-99.524923 99.524923V256h369.782153V85.346462H184.871385zM938.692923 768H540.396308v170.653538h298.692923a99.564308 99.564308 0 0 0 99.524923-99.524923V768z m0-583.128615a99.564308 99.564308 0 0 0-99.564308-99.524923h-298.692923V256h398.217846V184.871385zM85.307077 768v71.128615a99.603692 99.603692 0 0 0 99.524923 99.524923h270.257231V768h-369.821539z m0-85.346462h853.307077V341.346462H85.346462v341.307076z"
        fill={getIconColor(color, 1, '#3F51B5')}
        opacity=".495"
      />
    </svg>
 );
};

export default Wubanmawentianchong;

