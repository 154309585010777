/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentPersonAccounts24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M432 597.12c-15.018667 18.176-24.234667 40.533333-26.325333 64h-224.426667a31.957333 31.957333 0 0 0-31.914667 32v24.618667c0 22.869333 8.149333 44.970667 22.997334 62.336 50.218667 58.837333 127.317333 90.453333 233.344 94.08 2.090667 24.32 11.648 46.506667 26.325333 64.341333h-5.504c-134.186667 0-235.989333-38.613333-302.848-116.864A160 160 0 0 1 85.333333 717.738667v-24.661334c0-50.773333 39.466667-92.330667 89.386667-95.701333l6.570667-0.256h250.709333z m197.162667-106.666667h128c27.648 0 50.346667 20.992 53.077333 47.914667l0.298667 5.418667-0.042667 53.333333h53.333333c41.258667 0 74.666667 33.450667 74.666667 74.666667v192a74.666667 74.666667 0 0 1-74.666667 74.666666h-341.333333a74.666667 74.666667 0 0 1-74.666667-74.666666v-192c0-41.216 33.450667-74.666667 74.666667-74.666667h53.333333v-53.333333c0-27.605333 20.992-50.346667 47.914667-53.034667l5.418667-0.298667h128-128z m234.666666 170.666667h-341.333333a10.666667 10.666667 0 0 0-10.666667 10.666667v192c0 5.888 4.778667 10.666667 10.666667 10.666666h341.333333a10.666667 10.666667 0 0 0 10.666667-10.666666v-192a10.666667 10.666667 0 0 0-10.666667-10.666667z m-117.333333-106.666667h-106.666667v42.666667h106.666667v-42.666667zM426.538667 85.333333a213.333333 213.333333 0 1 1 0 426.666667 213.333333 213.333333 0 0 1 0-426.666667z m0 64a149.333333 149.333333 0 1 0 0 298.666667 149.333333 149.333333 0 0 0 0-298.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentPersonAccounts24Regular;

