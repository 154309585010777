/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseCloseArchiveManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M983.04 590.49984a24.14592 24.14592 0 0 0 0-3.91168 29.83936 29.83936 0 0 0 0-4.13696L871.56736 99.84A78.49984 78.49984 0 0 0 795.40224 40.96H228.59776a78.49984 78.49984 0 0 0-76.34944 60.25216l-110.30528 480.4608a29.83936 29.83936 0 0 0 0 4.11648c-0.49152 1.536-0.8192 3.11296-0.98304 4.7104v314.04032A78.49984 78.49984 0 0 0 119.45984 983.04h785.08032A78.49984 78.49984 0 0 0 983.04 904.54016V590.49984z m-754.44224-471.04h566.62016l99.30752 431.80032h-166.62528a39.26016 39.26016 0 0 0-39.26016 39.23968v98.14016h-353.28v-98.14016a39.26016 39.26016 0 0 0-39.26016-39.23968H129.47456l99.1232-431.80032z m8.62208 314.04032c0-21.68832 17.57184-39.26016 39.26016-39.26016h471.04a39.26016 39.26016 0 0 1 0 78.49984h-471.04a39.26016 39.26016 0 0 1-39.26016-39.23968z m58.88-176.64a39.26016 39.26016 0 0 1 39.26016-39.26016h353.28a39.26016 39.26016 0 0 1 0 78.49984h-353.28a39.26016 39.26016 0 0 1-39.26016-39.23968z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseCloseArchiveManage.defaultProps = {
  size: 18,
};

export default IconABusinessCaseCloseArchiveManage;
