/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Send24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M546.773333 520.405333l-321.365333 53.589334a21.333333 21.333333 0 0 0-16.469333 13.568L98.133333 884.394667c-10.581333 27.306667 17.962667 53.333333 44.16 40.234666l768-384a32 32 0 0 0 0-57.258666l-768-384c-26.197333-13.098667-54.741333 12.970667-44.16 40.234666l110.848 296.832a21.333333 21.333333 0 0 0 16.469334 13.610667l321.365333 53.546667a8.533333 8.533333 0 0 1 0 16.810666z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default Send24Filled;

