/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Kuaisuchuangjian: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M784.853333 521.642667a221.312 221.312 0 0 0-222.293333 0 223.701333 223.701333 0 0 0-111.189333 193.536c0 123.434667 99.541333 223.488 222.293333 223.488C796.458667 938.666667 896 838.613333 896 715.178667c0-79.829333-42.368-153.6-111.146667-193.536zM451.370667 85.333333v243.797334A81.066667 81.066667 0 0 1 370.517333 410.453333H128v406.357334a81.066667 81.066667 0 0 0 80.853333 81.28h275.242667a265.173333 265.173333 0 0 1-27.306667-332.202667 261.973333 261.973333 0 0 1 317.952-94.549333V166.613333A81.066667 81.066667 0 0 0 693.888 85.333333H451.413333z m222.293333 487.637334c11.178667 0 20.224 9.088 20.224 20.309333v101.546667h101.205333c11.178667 0 20.224 9.130667 20.224 20.352a20.266667 20.266667 0 0 1-20.224 20.309333h-101.248v101.717333a20.266667 20.266667 0 0 1-20.181333 20.309334 20.266667 20.266667 0 0 1-20.224-20.309334v-101.717333H552.533333a20.266667 20.266667 0 0 1-20.181333-20.309333c0-11.221333 9.045333-20.309333 20.181333-20.309334h100.949334v-101.589333c0-11.221333 9.045333-20.309333 20.181333-20.309333zM390.741333 105.642667L148.224 349.44h222.293333a20.266667 20.266667 0 0 0 20.224-20.309333V105.642667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Kuaisuchuangjian;

