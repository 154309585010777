/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentFolderZip24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M404.906667 187.818667A96 96 0 0 0 350.208 170.666667H181.333333l-6.570666 0.213333A96 96 0 0 0 85.333333 266.666667v490.666666l0.213334 6.570667A96 96 0 0 0 181.333333 853.333333h661.333334l6.570666-0.213333A96 96 0 0 0 938.666667 757.333333v-384l-0.213334-6.570666-0.725333-6.741334A96 96 0 0 0 842.666667 277.333333h-329.728L411.605333 192.896l-6.656-5.077333zM575.914667 341.333333v95.786667c0 17.664 14.293333 32 32 32h32v42.794667h-10.666667a32 32 0 0 0 0 64h10.666667v64h-10.666667a32 32 0 0 0 0 64h10.666667V789.333333H181.333333l-4.352-0.298666a32 32 0 0 1-27.648-31.701334v-309.376l200.832 0.042667 8.362667-0.384a96 96 0 0 0 53.077333-21.845333L512.896 341.333333h62.976z m128 426.666667h10.666666a32 32 0 0 0 0-64h-10.666666V640h10.666666a32 32 0 0 0 0-64h-10.666666v-106.88h32a32 32 0 0 0 32-32V341.333333h74.794666l4.352 0.298667a32 32 0 0 1 27.648 31.701333v384l-0.298666 4.352a32 32 0 0 1-31.701334 27.648h-138.794666V768z m0-426.666667v63.786667h-64V341.333333h64zM181.333333 234.666667h168.832l4.437334 0.298666a32 32 0 0 1 16.042666 7.125334l80.682667 67.2-80.64 67.285333-3.669333 2.602667A32 32 0 0 1 350.165333 384L149.333333 383.957333V266.666667l0.298667-4.352A32 32 0 0 1 181.333333 234.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentFolderZip24Regular;

