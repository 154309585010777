/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHangzhengbiaozhunyongzhangshenpiliucheng: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M853.333333 896H341.333333V298.666667h512v597.333333zM256 768H170.666667V128h512v85.333333H298.666667a42.666667 42.666667 0 0 0-42.666667 42.666667v512zM768 213.333333V85.333333a42.666667 42.666667 0 0 0-42.666667-42.666666H128a42.666667 42.666667 0 0 0-42.666667 42.666666v725.333334a42.666667 42.666667 0 0 0 42.666667 42.666666h128v85.333334a42.666667 42.666667 0 0 0 42.666667 42.666666h597.333333a42.666667 42.666667 0 0 0 42.666667-42.666666V256a42.666667 42.666667 0 0 0-42.666667-42.666667h-128z"
        fill={getIconColor(color, 0, '#1768E4')}
      />
      <path
        d="M519.765333 740.864a42.496 42.496 0 0 1-30.165333-12.501333L384 622.762667l60.330667-60.330667 75.434666 75.434667 211.2-211.2 60.330667 60.373333-241.365333 241.322667a42.496 42.496 0 0 1-30.165334 12.501333"
        fill={getIconColor(color, 1, '#8BB3F1')}
      />
    </svg>
  );
};

IconHangzhengbiaozhunyongzhangshenpiliucheng.defaultProps = {
  size: 18,
};

export default IconHangzhengbiaozhunyongzhangshenpiliucheng;
