/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Qichacha01: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 170.666667a341.333333 341.333333 0 0 1 305.92 493.226666l-4.266667 7.68-54.613333-29.013333a281.173333 281.173333 0 1 0-73.813333 88.32l15.36-15.36a8.533333 8.533333 0 0 1 11.946666 0l85.333334 85.333333a9.386667 9.386667 0 0 1 0 12.373334l-36.693334 36.693333a8.96 8.96 0 0 1-12.373333 0l-51.2-50.773333A341.333333 341.333333 0 1 1 512 170.666667z m0 139.093333a199.68 199.68 0 0 1 138.666667 55.04l4.266666 3.84-42.666666 42.666667a142.506667 142.506667 0 1 0-5.12 206.08l3.84-3.413334 42.666666 42.666667A202.24 202.24 0 1 1 512 309.76z m0 130.986667a72.533333 72.533333 0 0 1 45.226667 16.213333l2.986666 2.56-26.453333 28.586667a32.426667 32.426667 0 1 0 0 49.066666v-2.133333l26.88 27.733333a70.826667 70.826667 0 0 1-98.986667-101.546666 69.546667 69.546667 0 0 1 50.346667-20.48z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Qichacha01;

