/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const ATianjiaxiaji1X: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M608 704h256V768h-256v-64z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M768 608v256h-64v-256H768zM864 448H160A96 96 0 0 1 64 352v-192A96 96 0 0 1 160 64h704a96 96 0 0 1 96 96v192a96 96 0 0 1-96 96zM160 128a32 32 0 0 0-32 32v192c0 17.664 14.336 32 32 32h704a32 32 0 0 0 32-32v-192a32 32 0 0 0-32-32H160z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M864 960h-256A96 96 0 0 1 512 864v-256A96 96 0 0 1 608 512h256a96 96 0 0 1 96 96v256a96 96 0 0 1-96 96z m-256-384a32 32 0 0 0-32 32v256c0 17.664 14.336 32 32 32h256a32 32 0 0 0 32-32v-256a32 32 0 0 0-32-32h-256z"
        fill={getIconColor(color, 2, '#333333')}
      />
      <path
        d="M576 800H416a192 192 0 0 1-192-192V448h64v160a128 128 0 0 0 128 128h160v64z"
        fill={getIconColor(color, 3, '#333333')}
      />
    </svg>
 );
};

export default ATianjiaxiaji1X;

