/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Fukuanfangguanli: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M755.756522 487.513043C903.902609 487.513043 1024 607.610435 1024 755.756522 1024 903.902609 903.902609 1024 755.756522 1024c-148.146087 0-268.243478-120.097391-268.243479-268.243478 0-148.146087 120.097391-268.243478 268.243479-268.243479z m-267.174957 97.547131a315.547826 315.547826 0 0 0-49.842087 170.696348c0 80.183652 29.762783 153.377391 78.870261 209.229913-39.112348 6.878609-81.697391 10.24-127.643826 10.24-140.933565 0-250.10087-31.98887-326.322087-97.547131A182.873043 182.873043 0 0 1 0 739.06087V694.761739a109.746087 109.746087 0 0 1 109.746087-109.723826h378.835478z m401.65287 234.918956C858.757565 842.573913 810.674087 857.043478 756.869565 857.043478s-101.888-14.469565-133.364869-37.064348c-0.133565 1.224348-0.200348 2.448696-0.200348 3.673044 0 36.886261 59.792696 66.782609 133.565217 66.782609s133.565217-29.896348 133.565218-66.782609c0-1.224348-0.066783-2.448696-0.200348-3.673044z m0-66.782608C858.757565 775.791304 810.674087 790.26087 756.869565 790.26087s-101.888-14.469565-133.364869-37.064348A34.726957 34.726957 0 0 0 623.304348 756.869565c0 36.886261 59.792696 66.782609 133.565217 66.782609s133.565217-29.896348 133.565218-66.782609c0-1.246609-0.066783-2.448696-0.200348-3.673043zM756.869565 623.304348c-33.057391 0-64.155826 5.565217-87.885913 15.493565C647.034435 647.969391 623.304348 664.709565 623.304348 690.086957s23.730087 42.073043 45.679304 51.289043c23.730087 9.906087 54.828522 15.493565 87.885913 15.493565 33.057391 0 64.155826-5.565217 87.885913-15.493565C866.704696 732.182261 890.434783 715.464348 890.434783 690.086957s-23.707826-42.073043-45.679305-51.289044C820.98087 628.869565 789.926957 623.304348 756.869565 623.304348zM389.965913 0c134.678261 0 243.867826 109.167304 243.867826 243.845565 0 134.678261-109.189565 243.867826-243.867826 243.867826S146.120348 378.523826 146.120348 243.845565 255.287652 0 389.965913 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Fukuanfangguanli;

