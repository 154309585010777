/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodelvli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M838.386401 0c41.854256 0 75.900837 32.8093 75.900837 73.12762v365.723428c0 20.223157-16.980626 36.56381-37.971751 36.56381-20.94846 0-37.929086-16.340652-37.929086-36.56381V109.648765c0-20.223157-16.937961-36.56381-37.929087-36.56381H156.47348c-20.991125 0-37.929086 16.340652-37.929086 36.56381V914.30857c0 20.223157 16.937961 36.56381 37.971751 36.56381h492.694138c20.991125 0 37.971751 16.340652 37.971751 36.56381s-16.980626 36.56381-37.971751 36.56381H118.544394C76.732803 1023.957335 42.686221 991.148035 42.686221 950.829715V73.12762C42.686221 32.851965 76.604808 0 118.544394 0z m8.660972 546.110579L981.313779 887.60035 847.175368 1023.957335l-134.053081-136.356985 133.967751-341.489771z m0.127995 184.056331l-55.293696 140.879463 55.379025 56.317653 55.293697-56.232323-55.379026-140.964793z m-368.709971-86.524395c21.20445 0 38.31307 16.340652 38.313071 36.56381s-17.10862 36.56381-38.313071 36.56381H248.586976c-21.161785 0-38.270405-16.340652-38.270406-36.56381s17.065956-36.56381 38.270406-36.56381z m229.878422-219.425524c21.20445 0 38.31307 16.340652 38.31307 36.56381s-17.10862 36.56381-38.31307 36.56381H248.586976c-21.161785 0-38.270405-16.340652-38.270406-36.56381s17.065956-36.56381 38.270406-36.56381z m0-219.425524c21.20445 0 38.31307 16.340652 38.31307 36.56381s-17.10862 36.56381-38.31307 36.56381H248.586976c-21.161785 0-38.270405-16.340652-38.270406-36.56381s17.065956-36.56381 38.270406-36.56381z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconWodelvli.defaultProps = {
  size: 18,
};

export default IconWodelvli;
