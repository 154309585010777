/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Gonggonganjian: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M605.866667 0v271.061333c0 49.92 42.026667 90.368 93.866666 90.368H981.333333v512c0 49.92-42.026667 90.325333-93.866666 90.325334h-367.189334c15.616-28.501333 23.04-59.52 23.04-90.325334 0-77.909333-61.696-142.933333-142.250666-150.016a175.445333 175.445333 0 0 0 25.258666-208.085333C387.84 447.914667 309.248 411.306667 230.4 424.106667V90.368C230.4 40.448 272.426667 0 324.266667 0h281.6z m62.592 15.061333v256c0 16.64 13.994667 30.122667 31.274666 30.122667h265.941334L668.458667 15.061333z m-281.6 587.306667c0 66.517333-56.064 120.448-125.184 120.448S136.533333 668.885333 136.533333 602.368c0-66.56 56.021333-120.490667 125.141334-120.490667 69.12 0 125.184 53.930667 125.184 120.490667m93.866666 271.061333C480.725333 948.394667 418.133333 1024 261.674667 1024 105.258667 1024 42.666667 948.693333 42.666667 873.386667c0-49.877333 42.026667-90.325333 93.866666-90.325334h250.325334c51.84 0 93.866667 40.448 93.866666 90.368"
        fill={getIconColor(color, 0, '#636df1')}
      />
    </svg>
 );
};

export default Gonggonganjian;

