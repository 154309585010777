/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconWodekehu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M729.01365 928.734H62.71365l-11.2-6.8c-36.3-22.4-72.6-71.2-36.8-174.1v-5.1c53.2-102.9 205.9-182.8 246.4-203.8-56.9-52.8-77.8-157.1-77.8-234.1 0-139.9 92.7-253.5 206.2-253.5h13.1c113.6 0 205.8 114.1 205.8 253.9 0 76.8-20.5 180.8-77.3 234.5 41.4 20.5 202.5 105.3 244.4 204.8 41.9 99.5 0 154.5-35.8 177.5l-10.7 6.7z m-635.6-97.6h605.3c1.3-16.6-1.5-33.4-7.9-48.7-21.4-48.7-127.1-117.8-195.1-151.6h-7l-14.4-12.2c-36.3-33.7-45.1-87.7-21.4-131.1l5.1-9.7 7.9-7.8c37.1-46.7 55.2-105.8 50.7-165.3-1.4-86.3-50.7-155.9-114.1-155.9H389.01365c-62.9 0-112.6 69.7-112.6 156 0 73.1 21.9 146.2 50.7 165.3l8.9 6.3 5.1 9.7c23.6 43.7 14.6 97.9-21.9 131.6l-13.1 11.7h-8.4c-50.7 25.6-162.5 90.7-196.5 153.1-6.6 15.3-9.3 32.1-7.9 48.7l0.1-0.1zM825.81365 184.434h140.1c32.1 0 58.1 26 58.1 58.1v14.5c0 32.1-26 58.1-58.1 58.1H825.81365c-32.1 0-58.1-26-58.1-58.1v-14.5c0-32.1 26-58.1 58.1-58.1z m-65.1 195.8h205.2c32.1 0 58.1 26 58.1 58.1v14.5c0 32.1-26 58.1-58.1 58.1H760.71365c-32.1 0-58.1-26-58.1-58.1v-14.5c0-32.1 26-58.1 58.1-58.1z m129 196.4h76.2c32.1 0 58.1 26 58.1 58.1v14.5c0 32.1-26 58.1-58.1 58.1h-76.1c-32.1 0-58.1-26-58.1-58.1v-14.5c0-32.1 26-58.1 58-58.1z"
        fill={getIconColor(color, 0, '#666666')}
      />
    </svg>
  );
};

IconWodekehu.defaultProps = {
  size: 18,
};

export default IconWodekehu;
