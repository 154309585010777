/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCaigouguanliCaigouzhihang: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M735.6 98.7c71.6 0 121.2 55.1 121.2 115.7v176.3L443.7 815v110.2H232.4c-66.7-0.6-120.6-54.6-121.2-121.3V220c0.6-66.7 54.5-120.7 121.2-121.3h503.2z m18.7 484.9l88.2 88.2L589 925.2h-88.2V837l253.5-253.4z m82.6-71.7c5.5 0 11 0 16.5 5.5l55.1 49.6c5.5 5.5 5.5 11.1 5.5 16.5v3.3c0.1 5-1.9 9.8-5.5 13.2L870 644.2 781.9 556l38.5-38.5c5.5-5.6 11-5.6 16.5-5.6z m-476 5.9H237.8c-16.4 0-29.7 13.3-29.7 29.7s13.3 29.7 29.7 29.7h123.1c16.4 0 29.7-13.3 29.7-29.7s-13.3-29.7-29.7-29.7zM497.8 381h-260c-10.9-0.4-21.1 5.1-26.7 14.5-5.6 9.4-5.6 21 0 30.3 5.6 9.4 15.8 14.9 26.7 14.5h260c10.9 0.4 21.1-5.1 26.7-14.5 5.6-9.4 5.6-21 0-30.3-5.6-9.4-15.9-15-26.7-14.5z m91.3-136.9H237.8c-10.6 0-20.4 5.7-25.7 14.8-5.3 9.2-5.3 20.5 0 29.7s15.1 14.8 25.7 14.8H589c16.4 0 29.7-13.3 29.7-29.7 0-16.3-13.3-29.6-29.6-29.6z m0 0"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconCaigouguanliCaigouzhihang.defaultProps = {
  size: 18,
};

export default IconCaigouguanliCaigouzhihang;
