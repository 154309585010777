/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFapiaoguanli1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1251 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M880 498.133h9.6c19.523-2.868 34.343-19.503 34.343-39.598 0-18.274-12.254-33.686-28.993-38.467l-7.482-1.402h-60l77.867-86.666 1.6-1.867c3.532-4.803 5.983-10.612 6.879-16.921l-1.812 5.187c1.357-3.752 2.14-8.083 2.14-12.596 0-0.259-0.003-0.518-0.007-0.775l0.001-1.295c0.007-0.281 0.011-0.613 0.011-0.946 0-4.534-0.786-8.885-2.228-12.922l0.083-1.332c-3.352-6.499-8.288-11.77-14.31-15.431s6.843 3.698 5.243 3.165c-7.407-6.143-17.010-9.869-27.483-9.869-12.523 0-23.801 5.329-31.691 13.841l-106.692 118.162-106.667-118.133c-7.833-8.582-19.066-13.945-31.552-13.945-10.453 0-20.028 3.758-27.446 9.998l-1.802 1.547c-7.517 7.071-12.198 17.082-12.198 28.187 0 8.55 2.775 16.452 7.474 22.854 2.073 4.393 4.937 8.213 8.411 11.371l-5.020-6.145 77.066 83.734h-60.8c-20.087 2.904-35.348 20.003-35.348 40.667s15.262 37.763 35.128 40.641l108.755 0.026v73.333h-108.533c-19.565 1.807-34.979 17.373-36.539 36.847l0.006 6.353c1.662 19.596 17.137 35.103 36.569 36.805l109.565-0.006v67.466c0.568 5.809 2.255 11.114 4.844 15.856l-1.377-1.189c3.46 6.77 8.503 12.297 14.66 16.237l-5.859-4.504c5.243 5.066 11.833 8.772 19.179 10.529l-7.179-3.329c4.649 2.167 10.075 3.501 15.795 3.665l5.005-0.198c5.781-0.608 11.047-2.328 15.745-4.942l-1.079 1.476c6.795-3.482 12.343-8.547 16.301-14.729l-4.568 5.929c5.050-5.217 8.744-11.778 10.492-19.093l-3.292 7.36c2.162-4.674 3.487-10.129 3.638-15.876l-0.172-63.858h109.6c16.885-1.612 30.623-13.438 35.048-29.165 0.777-2.682 1.32-6.189 1.38-9.815l-0.161-0.487v-3.467c-0.348-3.712-1.148-7.128-2.351-10.347-3.737-12.756-17.644-24.385-34.447-25.701l-108.001 0.048v-76.267z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M1125.333 845.334v26.666c0 22.091-17.909 40-40 40h-746.666v-40c-0.004-22.088-17.911-39.994-40-39.994s-39.997 17.905-40 39.994v40h-106.667c0 0 0 0 0 0-22.091 0-40-17.909-40-40v-720c0-22.091 17.909-40 40-40h106.667v40c0.004 22.088 17.911 39.994 40 39.994s39.997-17.905 40-39.994v-40h746.666c22.091 0 40 17.909 40 40v480c0 22.091 17.909 40 40 40s40-17.909 40-40v-480c0-66.274-53.726-120-120-120h-933.334c-66.274 0-120 53.726-120 120v720c0 66.274 53.726 120 120 120h933.334c66.274 0 120-53.726 120-120v-26.666c0-22.091-17.909-40-40-40s-40 17.909-40 40z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M338.667 320.266c0-0.002 0-0.004 0-0.007 0-22.091-17.909-40-40-40-22.091 0-40 17.909-40 40 0 0.002 0 0.005 0 0.007v94.4c0.004 22.088 17.911 39.994 40 39.994s39.997-17.905 40-39.994zM298.666 569.334c-22.091 0-40 17.909-40 40v94.4c0.004 22.088 17.911 39.994 40 39.994s39.997-17.905 40-39.994v-94.401c0-22.091-17.909-40-40-40z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconFapiaoguanli1.defaultProps = {
  size: 18,
};

export default IconFapiaoguanli1;
