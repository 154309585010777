/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconZibaoshichang: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M938.666667 31.488c20.778667 0 37.632 15.018667 37.632 33.536 0 16.981333-14.165333 30.976-32.512 33.194667L938.666667 98.56h-69.802667l-1.962667 8.405333-4.309333 17.152-4.437333 16.853334-9.173334 32.682666c-50.474667 172.245333-119.893333 286.037333-210.346666 338.432 90.453333 52.48 159.872 166.186667 210.346666 338.346667l9.173334 32.64c2.986667 11.093333 5.888 22.442667 8.746666 34.005333l1.962667 8.362667H938.666667c20.778667 0 37.632 15.018667 37.632 33.536 0 16.981333-14.165333 31.018667-32.512 33.237333l-5.12 0.298667H85.333333c-20.778667 0-37.632-15.018667-37.632-33.536 0-16.981333 14.165333-30.976 32.512-33.194667L85.333333 925.44h69.802667l1.962667-8.405333 4.309333-17.152 4.437333-16.853334 9.173334-32.682666c50.474667-172.245333 119.893333-285.994667 210.346666-338.432-90.453333-52.48-159.872-166.186667-210.346666-338.346667l-9.173334-32.64c-2.986667-11.093333-5.888-22.442667-8.746666-34.005333l-1.962667-8.405334H85.333333c-20.778667 0-37.632-14.976-37.632-33.493333 0-16.981333 14.165333-31.018667 32.512-33.237333L85.333333 31.488h853.333334zM512 545.536c-118.357333-0.128-213.077333 119.509333-276.992 368.512l-2.901333 11.349333h559.744l-2.858667-11.306666c-62.506667-243.285333-154.453333-363.093333-269.184-368.384z m125.482667 245.845333c20.778667 0 37.674667 14.976 37.674666 33.493334 0 16.981333-14.165333 31.018667-32.554666 33.237333l-5.12 0.298667H386.56c-20.778667 0-37.674667-15.018667-37.674667-33.536 0-16.938667 14.165333-30.976 32.554667-33.194667l5.12-0.298667h250.965333zM791.893333 98.56H232.106667l2.901333 11.349333c62.506667 243.285333 154.453333 363.093333 269.184 368.384l7.850667 0.170667c118.314667 0.128 213.034667-119.509333 276.906666-368.512l2.901334-11.392zM587.306667 277.333333c20.778667 0 37.632 15.018667 37.632 33.536 0 16.938667-14.165333 30.976-32.554667 33.194667l-5.12 0.298667h-150.570667c-20.778667 0-37.632-14.976-37.632-33.493334 0-16.981333 14.165333-31.018667 32.554667-33.237333l5.12-0.298667h150.570667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconZibaoshichang.defaultProps = {
  size: 18,
};

export default IconZibaoshichang;
