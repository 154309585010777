/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationUsers: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M497.2544 41.3696c-117.02272 6.88128-213.4016 102.21568-221.26592 219.19744-8.84736 137.6256 99.328 252.6208 235.02848 252.6208 130.78528 0 236.01152-106.16832 236.01152-235.9296 0.98304-135.63904-113.09056-243.75296-249.77408-235.88864zM512 589.86496c-157.34784 0-471.04 78.6432-471.04 235.90912v117.9648C40.96 965.3248 58.65472 983.04 80.2816 983.04h863.4368c21.62688 0 39.3216-17.69472 39.3216-39.3216v-118.92736c0-156.30336-313.69216-234.92608-471.04-234.92608z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationUsers.defaultProps = {
  size: 18,
};

export default IconAAdministrationUsers;
