/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLingyongshenqing1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M271.066 312.43c16.512 0 29.897 13.381 29.897 29.889 0 16.271-13.006 29.506-29.19 29.88l-0.707 0.009H131.793v400.055h604.83V372.208H598.388c-16.276 0-29.514-13.002-29.889-29.184l-0.008-0.705c0-16.272 13.006-29.507 29.191-29.881l0.706-0.009H766.52c16.275 0 29.513 13.003 29.888 29.184l0.008 0.706v71.273h112.687c16.276 0 29.514 13.003 29.889 29.184l0.008 0.706v473.629c0 16.271-13.005 29.506-29.19 29.88l-0.707 0.009H203.085c-16.276 0-29.514-13.002-29.889-29.184l-0.008-0.705v-66.345a29.76 29.76 0 0 1 6.593-18.724h-77.884c-16.276 0-29.514-13.003-29.889-29.184l-0.008-0.706V342.32c0-16.272 13.005-29.507 29.19-29.881l0.707-0.009h169.17z m608.14 160.941h-82.79v328.781c0 16.272-13.005 29.507-29.19 29.881l-0.706 0.009-540.131-0.001a29.752 29.752 0 0 1 6.584 18.02l0.008 0.705v36.456h646.226v-413.85zM434.209 117c16.512 0 29.897 13.385 29.897 29.897v420.911l85.247-86.55 0.403-0.404c13.384-13.154 34.897-13.185 48.317 0.033l0.37 0.37c13.615 13.824 13.615 36.016 0 49.84L458.844 672.83l-0.388 0.387c-6.626 6.521-15.248 9.822-23.895 9.888h-0.856c-8.816-0.067-17.607-3.497-24.283-10.275L269.824 531.097c-13.615-13.824-13.615-36.016 0-49.84 0.122-0.124 0.246-0.247 0.37-0.37 13.555-13.351 35.368-13.186 48.72 0.37l85.397 86.704V146.897c0-16.512 13.386-29.897 29.897-29.897z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconLingyongshenqing1.defaultProps = {
  size: 18,
};

export default IconLingyongshenqing1;
