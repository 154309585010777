/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAnjianshenhe: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M675.0976 1024H144.128C64.4864 1024 0 958.2848 0 877.2096V146.7904C0 65.7152 64.512 0 144.1024 0H675.072C754.7136 0 819.2 65.7152 819.2 146.7904v730.4192C819.2 958.2848 754.688 1024 675.0976 1024z"
        fill={getIconColor(color, 0, '#80B7F9')}
      />
      <path
        d="M667.1104 222.8736H152.1664C124.672 222.8736 102.4 201.6256 102.4 175.4368 102.4 149.2224 124.672 128 152.1664 128h514.8672C694.528 128 716.8 149.248 716.8 175.4368c0 26.2144-22.272 47.4368-49.6896 47.4368z m-239.872 178.6112H152.1664C124.672 401.4848 102.4 380.2368 102.4 354.048c0-26.2144 22.272-47.4368 49.7664-47.4368h275.072c27.4944 0 49.7664 21.2224 49.7664 47.4368s-22.272 47.4368-49.7664 47.4368zM329.1392 588.8H152.1664C124.672 588.8 102.4 567.552 102.4 541.3632c0-26.2144 22.272-47.4368 49.7664-47.4368h176.9728c27.4944 0 49.7664 21.248 49.7664 47.4368 0 26.2144-22.272 47.4368-49.7664 47.4368z"
        fill={getIconColor(color, 1, '#FFFFFF')}
      />
      <path
        d="M993.6128 859.2128l-188.4928 144.64a74.7776 74.7776 0 0 1-90.9312 0l-188.5184-144.64a73.728 73.728 0 0 1-28.928-58.5472V526.8224c0-23.552 15.1808-44.3904 37.6832-51.712l208.2048-67.8656c11.0592-3.6096 23.04-3.6096 34.0992 0l208.2048 67.8912a54.4512 54.4512 0 0 1 37.6832 51.6864v273.8432a73.984 73.984 0 0 1-29.0048 58.5472z"
        fill={getIconColor(color, 2, '#80B7F9')}
      />
      <path
        d="M742.5536 407.1168c11.0848-3.6096 23.04-3.6096 34.1248 0l42.496 13.8752 0.0256 456.192a147.6096 147.6096 0 0 1-50.944 112.0512 145.536 145.536 0 0 1-44.8768 20.5056 74.4448 74.4448 0 0 1-9.2672-5.9904l-188.4928-144.64a73.728 73.728 0 0 1-28.928-58.5728V526.6944c0-23.5264 15.2064-44.3648 37.6832-51.712z"
        fill={getIconColor(color, 3, '#3E8BF8')}
      />
      <path
        d="M743.552 810.7008c-12.5952 0-24.6528-5.12-33.3824-14.1568l-81.152-84.0704a45.7472 45.7472 0 0 1 1.3312-64.9984 46.4128 46.4128 0 0 1 65.3824 1.3568l46.2848 47.9488 95.9232-108.544a46.3104 46.3104 0 0 1 65.2544-4.1472 45.824 45.824 0 0 1 4.1984 64.8704l-129.1776 146.1248c-8.5504 9.6512-20.7872 15.3344-33.7408 15.616h-0.9216z"
        fill={getIconColor(color, 4, '#FFFFFF')}
      />
    </svg>
  );
};

IconAnjianshenhe.defaultProps = {
  size: 18,
};

export default IconAnjianshenhe;
