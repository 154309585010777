/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentAppsListDetail24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M181.333333 170.666667A96 96 0 0 0 85.333333 266.666667v106.666666A96 96 0 0 0 181.333333 469.333333h106.666667A96 96 0 0 0 384 373.333333v-106.666666A96 96 0 0 0 288 170.666667h-106.666667zM149.333333 266.666667a32 32 0 0 1 32-32h106.666667a32 32 0 0 1 32 32v106.666666a32 32 0 0 1-32 32h-106.666667a32 32 0 0 1-32-32v-106.666666zM480 213.333333a32 32 0 0 0 0 64h426.666667a32 32 0 0 0 0-64h-426.666667z m0 128a32 32 0 0 0 0 64h298.666667a32 32 0 0 0 0-64h-298.666667z m-298.666667 213.333334A96 96 0 0 0 85.333333 650.666667v106.666666A96 96 0 0 0 181.333333 853.333333h106.666667A96 96 0 0 0 384 757.333333v-106.666666A96 96 0 0 0 288 554.666667h-106.666667z m-32 96a32 32 0 0 1 32-32h106.666667a32 32 0 0 1 32 32v106.666666a32 32 0 0 1-32 32h-106.666667a32 32 0 0 1-32-32v-106.666666zM480 597.333333a32 32 0 0 0 0 64h426.666667a32 32 0 0 0 0-64h-426.666667z m0 128a32 32 0 0 0 0 64h298.666667a32 32 0 0 0 0-64h-298.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentAppsListDetail24Regular;

