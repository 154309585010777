/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconQingjiajilu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M606.634667 96a32 32 0 0 1 4.352 63.701333l-4.352 0.298667H256a53.333333 53.333333 0 0 0-53.077333 47.872L202.666667 213.333333v597.333334c0 27.605333 20.992 50.346667 47.872 53.077333L256 864h512a53.333333 53.333333 0 0 0 53.077333-47.872L821.333333 810.666667V381.610667a32 32 0 0 1 63.701334-4.352l0.298666 4.352V810.666667a117.333333 117.333333 0 0 1-110.165333 117.12L768 928H256a117.333333 117.333333 0 0 1-117.12-110.165333L138.666667 810.666667V213.333333a117.333333 117.333333 0 0 1 110.165333-117.12L256 96h350.634667z m37.248 460.842667a32 32 0 0 1 4.352 63.701333l-4.352 0.298667h-298.666667a32 32 0 0 1-4.352-63.701334l4.352-0.298666h298.666667z m163.285333-415.573334l53.76 42.368-183.466667 232.533334c-10.24 12.970667-65.578667 51.626667-77.141333 42.538666-11.52-9.088 13.226667-71.936 23.466667-84.906666l183.381333-232.533334z m-291.285333 207.061334a32 32 0 0 1 4.352 63.701333l-4.352 0.298667h-170.666667A32 32 0 0 1 340.906667 348.586667l4.352-0.256h170.666666z m392.106666-278.741334c10.368 8.192 17.493333 26.837333 17.322667 32.341334l-0.341333 1.28-35.882667 45.568-53.76-42.368 35.925333-45.525334c2.005333-2.56 25.173333-0.426667 36.693334 8.704z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconQingjiajilu.defaultProps = {
  size: 18,
};

export default IconQingjiajilu;
