/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Zuzhijigou: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M204.02 517.106c0-14.501 10.035-26.287 22.31-26.287h242.653v116.916h61.328V490.82h243.26c12.088 0 22.356 12.055 22.356 26.287v90.63h61.33v-90.63c0-46.748-37.526-84.735-83.685-84.735h-243.26V326.87c54.356-15.07 94.373-66.413 94.373-127.439 0-72.77-57.034-131.839-127.417-131.839s-127.417 59.07-127.417 131.84c0 62.793 42.368 115.34 99.133 128.66v104.28H226.329c-46.113 0-83.638 37.987-83.638 84.735v90.63h61.328v-90.63zM497.266 665.098c-70.382 0-127.417 59.025-127.417 131.884 0 72.857 57.034 131.884 127.417 131.884s127.417-59.027 127.417-131.884c0-72.859-57.034-131.884-127.417-131.884zM189.224 668.702c-70.336 0-127.417 58.981-127.417 131.838 0 72.859 57.081 131.928 127.417 131.928 70.43 0 127.51-59.069 127.51-131.928 0-72.857-57.08-131.838-127.51-131.838zM826.59 669.235c-70.428 0-127.51 59.071-127.51 131.839 0 72.86 57.082 131.928 127.51 131.928 70.337 0 127.418-59.069 127.418-131.928 0-72.768-57.08-131.839-127.418-131.839z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Zuzhijigou;

