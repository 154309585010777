/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconClearCache: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M850.19973469 882.78857422H173.80026531c-18.03249121 0-32.58883953-14.55634833-32.58883953-32.58883953s14.55634833-32.58883953 32.58883953-32.58883953h676.39946938c18.03249121 0 32.58883953 14.55634833 32.58883953 32.58883953s-14.55634833 32.58883953-32.58883953 32.58883953zM386.14335012 349.0630443l35.7629925-54.48853968L735.63910078 500.49107027l-35.76299249 54.48853969z"
        fill={getIconColor(color, 0, '#515151')}
      />
      <path
        d="M543.64738417 771.98651982H363.46731137c-18.82910728 0-37.07885742-5.43147326-52.79392003-15.78748227l-81.39967918-53.44569683c-21.50863409-14.12183047-36.20982171-35.7028842-41.42403603-60.9049201s-0.36209822-50.83858967 13.75973225-72.34722376L454.20912456 184.73563146c29.11269665-44.32082176 88.85890246-56.77700043 133.25214387-27.66430377l207.33743906 136.07650995c21.50863409 14.12183047 36.20982171 35.7028842 41.42403601 60.9049201 5.21421433 25.2020359 0.36209822 50.83858967-13.75973224 72.34722376L624.10560798 728.53473377c-17.88765193 27.22978592-47.94180393 43.45178603-80.45822382 43.45178605zM534.66734839 206.46152448c-10.13875008 0-20.06024123 4.92453575-25.99865198 13.97699119L256.06898022 605.2765007c-4.56243754 6.95228576-6.15566969 15.20812511-4.41759825 23.31912517 1.66565181 8.11100006 6.44534827 15.06328583 13.39763402 19.62572338L346.44869518 701.73946572c5.06937504 3.33130359 10.93536616 5.06937504 17.0186162 5.06937503h180.18007278c10.5008483 0 20.20508051-5.21421433 25.92623235-13.97699118l198.35740327-302.13475227c4.56243754-6.95228576 6.15566969-15.20812511 4.41759825-23.31912518-1.66565181-8.11100006-6.44534827-15.06328583-13.39763402-19.62572336L551.68596458 211.53089952c-5.28663397-3.40372325-11.15262508-5.06937504-17.0186162-5.06937503z"
        fill={getIconColor(color, 1, '#515151')}
      />
    </svg>
  );
};

IconClearCache.defaultProps = {
  size: 18,
};

export default IconClearCache;
