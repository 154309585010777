/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDocumentMultiple24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 181.333333A96 96 0 0 1 266.666667 85.333333h181.504v202.581334a96 96 0 0 0 96 96H746.666667v352.085333a96 96 0 0 1-96 96h-384A96 96 0 0 1 170.666667 736v-554.666667z m341.504 106.581334v-182.613334c3.242667 2.474667 6.4 5.205333 9.301333 8.149334l197.077333 197.077333c2.986667 2.986667 5.717333 6.101333 8.234667 9.386667h-182.613333a32 32 0 0 1-32-32zM373.333333 938.666667a96 96 0 0 1-90.538666-64h367.872a138.666667 138.666667 0 0 0 138.666666-138.666667V381.312l35.84 35.84c18.048 18.048 28.16 42.453333 28.16 67.925333v250.922667A202.666667 202.666667 0 0 1 650.666667 938.666667h-277.333334z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentDocumentMultiple24Filled;

