/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconEye: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M892.576 513.76c-122.016-220.064-306.624-262.304-378.56-257.824l-1.952 0.128-1.856-0.128h-0.288l-1.696-0.064a312.16 312.16 0 0 0-37.248 2.144c-25.536 3.008-61.28 9.92-101.216 25.728-76.896 30.4-170.88 94.08-238.656 229.28C172.32 605.824 297.6 768 512 768c213.856 0 339.008-161.344 380.576-254.24zM511.744 191.968c98.112-5.312 311.232 51.776 444.576 305.12l6.784 12.928-5.28 13.568C918.08 625.824 773.888 832 512 832c-261.888 0-406.08-206.176-445.824-308.416l-4.992-12.8 5.888-12.48c75.84-160.096 186.432-237.408 279.168-274.048 46.048-18.24 87.36-26.272 117.28-29.76 15.008-1.792 27.2-2.4 35.84-2.56a248.224 248.224 0 0 1 12.384 0z"
        fill={getIconColor(color, 0, '#515151')}
      />
      <path
        d="M512 608a96 96 0 1 1 0-192 96 96 0 0 1 0 192z m0 64a160 160 0 1 0 0-320 160 160 0 0 0 0 320z"
        fill={getIconColor(color, 1, '#515151')}
      />
    </svg>
  );
};

IconEye.defaultProps = {
  size: 18,
};

export default IconEye;
