/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACommonToolsQuanShi: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M960 20.48H64C28.65152 20.48 0 48.80384 0 83.72224V716.1856c0 34.9184 28.672 63.24224 64 63.24224h339.21024l-188.8256 166.33856a31.45728 31.45728 0 0 0 9.33888 56.56576 32.23552 32.23552 0 0 0 32.91136-9.76896l243.2-213.13536h47.37024l243.2 213.13536c5.632 5.4272 13.25056 8.3968 21.11488 8.23296a31.98976 31.98976 0 0 0 29.51168-20.41856 31.37536 31.37536 0 0 0-8.3968-34.6112l-188.78464-166.33856h316.14976c35.34848 0 64-28.32384 64-63.24224V83.72224C1024 48.80384 995.328 20.48 960 20.48zM149.11488 286.1056c0-17.44896 14.336-31.62112 32.01024-31.62112h256c17.67424 0 31.98976 14.17216 31.98976 31.62112 0 17.46944-14.336 31.62112-31.98976 31.62112h-256A31.80544 31.80544 0 0 1 149.0944 286.1056zM266.24 513.80224c0-17.46944 14.336-31.62112 32.01024-31.62112h138.8544c17.69472 0 32.01024 14.15168 32.01024 31.62112 0 17.46944-14.336 31.62112-31.98976 31.62112h-138.89536A31.80544 31.80544 0 0 1 266.24 513.80224z m470.40512 44.27776c-64.77824 0.24576-123.31008-38.11328-148.2752-97.1776a156.75392 156.75392 0 0 1 34.26304-172.52352 161.3824 161.3824 0 0 1 174.44864-34.57024c59.86304 24.45312 98.9184 82.1248 98.9184 146.14528 0 87.08096-71.24992 157.75744-159.35488 158.1056z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACommonToolsQuanShi.defaultProps = {
  size: 18,
};

export default IconACommonToolsQuanShi;
