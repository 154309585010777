/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTips: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M884.9375 757.8125c-57.375-45.5625-84.375-92.8125-84.375-109.6875V450.6875c0-126-82.125-233.4375-196.3125-271.125v-26.4375C604.25 103.0625 563.1875 62 513.125 62 462.5 62 422 103.0625 422 153.125v28.125C310.0625 220.0625 230.1875 326.375 230.1875 450.6875v196.875c-2.8125 17.4375-46.125 70.875-91.125 112.5-11.8125 11.25-15.75 28.125-9.5625 43.3125s20.25 24.75 36.5625 24.75h209.8125c11.8125 78.1875 66.9375 133.875 137.25 133.875s125.4375-55.6875 137.25-133.875h209.8125c16.875 0 31.5-10.6875 37.125-26.4375s0.5625-33.75-12.375-43.875z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconTips.defaultProps = {
  size: 18,
};

export default IconTips;
