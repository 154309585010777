/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconJieanshenqing: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M754.24 0c93.568 0 170.112 84.32 173.632 189.504l0.128 7.552 0.032 307.008a272 272 0 1 0-147.2 517.664 161.28 161.28 0 0 1-19.904 2.144l-6.624 0.128H205.696c-93.536 0-170.048-84.32-173.568-189.504L32 826.944V197.056C32 90.912 106.336 4.096 199.04 0.128L205.728 0H754.24z m185.76 853.312c11.008 0 20 9.536 20 21.344 0 11.776-8.96 21.344-20 21.344h-280c-11.008 0-20-9.536-20-21.344 0-11.808 8.96-21.344 20-21.344h280zM451.808 768H198.88c-9.696 0-17.536 17.76-17.536 39.648 0 20.288 6.752 37.056 15.488 39.36l2.048 0.256h252.928c9.696 0 17.536-17.76 17.536-39.616 0-21.888-7.872-39.648-17.536-39.648z m355.52-132.416l56.544 60.352-127.296 135.68v0.064h-56.544v-60.352l127.296-135.744z m99.04-48.96c16.64 15.52 18.272 42.464 3.712 60.16a38.72 38.72 0 0 1-4.704 4.864l-27.392 29.184-56.544-60.352 28.288-30.176 0.192 0.224a38.272 38.272 0 0 1 56.448-3.936zM480 192a29.888 29.888 0 0 0-25.184 14.08l-1.888 3.456-42.944 90.816-96.128 14.56a30.592 30.592 0 0 0-24.352 21.44c-3.2 10.112-1.184 21.12 5.088 29.312l2.56 2.944 69.344 70.72-16.384 99.84c-1.92 11.808 2.72 23.744 12.032 30.784 8.256 6.272 18.88 7.68 28.32 4.032l3.456-1.6 85.984-47.136 85.984 47.136a29.056 29.056 0 0 0 31.776-2.432 31.968 31.968 0 0 0 12.448-26.88l-0.416-3.904-16.416-99.84 69.568-70.72c8.256-8.352 11.232-20.896 7.68-32.288a30.72 30.72 0 0 0-20.64-20.576l-3.712-0.832-96.16-14.56-42.944-90.816A30.144 30.144 0 0 0 480 192z m0.064 102.624l22.912 48.48c3.84 8.128 10.88 14.08 19.136 16.416l3.616 0.8 51.328 7.744-37.12 37.76a32.128 32.128 0 0 0-9.12 24l0.384 3.84 8.768 53.28-45.888-25.12a28.96 28.96 0 0 0-24.704-1.6l-3.36 1.6-45.888 25.12 8.768-53.28a32.608 32.608 0 0 0-6.24-25.024l-2.464-2.848-37.12-37.728 51.296-7.744c8.64-1.28 16.224-6.4 20.928-13.888l1.824-3.328 22.944-48.48z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconJieanshenqing.defaultProps = {
  size: 18,
};

export default IconJieanshenqing;
