/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Jieanshenqing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M756.869565 445.217391c147.522783 0 267.130435 119.607652 267.130435 267.130435a267.130435 267.130435 0 0 1-534.26087 0c0-147.522783 119.607652-267.130435 267.130435-267.130435z m112.417392-275.478261c54.984348 0 101.531826 41.405217 109.167304 97.057392l0.77913 7.924869 0.222609 7.702261v192.289391a323.383652 323.383652 0 0 0-218.86887-85.147826c-180.313043 0-326.500174 147.745391-326.500173 329.97287 0 44.098783 8.704 86.928696 24.887652 126.374956H110.191304C52.246261 845.913043 4.229565 800.055652 0.24487 740.930783L0 733.206261V370.042435l303.972174 0.044522 9.616696-0.445218a108.655304 108.655304 0 0 0 53.27026-19.700869l7.635479-6.010435 204.421565-174.191305H869.286957z m-73.238261 424.737392l-143.582609 143.62713a65.335652 65.335652 0 0 0-17.207652 30.363826l-11.130435 44.521739c-4.87513 19.411478 12.688696 36.953043 32.055652 32.100174l44.566261-11.130434a65.357913 65.357913 0 0 0 30.363826-17.185392l143.627131-143.649391a55.629913 55.629913 0 0 0-78.669913-78.647652zM303.994435 44.521739c22.528 0 44.477217 7.056696 62.886956 20.123826l7.635479 6.010435 103.001043 87.707826-150.016 127.866435-4.162783 3.072a36.218435 36.218435 0 0 1-14.202434 5.298087l-5.186783 0.356174L0 294.912V157.206261c0-59.236174 44.855652-108.388174 102.64487-112.417391L110.191304 44.521739h193.78087z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Jieanshenqing;

