/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const KuaisuchuangjianXianxing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M667.989333 479.786667c-125.909333 0-228.010667 102.741333-228.010666 229.461333S542.122667 938.666667 668.032 938.666667C793.941333 938.666667 896 835.968 896 709.248c0-126.72-102.058667-229.461333-228.010667-229.461333zM441.173333 85.333333a24.064 24.064 0 0 0-3.285333 0.298667 22.272 22.272 0 0 1-2.346667 0.256c-8.576 0.469333-17.024 2.346667-24.96 5.546667-2.304 0.981333-4.48 2.261333-6.656 3.498666l-2.005333 1.194667-1.92 0.938667a26.026667 26.026667 0 0 0-3.2 1.792c-3.072 2.218667-5.973333 4.650667-8.746667 7.253333a25.216 25.216 0 0 1-1.450666 1.194667 19.626667 19.626667 0 0 0-1.962667 1.621333L151.466667 343.466667C136.448 358.613333 128 379.093333 128 400.426667v409.429333a80.213333 80.213333 0 0 0 80 80.512h272.384a262.314667 262.314667 0 0 1-43.093333-60.373333H208a20.053333 20.053333 0 0 1-20.010667-20.138667V407.338667h180.010667A80.213333 80.213333 0 0 0 448 326.826667V145.706667h240a20.053333 20.053333 0 0 1 20.010667 20.138666v284.8c20.608 3.2 40.746667 8.917333 59.989333 16.981334V165.845333A80.213333 80.213333 0 0 0 688 85.333333z m226.688 443.733334l8.362667 0.213333c95.36 4.352 170.837333 83.541333 170.837333 179.968 0 99.456-80.170667 180.096-179.029333 180.096-98.858667 0-178.986667-80.64-178.986667-180.096 0-93.482667 70.954667-170.837333 162.005334-179.370667l8.618666-0.597333 8.192-0.170667z m0.170667 61.44a24.576 24.576 0 0 0-24.490667 24.618666v69.418667h-68.992a24.533333 24.533333 0 0 0-24.192 20.693333l-0.298666 4.010667c0 13.610667 10.965333 24.618667 24.490666 24.618667h68.992v69.461333c0 12.245333 8.874667 22.4 20.522667 24.32l3.968 0.341333a24.576 24.576 0 0 0 24.490667-24.661333V733.866667h69.034666c12.16 0 22.272-8.96 24.149334-20.650667l0.341333-3.968a24.576 24.576 0 0 0-24.490667-24.661333H692.48v-69.461334a24.618667 24.618667 0 0 0-20.565333-24.32zM388.010667 190.848V326.826667a20.053333 20.053333 0 0 1-20.010667 20.138666H232.874667l155.136-156.16z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default KuaisuchuangjianXianxing;

