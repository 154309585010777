/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Tishi: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M320.232727 656.384C211.874909 594.571636 139.636364 482.024727 139.636364 353.466182 139.636364 158.254545 306.269091 0 512 0s372.363636 158.254545 372.363636 353.466182c0 128.558545-72.331636 241.105455-180.596363 302.917818-6.050909 31.650909-20.573091 60.322909-41.332364 84.154182H361.565091a180.410182 180.410182 0 0 1-41.425455-84.154182z m457.728-269.312A267.729455 267.729455 0 0 0 512 117.853091c-23.552 0-35.467636 11.264-35.467636 33.605818 0 5.585455 11.915636 16.756364 35.467636 16.756364 113.570909 0 212.805818 94.301091 212.805818 218.856727 0 5.585455 11.915636 16.849455 35.374546 16.849455 5.864727 0 17.780364-11.264 17.780363-16.756364z m-425.518545 437.527273c0-18.525091 15.825455-33.512727 35.374545-33.512728h248.366546c19.549091 0 35.374545 14.894545 35.374545 33.512728 0 18.618182-15.825455 33.698909-35.374545 33.698909H387.816727a34.443636 34.443636 0 0 1-35.374545-33.605818z m302.545454 84.247272c-26.065455 49.803636-80.337455 84.154182-142.987636 84.154182a160.581818 160.581818 0 0 1-142.987636-84.154182h285.975272z"
        fill={getIconColor(color, 0, '#F5BE41')}
      />
    </svg>
 );
};

export default Tishi;

