/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialChargeCreate: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M204.43136 102.4v88.2688h75.01824V102.4h707.03104C1007.12448 102.4 1024 120.87296 1024 143.4624v737.0752C1024 903.12704 1007.12448 921.6 986.48064 921.6H279.4496v-94.45376H204.43136V921.6h-166.912C16.87552 921.6 0 903.12704 0 880.5376V143.4624C0 120.87296 16.87552 102.4 37.51936 102.4h166.912z m75.01824 539.97568H204.43136v102.64576h75.01824v-102.64576zM783.9744 296.22272a40.96 40.96 0 0 0-57.67168 5.24288L655.36 386.53952l-70.94272-85.07392-3.52256-3.74784a40.96 40.96 0 0 0-59.392 56.17664L567.9104 409.6H532.48l-4.77184 0.26624A40.96 40.96 0 0 0 532.48 491.52h81.89952v40.96H532.48l-4.77184 0.26624A40.96 40.96 0 0 0 532.48 614.4h81.89952L614.4 696.32l0.26624 4.77184A40.96 40.96 0 0 0 696.32 696.32l-0.02048-81.92H778.24l4.77184-0.26624A40.96 40.96 0 0 0 778.24 532.48h-81.94048v-40.96H778.24l4.77184-0.26624A40.96 40.96 0 0 0 778.24 409.6h-35.45088l46.42816-55.7056 3.072-4.13696a40.96 40.96 0 0 0-8.31488-53.53472z m-504.5248 161.3824H204.43136v102.64576h75.01824v-102.66624z m0-184.79104H204.43136v102.64576h75.01824v-102.64576z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialChargeCreate.defaultProps = {
  size: 18,
};

export default IconAFinancialChargeCreate;
