/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialBillingsMyBillings: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 0c81.1008 0 147.456 65.82272 147.456 146.28864h184.32c20.2752 0 36.864 16.44544 36.864 36.57728v804.5568c0 20.11136-16.5888 36.57728-36.864 36.57728H180.224c-20.2752 0-36.864-16.46592-36.864-36.57728V182.8864c0-20.13184 16.5888-36.57728 36.864-36.57728h184.32C364.544 64.9216 430.8992 0 512 0z m129.024 409.6c-9.25696 0-17.69472 4.11648-25.35424 12.288L512 530.88256 408.33024 421.888c-7.65952-8.15104-16.09728-12.26752-25.35424-12.26752-9.27744 0-17.7152 4.11648-25.3952 12.288-7.65952 8.13056-11.53024 17.1008-11.53024 26.95168 0 9.85088 3.8912 18.82112 11.55072 26.97216l73.728 78.39744h-66.82624c-11.53024 0.79872-20.56192 4.75136-27.0336 11.63264-6.59456 7.00416-9.78944 16.32256-9.78944 28.13952 0 11.81696 3.2768 21.23776 9.76896 28.13952 6.49216 6.9632 15.60576 10.38336 27.05408 10.38336h110.67392v47.75936h-110.67392c-11.53024 0-20.56192 3.4816-27.0336 10.38336-6.59456 6.98368-9.78944 16.30208-9.78944 28.13952 0 11.81696 3.2768 21.4016 9.76896 28.75392 6.49216 7.35232 15.60576 11.01824 27.05408 11.01824h110.592v62.464c0 12.26752 3.2768 21.85216 9.76896 28.75392 6.49216 6.9632 15.58528 10.38336 27.0336 10.38336 11.4688 0 20.5824-3.4816 27.07456-10.38336 6.49216-6.90176 9.76896-16.5888 9.76896-28.7744V758.5792h110.592c11.53024 0 20.56192-3.6864 27.0336-11.01824 6.49216-7.35232 9.78944-16.93696 9.78944-28.75392 0-11.83744-3.2768-21.23776-9.78944-28.13952-6.5536-6.90176-15.5648-10.38336-27.0336-10.38336h-110.42816v-47.75936h110.592c11.53024 0 20.56192-3.4816 27.0336-10.38336 6.51264-6.90176 9.78944-16.32256 9.78944-28.13952 0-11.81696-3.2768-21.23776-9.76896-28.13952-6.57408-6.88128-15.60576-10.83392-27.05408-11.63264h-66.84672l73.74848-78.39744c7.68-8.15104 11.55072-17.12128 11.55072-26.97216s-3.8912-18.82112-11.55072-26.97216c-7.65952-8.15104-16.09728-12.26752-25.37472-12.26752zM512 109.71136c-20.2752 0-36.864 16.46592-36.864 36.57728s16.5888 36.57728 36.864 36.57728 36.864-16.46592 36.864-36.57728-16.5888-36.57728-36.864-36.57728z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialBillingsMyBillings.defaultProps = {
  size: 18,
};

export default IconAFinancialBillingsMyBillings;
