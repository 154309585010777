/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconXinzengrenwu: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M810.688 0C892.992 0 960 69.44 960 154.816v714.368C960 954.56 892.992 1024 810.656 1024H213.344C130.976 1024 64 954.56 64 869.184V154.816C64 69.44 130.976 0 213.344 0zM322.976 568.96a111.104 111.104 0 1 0 0 222.176 111.104 111.104 0 0 0 0-222.176z m0 52.896a58.208 58.208 0 1 1 0 116.384 58.208 58.208 0 0 1 0-116.384zM781.056 640h-216c-19.84 0-36 21.472-36 48 0 26.496 16.128 48 36 48h216c19.904 0 36-21.504 36-48 0-26.528-16.096-48-36-48z m-364.8-366.336L307.2 377.12l-55.392-55.392a26.304 26.304 0 0 0-37.216 37.184l68.48 68.448a26.4 26.4 0 0 0 3.712 3.104 25.952 25.952 0 0 0 38.144 2.624l127.04-120.48a26.88 26.88 0 0 0 1.28-37.632l-0.32-0.32a25.952 25.952 0 0 0-36.672-0.96zM781.056 320h-216c-19.84 0-36 21.472-36 48 0 26.496 16.128 48 36 48h216c19.904 0 36-21.504 36-48 0-26.528-16.096-48-36-48z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconXinzengrenwu.defaultProps = {
  size: 18,
};

export default IconXinzengrenwu;
