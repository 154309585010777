/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersCustomerStorageApply: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M197.98016 40.96a52.34688 52.34688 0 0 1 0 104.67328c-26.17344 0-52.34688 23.552-52.34688 52.4288v418.5088c0 28.5696 23.61344 52.4288 52.34688 52.4288h104.6528c52.34688 0 52.34688 104.69376 209.36704 104.69376 157.02016 0 160.93184-104.67328 209.34656-104.67328h105.0624c28.672 0 52.4288-26.17344 51.95776-52.44928V198.08256c0-28.59008-26.17344-52.4288-52.34688-52.4288a52.34688 52.34688 0 0 1 0-104.69376h52.81792A104.16128 104.16128 0 0 1 983.04 145.12128v733.71648A104.16128 104.16128 0 0 1 878.87872 983.04H145.16224A104.16128 104.16128 0 0 1 40.96 878.87872V145.16224A104.16128 104.16128 0 0 1 145.16224 40.96zM616.6528 276.31616a78.49984 78.49984 0 0 0-78.49984-78.336 78.2336 78.2336 0 0 0-78.52032 78.336v131.01056h104.69376L407.32672 616.6528 250.30656 407.3472h104.67328v-130.94912c0-101.10976 82.18624-183.0912 183.1936-183.0912a183.02976 183.02976 0 0 1 183.17312 183.0912v130.94912a52.34688 52.34688 0 1 1-104.6528 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACustomersCustomerStorageApply.defaultProps = {
  size: 18,
};

export default IconACustomersCustomerStorageApply;
