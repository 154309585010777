/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentFolderAdd24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M746.666667 469.333333a234.666667 234.666667 0 1 1 0 469.333334 234.666667 234.666667 0 0 1 0-469.333334z m0 85.333334l-3.84 0.298666a21.333333 21.333333 0 0 0-17.152 17.152L725.333333 576V682.666667h-106.752l-3.84 0.341333a21.333333 21.333333 0 0 0-17.152 17.152l-0.341333 3.84 0.341333 3.84a21.333333 21.333333 0 0 0 17.152 17.152l3.84 0.341333H725.333333v106.794667l0.341334 3.84a21.333333 21.333333 0 0 0 17.152 17.152l3.84 0.341333 3.84-0.341333a21.333333 21.333333 0 0 0 17.152-17.152l0.341333-3.84V725.333333l106.837333 0.042667 3.84-0.341333a21.333333 21.333333 0 0 0 17.152-17.152l0.341334-3.84-0.341334-3.84a21.333333 21.333333 0 0 0-17.194666-17.152l-3.84-0.341334H768v-106.666666l-0.341333-3.84a21.333333 21.333333 0 0 0-17.152-17.194667L746.666667 554.666667z m96-277.333334a96 96 0 0 1 95.104 82.688l0.682666 6.741334 0.213334 6.570666v130.56A277.333333 277.333333 0 0 0 512.981333 853.333333H181.333333a96 96 0 0 1-95.786666-89.429333L85.333333 757.333333v-309.376l264.832 0.042667 8.362667-0.384a96 96 0 0 0 46.421333-16.768l6.656-5.12L589.696 277.333333h252.970667zM350.165333 170.666667c19.626667 0 38.741333 6.016 54.784 17.152l6.656 5.12 89.728 74.709333-130.688 108.928-3.626666 2.602667a32 32 0 0 1-12.373334 4.522666L350.122667 384 85.333333 383.957333V266.666667a96 96 0 0 1 89.429334-95.786667L181.333333 170.666667h168.832z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentFolderAdd24Filled;

