/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAdministrationTenantThirdPartySettings: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 40.96c260.15744 0 470.99904 210.88256 470.99904 471.04s-210.88256 471.04-471.04 471.04C251.86304 983.04 40.96 772.15744 40.96 511.95904 40.96 251.86304 251.84256 40.96 512 40.96z m35.57376 422.64576a41.12384 41.12384 0 0 0-0.88064 42.1888 70.7584 70.7584 0 0 1-14.6432 78.35648L421.6832 694.6816a70.28736 70.28736 0 0 1-99.328 0 70.28736 70.28736 0 0 1 0-99.328l41.12384-41.14432-0.28672-0.26624a41.08288 41.08288 0 1 0-57.18016-58.6752l-0.12288-0.12288-41.94304 41.94304a152.49408 152.49408 0 0 0 107.84768 260.38272c40.46848 0 79.2576-16.0768 107.84768-44.68736l110.67392-110.67392a152.576 152.576 0 0 0 30.5152-171.54048l-0.79872-1.80224c-0.1024-0.12288-0.1024-0.2048-0.22528-0.4096a41.12384 41.12384 0 0 0-72.25344-4.75136z m110.3872-248.2176c-40.448 0-79.2576 16.0768-107.84768 44.68736l-110.65344 110.65344a152.576 152.576 0 0 0-30.59712 171.47904l0.77824 1.76128c0.12288 0.12288 0.12288 0.24576 0.2048 0.4096a41.12384 41.12384 0 1 0 73.1136-37.41696 70.73792 70.73792 0 0 1 14.66368-78.37696l110.46912-110.42816a70.28736 70.28736 0 0 1 99.328 0 70.28736 70.28736 0 0 1 0 99.328l-41.12384 41.12384 0.26624 0.3072a41.08288 41.08288 0 1 0 57.22112 58.6752l0.08192 0.12288 41.94304-41.94304a152.63744 152.63744 0 0 0 0-215.69536 152.49408 152.49408 0 0 0-107.84768-44.68736z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAdministrationTenantThirdPartySettings.defaultProps = {
  size: 18,
};

export default IconAAdministrationTenantThirdPartySettings;
