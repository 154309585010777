/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconSuoyougongneng: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M128 800a72 72 0 1 1 0 144 72 72 0 0 1 0-144z m794.928 24C947.84 824 968 845.48 968 872s-20.16 48-45.072 48H341.072C316.16 920 296 898.52 296 872s20.16-48 45.072-48zM128 440a72 72 0 1 1 0 144 72 72 0 0 1 0-144z m794.784 24c24.96 0 45.216 21.48 45.216 48s-20.232 48-45.216 48H341.216C316.256 560 296 538.52 296 512s20.232-48 45.216-48zM128 80a72 72 0 1 1 0 144 72 72 0 0 1 0-144z m794.928 24C947.84 104 968 125.48 968 152s-20.16 48-45.072 48H341.072C316.16 200 296 178.52 296 152s20.16-48 45.072-48z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconSuoyougongneng.defaultProps = {
  size: 18,
}

export default IconSuoyougongneng
