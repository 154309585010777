/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialInvoicesMyInvoices: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M901.12 81.92a122.88 122.88 0 0 1 122.88 122.88v142.27456c-60.6208 30.18752-102.4 92.61056-102.4 164.94592 0 72.31488 41.7792 134.7584 102.4 164.92544V819.2a122.88 122.88 0 0 1-122.88 122.88H122.88a122.88 122.88 0 0 1-122.88-122.88v-142.25408C60.6208 646.7584 102.4 584.33536 102.4 512s-41.7792-134.7584-102.4-164.92544V204.8a122.88 122.88 0 0 1 122.88-122.88h778.24z m-194.56 675.84h-389.12a30.72 30.72 0 0 0-30.72 30.72v20.48a30.72 30.72 0 0 0 30.72 30.74048h389.12a30.72 30.72 0 0 0 30.72-30.72v-20.48c0-16.9984-13.76256-30.72-30.72-30.72z m0-143.36h-389.12a30.72 30.72 0 0 0-30.72 30.72v20.48a30.72 30.72 0 0 0 30.72 30.74048h389.12a30.72 30.72 0 0 0 30.72-30.72v-20.48c0-16.9984-13.76256-30.74048-30.72-30.74048z m-194.49856-409.6c-37.5808 0-68.03456 32.1536-68.03456 71.82336 0 32.72704 20.66432 60.29312 48.96768 68.95616C423.07584 355.47136 368.64 419.16416 368.64 495.616v0.94208c0 12.34944 1.4336 24.3712 4.096 35.92192h278.528c2.6624-11.53024 4.096-23.552 4.096-35.92192V495.616c0.06144-76.45184-54.31296-140.14464-124.25216-150.03648 28.32384-8.72448 48.98816-36.29056 48.98816-68.95616 0-39.66976-30.45376-71.82336-68.03456-71.82336z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAFinancialInvoicesMyInvoices.defaultProps = {
  size: 18,
};

export default IconAFinancialInvoicesMyInvoices;
