/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessCaseInfoChangeAudits: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M854.6304 0c36.98688 0 67.584 30.57664 66.9696 68.3008v887.3984C921.6 993.42336 891.61728 1024 854.6304 1024H128.4096C91.38176 1024 61.44 993.42336 61.44 955.6992V68.3008C61.44 30.57664 91.40224 0 128.4096 0z m-45.3632 901.48864h-184.9344a10.09664 10.09664 0 0 0-9.9328 10.24c0 5.67296 4.44416 10.28096 9.9328 10.28096h184.9344a10.09664 10.09664 0 0 0 9.9328-10.26048c0-5.67296-4.44416-10.24-9.9328-10.24z m-91.25888-225.52576c-32.9728 0-59.8016 23.51104-59.8016 52.4288 0 18.55488 11.264 35.67616 29.20448 45.056l-5.81632 41.30816H632.832c-10.15808 0-18.432 7.24992-18.432 16.1792v33.85344c0 8.92928 8.27392 16.19968 18.432 16.19968h167.936c10.15808 0 18.432-7.2704 18.432-16.1792v-33.87392c0-8.92928-8.27392-16.1792-18.432-16.1792h-46.44864l-5.81632-41.24672c18.00192-9.37984 29.32736-26.50112 29.32736-45.11744 0-28.91776-26.8288-52.4288-59.8016-52.4288zM719.50336 450.56H263.39328c-34.05824 0-51.13856 40.91904-27.0336 64.8192l150.36416 149.34016c7.10656 7.10656 16.85504 11.12064 27.0336 11.12064 10.19904 0 19.94752-4.01408 27.0336-11.12064 14.9504-14.9504 14.9504-38.74816 0-53.69856l-85.11488-84.54144h363.8272c21.17632-0.512 38.05184-17.34656 38.05184-37.94944s-16.87552-37.43744-38.05184-37.96992zM569.22112 102.4c-10.17856 0-19.90656 4.01408-26.99264 11.12064a37.7856 37.7856 0 0 0 0 53.69856l84.97152 84.52096H264.04864c-21.52448 0-38.97344 16.9984-38.97344 37.96992S242.52416 327.68 264.04864 327.68h455.2704c33.9968 0 51.03616-40.89856 26.99264-64.79872l-150.1184-149.36064A38.11328 38.11328 0 0 0 569.22112 102.4z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessCaseInfoChangeAudits.defaultProps = {
  size: 18,
};

export default IconABusinessCaseInfoChangeAudits;
