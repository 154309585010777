/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const StoreMicrosoft24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M341.333333 160V256H117.333333a32 32 0 0 0-32 32v490.666667A117.333333 117.333333 0 0 0 202.666667 896h618.666666A117.333333 117.333333 0 0 0 938.666667 778.666667V288a32 32 0 0 0-32-32H682.666667V160A74.666667 74.666667 0 0 0 608 85.333333h-192A74.666667 74.666667 0 0 0 341.333333 160z m74.666667-10.666667h192a10.666667 10.666667 0 0 1 10.666667 10.666667V256h-213.333334V160a10.666667 10.666667 0 0 1 10.666667-10.666667zM341.333333 554.666667V405.333333h149.333334V554.666667H341.333333z m0 192V597.333333h149.333334v149.333334H341.333333z m341.333334-192h-149.333334V405.333333H682.666667V554.666667z m-149.333334 192V597.333333H682.666667v149.333334h-149.333334z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default StoreMicrosoft24Filled;

