/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconShoucang2: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M510 172.5l104.7 212 8.6 17.5 19.4 2.8 234 34L707.3 604l-14 13.6 3.3 19.3 40 233.1-209.3-110-17.3-9.1-17.3 9.1-209.3 110 40-233.1 3.3-19.3-14-13.7-169.4-165 234-34 19.4-2.8 8.6-17.5L510 172.5m0-84L372 368.1 63.3 413l223.4 217.7L234 938l276-145.1L786 938l-52.7-307.4L956.6 413 648 368.1 510 88.5z"
        fill={getIconColor(color, 0, '#060001')}
      />
    </svg>
  )
}

IconShoucang2.defaultProps = {
  size: 18,
}

export default IconShoucang2
