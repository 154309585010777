/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialCostsExpendituresMyExpenditures: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M728.18688 0c24.12544 0 47.28832 9.80992 64.34816 27.25888A94.16704 94.16704 0 0 1 819.2 93.0816V515.8912A266.8544 266.8544 0 0 0 773.69344 512c-150.81472 0-273.08032 125.05088-273.08032 279.26528 0 97.15712 48.49664 182.6816 122.0608 232.73472H91.01312C40.7552 1024 0 982.3232 0 930.9184V93.0816C0 41.6768 40.7552 0 91.01312 0h637.17376z m-411.2384 457.1136h-111.67744c-26.86976 0-48.61952 21.97504-48.61952 49.11104 0 27.136 21.74976 49.13152 48.61952 49.13152h111.67744c26.84928 0 48.59904-21.99552 48.59904-49.13152s-21.74976-49.11104-48.59904-49.11104zM556.83072 203.24352H205.27104c-26.86976 0-48.61952 21.99552-48.61952 49.152 0 27.11552 21.87264 49.11104 48.61952 49.11104h351.55968c26.84928 0 48.61952-21.99552 48.61952-49.13152S583.68 203.24352 556.83072 203.24352z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M798.72 1020.5184c-124.416 0-225.28-100.08576-225.28-223.5392S674.304 573.44 798.72 573.44s225.28 100.08576 225.28 223.5392-100.864 223.51872-225.28 223.51872z m-105.30816-187.22816a17.57184 17.57184 0 0 0-17.55136 17.6128c0 9.70752 7.86432 17.57184 17.55136 17.57184h52.51072v35.55328a17.57184 17.57184 0 1 0 35.10272 0v-35.55328h52.77696c0.83968 0.04096 1.67936 0.04096 2.51904 0h25.72288l-9.728 9.74848a17.6128 17.6128 0 0 0 0.4096 24.4736 17.5104 17.5104 0 0 0 24.39168 0.4096l39.3216-39.424a17.87904 17.87904 0 0 0 5.12-12.63616v-1.08544V848.46592a0.57344 0.57344 0 0 0 0-0.36864 4.3008 4.3008 0 0 0 0-0.512v-0.57344V844.02176v-0.4096a1.2288 1.2288 0 0 0 0-0.36864v-2.21184l-0.3072-0.47104v-0.3072l-0.3072-0.34816v-0.3072l-0.34816-0.4096-0.36864-0.36864-39.23968-39.5264a17.5104 17.5104 0 0 0-24.82176 0.04096 17.63328 17.63328 0 0 0 0 24.8832l9.4208 9.37984h-80.75264v-28.4672h48.96768a17.57184 17.57184 0 0 0 17.55136-17.59232 17.57184 17.57184 0 0 0-17.55136-17.59232h-35.18464l49.07008-65.92512a17.57184 17.57184 0 0 0-13.9264-28.09856 17.73568 17.73568 0 0 0-14.11072 7.0656l-56.0128 74.9568-56.19712-75.0592a17.53088 17.53088 0 0 0-24.576-3.52256 17.63328 17.63328 0 0 0-3.50208 24.65792l49.27488 65.88416H693.4528a17.57184 17.57184 0 0 0-17.53088 17.59232c0 9.70752 7.84384 17.59232 17.53088 17.59232h52.5312v28.20096h-52.57216z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconAFinancialCostsExpendituresMyExpenditures.defaultProps = {
  size: 18,
};

export default IconAFinancialCostsExpendituresMyExpenditures;
