/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconRuku: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M258.701056 193.045226l512.00016 0 0 63.99952L258.701056 257.044746 258.701056 193.045226zM256.993602 321.671065l511.99916 0 0 63.99952L256.993602 385.670585 256.993602 321.671065zM256.993602 449.663108l131.31098 0 0 64.00052L256.993602 513.663628 256.993602 449.663108zM256.993602 577.665147l57.948456 0 0 64.00052-57.948456 0L256.993602 577.665147zM898.701256 0 130.701016 0C95.356326 0 66.702495 28.653831 66.702495 64.00052l0 895.998281c0 35.34469 28.653831 63.99952 63.99952 63.99952l299.403191-0.327895 0-63.99952-299.403191 0.327895L130.702015 64.00052l768.00024 0 0 611.622281 63.99952 0L962.701775 64.00052C962.700776 28.653831 934.041947 0 898.701256 0L898.701256 0z"
        fill={getIconColor(color, 0, '#272636')}
      />
      <path
        d="M962.700776 998.077615c-4.265635-63.979527-29.857446-375.345889-362.549984-392.40643l0-98.101607c0-4.265635 0-17.06154-8.53027-29.857446s-25.591811-12.795905-46.917986-4.265635l-4.265635 0-187.672945 204.734485c-4.265635 4.265635-21.326176 25.591811-21.326176 46.917986 0 25.591811 21.326176 46.917986 25.591811 46.917986l187.672945 213.264755c4.265635 4.265635 17.06154 17.06154 29.857446 17.06154 12.795905 0 21.326176-8.53027 25.591811-17.06154 4.265635-8.53027 4.265635-17.06154 4.265635-29.857446L604.417426 831.731846c55.449256 0 208.99912 8.53027 302.836092 174.877039 6.536908 14.430382 17.06154 17.06154 25.591811 17.06154l8.53027 0 6.380958-1.880398C960.551464 1017.524392 962.700776 1002.34325 962.700776 998.077615L962.700776 998.077615zM617.212332 789.078495c-21.326176 0-34.122081 0-34.122081 0l-21.326176 4.265635 0 145.019594L391.151671 742.160509c-4.265635-4.265635-12.795905-12.795905-12.795905-17.06154 0-4.265635 4.265635-12.795905 8.53027-17.06154l166.346769-179.142674 0 123.693418 21.326176 0c238.856566 0 311.366363 162.081134 332.692538 272.978647C809.149912 801.8744 676.926224 789.078495 617.212332 789.078495L617.212332 789.078495z"
        fill={getIconColor(color, 1, '#272636')}
      />
    </svg>
  );
};

IconRuku.defaultProps = {
  size: 18,
};

export default IconRuku;
