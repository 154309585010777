/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAOfficeStamp: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M858.5216 896.75776c40.5504 0 61.52192 19.78368 62.93504 59.33056l0.06144 4.25984c0 42.43456-20.992 63.65184-63.01696 63.65184H165.51936c-40.5504 0-61.5424-19.78368-62.93504-59.33056l-0.08192-4.25984c0-42.43456 21.01248-63.65184 63.01696-63.65184H858.5216zM512.04096 0c117.94432 0 214.26176 95.21152 216.7808 214.28224a201.64608 201.64608 0 0 1-78.2336 161.85344c-60.2112 65.80224-66.23232 77.84448-66.23232 119.82848v12.84096c0 107.68384 180.71552 191.6928 289.03424 191.6928a43.08992 43.08992 0 0 1 35.16416 12.43136c9.29792 9.27744 14.00832 22.26176 12.84096 35.4304v58.12224c0.02048 7.0656-2.7648 13.86496-7.72096 18.88256-4.95616 4.99712-11.6736 7.80288-18.69824 7.7824H128.77824A26.56256 26.56256 0 0 1 102.4 806.5024V748.3392a47.84128 47.84128 0 0 1 14.04928-34.26304 46.8992 46.8992 0 0 1 34.11968-13.68064c108.46208 0 289.09568-83.7632 289.09568-191.6928v-13.96736c-0.18432-42.20928-3.39968-56.1152-66.21184-118.784a201.33888 201.33888 0 0 1-78.17216-161.66912C297.82016 95.232 394.11712 0 512.06144 0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAOfficeStamp.defaultProps = {
  size: 18,
};

export default IconAOfficeStamp;
