/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersHighSeas: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M494.87872 522.48576a398.39744 398.39744 0 0 1 136.11008 58.69568c-9.17504 6.88128-17.96096 14.5408-26.35776 22.95808-24.04352 24.08448-42.0864 51.17952-54.10816 81.26464a247.35744 247.35744 0 0 0-18.04288 92.5696c0 31.58016 6.02112 62.44352 18.0224 92.52864a237.83424 237.83424 0 0 0 44.9536 71.59808L0 942.08c0-206.31552 138.89536-378.32704 324.32128-419.59424L409.6 589.98784l85.27872-67.50208z m-118.25152 111.28832l-1.16736 1.26976 27.32032 30.65856L348.16 875.1104l61.44 45.9776 61.44-45.9776-54.62016-209.42848L450.56 635.04384c-13.06624-9.76896-63.5904-10.19904-73.9328-1.26976zM419.4304 81.92c118.72256 0 214.67136 97.13664 214.67136 216.7808 0 119.66464-95.9488 216.80128-214.67136 216.80128C300.7488 515.4816 204.8 418.36544 204.8 298.7008 204.8 179.05664 300.7488 81.92 419.45088 81.92z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M798.78144 1024A225.28 225.28 0 1 1 1024 798.78144c-0.16384 124.3136-100.90496 225.0752-225.21856 225.21856z m-18.57536-156.34432c1.61792 0.32768 3.23584 0.49152 4.89472 0.49152h117.67808a25.8048 25.8048 0 1 0 0-51.6096h-101.84704v-98.0992a25.8048 25.8048 0 0 0-51.6096 0v123.6992a25.94816 25.94816 0 0 0 30.88384 25.37472v0.14336z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconACustomersHighSeas.defaultProps = {
  size: 18,
};

export default IconACustomersHighSeas;
