/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Hexiaopingzheng: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M199.813565 109.723826C199.813565 49.129739 250.12313 0 312.186435 0h424.603826c62.063304 0 112.37287 49.129739 112.372869 109.723826v377.900522H1024v158.47513c0 87.529739-72.681739 158.47513-162.348522 158.475131h-266.017391c2.470957-15.894261 3.784348-32.189217 3.784348-48.751305a310.806261 310.806261 0 0 0-68.741565-195.072h131.183304c20.702609 0 37.465043-16.361739 37.465043-36.552347 0-20.21287-16.784696-36.574609-37.465043-36.574609h-213.993739a332.421565 332.421565 0 0 0-248.05287-40.292174V109.746087z m649.349565 621.701565h12.488348c48.283826 0 87.418435-38.199652 87.418435-85.325913V560.751304h-99.906783v170.674087zM387.116522 146.276174c-20.702609 0-37.465043 16.384-37.465044 36.574609 0 20.21287 16.784696 36.574609 37.465044 36.574608h274.743652c20.702609 0 37.465043-16.361739 37.465043-36.574608 0-20.190609-16.784696-36.574609-37.465043-36.574609H387.116522z m0 170.674087c-20.702609 0-37.465043 16.384-37.465044 36.574609s16.784696 36.574609 37.465044 36.574608h274.743652c20.702609 0 37.465043-16.384 37.465043-36.574608s-16.784696-36.574609-37.465043-36.574609H387.116522z m162.348521 438.850782c0-95.810783-52.357565-184.342261-137.349565-232.247652a280.553739 280.553739 0 0 0-274.743652 0C52.357565 571.436522 0 660.012522 0 755.801043 0 903.92487 122.991304 1024 274.721391 1024c151.730087 0 274.743652-120.07513 274.743652-268.198957z m-249.766956 24.397914l0.066783 122.034086c0 13.467826-11.197217 24.397913-24.976696 24.397914-13.801739 0-24.976696-10.907826-24.976696-24.397914v-122.034086H124.66087c-13.801739 0-24.976696-10.907826-24.976696-24.397914 0-13.467826 11.174957-24.375652 24.976696-24.375652h125.083826v-121.900521c0-13.467826 11.174957-24.375652 24.954434-24.375653 13.801739 0 24.976696 10.907826 24.976696 24.375653v121.900521h124.727652c13.801739 0 24.976696 10.907826 24.976696 24.375652s-11.174957 24.397913-24.976696 24.397914h-124.727652z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Hexiaopingzheng;

