/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAAnalyzeStatisticsIncomeReport: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M1024.004096 901.46816a107.1104 107.1104 0 0 1-7.33184 39.81312 98.6112 98.6112 0 0 1-20.86912 32.52224c-9.0112 9.33888-19.53792 16.62976-31.58016 21.87264a97.8944 97.8944 0 0 1-39.46496 7.84384H106.008576a107.3152 107.3152 0 0 1-74.42432-29.71648 104.96 104.96 0 0 1-23.12192-32.52224A94.6176 94.6176 0 0 1 0.004096 901.46816V341.89312c0-28.40576 9.95328-52.5312 29.9008-72.33536 19.90656-19.80416 44.15488-29.71648 72.72448-29.71648h818.74944c28.5696 0 52.81792 9.91232 72.74496 29.696 19.92704 19.82464 29.88032 43.95008 29.88032 72.35584v152.51456H768.004096c-28.5696 0-52.81792 9.70752-72.74496 29.14304-19.92704 19.456-29.88032 43.37664-29.88032 71.7824 0.75776 19.43552 4.89472 36.61824 12.41088 51.56864 6.00064 12.71808 15.9744 24.30976 29.88032 34.77504 13.9264 10.46528 34.01728 15.70816 60.33408 15.70816H1024.004096v204.0832zM870.629376 188.25216h-512a7232.65536 7232.65536 0 0 0 115.03616-60.55936 10644.8896 10644.8896 0 0 0 93.5936-49.33632 7524.9664 7524.9664 0 0 1 72.17152-38.13376c26.3168-14.19264 49.82784-20.74624 70.49216-19.61984 20.6848 1.1264 38.15424 4.66944 52.4288 10.6496 16.54784 8.23296 30.84288 19.06688 42.86464 32.52224l65.41312 124.47744zM717.254656 595.3536c0-14.21312 4.89472-26.17344 14.66368-35.90144 9.76896-9.70752 21.79072-14.56128 36.08576-14.56128s26.3168 4.85376 36.08576 14.56128c9.76896 9.728 14.66368 21.7088 14.66368 35.90144 0 14.19264-4.89472 26.33728-14.66368 36.4544a48.27136 48.27136 0 0 1-36.08576 15.11424 48.27136 48.27136 0 0 1-36.08576-15.13472 50.50368 50.50368 0 0 1-14.66368-36.4544z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAAnalyzeStatisticsIncomeReport.defaultProps = {
  size: 18,
};

export default IconAAnalyzeStatisticsIncomeReport;
