/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentBookContacts24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M661.333333 522.666667a32 32 0 0 0-32-32h-213.333333a32 32 0 0 0-32 32v21.333333c0 42.666667 59.008 74.666667 138.666667 74.666667s138.666667-32 138.666666-74.666667v-21.333333zM597.333333 373.12C597.333333 331.946667 563.925333 298.666667 522.666667 298.666667s-74.666667 33.237333-74.666667 74.453333a74.666667 74.666667 0 1 0 149.333333 0zM170.666667 192A106.666667 106.666667 0 0 1 277.333333 85.333333H768a106.666667 106.666667 0 0 1 106.666667 106.666667v608a32 32 0 0 1-32 32H234.666667a42.666667 42.666667 0 0 0 42.666666 42.666667h565.333334a32 32 0 0 1 0 64H277.333333A106.666667 106.666667 0 0 1 170.666667 832v-640z m64 0V768H810.666667V192a42.666667 42.666667 0 0 0-42.666667-42.666667H277.333333a42.666667 42.666667 0 0 0-42.666666 42.666667z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentBookContacts24Regular;

