/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveOfficeSuppliesAuditOfficeSuppliesAsset: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M746.53696 501.02272C886.70208 501.02272 1003.52 618.0864 1003.52 762.51136 1003.52 906.97728 888.50432 1024 746.53696 1024c-141.98784 0-257.00352-117.02272-257.00352-261.48864 0-144.44544 115.01568-261.48864 257.00352-261.48864z m0-501.02272c39.5264 0 71.8848 32.91136 71.8848 73.13408v358.4c-25.16992-5.46816-48.5376-9.13408-73.68704-9.13408-185.11872 0-334.27456 153.6-334.27456 340.11136 0 91.42272 35.9424 179.2 100.63872 243.2H92.3648C52.8384 1005.71136 20.48 972.8 20.48 932.57728V73.1136C20.48 32.91136 52.8384 0 92.3648 0z m-66.49856 652.8c-10.79296-12.8-30.55616-12.8-43.13088 0-12.5952 10.97728-12.5952 31.08864 0 43.88864l64.69632 65.82272-64.7168 65.82272c-12.57472 10.97728-12.57472 31.08864 0 43.88864 10.79296 12.8 30.55616 12.8 43.15136 0l64.69632-65.82272 64.69632 65.82272c5.38624 5.48864 12.57472 9.15456 21.56544 9.15456 7.168 0 16.1792-3.66592 21.56544-9.15456 12.57472-10.9568 12.57472-31.08864 0-43.88864l-64.7168-65.82272 64.7168-65.82272c12.57472-10.97728 12.57472-31.08864 0-43.88864-10.79296-12.8-30.55616-12.8-43.13088 0l-64.7168 65.82272zM397.88544 437.02272H155.2384c-21.56544 0-37.72416 18.28864-37.72416 38.4S133.71392 512 155.2384 512h242.62656c19.7632 0 37.74464-14.62272 37.74464-36.57728a38.2976 38.2976 0 0 0-37.74464-38.4z m168.91904-235.88864H153.47712c-21.56544 0-37.74464 16.46592-37.74464 38.4a38.2976 38.2976 0 0 0 37.74464 38.4h413.32736c21.56544 0 37.74464-16.44544 37.74464-38.4a38.2976 38.2976 0 0 0-37.74464-38.4z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveOfficeSuppliesAuditOfficeSuppliesAsset.defaultProps = {
  size: 18,
};

export default IconAExecutiveOfficeSuppliesAuditOfficeSuppliesAsset;
