/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const ClipboardCode24Filled: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M906.57526518 797.83405037V393.42029392H117.42473482v403.89594074A128.93601185 128.93601185 0 0 0 246.36074667 926.25224651h531.27850666a128.93601185 128.93601185 0 0 0 128.93601185-128.41819614z m-501.24521835-116.50844445l-112.8837381 118.06189038a26.40858075 26.40858075 0 0 1-37.80051672 0 30.55110281 30.55110281 0 0 1 0-41.42522429l91.65330963-98.90272476L253.09234608 563.78153127a31.06891852 31.06891852 0 0 1 0-41.94304 27.44421095 27.44421095 0 0 1 38.83614815 0l114.4371828 119.61533629a30.55110281 30.55110281 0 0 1 8.28504535 20.71261154 32.10454873 32.10454873 0 0 1-9.32067555 19.15916682z m346.41844148 126.86475022h-281.69152831a28.99765689 28.99765689 0 0 1-29.51547259-29.51547259 29.51547259 29.51547259 0 0 1 29.51547259-29.5154726h281.69152831a30.03328831 30.03328831 0 0 1 29.51547259 29.5154726 29.51547259 29.51547259 0 0 1-29.51547259 29.51547259zM117.42473482 334.38934873h789.15053036V245.84293095A144.47047111 144.47047111 0 0 0 765.72950164 97.74775349H258.27049836a144.47047111 144.47047111 0 0 0-140.84576354 148.09517746z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default ClipboardCode24Filled;

