/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconKehuguanliLinear: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M793.58016 511.9872c95.434414 0 175.61161 65.278368 198.343842 153.59616h-80.996375a128.047999 128.047999 0 0 0-111.331617-76.670083L793.58016 588.78528H230.39424a127.9968 127.9968 0 0 0-127.868803 122.441739L102.39744 716.78208v102.397441a127.9968 127.9968 0 0 0 122.441739 127.868803L230.39424 947.176321h281.59296v72.138996c0 1.58716 0.051199 3.148721 0.179196 4.684683L230.39424 1023.974401a204.79488 204.79488 0 0 1-204.79488-204.79488v-102.397441a204.79488 204.79488 0 0 1 204.79488-204.79488h563.18592z m187.20812 204.79488c23.833004 0 43.18612 18.764331 43.186121 41.880553v223.431215c0 23.116222-19.353116 41.880553-43.21172 41.880553H606.39764C582.539037 1023.974401 563.18592 1005.21007 563.18592 982.093848v-223.431215c0-23.116222 19.353116-41.880553 43.21172-41.880553h374.39064zM947.176321 793.58016H639.984v153.596161h307.192321v-153.596161zM471.540211 0a230.39424 230.39424 0 0 1 230.394241 230.39424 230.39424 230.39424 0 1 1-230.394241-230.39424z m0 76.79808a153.59616 153.59616 0 1 0 0 307.19232 153.59616 153.59616 0 0 0 0-307.19232z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconKehuguanliLinear.defaultProps = {
  size: 18,
}

export default IconKehuguanliLinear
