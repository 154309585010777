/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBianji: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M318.976 565.973333a8.533333 8.533333 0 0 0-1.664 2.901334L265.941333 763.306667a34.133333 34.133333 0 0 0 8.192 32.128 31.616 31.616 0 0 0 31.018667 8.362666l187.008-52.650666c0.298667 0 0.426667 0.298667 0.682667 0.298666 2.133333 0 4.266667-0.853333 5.845333-2.517333L998.826667 232.746667c14.890667-15.36 23.04-36.266667 23.04-59.008 0-25.813333-10.624-51.584-29.184-70.698667L945.493333 54.229333a96.426667 96.426667 0 0 0-68.48-30.122666c-22.058667 0-42.325333 8.405333-57.173333 23.722666L319.658667 564.181333c-0.512 0.469333-0.384 1.194667-0.725334 1.792M949.888 182.186667L900.266667 233.429333l-80.512-84.48 48.938666-50.517333c7.765333-8.021333 22.741333-6.869333 31.658667 2.346667l47.274667 48.853333c4.906667 5.077333 7.722667 11.818667 7.722666 18.517333a20.053333 20.053333 0 0 1-5.418666 14.08M407.893333 574.037333l360.917334-372.565333 80.554666 84.48-360.234666 371.882667-81.237334-83.797334z m-65.706666 151.04l26.026666-98.816 69.546667 71.808-95.573333 27.008z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M981.589333 386.773333c-18.944 0-34.474667 15.872-34.56 35.669334v481.28c0 25.216-19.84 45.738667-44.330666 45.738666H114.005333c-24.448 0-44.373333-20.48-44.373333-45.738666V118.229333c0-25.301333 19.925333-45.824 44.373333-45.824h507.989334a35.072 35.072 0 0 0 34.474666-35.584 35.114667 35.114667 0 0 0-34.474666-35.626666H108.757333C49.152 1.194667 0.64 51.242667 0.64 112.810667v796.330666c0 61.568 48.512 111.573333 108.117333 111.573334h799.146667c59.690667 0 108.16-50.005333 108.16-111.573334V422.229333a35.114667 35.114667 0 0 0-34.474667-35.456"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconBianji.defaultProps = {
  size: 18,
};

export default IconBianji;
