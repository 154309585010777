/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveOfficeSuppliesMyRequisition: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M819.2 614.4c113.11104 0 204.8 91.68896 204.8 204.8s-91.68896 204.8-204.8 204.8-204.8-91.68896-204.8-204.8 91.68896-204.8 204.8-204.8z m-521.3184-59.5968a128.6144 128.6144 0 0 0 123.78112 128.4096l4.7104 0.08192h160.768A285.45024 285.45024 0 0 0 552.96 819.2c0 60.928 19.00544 117.41184 51.4048 163.86048L137.3184 983.04a53.59616 53.59616 0 0 1-53.43232-50.13504l-0.1024-3.39968V554.82368h214.09792z m521.35936 134.06208c-29.2864 0-53.00224 25.6-53.00224 57.1392 0 26.03008 16.09728 47.96416 38.15424 54.84544-54.4768 7.8848-96.91136 58.53184-96.91136 119.35744v0.75776c0 9.8304 1.1264 19.39456 3.19488 28.5696H927.744c2.06848-9.17504 3.19488-18.75968 3.19488-28.5696v-0.75776c0.04096-60.8256-42.33216-111.47264-96.82944-119.35744 22.07744-6.92224 38.17472-28.8768 38.17472-54.84544 0-31.5392-23.73632-57.1392-53.02272-57.1392z m-135.94624-134.0416a85.72928 85.72928 0 0 1-85.64736 85.64736h-171.29472a85.72928 85.72928 0 0 1-85.64736-85.64736h342.58944zM983.04 340.70528V512H40.96v-171.29472h942.08zM755.95776 40.96a85.48352 85.48352 0 0 1 71.2704 38.13376l145.85856 218.78784H50.91328L196.77184 79.09376A85.46304 85.46304 0 0 1 268.04224 40.96z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveOfficeSuppliesMyRequisition.defaultProps = {
  size: 18,
};

export default IconAExecutiveOfficeSuppliesMyRequisition;
