/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconKehuguanli1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M793.58016 511.9872c95.434414 0 175.61161 65.278368 198.343842 153.59616H564.79868c-27.903302 0-50.737932 22.885828-52.683483 51.864304l-0.127997 3.9935v297.874153c0 1.58716 0.051199 3.148721 0.179196 4.684683L230.39424 1023.974401a204.79488 204.79488 0 0 1-204.79488-204.79488v-102.397441a204.79488 204.79488 0 0 1 204.79488-204.79488h563.18592z m187.20812 204.79488c23.833004 0 43.18612 18.764331 43.186121 41.880553v223.431215c0 23.116222-19.353116 41.880553-43.21172 41.880553H606.39764C582.539037 1023.974401 563.18592 1005.21007 563.18592 982.093848v-223.431215c0-23.116222 19.353116-41.880553 43.21172-41.880553h374.39064zM755.18112 793.58016h-76.79808a38.39904 38.39904 0 0 0 0 76.798081h76.79808a38.39904 38.39904 0 0 0 0-76.798081zM471.565811 0a230.39424 230.39424 0 0 1 230.39424 230.39424 230.39424 230.39424 0 1 1-230.39424-230.39424z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  )
}

IconKehuguanli1.defaultProps = {
  size: 18,
}

export default IconKehuguanli1
