/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAWorksLogStatistics: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M728.18688 0c24.12544 0 47.28832 9.80992 64.34816 27.25888A94.16704 94.16704 0 0 1 819.2 93.0816V515.8912A266.8544 266.8544 0 0 0 773.69344 512c-150.81472 0-273.08032 125.05088-273.08032 279.26528 0 97.15712 48.49664 182.6816 122.0608 232.73472H91.01312C40.7552 1024 0 982.3232 0 930.9184V93.0816C0 41.6768 40.7552 0 91.01312 0h637.17376z m59.1872 584.43776v230.5024c0 5.40672 3.80928 9.89184 8.8064 10.83392l1.9456 0.18432h214.016C1001.34912 937.14432 909.66016 1024 798.1056 1024c-118.784 0-215.10144-98.5088-215.10144-220.0576 0-117.84192 90.58304-213.72928 204.34944-219.50464zM819.65056 573.44c111.84128 5.67296 201.27744 98.44736 204.26752 213.52448l0.08192 5.98016h-204.34944V573.44z m-502.70208-116.3264h-111.67744c-26.86976 0-48.61952 21.97504-48.61952 49.11104 0 27.136 21.74976 49.13152 48.61952 49.13152h111.67744c26.84928 0 48.59904-21.99552 48.59904-49.13152s-21.74976-49.11104-48.59904-49.11104zM556.83072 203.24352H205.27104c-26.86976 0-48.61952 21.99552-48.61952 49.152 0 27.11552 21.87264 49.11104 48.61952 49.11104h351.55968c26.84928 0 48.61952-21.99552 48.61952-49.13152S583.68 203.24352 556.83072 203.24352z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAWorksLogStatistics.defaultProps = {
  size: 18,
};

export default IconAWorksLogStatistics;
