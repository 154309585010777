/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHumanResourceEmployeeOutLogCreate: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M634.83904 28.672v202.24c-56.40192-91.79136-145.32608-136.88832-226.85696-112.4352-4.21888 1.2288-8.41728 2.51904-12.5952 3.87072-47.73888 15.872-71.8848 49.152-67.11296 103.0144 2.31424 26.29632-6.00064-8.37632 37.70368 166.52288 26.48064 105.86112 32.80896 161.83296 31.0272 233.90208-0.14336 7.49568-0.3072 11.6736-0.86016 28.28288a1616.13824 1616.13824 0 0 0-1.24928 60.8256c0 32.60416 8.2944 67.25632 24.76032 104.30464H143.36a61.44 61.44 0 0 1-61.44-61.44V81.92a61.44 61.44 0 0 1 61.44-61.44h460.8c11.18208 0 21.66784 2.9696 30.67904 8.192zM425.65632 174.08c51.24096-15.36 122.88 15.36 168.96 107.52S619.52 450.56 696.32 450.56V51.2c235.52 184.32 245.76 291.84 245.76 399.36v245.76c0 45.2608-28.5696 165.74464-30.72 215.04-1.26976 28.81536 11.81696 61.93152 30.72 92.16H635.4944c-119.3984-122.88-179.13856-220.16-179.13856-291.84 0-107.52 15.36-153.6-30.72-337.92s-51.24096-184.32 0-199.68z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAHumanResourceEmployeeOutLogCreate.defaultProps = {
  size: 18,
};

export default IconAHumanResourceEmployeeOutLogCreate;
