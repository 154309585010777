/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBangzhuyufankui: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.562537 958.708971c-246.391666 0-446.862467-200.387913-446.862467-446.705901 0-119.341039 46.50722-231.570099 130.967844-316.044026 84.624353-84.283592 196.798155-130.669038 315.894623-130.669038 89.887216 0 176.590927 26.63973 250.77244 77.04984C885.02018 225.671763 958.275601 363.873358 958.275601 512.00307c0 11.014868-0.38067 20.98187-1.169639 30.474059C941.726693 775.673262 746.01529 958.708971 511.562537 958.708971zM511.562537 120.989585c-104.259552 0-202.468294 40.619116-276.553617 114.391307-73.880661 73.90829-114.609271 172.157965-114.609271 276.622178 0 215.612663 175.475524 391.005298 391.161864 391.005298 205.216895 0 376.516306-160.189376 389.993249-364.693026 0.707105-8.402367 1.019213-16.863063 1.019213-26.312272 0-129.662105-64.130599-250.635317-171.546028-323.591933C666.123728 144.298454 590.231247 120.989585 511.562537 120.989585z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M177.4459 551.812751c-7.179516 0-13.272281-5.494131-13.870916-12.774955-0.733711-8.962116-1.101077-18.059308-1.101077-27.033703 0-7.696286 6.227842-13.925151 13.925151-13.925151 7.682983 0 13.925151 6.228865 13.925151 13.925151 0 8.213056 0.339738 16.548908 1.00591 24.749684 0.639566 7.668657-5.072529 14.380522-12.742209 15.033391C178.207239 551.812751 177.82657 551.812751 177.4459 551.812751z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M302.130643 775.591397c-2.882654 0-5.792936-0.89744-8.281617-2.746554-55.169507-40.932248-95.29846-96.958262-116.063389-162.042583-2.339278-7.314593 1.713015-15.148002 9.029654-17.486256 7.343245-2.365884 15.162328 1.713015 17.501606 9.027607 18.983353 59.481719 55.672974 110.693078 106.124016 148.144038 6.173606 4.569062 7.465019 13.298887 2.882654 19.472494C310.589292 773.632789 306.387597 775.591397 302.130643 775.591397z"
        fill={getIconColor(color, 2, '#333333')}
      />
      <path
        d="M505.062496 698.595792c-15.380292 0-27.850301-12.470009-27.850301-27.849278L477.212194 534.522969c0-15.379269 12.470009-27.850301 27.850301-27.850301 64.796772 0 110.053512-45.140083 110.053512-109.780289 0-61.376882-45.759183-109.454878-104.179734-109.454878-58.915831 0-103.34881 46.758953-103.34881 108.768239l0 23.899316c0 15.380292-12.470009 27.849278-27.850301 27.849278s-27.849278-12.470009-27.849278-27.849278l0-23.899316c0-92.225471 69.862137-164.467819 159.048389-164.467819 89.648786 0 159.878291 72.541153 159.878291 165.154457 0 84.821851-57.595766 150.882406-137.903813 163.353438L532.910751 670.746514C532.911774 686.125783 520.441764 698.595792 505.062496 698.595792z"
        fill={getIconColor(color, 3, '#333333')}
      />
      <path
        d="M532.204669 749.808174c0 14.875802-12.035104 26.897603-26.897603 26.897603-14.836917 0-26.898627-12.021801-26.898627-26.897603 0-14.821567 12.06171-26.872021 26.898627-26.872021C520.170588 722.937177 532.204669 734.986607 532.204669 749.808174z"
        fill={getIconColor(color, 4, '#333333')}
      />
    </svg>
  );
};

IconBangzhuyufankui.defaultProps = {
  size: 18,
};

export default IconBangzhuyufankui;
