/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const Biangengshenqing: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M585.817043 0v292.574609c0 53.871304 44.076522 97.502609 98.393044 97.502608H979.478261V877.746087c0 53.871304-44.076522 97.502609-98.415304 97.502609H545.947826a314.768696 314.768696 0 0 0 33.257739-398.625392c-85.593043-123.904-247.206957-171.27513-387.049739-113.463652V97.52487C192.155826 43.653565 236.187826 0 290.54887 0h295.268173z m73.817044 24.375652v268.198957a24.486957 24.486957 0 0 0 24.598261 24.375652h270.647652L659.634087 24.375652zM44.521739 755.801043c0 95.833043 51.578435 184.364522 135.323826 232.269914a272.784696 272.784696 0 0 0 270.647652 0c83.745391-47.905391 135.323826-136.45913 135.323826-232.269914 0-148.101565-121.165913-268.176696-270.647652-268.176695S44.521739 607.699478 44.521739 755.801043z m418.281739-170.651826a24.486957 24.486957 0 0 1 24.598261 24.375653v97.502608a24.486957 24.486957 0 0 1-24.620522 24.397913h-98.393043a24.486957 24.486957 0 0 1-24.620522-24.375652 24.486957 24.486957 0 0 1 24.598261-24.375652h49.196522a123.213913 123.213913 0 0 0-98.393044-48.751304 123.169391 123.169391 0 0 0-99.728695 50.509913 24.709565 24.709565 0 0 1-42.807653-4.051479 24.197565 24.197565 0 0 1 2.938435-24.531478 172.521739 172.521739 0 0 1 139.575652-70.700522c48.239304 0 91.803826 19.634087 123.035827 51.2V609.502609a24.486957 24.486957 0 0 1 24.59826-24.375652zM192.133565 875.27513v26.824348a24.486957 24.486957 0 0 1-24.620522 24.375652 24.486957 24.486957 0 0 1-24.59826-24.375652v-97.524869a24.486957 24.486957 0 0 1 24.59826-24.375652h98.415305a24.486957 24.486957 0 0 1 24.620522 24.375652 24.486957 24.486957 0 0 1-24.620522 24.375652h-49.196522a123.14713 123.14713 0 0 0 98.393044 48.751304c37.709913 0 71.457391-16.762435 94.052173-43.297391a24.754087 24.754087 0 0 1 34.415305-2.582261c10.24 8.592696 11.664696 23.730087 3.183304 34.05913a172.521739 172.521739 0 0 1-131.628522 60.594087 172.521739 172.521739 0 0 1-123.013565-51.2z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default Biangengshenqing;

