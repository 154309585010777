/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IcFluentDataPie24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M437.333333 181.333333A32 32 0 0 1 469.333333 213.333333v341.333334h341.333334a32 32 0 0 1 31.701333 27.648l0.298667 4.352c0 212.053333-171.946667 362.666667-384 362.666666a384 384 0 0 1-384-384c0-212.053333 150.613333-384 362.666666-384zM405.333333 246.912l-8.917333 1.024c-157.44 20.053333-257.749333 154.538667-257.749333 317.397333a320 320 0 0 0 320 320c162.858667 0 297.301333-100.352 317.44-257.749333l0.938666-8.917333H437.333333a32 32 0 0 1-31.701333-27.648L405.333333 586.666667V246.912z m160-172.245333a384 384 0 0 1 384 384 32 32 0 0 1-32 32h-352a32 32 0 0 1-32-32V106.666667a32 32 0 0 1 32-32zM597.333333 140.245333V426.666667h286.378667l-0.981333-8.917334a320.170667 320.170667 0 0 0-276.48-276.48L597.333333 140.245333z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
 );
};

export default IcFluentDataPie24Regular;

