/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react'
import { getIconColor } from './helper'

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number
  color?: string | string[]
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
}

const IconBanbenliebiao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M836 956H188a90.1 90.1 0 0 1-90-90V158a90.1 90.1 0 0 1 90-90h648a90.1 90.1 0 0 1 90 90v708a90.1 90.1 0 0 1-90 90zM188 128a30 30 0 0 0-30 30v708a30 30 0 0 0 30 30h648a30 30 0 0 0 30-30V158a30 30 0 0 0-30-30z m118.5 146.5h-85v85h85z m496 22h-447v60h447z m-496 156h-85v85h85z m496 22h-447v60h447z m-496 190h-85v85h85z m496 22h-447v60h447z"
        fill={getIconColor(color, 0, '#0089FF')}
      />
    </svg>
  )
}

IconBanbenliebiao.defaultProps = {
  size: 18,
}

export default IconBanbenliebiao
