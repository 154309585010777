/* tslint:disable */
/* eslint-disable */
/* 修复 defaultProps 被废弃，控制台一片报错的问题 */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const GridDots24Regular: FunctionComponent<Props> = ({ size = 18, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 746.666667a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m298.666667 0a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m-597.333334 0a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m298.666667-298.666667a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m298.666667 0a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m-597.333334 0a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m298.666667-298.666667a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m298.666667 0a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m-597.333334 0a64 64 0 1 1 0 128 64 64 0 0 1 0-128z"
        fill={getIconColor(color, 0, '#212121')}
      />
    </svg>
 );
};

export default GridDots24Regular;

