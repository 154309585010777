/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFinancialReceiptManualClaimed: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M6.21015 812.19584c0.65536 0-14.29504-17.36704 0-28.7744 8.88832-7.3728 76.47232-69.95968 202.93632-187.8016a98.05824 98.05824 0 0 1 22.40512-15.83104 57.18016 57.18016 0 0 1 26.25536-6.38976h362.7008s50.66752 4.01408 49.9712 50.81088c-0.6144 29.53216-18.8416 56.2176-49.27488 62.93504-27.29984 4.64896-91.66848 10.01472-137.728 13.312-16.95744 1.39264 0 12.71808 0 12.71808l44.11392 32.27648c10.5472 7.74144 23.3472 11.91936 36.43392 11.91936H733.18871a61.6448 61.6448 0 0 0 36.90496-12.288 3999.92832 3999.92832 0 0 0 124.49792-96.09216c90.25536-66.82624 124.6208 0 124.6208 0s11.12064 24.08448-0.6144 37.49888c-31.8464 29.696-106.3936 98.73408-223.70304 206.88896a61.72672 61.72672 0 0 1-28.0576 14.7456l1.39264-0.26624c-9.66656 2.19136-19.61984 3.2768-29.53216 3.2768-254.85312-0.43008-391.45472-0.32768-409.72288 0-27.93472 0-47.4112 18.80064-47.4112 18.80064l-70.06208 62.8736c-28.63104 24.08448-44.2368 0-44.2368 0L6.21015 812.19584z m669.12256-284.81536a125.60384 125.60384 0 0 0-54.8864-15.36h-59.1872c2.58048-4.5056 0-9.68704 0-14.90944V433.7664h124.17024v-61.37856h-124.17024v-53.61664h124.17024v-61.48096h-110.61248l102.2976-101.76512-21.99552-21.97504-21.504-21.44256-101.92896 100.80256-94.49472-100.59776-21.11488 20.95104-22.46656 22.44608 95.76448 101.62176h-113.60256v61.44h123.82208v53.5552H375.81271v61.48096h123.84256v63.26272c0 5.48864-2.39616 10.52672 0 14.99136H285.04535c-101.80608-135.31136-74.752-327.22944 60.53888-429.32224C480.85463-19.33312 673.44855 6.81984 776.40151 141.25056c102.97344 134.4512 77.55776 326.59456-56.832 429.81376a107.9296 107.9296 0 0 0-44.2368-43.6224v-0.06144z"
        fill={getIconColor(color, 0, '#515151')}
      />
    </svg>
  );
};

IconAFinancialReceiptManualClaimed.defaultProps = {
  size: 18,
};

export default IconAFinancialReceiptManualClaimed;
