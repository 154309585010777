/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconDateRange: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 976.54166666C279.01953125 976.54166666 90.125 787.64713541 90.125 554.66666666S279.01953125 132.79166666 512 132.79166666s421.875 188.89453125 421.875 421.875-188.89453125 421.875-421.875 421.875z m35.15625027-401.16796848V378.77994818C547.15625027 359.65494791 531.40625 343.72916666 512 343.72916666c-19.54687527 0-35.15625027 15.67968723-35.15625027 35.05078152v211.1484375a34.59375 34.59375 0 0 0 10.05468777 24.60937446l99.91406277 99.91406277a34.91015625 34.91015625 0 0 0 49.46484375-0.24609402 35.15625027 35.15625027 0 0 0 0.24609321-49.46484375L547.15625027 575.37369818z"
        fill={getIconColor(color, 0, '#3D3D3D')}
      />
    </svg>
  );
};

IconDateRange.defaultProps = {
  size: 18,
};

export default IconDateRange;
