/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconABusinessConsultantCasesManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M856.84224 551.0144c69.48864 0.49152 125.70624 57.30304 126.19776 127.52896v278.97856A26.0096 26.0096 0 0 1 957.78816 983.04h-275.8656c-69.46816-0.49152-125.68576-57.30304-126.1568-127.52896V576.55296a26.0096 26.0096 0 0 1 25.25184-25.53856z m-413.7984-0.04096a26.0096 26.0096 0 0 1 25.25184 25.51808v279.01952c-0.57344 70.16448-56.75008 126.89408-126.19776 127.3856H66.23232A26.0096 26.0096 0 0 1 40.96 957.35808V678.54336c0.49152-70.22592 56.70912-127.03744 126.19776-127.52896h275.8656z m397.9264-486.6048c86.016 36.02432 142.09024 120.85248 142.06976 214.95808-0.16384 128.36864-103.09632 232.38656-230.11328 232.5504-93.10208 0-177.0496-56.64768-212.6848-143.5648-35.6352-86.9376-15.95392-186.9824 49.88928-253.5424a228.41344 228.41344 0 0 1 250.83904-50.3808zM342.09792 40.96c69.50912 0.49152 125.70624 57.30304 126.19776 127.52896V447.2832a26.0096 26.0096 0 0 1-25.25184 25.51808H167.15776C97.66912 472.33024 41.45152 415.5392 40.96 345.2928V66.47808A26.0096 26.0096 0 0 1 66.21184 40.96z m386.6624 128.79872c-14.5408 0-26.35776 11.91936-26.35776 26.624v127.73376c0.04096 7.9872 3.584 15.52384 9.68704 20.5824 6.10304 5.05856 14.1312 7.10656 21.87264 5.59104v0.16384c1.6384 0.32768 3.31776 0.49152 4.97664 0.49152h120.25856c14.5408 0 26.35776-11.91936 26.35776-26.624 0-14.72512-11.79648-26.624-26.35776-26.624H755.0976V196.36224c0-14.70464-11.79648-26.624-26.35776-26.624z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconABusinessConsultantCasesManage.defaultProps = {
  size: 18,
};

export default IconABusinessConsultantCasesManage;
