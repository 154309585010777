/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconACustomersMyCustomersCreate: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M837.8368 652.73856c-23.28576 10.5472-46.57152 15.83104-69.8368 15.83104-25.6 0-51.2-12.77952-76.8-25.55904-44.81024-25.55904-76.8-76.6976-70.41024-127.81568 0-83.08736 70.41024-153.3952 153.6-153.3952 83.21024 0 153.6 70.30784 153.6 153.3952 0 57.52832-38.37952 108.6464-83.18976 134.20544a2.08896 2.08896 0 0 1 0.12288-0.04096l0.4096-0.12288c2.88768-0.6144 18.1248-1.10592 63.46752 38.5024C960 732.50816 1017.61024 847.54432 1024 962.56H0c12.8-217.2928 121.61024-396.24704 262.41024-460.14464a221.24544 221.24544 0 0 0 115.17952 31.9488c38.4 0 76.8-6.38976 108.81024-31.9488 0 0 19.21024-6.38976 96.01024 57.50784 25.6 25.57952 57.58976 63.91808 83.18976 95.86688 6.41024-6.38976 19.21024-6.38976 25.6-12.77952 19.21024 19.16928 44.81024 25.55904 76.8 25.55904 32.01024 0 57.61024-6.38976 76.8-19.16928z m-44.2368 35.0208c-6.41024-6.38976-38.4-6.38976-44.81024 0l12.82048 25.55904-25.6 146.98496c6.38976 19.16928 12.77952 31.9488 12.77952 51.13856l19.21024 19.16928 38.4-38.35904-32.01024-178.93376z m-377.61024-134.22592c-12.8-12.77952-64-12.77952-70.38976 0l25.6 38.35904-51.2 262.02112 57.61024 57.52832 57.58976-57.52832-51.2-262.02112zM377.61024 61.44c128 0 230.4 102.25664 230.4 230.07232 0 89.47712-51.2 159.76448-121.58976 198.12352-32.01024 19.16928-70.41024 31.9488-108.81024 31.9488-38.4 0-83.18976-12.77952-115.2-31.9488-70.38976-38.35904-115.17952-115.03616-115.17952-198.12352 0-127.81568 102.4-230.07232 230.4-230.07232z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconACustomersMyCustomersCreate.defaultProps = {
  size: 18,
};

export default IconACustomersMyCustomersCreate;
