/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconPingtaiguanli: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M85.333 725.333h853.248c0.256 0 0.086-597.333 0.086-597.333H85.419c-0.256 0-0.086 597.333-0.086 597.333zM0 128c0-47.147 37.973-85.333 85.419-85.333H938.58A85.333 85.333 0 0 1 1024 128v597.333c0 47.19-37.973 85.334-85.419 85.334H85.42A85.333 85.333 0 0 1 0 725.333V128z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M725.248 554.667c0.085-0.171-426.496 0-426.496 0-0.085 0.17 426.496 0 426.496 0z m-469.248 0C256 531.115 275.456 512 298.752 512h426.496a42.624 42.624 0 1 1 0 85.333H298.752A42.624 42.624 0 0 1 256 554.667z m298.581-256c0.086-0.171-255.829 0-255.829 0-0.085 0.17 255.83 0 255.83 0z m-298.581 0C256 275.115 275.456 256 298.752 256h255.83a42.624 42.624 0 1 1 0 85.333h-255.83A42.624 42.624 0 0 1 256 298.667zM469.333 725.333h85.334v213.334h-85.334z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M298.667 896h426.666v85.333H298.667V896z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconPingtaiguanli.defaultProps = {
  size: 18,
};

export default IconPingtaiguanli;
