/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAExecutiveExpressExpressManage: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M1024 725.79072a96.41984 96.41984 0 0 1-53.10464 86.28224s-261.09952 130.8672-374.8864 187.61728c-23.90016 11.96032-52.0192-5.5296-52.0192-32.44032V472.3712c0-20.39808 12.8-38.56384 31.80544-45.40416l137.4208-49.31584a3.9936 3.9936 0 0 1 5.28384 3.80928v198.08256c0 5.9392 6.30784 9.8304 11.59168 7.22944l43.6224-21.9136a16.11776 16.11776 0 0 0 8.78592-14.336v-192.16384c0-3.39968 2.10944-6.41024 5.30432-7.51616l203.79648-73.1136a24.04352 24.04352 0 0 1 32.09216 22.69184C1024 406.77376 1024 725.79072 1024 725.79072zM479.98976 472.1664v492.56448c0 27.32032-29.0816 44.81024-52.98176 31.8464L50.5856 793.57952A96.54272 96.54272 0 0 1 0 708.608s0.1024-305.64352 0.3072-409.8048c0-16.67072 16.4864-28.32384 32.09216-22.58944l415.90784 150.54848a48.25088 48.25088 0 0 1 31.68256 45.40416z m498.8928-258.12992l-207.872 74.62912a16.4864 16.4864 0 0 1-11.40736-0.2048L300.19584 101.23264a4.05504 4.05504 0 0 1 0.1024-7.53664L478.4128 26.624A95.21152 95.21152 0 0 1 512.1024 20.48c11.40736 0 22.69184 2.00704 33.5872 6.02112l415.90784 155.89376c7.20896 2.70336 13.90592 6.22592 20.11136 10.3424a12.0832 12.0832 0 0 1-2.80576 21.2992zM655.62624 315.1872c6.79936 2.80576 6.59456 12.55424-0.3072 14.9504l-110.8992 39.77216a95.41632 95.41632 0 0 1-64.90112-0.08192L47.5136 213.31968a12.0832 12.0832 0 0 1-2.39616-21.504c5.40672-3.2768 11.10016-6.20544 17.2032-8.51968L196.4032 132.87424a15.44192 15.44192 0 0 1 11.59168 0.2048l447.61088 182.10816z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconAExecutiveExpressExpressManage.defaultProps = {
  size: 18,
};

export default IconAExecutiveExpressExpressManage;
