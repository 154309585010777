/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconLiebiao: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M506.624 207.9744h401.152c17.664 0 32-14.336 32-32s-14.336-32-32-32H506.624c-17.664 0-32 14.336-32 32 0 17.7152 14.336 32 32 32zM907.776 591.9744H506.624c-17.664 0-32 14.336-32 32s14.336 32 32 32h401.152c17.664 0 32-14.336 32-32s-14.336-32-32-32zM907.776 816.0256H506.624c-17.664 0-32 14.336-32 32s14.336 32 32 32h401.152c17.664 0 32-14.336 32-32 0-17.7152-14.336-32-32-32zM907.776 368.0256H506.624c-17.664 0-32 14.336-32 32s14.336 32 32 32h401.152c17.664 0 32-14.336 32-32 0-17.7152-14.336-32-32-32zM362.5472 117.504H109.3632c-31.488 0-57.1392 25.6512-57.1392 57.1392v253.184c0 31.488 25.6512 57.1392 57.1392 57.1392h253.184c31.488 0 57.1392-25.6512 57.1392-57.1392V174.592c-0.0512-31.488-25.6512-57.088-57.1392-57.088z m-6.912 303.4112H116.224V181.504h239.4112v239.4112zM362.5472 528.128H109.3632c-31.488 0-57.1392 25.6512-57.1392 57.1392v253.184c0 31.488 25.6512 57.1392 57.1392 57.1392h253.184c31.488 0 57.1392-25.6512 57.1392-57.1392V585.216c-0.0512-31.488-25.6512-57.088-57.1392-57.088z m-6.912 303.4112H116.224v-239.4112h239.4112v239.4112z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconLiebiao.defaultProps = {
  size: 18,
};

export default IconLiebiao;
